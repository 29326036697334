import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SnackbarContainer, getColorSnackBar } from './styled';
import { AiFillCheckCircle, AiFillWarning } from 'react-icons/ai';
import { RiLoader2Line } from 'react-icons/ri';
import { IoIosClose } from 'react-icons/io';
import { FiAlertOctagon } from 'react-icons/fi';

let snackbarRoot = document.getElementById('snackbar-root');

if (!snackbarRoot) {
  snackbarRoot = document.createElement('div');
  snackbarRoot.setAttribute('id', 'snackbar-root');
  document.body.insertBefore(snackbarRoot, document.body.firstChild);
}

function CustomSnackbar({ message, closeSnackbar, typeMessage }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (message) {
      setOpen(true);
      const timer = setTimeout(() => {
        setOpen(false);
      }, 3500);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const iconsMessage = {
    error: <AiFillWarning color="#DA0505" size={18} />,
    confirmation: <AiFillCheckCircle color="#068E2C" size={15} />,
    alert: <FiAlertOctagon color="#E9B600" size={18} />,
    loading: <RiLoader2Line color="#606062" size={18} />,
  };

  return (
    <SnackbarContainer show={open} typeMessage={typeMessage}>
      <div className="message-icon">
        <div>{iconsMessage[typeMessage]}</div>
        {message}
      </div>
      <button className="close-button" onClick={() => closeSnackbar()}>
        <IoIosClose color={getColorSnackBar[typeMessage]} size={20} />
      </button>
    </SnackbarContainer>
  );
}

/**
 * customSnackbar function to display a Snackbar with a message and an optional type.
 * @param {string} message - The message to be displayed in the Snackbar.
 * @param {string} typeMessage - The type of the message (error, confirmation, alert, loading).
 */

export function customSnackbar(message, typeMessage) {
  while (snackbarRoot.firstChild) {
    snackbarRoot.removeChild(snackbarRoot.firstChild);
  }

  const snackbarElement = document.createElement('div');
  snackbarRoot.appendChild(snackbarElement);

  const closeSnackbar = () => {
    if (snackbarRoot.contains(snackbarElement)) {
      snackbarRoot.removeChild(snackbarElement);
    }
  };

  ReactDOM.render(
    <CustomSnackbar
      message={message}
      typeMessage={typeMessage}
      closeSnackbar={closeSnackbar}
    />,
    snackbarElement
  );
  setTimeout(closeSnackbar, 3500);
}
