import {
  Box,
  CloseButton,
  Content,
  TopBar,
  Wrapper,
  ContainerContent,
} from './styled';
import { IoClose } from 'react-icons/io5';
import { useState, useRef, useEffect } from 'react';

import { ModalStepsFinalizeJob } from './modalStepsFinalizeJob';
import { getFinalCandidates } from 'views/JobOfferRebuild/services';
import {
  getFinalCandidatesIES,
  getJobsOpenings,
} from 'views/StaffPages/jobOpeningsRebuild/services';
import JobOfferProvider from 'views/JobOfferRebuild/Contexts/JobOfferContext';

export function ModalFinalization(props) {
  const {
    visible,
    controlModal,
    setModalFinish,
    jobID,
    status,
    isPartnerOffer,
    university,
    company,
    closeModal,
    activeTab,
    setListJobs,
    setJobsInstitution,
    setListJobsCompany,
    setListJobsCompanyHistory,
    setJobsCount,
    companyFilters,
    universityFilters,
    setJobsCountInstitution,
    setJobsCountPartners,
    setCountPublishedJobs,
    setCountMyJobs,
    countJobs,
    setCountProgressJobs,
    dataFunction,
  } = props;
  const [candidates, setCandidates] = useState([]);

  const getCandidates = async (jobID, university) => {
    const isPartner = isPartnerOffer;
    if (university) {
      const optionsCandidates = await getFinalCandidatesIES(jobID, isPartner);
      setCandidates(optionsCandidates);
      getJobsOpenings();
    } else {
      const optionsCandidates = await getFinalCandidates(jobID, isPartner);
      setCandidates(optionsCandidates);
    }
  };

  useEffect(() => {
    getCandidates(jobID, university);
  }, []);

  return (
    <JobOfferProvider>
      <Wrapper visible={visible}>
        <Box>
          <Content>
            <TopBar>
              <p className="title">Finalizar vaga</p>
              <CloseButton type="button" onClick={() => controlModal(false)}>
                <IoClose size={18} />
              </CloseButton>
            </TopBar>

            <ContainerContent>
              <ModalStepsFinalizeJob
                optionsCandidate={candidates}
                jobID={jobID}
                status={status}
                closeModal={closeModal}
                university={university}
                company={company}
                setModalFinish={setModalFinish}
                activeTab={activeTab}
                setListJobs={setListJobs}
                setJobsInstitution={setJobsInstitution}
                setListJobsCompany={setListJobsCompany}
                setListJobsCompanyHistory={setListJobsCompanyHistory}
                setJobsCount={setJobsCount}
                companyFilters={companyFilters}
                universityFilters={universityFilters}
                setJobsCountInstitution={setJobsCountInstitution}
                setJobsCountPartners={setJobsCountPartners}
                setCountPublishedJobs={setCountPublishedJobs}
                setCountMyJobs={setCountMyJobs}
                countJobs={countJobs}
                setCountProgressJobs={setCountProgressJobs}
                dataFunction={dataFunction}
              />
            </ContainerContent>
          </Content>
        </Box>
      </Wrapper>
    </JobOfferProvider>
  );
}
