export default function EveningIcon({ color }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3722 10.2326C17.2137 10.2615 17.0553 10.3554 16.9256 10.4566C15.9461 11.2298 14.8514 11.7212 13.6197 11.8946C12.3737 12.0681 11.1709 11.9091 10.0257 11.4105C8.44842 10.724 7.28163 9.59667 6.55419 8.02856C5.86996 6.53994 5.74752 4.99351 6.15805 3.40372C6.38853 2.50765 6.79907 1.70553 7.36806 0.982901C7.56252 0.737207 7.56972 0.477059 7.41127 0.253043C7.26002 0.0362536 6.98633 -0.0504626 6.70544 0.0290269C5.61787 0.339758 4.61674 0.823923 3.71644 1.5032C1.68536 3.03518 0.475358 5.05854 0.100833 7.58775C0.0576188 7.86235 0.036012 8.14418 0 8.42601V9.37988C0.0144048 9.44492 0.0216072 9.50273 0.036012 9.56776C0.0864288 9.94353 0.115237 10.3265 0.194464 10.7023C0.705834 13.087 1.95185 15.0092 3.96132 16.3822C6.3093 17.9864 8.88776 18.3911 11.6175 17.6251C13.3533 17.1409 14.8153 16.1943 15.9965 14.8285C16.9761 13.694 17.6243 12.386 17.9772 10.9263C18.0852 10.5072 17.7971 10.1603 17.3722 10.2326ZM8.16752 16.7001C4.51591 16.2377 1.72858 13.2965 1.31084 9.78455C0.871489 6.04132 3.03221 3.04963 5.45942 1.84283C4.17739 5.2103 4.56632 8.33206 7.1952 10.8974C9.82408 13.4555 12.9355 13.7518 16.2702 12.3643C14.8081 15.2621 11.6319 17.1337 8.16752 16.7001Z"
        fill={color}
      />
    </svg>
  );
}
