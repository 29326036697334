import styled from 'styled-components';
// number of mentoring steps
export const Container = styled.div`
  width: auto;
  height: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${props => props.isMobile && 'margin-top: 1rem'}
`;

export const StepItem = styled.button`
  display: flex;
  width: 7.5rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: none;
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  background-color: #fff;
  transition: filter 0.2s;
  padding: 0.25rem 0 0;
  border-radius: 0.25rem;

  &:hover {
    filter: ${(props) => (props.active ? 'none' : 'brightness(0.95)')};
  }

  span {
    display: flex;
    width: 2.125rem;
    height: 2.125rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1875rem;
    font-weight: 700;
    background: ${(props) =>
      props.active ? props.universityColor : '#F5F5F5'};
    color: ${(props) => (props.active ? '#fff' : '#606062')};
  }

  &:after {
    content: '';
    height: 0.25rem;
    width: 100%;
    background: ${(props) =>
      props.active ? props.universityColor : '#F5F5F5'};
  }
`;
