import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import MentorshipModal from 'components/MentorshipModal';
import GenericPDFViewer from 'components/GenericPDFViewer';
import { useMentorship } from '../../contexts/MentorshipContext';
import {
  ActionsContainer,
  Container,
  DescriptionBox,
  FilledButton,
  Header,
  OutlinedButton,
  RatingContainer,
  RealizedMentorshipContainer,
  StarIcon,
  StudentNoteContainer,
  DropArea,
  ButtonFile,
  ContainerButton,
} from './styles';
import { Form } from '@unform/web';
import ModalitySelect from '../ScheduleMentorshipModal/ModalitySelect';
import Input from '../ScheduleMentorshipModal/Input';
import DatePickerCustom from '../ScheduleMentorshipModal/DatePickerCustom';
import TimePicker from '../ScheduleMentorshipModal/TimePicker';
import { Divider, StatusIndicator } from '../../styles';
import { handleFieldMask } from 'utils/validation';
import { maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';
import { leftPad } from 'utils/stringUtils';
import HtmlEditor from '../../../../Student/Mentorship/components/ModalRequestMentorship/HtmlEditor';
import SuccessModal from 'components/SuccessModal';
import { BsUpload } from 'react-icons/bs';
import BaseLayoutContext from 'contexts/base-layout';
import { GenerateBlob } from 'components/UploadEvidences/components/PdfViwer/generateBlob';
import PdfViwerMentorship from '../PdfViwerMentorship';
import DisplayDocument from 'components/DisplayDocument/DisplayDocument';
import { FaRegTrashAlt } from 'react-icons/fa';

export default function DetailMentorshipModal() {
  const {
    isDetailMentorshipModalOpen,
    closeDetailMentorshipModal,
    selectedMentorship,
    openCancelMentorshipModal,
    openRescheduleMentorshipModal,
    handleChangeMentorshipStatus,
  } = useMentorship();
  const formRef = useRef(null);
  const formConfirmRef = useRef(null);
  const [selectedModality, setSelectedModality] = useState('presential');
  const [startDate, setStartDate] = useState(null);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(null);
  const { universityColor, metadata, openModalWithContent } = useContext(
    BaseLayoutContext
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [zIndex, setZIndex] = useState(null);

  useEffect(() => {
    if (!!selectedMentorship) {
      if (!!selectedMentorship.details.scheduled.access_link) {
        setSelectedModality('remote');
      } else {
        setSelectedModality('presential');
      }
    }
    setConfirmClicked(false);
    setShowSuccessMessage(false);
  }, [selectedMentorship, isDetailMentorshipModalOpen]);

  function timeToString(date) {
    if (date === null) {
      return 'Não informado';
    }
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${leftPad(hours, 2)}:${leftPad(minutes, 2)}`;
  }

  useEffect(() => {
    if (selectedMentorship !== null && isDetailMentorshipModalOpen) {
      const scheduled = selectedMentorship.details.scheduled;
      formRef.current.setData({
        zip_code: scheduled.zip_code || 'Não informado',
        city: scheduled.city || 'Não informado',
        state: scheduled.state || 'Não informado',
        district: scheduled.district || 'Não informado',
        address: scheduled.address || 'Não informado',
        address_number: scheduled.address_number || 'Não informado',
        address_complement: scheduled.address_complement || 'Não informado',
        access_link: scheduled.access_link || 'Não informado',
        time: timeToString(
          scheduled.start_at ? new Date(scheduled.start_at) : null
        ),
        responsible: scheduled.created_by || 'Não informado',
        feedback: '',
      });
      if (!!scheduled.start_at) {
        setStartDate(new Date(scheduled.start_at));
      } else {
        formRef.current.setData({
          date: 'Não informado',
        });
      }
    }
  }, [isDetailMentorshipModalOpen, selectedMentorship, selectedModality]);

  async function handleZipCodeChange(e) {
    const value = e.target.value;
    if (value.length === 9) {
      const response = await zipCodeRequest(value);
      if (response.erro) {
        formRef.current.setFieldError('zip_code', 'CEP inválido');
      } else {
        formRef.current.setErrors({});
        formRef.current.setData({
          address: response.logradouro,
          district: response.bairro,
          city: response.localidade,
          state: response.uf,
        });
      }
    }
  }

  async function handleConfirmMentorship(data) {
    setRequestInProgress(true);
    const success = await handleChangeMentorshipStatus({
      status: 'realized',
      feedback: data.feedback,
      document: selectedFile,
    });
    if (success) {
      setShowSuccessMessage(true);
    }
    setRequestInProgress(false);
  }

  function getStatusLabel() {
    switch (selectedMentorship.status.value) {
      case 'canceled':
        return 'MENTORIA CANCELADA';
      case 'realized':
        return 'MENTORIA REALIZADA';
      case 'expired':
        return 'MENTORIA REALIZADA';
    }
  }

  const handleSelect = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      setSelectedFile(null);
      return;
    }
    if (file.type !== 'application/pdf') {
      handleDelete();
      return;
    }
    setSelectedFile(file);
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
  };

  const openFile = async (e) => {
    e && e.preventDefault();
    const file = selectedFile || selectedMentorship?.details?.document?.file;
    const fileName =
      selectedFile?.name || selectedMentorship?.details?.document?.name;
    const canDownload = selectedFile ? false : true;
    setZIndex(3);
    if (file) {
      openModalWithContent(
        <GenericPDFViewer file={file} name={fileName} download={canDownload} />,
        null,
        null,
        () => {
          handleClosePreview();
        }
      );
    }
  };
  const closeModal = () => {
    setSelectedFile(null);
    closeDetailMentorshipModal();
  };

  return (
    <MentorshipModal
      isOpen={isDetailMentorshipModalOpen}
      onClose={closeModal}
      zIndex={zIndex}
      header={
        !showSuccessMessage && (
          <Header>
            {!!selectedMentorship &&
              (selectedMentorship.status.value === 'canceled' ||
                selectedMentorship.status.value === 'realized' ||
                selectedMentorship.status.value === 'expired') && (
                <StatusIndicator
                  status={
                    selectedMentorship.status.value === 'expired'
                      ? 'realized'
                      : selectedMentorship.status.value
                  }
                >
                  {getStatusLabel()}
                </StatusIndicator>
              )}
            <h1>Mentoria de Carreira</h1>
            <p>Confira as informações da Mentoria agendada</p>
          </Header>
        )
      }
    >
      {showSuccessMessage ? (
        <SuccessModal
          title="Mentoria salva com sucesso!"
          description="Consulte as mentorias realizadas na aba Histórico de Mentorias!"
        />
      ) : (
        <Container
          status={!!selectedMentorship && selectedMentorship.status.value}
        >
          <Form ref={formRef}>
            {!!selectedMentorship &&
              selectedMentorship.status.value !== 'canceled' &&
              selectedMentorship.status.value !== 'realized' &&
              selectedMentorship.status.value !== 'expired' && (
                <Fragment>
                  <div>
                    <label>Modalidade de preferência</label>
                    <ModalitySelect
                      selectedModality={selectedModality}
                      setSelectedModality={setSelectedModality}
                      formRef={formRef}
                      disabled
                    />
                  </div>
                  {selectedModality === 'remote' && (
                    <Input
                      name={'access_link'}
                      label={'Link da mentoria'}
                      disabled
                    />
                  )}
                  {selectedModality === 'presential' && (
                    <Fragment>
                      <Input
                        name={'zip_code'}
                        label={'CEP'}
                        maxlength={9}
                        onKeyUp={async (event) => {
                          handleFieldMask(event, maskZipCode);
                          await handleZipCodeChange(event);
                        }}
                        disabled
                      />
                      <Input name={'address'} label={'Avenida/Rua'} disabled />
                      <Input
                        name={'address_number'}
                        label={'Número'}
                        disabled
                      />
                      <Input
                        name={'address_complement'}
                        label={'Complemento'}
                        disabled
                      />
                      <Input name={'district'} label={'Bairro'} disabled />
                      <Input name={'city'} label={'Cidade'} disabled />
                      <Input name={'state'} label={'UF'} disabled />
                    </Fragment>
                  )}
                </Fragment>
              )}
            {selectedMentorship &&
            selectedMentorship.details.scheduled &&
            !!selectedMentorship.details.scheduled.start_at ? (
              <DatePickerCustom
                label="Data"
                name={'date'}
                date={startDate}
                setDate={setStartDate}
                style={{ gridArea: 'date' }}
                isClearable={false}
                disabled
              />
            ) : (
              <Input
                name={'date'}
                label={'Data'}
                style={{ gridArea: 'date' }}
                disabled
              />
            )}
            <TimePicker
              name={'time'}
              label={'Horário'}
              style={{ gridArea: 'time' }}
              disabled
            />
            {selectedMentorship &&
              (selectedMentorship.status.value === 'canceled' ||
                selectedMentorship.status.value === 'realized' ||
                selectedMentorship.status.value === 'expired') && (
                <Input
                  name={'responsible'}
                  label={'Responsável pela mentoria'}
                  style={{ gridArea: 'responsible' }}
                  disabled
                />
              )}
            {selectedMentorship &&
              (selectedMentorship.status.value === 'canceled' ||
                (selectedMentorship.status.value === 'realized' &&
                  !!selectedMentorship.details.feedback)) && (
                <DescriptionBox>
                  <label>
                    {selectedMentorship.status.value === 'canceled'
                      ? 'Motivo do cancelamento'
                      : 'Comentários sobre a mentoria'}
                  </label>
                  <div
                    style={{ minHeight: '39px' }}
                    dangerouslySetInnerHTML={{
                      __html: !!selectedMentorship
                        ? selectedMentorship.status.value === 'canceled'
                          ? selectedMentorship.details.status.description ||
                            'Não informado'
                          : selectedMentorship.details.feedback ||
                            'Não informado'
                        : 'Não informado',
                    }}
                  />
                </DescriptionBox>
              )}
          </Form>
          {!!selectedMentorship &&
            selectedMentorship.status.value !== 'canceled' &&
            selectedMentorship.status.value !== 'realized' &&
            selectedMentorship.status.value !== 'expired' && (
              <Fragment>
                <Divider style={{ marginTop: '3rem' }} />
                <StudentNoteContainer>
                  <strong>Observações do estudante</strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        !!selectedMentorship &&
                        selectedMentorship.details &&
                        selectedMentorship.details.status &&
                        selectedMentorship.details.status.description
                          ? selectedMentorship.details.status.description
                          : 'Não informado',
                    }}
                  />
                </StudentNoteContainer>
                <ActionsContainer>
                  <OutlinedButton onClick={openRescheduleMentorshipModal}>
                    Reagendar mentoria
                  </OutlinedButton>
                  <OutlinedButton onClick={openCancelMentorshipModal}>
                    Cancelar mentoria
                  </OutlinedButton>
                </ActionsContainer>
                <Divider />
                <RealizedMentorshipContainer>
                  <strong>A mentoria já foi realizada?</strong>
                  <p>
                    Confirme a realização da mentoria e envie seu feedback para
                    o estudante.
                  </p>
                  {!confirmClicked && (
                    <FilledButton onClick={() => setConfirmClicked(true)}>
                      Confirmar realização da mentoria
                    </FilledButton>
                  )}
                  {confirmClicked && <FormFeedback />}
                </RealizedMentorshipContainer>
              </Fragment>
            )}
          {!!selectedMentorship &&
            (selectedMentorship.status.value === 'realized' ||
              selectedMentorship.status.value === 'expired') &&
            !selectedMentorship.details.feedback && (
              <RealizedMentorshipContainer style={{ marginTop: 0 }}>
                <FormFeedback />
              </RealizedMentorshipContainer>
            )}
          <ContainerButton>
            {selectedMentorship && selectedMentorship.details.score > 0 && (
              <RatingContainer>
                <span>Avaliação do estudante:</span>
                {[...Array(5)].map((_, index) => (
                  <StarIcon
                    key={index}
                    selected={index < selectedMentorship.details.score}
                  />
                ))}
              </RatingContainer>
            )}

            {selectedFile || selectedMentorship?.details?.document !== null ? (
              <ButtonFile onClick={(e) => openFile(e)}>
                Visualizar documento
              </ButtonFile>
            ) : null}
          </ContainerButton>
        </Container>
      )}
    </MentorshipModal>
  );

  function filename() {
    const documentName = selectedMentorship?.details?.document?.name;
    const fileName = selectedFile?.name;

    return documentName || fileName || 'Nenhum arquivo selecionado';
  }

  function FormFeedback() {
    return (
      <Form ref={formConfirmRef} onSubmit={handleConfirmMentorship}>
        <strong>Comentários sobre a mentoria</strong>
        <span>Os comentários não ficarão visíveis para o estudante</span>
        <HtmlEditor
          name={'feedback'}
          placeholder={'Digite aqui'}
          height={'100px'}
          minHeight={'50px'}
          value={
            formConfirmRef.current
              ? formConfirmRef?.current?.getData().feedback
              : ''
          }
        />
        {metadata?.role === 'university' && (
          <>
            <br />
            <strong>Upload de documento</strong>
            <span style={{ fontSize: '14px' }}>
              Caso queira, adicione um documento que foi desenvolvido durante a
              mentoria. <br />
              <strong>
                Este documento ficará disponível para o estudante.
              </strong>
            </span>
            {(selectedFile || selectedMentorship?.details?.document?.name) && (
              <>
                <br />
                <strong>
                  Arquivo selecionado: {filename()}
                  {selectedFile && (
                    <FaRegTrashAlt
                      onClick={(e) => handleDelete(e)}
                      style={{ float: 'right', cursor: 'pointer' }}
                      size={20}
                    />
                  )}
                </strong>
              </>
            )}
            {!selectedMentorship?.details?.document?.name && !selectedFile && (
              <DropArea
                universityColor={universityColor}
                selectedFile={selectedFile}
              >
                <input
                  type="file"
                  name={'inputDocument'}
                  accept="application/pdf"
                  onChange={(e) => handleSelect(e)}
                />
                <div>
                  <div className="circle-area">
                    <BsUpload color="#fff" size={28} />
                  </div>
                  <p>
                    <strong>Clique aqui</strong> para selecionar um arquivo ou{' '}
                    <strong>arraste-o</strong> para esta área. (Apenas arquivos{' '}
                    <strong>PDF</strong> serão aceitos.)
                  </p>
                </div>
              </DropArea>
            )}
          </>
        )}
        <FilledButton disabled={requestInProgress}>
          {requestInProgress ? 'Salvando...' : 'Salvar'}
        </FilledButton>
      </Form>
    );
  }
}
