import { authenticatedAPI } from 'services/api';

const getTalentsFilters = async (params) => {
  const response = authenticatedAPI
    .options(`/api/internal/university/students?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTalentsCitiesFilters = async (state) => {
  const response = authenticatedAPI
    .options(`/api/internal/university/students?${state}`)
    .then((res) => res.data.cities)
    .catch((error) => error);

  return response;
};

const getFilteredTalents = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/students?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTalentExtraCourseDetail = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/student/additional-course/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const postAnalysisFeedback = async (id, values, status) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/analysis-feedback/${id}/status/${status}`, values)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getTalentsFilters,
  getFilteredTalents,
  getTalentsCitiesFilters,
  getTalentExtraCourseDetail,
  postAnalysisFeedback,
};
