import styled from 'styled-components';
import addIcon from 'assets/mentorship/add.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 45rem;
  margin: 3rem auto auto;

  > p {
    font: 1.063rem 'Roboto', sans-serif;
    color: #4b4b4d;
    line-height: 1.18rem;
    text-align: center;
  }
`;

export const CardAddNewMentorship = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 45rem;
  margin: 2rem auto 0;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: #fff;

  @media (max-width: 600px) {
    width: 95%;
  }

  strong {
    color: ${props => props.color ? props.color : "#009291"};
    font: 1.375rem 'Roboto', sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 1.625rem;
    margin-bottom: 0.75rem;
  }

  p {
    max-width: 20rem;
    font: 0.875rem 'Roboto', sans-serif;
    color: #606062;
    margin-bottom: 3.125rem;
    line-height: 1.18rem;
    text-align: center;
  }
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  text-align: center;
  height: 9rem;
  width: 16rem;
  border-radius: 0.625rem;
  border-style: dashed;
  border-color: #c4c4c4;
  color: #4b4b4d;
  font: 1.25rem 'Roboto', sans-serif;
  font-weight: bold;
  line-height: 1.1rem;
  justify-content: center;
  background-color: #fff;
  flex-direction: column;
  transition: filter 0.2s;
  padding: 0;
  cursor: pointer;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const AddButtonIcon = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  height: 2rem;
  width: 2rem;
  border-radius: 0.625rem;
  background-color: ${props => props.color ? props.color : "#009291"};
  align-items: center;
  display: flex;
  margin: auto auto 0.8125rem auto;
  background-image: url(${addIcon});
`;
