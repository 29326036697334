import React, { useContext, useState } from 'react';
import {
  ModalReward,
  BackgroundModalReward,
  SectionRewardLevel,
  SectionButtonLevel,
  Divider,
  ParagraphReward,
  IconReward,
  TitleReward,
  StatusReward,
  ContainerIconReward,
  ContainerStatus,
  HeaderModal,
  TitleModal,
  ButtonCloseModal,
  ButtonSeeReward,
  ButtonCopyLink,
} from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { IconCurriculumReward } from 'assets/img/iconsCurriculum/iconCurriculumReward';
import { IconProfileReward } from 'assets/img/iconsCurriculum/iconProfileReward';
import { IconGiftReward } from 'assets/img/iconsCurriculum/iconGiftReward';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';

import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import defineUrlPublicProfile from 'utils/defineUrlPublicProfile';
import BaseLayoutContext from 'contexts/base-layout';
import LinkIconAlt from 'assets/ComponentIcons/LinkIcon';
import { generatePublicProfileLog } from 'services/student/profile';

const ModalRewards = ({ closeModalReward, gamificationLevel, props }) => {
  const { universityColor, handleCurriculum } = useContext(ProfileContext);
  const { metadata, openSnackbar } = useContext(BaseLayoutContext);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const moreLevelOne = gamificationLevel > 1;
  const moreLevelTwo = gamificationLevel > 2;

  const returnColorOne = () => {
    return moreLevelOne ? universityColor : '#606062';
  };

  const returnColorTwo = () => {
    return moreLevelTwo ? universityColor : '#606062';
  };

  const returnIconLevelOne = () => {
    if (gamificationLevel <= 1) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 1) {
      return <IconCurriculumReward color={universityColor} />;
    }
  };

  const returnStatusLevelOne = () => {
    if (gamificationLevel <= 1) {
      return 'Bloqueado';
    }
    if (gamificationLevel > 1) {
      return 'Resgatada';
    }
  };

  const returnIconLevelTwo = () => {
    if (gamificationLevel <= 2) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 2) {
      return <IconProfileReward color={universityColor} />;
    }
  };

  const returnStatusLevelTwo = () => {
    if (gamificationLevel <= 2) {
      return 'Bloqueado';
    }
    if (gamificationLevel > 2) {
      return 'Resgatada';
    }
  };

  const copyLink = () => {
    const url = `${defineUrlPublicProfile(metadata.university_slug)}/${
      metadata.public_profile
    }`;
    navigator.clipboard.writeText(url);
    openSnackbar('Link copiado com sucesso!', false);
    generatePublicProfileLog();
  };

  return (
    <BackgroundModalReward>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
        />
      )}
      <ModalReward>
        <HeaderModal>
          <ContainerStatus>
            <TitleModal>Minhas Conquistas</TitleModal>
            <TitleReward isParagraph>
              Confira abaixo as recompensas.
            </TitleReward>
          </ContainerStatus>

          <ButtonCloseModal onClick={closeModalReward}>
            <IconCloseModal color={'#4B4B4D'} />
          </ButtonCloseModal>
        </HeaderModal>
        <SectionRewardLevel>
          <SectionButtonLevel>
            <ContainerIconReward>
              <IconReward>{returnIconLevelOne()}</IconReward>
              <ContainerStatus>
                <TitleReward>Nível 1- Currículo Profissional</TitleReward>
                <StatusReward returnColor={returnColorOne}>
                  {returnStatusLevelOne()}
                </StatusReward>
              </ContainerStatus>
            </ContainerIconReward>
            <div className="buttonsContainer">
              {moreLevelOne ? (
                <ButtonSeeReward
                  universityColor={universityColor}
                  onClick={() => handleCurriculum()}
                >
                  <IconGiftReward />
                  Visualizar Recompensa
                </ButtonSeeReward>
              ) : null}
              {gamificationLevel > 1 && (
                <ButtonCopyLink onClick={() => copyLink()}>
                  <LinkIconAlt />
                  Gerar link público
                </ButtonCopyLink>
              )}
            </div>
          </SectionButtonLevel>

          <ParagraphReward>
            Gere o seu Currículo do Futuro, acesse as vagas mais alinhadas ao
            seu perfil e comece a jornada do protagonismo!
          </ParagraphReward>
        </SectionRewardLevel>

        <Divider />

        <SectionRewardLevel>
          <SectionButtonLevel>
            <ContainerIconReward>
              <IconReward>{returnIconLevelTwo()}</IconReward>
              <ContainerStatus>
                <TitleReward>Nível 2- Perfil Campeão</TitleReward>
                <StatusReward returnColor={returnColorTwo}>
                  {returnStatusLevelTwo()}
                </StatusReward>
              </ContainerStatus>
            </ContainerIconReward>
          </SectionButtonLevel>

          <ParagraphReward>
            Ao alcançar o Perfil Campeão seu currículo ficará super atraente e
            suas chances de conseguir seu lugar no mercado de trabalho
            aumentarão significativamente!
          </ParagraphReward>
        </SectionRewardLevel>
      </ModalReward>
    </BackgroundModalReward>
  );
};

export default ModalRewards;
