import React, { createContext, useState, useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';

export const JobOffersContext = createContext();

export default function JobOffersProvider({ children }) {
  const {
    profile_id,
    academic_profile,
    closeModal,
    openSnackbar,
    handleIsLoadingState,
    openModalWithContent,
    setGlobalState,
  } = useContext(BaseLayoutContext);
  const ProfileId = profile_id;
  const academicProfile = academic_profile;

  const [errorDate, setErrorDate] = useState(false);
  const [countJobs, setCountJobs] = useState(null);

  const [listJobOffers, setListJobOffers] = useState([]);
  const [listFavoriteJobOffers, setListFavoriteJobOffers] = useState([]);

  const [listExternalJobOffers, setListExternalJobOffers] = useState([]);
  const [listExternalOtherJobs, setListExternalOtherJobs] = useState([]);

  const [listApplicationJobs, setListApplicationJobs] = useState(0);
  const [listFinishedJobs, setListFinishedJobs] = useState(0);

  const [listFavoriteJobs, setListFavoriteJobs] = useState(0);

  const [visibleItems, setVisibleItems] = useState(20);
  const [search, setSearch] = useState('');

  const [openCoverLetterModal, setOpenCoverLetterModal] = useState(false);
  const [hasCoverLetter, setHasCoverLetter] = useState(false);

  // count states
  const [favoriteCompaniesJobsCount, setFavoriteCompaniesJobsCount] = useState(
    0
  );
  const [otherJobsCount, setOtherJobsCount] = useState(0);
  const [applicationsCount, setApplicationsCount] = useState(0);
  const [favoriteJobsCount, setFavoriteJobsCount] = useState(0);

  // loading states
  const [isLoadingFavoriteJobs, setIsLoadingFavoriteJobs] = useState(false);
  const [isLoadingOtherJobs, setIsLoadingOtherJobs] = useState(false);
  const [isLoadingExternalJobs, setIsLoadingExternalJobs] = useState(false);
  const [isLoadingExternalOtherJobs, setIsLoadingExternalOtherJobs] = useState(
    false
  );
  const [isLoadingApplications, setIsLoadingApplications] = useState(false);
  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);

  const [activeSubTab, setActiveSubTab] = useState(0);

  const [
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
  ] = useState(false);

  const filterData = (dataArray, search) => {
    if (!dataArray) return [];

    const lowercaseSearch = search.toLowerCase();

    return dataArray.filter((item) => {
      const lowercaseItemName = item.name.toLowerCase();
      const itemId = item.id.toString();
      return lowercaseSearch === ''
        ? true
        : lowercaseItemName.includes(lowercaseSearch) ||
            itemId.includes(lowercaseSearch);
    });
  };

  const handleMoreItems = (data, fetchFunction) => {
    if (data.next) {
      const query = data.next.split('?');
      fetchFunction(query[1], true);
    }
  };

  const handleExclusiveJobsCount = async (
    countJobs,
    otherJobs = 0,
    favCompanies = 0
  ) => {
    if (otherJobs) {
      setOtherJobsCount(otherJobs);
    }
    if (favCompanies) {
      setFavoriteCompaniesJobsCount(favCompanies);
    }
    countJobs['exclusive_offers'] = otherJobsCount + favoriteCompaniesJobsCount;
    return countJobs;
  };

  return (
    <JobOffersContext.Provider
      value={{
        ProfileId,
        academicProfile,

        errorDate,
        setErrorDate,

        listJobOffers,
        setListJobOffers,
        listFavoriteJobOffers,
        setListFavoriteJobOffers,

        listExternalJobOffers,
        setListExternalJobOffers,
        listExternalOtherJobs,
        setListExternalOtherJobs,

        listApplicationJobs,
        setListApplicationJobs,
        listFinishedJobs,
        setListFinishedJobs,

        listFavoriteJobs,
        setListFavoriteJobs,

        isLoadingFavoriteJobs,
        setIsLoadingFavoriteJobs,
        isLoadingOtherJobs,
        setIsLoadingOtherJobs,
        isLoadingApplications,
        setIsLoadingApplications,
        isLoadingFavorites,
        setIsLoadingFavorites,
        isLoadingExternalJobs,
        setIsLoadingExternalJobs,
        isLoadingExternalOtherJobs,
        setIsLoadingExternalOtherJobs,

        handleExclusiveJobsCount,

        search,
        setSearch,

        visibleItems,
        setVisibleItems,
        filterData,

        handleMoreItems,

        openGamificationLevelOneIncompleteModal,
        setOpenGamificationLevelOneIncompleteModal,

        closeModal,
        openSnackbar,
        handleIsLoadingState,
        openModalWithContent,
        setGlobalState,

        openCoverLetterModal,
        setOpenCoverLetterModal,
        hasCoverLetter,
        setHasCoverLetter,

        countJobs,
        setCountJobs,

        activeSubTab,
        setActiveSubTab,
      }}
    >
      {children}
    </JobOffersContext.Provider>
  );
}
