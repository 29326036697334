import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    margin: 3rem 0 0 0;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    color: #4b4b4d;
    line-height: 20px;
    font-weight: 400;
    max-width: 720px;
    text-align: center;
  }
`;

export const TabContent = styled.div`
  width: 720px;
  max-width: 99%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 2rem 2.5rem;
  border-radius: 10px;
  margin-top: 52px;
  @media (max-width: 600px) {
    padding: 2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > h1 {
    font-size: 22px;
    line-height: 25.78px;
    font-weight: 700;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-family: 'Roboto', sans-serif;
    margin: 0;
    width: 100%;
  }

  > h2 {
    font-size: 22px;
    line-height: 25.78px;
    font-weight: 700;
    color: #606062;
    font-family: 'Roboto', sans-serif;
    width: 500px;
    max-width: 100%;
    text-align: center;
  }
  > div {
    margin-top: 3rem;
    gap: 4.5rem;
    display: flex;
    flex-direction: column;
  }
`;

export const LoadingCard = styled.div`
  min-width: 720px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
  border-radius: 10px;
  margin-top: 52px;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  color: #c4c4c4;
`;
