import { MentorshipContextProvider } from './contexts/MentorshipContext';
import React from 'react';
import MentorshipContent from './content';

export default function Mentorship(props) {
  return (
    <MentorshipContextProvider>
      <MentorshipContent {...props} />
    </MentorshipContextProvider>
  );
}
