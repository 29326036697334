import { authenticatedAPI } from 'services/api';

export const checkElegibleMentorship = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/students/me/mentorships/eligible`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchOptions = async () => {
  const response = await authenticatedAPI
    .options(`api/internal/students/me/mentorships`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchCurrentMentorship = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/students/me/mentorships`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchMentorshipHistoric = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/students/me/mentorships/historic`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const sendMentorshipSolicitation = async (data) => {
  const response = await authenticatedAPI
    .post(`api/internal/students/me/mentorships`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const editMentorshipSolicitation = async (data, id) => {
  const response = await authenticatedAPI
    .put(`api/internal/students/me/mentorships/${id}`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const changeMentorshipStatus = async (data, id) => {
  const response = await authenticatedAPI
    .post(`api/internal/students/me/mentorships/${id}/status`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const evaluateMentorship = async (data, id) => {
  const response = await authenticatedAPI
    .patch(`api/internal/students/me/mentorships/${id}/score`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};
