import styled from 'styled-components';
import checkIcon from 'assets/mentorship/check.svg';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
  min-height: 230px;
  text-align: center;
  padding: 0 3rem 2rem;

  h2 {
    font-family: Roboto, sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 0;
    position: revert;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-size: 1.375rem;
    line-height: 1.4375rem;
    font-weight: 700;
    max-width: 16rem;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: #606062;
    line-height: 1rem;
    margin-top: 1.125rem;
    max-width: 20rem;
  }
`;

export const BoxIcon = styled.div`
  width: 6.25rem;
  height: 6.25rem;
`;
