import React, { Fragment, useContext, useState, useEffect } from 'react';
import { shape, func, number } from 'prop-types';
import IcoDone from '@material-ui/icons/Done';
import IcoChecked from 'assets/img/icons/icoChecked.png';
import IcoValores from 'assets/img/icons/icoValores.png';
import IcoExpProfissional from 'assets/img/icons/icoExpProfissional.png';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, Hidden, Grid, List } from '@material-ui/core';

// core components
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';
import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import CardComposition from 'components/CardComposition/CardComposition';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IcoMonetization from '@material-ui/icons/MonetizationOn';
import IcoInfo from '@material-ui/icons/Info';
import Divider from '@material-ui/core/Divider';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import icoTestSVG from 'assets/img/icons/icoTestSVG.svg';
import icoSalarySVG from 'assets/img/icons/icoSalarySVG.svg';
import icoTestMobileSVG from 'assets/img/icons/icoTestMobileSVG.svg';
import icoStar from 'assets/student/starRectangle.svg';

import {
  AcademicDescription,
  BehavialDescription,
  ProfessionalDescription,
  MatchPercentageDescription,
} from './RequerimentDescription';
import {
  Salarycontainer,
  MediumSalarycontainer,
  RequirementsContainer,
  Buttom,
  ButtonMobile,
  CustomButton,
  CustomDiv,
  LinkUrlContainer,
  InfosContainer,
  InstructionText,
} from './styles';
import ProfessionRequirementsReturn from './ProfessionRequirementsReturn';
import BaseLayoutContext from '../../../../contexts/base-layout';
import ReactHtmlParser from 'react-html-parser';
import SalaryIcon from 'assets/ComponentIcons/SalaryIcon.jsx';
import {PublicTenderIconMobile, PublicTenderIcon} from 'assets/ComponentIcons/PublicTender';

const styles = {
  ...behavioralTestStyle,
  cardIcon: {
    marginTop: '-20px',
  },
  cardTitle: {
    fontSize: 20,
  },
  matching: {
    color: '#1CB45F',
    fill: '#1CB45F',
    fontWeight: 'bold',
  },
  notMatching: {
    color: '#aaa',
    fill: '#aaa',
    fontWeight: 'bold',
  },
  courseText: {
    color: '#50B6C2',
    fill: '#50B6C2',
    fontWeight: 'bold',
  },
  iconChecked: {
    marginRight: 22,
  },
  requirementTitle: {
    fontSize: 17,
  },
  requirementSubTitle: {
    marginLeft: 67,
  },
  MatchContentContainer: {
    justifyContent: 'center',
  },
  iconTransform: {
    transform: 'translateY(25%)',
  },
  marginTopClass: {
    marginTop: '1.5rem',
  },
  paragraphClass: {
    fontWeight: 'bold',
    color: '#718096',
  },
};

function ProfessionRequirementsDetails(props) {
  const [showMore, setShowMore] = useState(true);

  const { classes, profession, favoriteHandler, favoriteProfessions } = props;
  const {
    technical_competences,
    academic_profile,
    metadata,
    isStudent,
    universityColor,
  } = useContext(BaseLayoutContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [academicState, setAcademicState] = useState(false);
  const [behavioralState, setBehavioralState] = useState(false);
  const [professionalState, setProfessionalState] = useState(false);
  const [matchState, setMatchState] = useState(false);
  const [marked, setMarked] = useState(
    !!favoriteProfessions.find((item) => item === props.profession)
  );
  const [textAtuation, setTextAtuation] = useState('Favoritar atuação');
  const { summary } = profession.score;
  const { transversal_profession } = profession.profession;

  if (transversal_profession) {
    delete summary.academic_requirements;
  }

  const titles = {
    academic_requirements: 'Requisitos Acadêmicos',
    behavioral_requirements: 'Requisitos Comportamentais',
    professional_requirements: 'Requisitos Profissionais',
    academic: 'Formação acadêmica',
    attributes: 'Requisitos comportamentais',
    extracourses: 'Requisitos de Mercado',
    experiences: 'Conhecimentos Específicos',
  };
  const icons = {
    academic_requirements: IcoChecked,
    behavioral_requirements: IcoValores,
    professional_requirements: IcoExpProfissional,
  };
  const median = (profession.score.average * 10).toFixed(0);

  function handleClick(event, key) {
    setAnchorEl(event.currentTarget);
    setOpen(true);

    switch (key) {
      case 'academic_requirements':
        setAcademicState(true);
        break;
      case 'behavioral_requirements':
        setBehavioralState(true);
        break;
      case 'professional_requirements':
        setProfessionalState(true);
        break;
      case 'match_percentage':
        setMatchState(true);
        break;
      default:
        return null;
    }

    return null;
  }

  const handleClose = () => {
    setAnchorEl(null);

    setOpen(false);
    setProfessionalState(false);
    setAcademicState(false);
    setBehavioralState(false);
    setMatchState(false);
  };

  const {
    salary_range: salary,
    previous_requirements: previousRequirements,
    notice_url: noticeUrl,
  } = profession.profession;

  function notMatchingValues(requirements, competences) {
    const res = requirements.filter((item) => {
      return !competences.find((element) => {
        return item.name === element.name || item.matching === true;
      });
    });
    return res;
  }

  function matchingValues(requirements, competences) {
    const res = requirements.filter((item) =>
      competences.some(
        (competence) => competence.name === item.name || item.matching === true
      )
    );
    return res;
  }

  useEffect(() => {
    if (metadata.university_slug !== 'uniexemplo') {
      triggerHotjarEvent('my_career_details');
    }
    Mixpanel.track('Abriu detalhes da atuação');
  }, []);

  useEffect(() => {
    if (marked) {
      setTextAtuation('Atuação favoritada');
    } else {
      setTextAtuation('Favoritar atuação');
    }
  }, [marked]);

  return (
    <Grid container className={classes.fullScreenGridContainer}>
      <Grid
        item
        container
        xs
        md={12}
        sm={12}
        className={classes.fullScreenGrid}
      >
        <Grid item xs={12} md={12} sm={12} style={{ padding: '0 3rem' }}>
          <Hidden smUp>
            <Card
              classes={classes.card}
              elevation={0}
              style={{
                marginBottom: 50,
                background: 'transparent',
              }}
            >
              <p style={{ textAlign: 'justify', margin: '0 0.8rem 0.8rem' }}>
                {ReactHtmlParser(
                  profession.profession.description.replace(
                    /style="[^"]*"/g,
                    ''
                  )
                )}
              </p>
              {previousRequirements && (
                <RequirementsContainer style={{ paddingLeft: '3rem' }}>
                  <IcoInfo />
                  <p>Pré Requisitos Obrigatórios - {previousRequirements}</p>
                </RequirementsContainer>
              )}
              {salary && (
                <Salarycontainer universityColor={universityColor} style={{ paddingLeft: '3rem' }}>
                  <IcoMonetization />
                  <p>Faixa Salarial - {salary}</p>
                </Salarycontainer>
              )}
              {noticeUrl && (
                <LinkUrlContainer universityColor={universityColor}>
                  <PublicTenderIconMobile color={universityColor || "#009291"} />
                  <div>
                    <h3>Aplicável para concurso público</h3>
                    <p>
                      <a href={noticeUrl} target="_blank">
                        Ver editais {'>'}
                      </a>
                    </p>
                  </div>
                </LinkUrlContainer>
              )}
            </Card>
            <CardComposition
              style={{ marginBottom: 0 }}
              elevation={0}
              infoFunction={(event) => handleClick(event, 'match_percentage')}
              CardContent={() => (
                <Fragment>
                  {matchState && (
                    <Popover
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                    >
                      <MatchPercentageDescription />
                    </Popover>
                  )}
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <h1
                      style={{
                        marginTop: 80,
                        fontWeight: 'bold',
                        color: universityColor ? universityColor : '#009291',
                      }}
                    >
                      {median}%
                    </h1>
                    <CircularProgress
                      style={{
                        color: universityColor ? universityColor : '#009291',
                        marginTop: -140,
                        strokeLinecap: 'round',
                      }}
                      value={Number(median)}
                      size={170}
                      variant="static"
                    />
                    <h4 style={{ fontWeight: 'bold' }}>
                      Seu potencial de empregabilidade para esta atuação
                    </h4>
                    <ButtonMobile
                      onClick={() => {
                        favoriteHandler(profession);
                        setMarked(!marked);
                      }}
                      marked={marked}
                    >
                      <img src={icoStar} alt="" />
                      {<strong> {textAtuation}</strong>}
                    </ButtonMobile>
                    <InstructionText>
                      Ao favoritar uma atuação, ela ficará em destaque no menu
                      "Mercado”.
                    </InstructionText>
                  </div>
                </Fragment>
              )}
            />
          </Hidden>
          <Hidden xsDown>
            <Card
              classes={classes.card}
              elevation={0}
              style={{
                marginBottom: 50,
                background: 'transparent',
              }}
            >
              <p style={{ textAlign: 'justify', margin: '0 3rem 3rem' }}>
                {ReactHtmlParser(
                  profession.profession.description.replace(
                    /style="[^"]*"/g,
                    ''
                  )
                )}
              </p>

              <InfosContainer>
                {salary && salary !== 'Não identificado' &&(
                  <Fragment>
                    <MediumSalarycontainer>
                      <SalaryIcon
                        color={universityColor ? universityColor : '#009291'}
                      />
                        <div>
                          <h3>Média Salarial - {salary}</h3>
                          <p className="paragraph">
                            Média salarial obtida a partir de pesquisas
                            realizadas nas principais plataformas do país, como:
                            Vagas.com, Catho, Michael Page, CAGED, Glassdoor,
                            entre outros.
                          </p>
                        </div>
                    </MediumSalarycontainer>
                  </Fragment>
                )}

                {noticeUrl && (
                  <Fragment>
                    <LinkUrlContainer universityColor={universityColor}>
                      <PublicTenderIcon color={universityColor || "#009291"}/>
                      {noticeUrl !== 'Não identificada' && (
                        <div>
                          <h3>Aplicável para concurso público</h3>
                          <p>
                            <a href={noticeUrl} target="_blank">
                              Ver editais {'>'}
                            </a>
                          </p>
                        </div>
                      )}
                    </LinkUrlContainer>
                  </Fragment>
                )}
              </InfosContainer>

              {previousRequirements && (
                <RequirementsContainer style={{ paddingLeft: '3rem' }}>
                  <IcoInfo />
                  <p>Pré Requisitos Obrigatórios - {previousRequirements}</p>
                </RequirementsContainer>
              )}
            </Card>
          </Hidden>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={8} sm={8} style={{ padding: '0 3rem' }}>
            {Object.keys(summary).map((key) => (
              <CardComposition
                key={key}
                iconPath={icons[key]}
                iconDescription={titles[key]}
                cardTitleText={titles[key]}
                infoFunction={(event) => handleClick(event, key)}
                CardContent={() => (
                  <Fragment>
                    {(academicState ||
                      behavioralState ||
                      professionalState) && (
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        {academicState && <AcademicDescription />}
                        {behavioralState && <BehavialDescription />}
                        {professionalState && <ProfessionalDescription />}
                      </Popover>
                    )}
                    {summary[key].requirements.map((requirement) => {
                      if (requirement.name === 'academic') {
                        const newValue = requirement.values.filter(
                          (item) => item.matching === true
                        );
                        return (
                          <List key={key} className={classes.list}>
                            {newValue.map((value, index) => (
                              <ProfessionRequirementsReturn
                                index={index}
                                requirement={requirement}
                                value={value}
                                classes={classes}
                                keyName={key}
                                titles={titles}
                                name={academic_profile.name}
                              />
                            ))}
                          </List>
                        );
                      }
                      if (requirement.name === 'extracourses') {
                        const notMatchingArray = notMatchingValues(
                          requirement.values,
                          technical_competences.values
                        );
                        const mainValues = notMatchingArray.slice(0, 10);
                        const moreValues = notMatchingArray.slice(10);
                        const matchingArray = matchingValues(
                          requirement.values,
                          technical_competences.values
                        );
                        return (
                          <List key={key} className={classes.list}>
                            {matchingArray.map((value, index) => (
                              <ProfessionRequirementsReturn
                                competences={technical_competences}
                                index={index}
                                requirement={requirement}
                                value={value}
                                classes={classes}
                                keyName={key}
                                titles={titles}
                                name={academic_profile.name}
                                isExtracourse
                                isMatching
                              />
                            ))}
                            {!!matchingArray && matchingArray.length > 0 && (
                              <Divider className={classes.marginTopClass} />
                            )}
                            {mainValues.map((value, index) => (
                              <ProfessionRequirementsReturn
                                competences={technical_competences}
                                name={academic_profile.name}
                                index={index}
                                requirement={requirement}
                                value={value}
                                classes={classes}
                                keyName={key}
                                titles={titles}
                                isExtracourse
                              />
                            ))}
                            {moreValues.length > 0 && (
                              <Fragment>
                                <CustomDiv active={showMore}>
                                  {moreValues.map((value, index) => (
                                    <ProfessionRequirementsReturn
                                      competences={technical_competences}
                                      name={academic_profile.name}
                                      index={index}
                                      requirement={requirement}
                                      value={value}
                                      classes={classes}
                                      keyName={key}
                                      titles={titles}
                                      isMoreRequirements
                                      isExtracourse
                                    />
                                  ))}
                                </CustomDiv>
                                <CustomButton
                                  type="button"
                                  universityColor={universityColor}
                                  onClick={() => {
                                    setShowMore(!showMore);
                                  }}
                                >
                                  <strong>
                                    {showMore
                                      ? 'Outros Requisitos'
                                      : 'Menos Requisitos'}
                                    {showMore ? (
                                      <ExpandMoreIcon
                                        className={classes.iconTransform}
                                      />
                                    ) : (
                                      <ExpandLessIcon
                                        className={classes.iconTransform}
                                      />
                                    )}
                                  </strong>
                                </CustomButton>
                              </Fragment>
                            )}
                          </List>
                        );
                      }
                      return (
                        <List key={key} className={classes.list}>
                          {requirement.values.map((value, index) => (
                            <ProfessionRequirementsReturn
                              competences={technical_competences}
                              name={academic_profile.name}
                              index={index}
                              requirement={requirement}
                              value={value}
                              classes={classes}
                              keyName={key}
                              titles={titles}
                            />
                          ))}
                        </List>
                      );
                    })}
                  </Fragment>
                )}
                emptyStateText="Você ainda não possui recomendações de áreas de atuação."
              />
            ))}
            <p>
              Os itens marcados com{' '}
              <IcoDone
                className={classNames({
                  [classes.matching]: true,
                })}
              />{' '}
              são os que você possui para a atuação.
            </p>
          </Grid>
          <Hidden xsDown>
            <Grid item xs md={4} sm={4} style={{ padding: '0 3rem 0 0' }}>
              <CardComposition
                infoFunction={(event) => handleClick(event, 'match_percentage')}
                CardContent={() => (
                  <Fragment>
                    {matchState && (
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'center',
                          horizontal: 'center',
                        }}
                      >
                        <MatchPercentageDescription />
                      </Popover>
                    )}
                    <div
                      style={{
                        marginBottom: 5,
                        textAlign: 'center',
                      }}
                    >
                      <h1
                        style={{
                          marginTop: 80,
                          fontWeight: 'bold',
                          color: universityColor ? universityColor : '#009291',
                        }}
                      >
                        {median}%
                      </h1>
                      <CircularProgress
                        style={{
                          color: universityColor ? universityColor : '#009291',
                          marginTop: -140,
                          strokeLinecap: 'round',
                        }}
                        value={Number(median)}
                        size={170}
                        variant="static"
                      />
                      <h4 style={{ fontWeight: 'bold' }}>
                        Seu potencial de empregabilidade para esta atuação
                      </h4>
                      <Buttom
                        onClick={() => {
                          favoriteHandler(profession);
                          setMarked(!marked);
                        }}
                        marked={marked}
                      >
                        <img src={icoStar} alt="" />
                        {<strong> {textAtuation}</strong>}
                      </Buttom>
                      <InstructionText>
                        Ao favoritar uma atuação, ela ficará em destaque no menu
                        "Mercado”.
                      </InstructionText>
                    </div>
                  </Fragment>
                )}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
}

ProfessionRequirementsDetails.propTypes = {
  classes: shape.isRequired,
  profession: shape.isRequired,
  favoriteProfessions: shape.isRequired,
  favoriteHandler: func.isRequired,
};

export default withStyles(styles)(ProfessionRequirementsDetails);
