import * as Yup from 'yup';

export const approveFeedbackSchema = Yup.object().shape({
  activityType: Yup.object()
    .shape({
      label: Yup.string().required('Escolha um tipo de atividade.'),
      value: Yup.string().required('Escolha um tipo de atividade.'),
    })
    .required('Escolha um tipo de atividade.'),
  dedicatedHours: Yup.number().required(
    'A quantidade de horas dedicadas é obrigatória'
  ),
  competences: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
  feedback: Yup.string(),
});

export const failedFeedbackSchema = Yup.object().shape({
  feedback: Yup.string().required('O feedback é obrigatório'),
});
