import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, ListItem } from '@material-ui/core';
import { ConfigurationCardComposistion } from './style';
import GridContainer from 'components/Grid/GridContainer.jsx';

import CardComposition from 'components/CardComposition/CardComposition';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import ConfigurationForm from './ConfigurationForm';
import ViewsHeader from 'components/ViewsHeader/ViewsHeader.jsx';
import notifcationBG from 'assets/img/notifications.jpg';
import ChangePassword from './ChangePassword/ChangePassword';
import Permissions from './components/Permissions';
import { ButtonConfig } from './Buttons';
import { getSettings, saveSettings } from 'services/student/notifications';

const Configurations = (props) => {
  const { classes, openSnackbar, universityColor, handleIsLoadingState } =
    props;
  let propsCopy = Object.assign({}, props);
  const [notificationsOptions, setNotificationsOptions] = useState(null);
  const [permissionsOptions, setPermissionsOptions] = useState(null);
  const [settings, setSettings] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  delete propsCopy.classes;

  useEffect(() => {
    (async () => {
      const response = await getSettings();
      if (response.message) {
        handleIsLoadingState(false);
        openSnackbar(
          'Falha ao carregar as configurações, tente novamente mais tarde!',
          true
        );
        setSettings({ notifications: [], permissions: [] });
      } else {
        setSettings(response);
        handleIsLoadingState(false);
      }
    })();
  }, []);
  const save = async () => {
    setIsSubmitting(true);
    const body = {};
    await new Promise((resolve) => {
      permissionsOptions.map((item, index) => {
        Object.entries(item).map(([key, value]) => {
          Object.assign(body, {
            ...body,
            [key]: value,
          });
        });
        index === permissionsOptions.length - 1 && resolve();
      });
    });
    const notificationsEnabled = notificationsOptions.filter(
      (item) => item.enabled
    );
    const response = saveSettings({
      ...body,
      notifications_enabled: notificationsEnabled.map((item) => item.value),
    });

    if (response.message) {
      openSnackbar(
        'Error ao salvar as configurações, tente novamente mais tarde!',
        true
      );
    } else {
      openSnackbar('Configurações salvas com sucesso!');
      setIsSubmitting(false);
    }
  };
  return (
    <Fragment>
      <ViewsHeader backgroundImage={notifcationBG} />
      <GridContainer justify="center" className={classes.marginReset}>
        <Grid item md={10} lg={6} sm={12}>
          <ConfigurationCardComposistion
            classes={classes}
            cardTitleText="Meus dados de acesso"
            CardContent={ChangePassword}
            openSnackbar={openSnackbar}
          />
          {settings?.notifications && (
            <ConfigurationCardComposistion
              classes={classes}
              cardTitleText="Notificar-me por e-mail quando"
              CardContent={ConfigurationForm}
              options={settings?.notifications ?? []}
              setNotificationsOptions={setNotificationsOptions}
            />
          )}
          {settings?.permissions && (
            <ConfigurationCardComposistion
              classes={classes}
              cardTitleText="Permissões"
              CardContent={Permissions}
              universityColor={universityColor}
              options={settings?.permissions ?? []}
              setPermissionsOptions={setPermissionsOptions}
            />
          )}

          <ListItem className={classes.listItem}>
            <ButtonConfig
              universityColor={universityColor}
              type="button"
              className={classes.saveButton}
              round
              onClick={save}
              disabled={isSubmitting}
            >
              Salvar
            </ButtonConfig>
          </ListItem>
        </Grid>
      </GridContainer>
    </Fragment>
  );
};

Configurations.propTypes = {
  classes: PropTypes.object.isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

export default withStyles(dashboardStyle)(Configurations);
