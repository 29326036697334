import React, { Fragment, useState, useContext, useRef } from 'react';
import calendarIcon from 'assets/mentorship/calendar.svg';
import clockIcon from 'assets/mentorship/clock.svg';
import modalityIcon from 'assets/mentorship/modality.svg';
import mentorIcon from 'assets/mentorship/mentor.svg';

import BaseLayoutContext from 'contexts/base-layout';

import {
  Actions,
  ButtonAccessMentorship,
  CancelButton,
  DetailButtonIcon,
  DetailIcon,
  DetailItem,
  DetailMentorshipContainer,
  IconContainer,
  LinkIcon,
  LocalizationContainer,
  Container,
  RoundedButton,
  RoundedOutlinedButton,
  StatusIcon,
  StatusLabel,
  StatusMentorshipContainer,
  CancellationReason,
  RatingContainer,
  StarIcon,
  ButtonFile,
  FragmentFooter,
} from './styles';
import { useMentorship } from '../../contexts/MentorshipContext';
import { ModalityIcon } from 'assets/ComponentIcons/ModalityIcon';
import { CalendarIcon } from 'assets/ComponentIcons/CalendarIcon';
import { MentorIcon } from 'assets/ComponentIcons/MentorIcon';
import { ClockIcon } from 'assets/ComponentIcons/ClockIcon';
import { PinIcon } from 'assets/ComponentIcons/PinIcon';
import { Document, Page, pdfjs } from 'react-pdf';
import { StyleSheet } from '@react-pdf/renderer';
import GenericPDFViewer from 'components/GenericPDFViewer';

export default function MentorshipCard({ mentorship }) {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [pdfPages, setPdfPages] = useState(0);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(0);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(null);
  const {
    handleEditMentorship,
    openCancelMentorshipModal,
    handleChangeMentorshipStatus,
    handleRescheduleMentorship,
    openAdjustMentorshipStatusModal,
    setSelectedMentorship,
    openEvaluateMentorshipModal,
  } = useMentorship();
  const { document } = mentorship;
  const documentDiv = useRef(null);
  const hasDocument = Boolean(document?.file);
  const { universityColor, openModalWithContent } = useContext(
    BaseLayoutContext
  );

  const currentStatus = mentorship.status;
  const scheduled = mentorship.scheduled;

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
  };

  const callModal = (file) => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <GenericPDFViewer
        universityColor={universityColor}
        file={file}
        name={document?.name}
        download={true}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  function getStatusLabel() {
    switch (currentStatus.status.value) {
      case 'requested':
        return 'Aguardando agendamento';
      case 'reschedule_request':
        return 'Aguardando reagendamento';
      case 'accepted':
        return 'Mentoria aceita';
      case 'rescheduled':
        return 'Reagendamento Solicitado';
      case 'canceled':
        return 'Mentoria cancelada';
      case 'realized':
        return 'Mentoria realizada';
      default:
        return 'Mentoria expirada';
    }
  }

  function handleClickAdjust() {
    setSelectedMentorship(mentorship);
    openAdjustMentorshipStatusModal();
  }

  async function handleAcceptMentorship() {
    setRequestInProgress(true);
    await handleChangeMentorshipStatus('accepted');
    setRequestInProgress(false);
  }

  function handleClickEvaluate() {
    setSelectedMentorship(mentorship);
    openEvaluateMentorshipModal();
  }
  return (
    <Container>
      {currentStatus.status.value !== 'scheduled' &&
        currentStatus.status.value !== 'rescheduled' && (
          <div style={{ display: 'inline-block' }}>
            <StatusMentorshipContainer status={currentStatus.status.value}>
              <IconContainer status={currentStatus.status.value}>
                <StatusIcon status={currentStatus.status.value} />
              </IconContainer>
              <StatusLabel status={currentStatus.status.value}>
                {getStatusLabel()}
              </StatusLabel>
            </StatusMentorshipContainer>
          </div>
        )}
      <h2>Mentoria de Carreiras</h2>
      {currentStatus.status.value === 'requested' && (
        <Fragment>
          <span>
            Solicitada dia{' '}
            {currentStatus &&
              new Date(currentStatus.created_at).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })}
          </span>
          <p>
            Aguarde o feedback da sua instituição de ensino, ela poderá aceitar
            ou recusar a sua solicitação. Te manteremos informado por e-mail e
            pela plataforma! :)
          </p>
          <RoundedButton
            universityColor={universityColor}
            onClick={handleEditMentorship}
          >
            <DetailButtonIcon />
            Ver detalhes da mentoria
          </RoundedButton>
        </Fragment>
      )}
      {currentStatus.status.value === 'canceled' && (
        <span>
          Cancelada{' '}
          {currentStatus.is_created_by_university
            ? 'pela instituição de ensino'
            : 'por você'}{' '}
          dia{' '}
          {currentStatus &&
            new Date(currentStatus.created_at).toLocaleDateString('pt-BR', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
        </span>
      )}
      {currentStatus.status.value === 'reschedule_request' && (
        <p>Aguarde o feedback do mentor com a nova opção de data e horário!</p>
      )}
      {!!scheduled.created_by &&
        !!scheduled.start_at &&
        currentStatus.status.value !== 'reschedule_request' && (
          <DetailMentorshipContainer>
            <DetailItem>
              <ModalityIcon color={universityColor || '#009291'} />
              <div>
                <strong>Modalidade</strong>
                <span>
                  {!!scheduled.access_link
                    ? 'Mentoria online'
                    : 'Mentoria presencial'}
                </span>
              </div>
            </DetailItem>
            <DetailItem>
              <ClockIcon color={universityColor || '#009291'} />
              <div>
                <strong>Horário</strong>
                <span>
                  {new Date(scheduled.start_at)
                    .toLocaleTimeString('pt-BR', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(':', 'h')}
                </span>
              </div>
            </DetailItem>
            <DetailItem>
              <CalendarIcon color={universityColor || '#009291'} />
              <div>
                <strong>Data</strong>
                <span>
                  {new Date(scheduled.start_at).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </span>
              </div>
            </DetailItem>
            <DetailItem>
              <MentorIcon color={universityColor || '#009291'} />
              <div style={{ marginLeft: '4px' }}>
                <strong>Mentor</strong>
                <span>{scheduled.created_by}</span>
              </div>
            </DetailItem>
          </DetailMentorshipContainer>
        )}
      {!!scheduled.created_by &&
        currentStatus.status.value !== 'expired' &&
        currentStatus.status.value !== 'realized' &&
        currentStatus.status.value !== 'reschedule_request' && (
          <LocalizationContainer>
            {!!scheduled.access_link &&
              currentStatus.status.value !== 'canceled' &&
              currentStatus.status.value !== 'scheduled' &&
              currentStatus.status.value !== 'rescheduled' && (
                <div style={{ display: 'inline-block' }}>
                  <ButtonAccessMentorship
                    universityColor={universityColor}
                    href={scheduled.access_link}
                    target="_blank"
                  >
                    <LinkIcon />
                    ACESSE A SALA DA MENTORIA
                  </ButtonAccessMentorship>
                </div>
              )}
            {!scheduled.access_link && (
              <Fragment>
                <div>
                  <PinIcon color={universityColor || '#009291'} />
                  <strong>Local da mentoria:</strong>
                </div>
                <p>
                  {scheduled.address}, {scheduled.district} -{' '}
                  {scheduled.address_number} CEP: {scheduled.zip_code} -{' '}
                  {scheduled.city} | {scheduled.state}
                </p>
              </Fragment>
            )}
          </LocalizationContainer>
        )}
      {(currentStatus.status.value === 'scheduled' ||
        currentStatus.status.value === 'rescheduled') && (
        <Actions>
          <RoundedOutlinedButton
            universityColor={universityColor}
            noMarginBottom={true}
            onClick={handleRescheduleMentorship}
          >
            SOLICITAR REAGENDAMENTO
          </RoundedOutlinedButton>
          <RoundedButton
            universityColor={universityColor}
            disabled={requestInProgress}
            onClick={handleAcceptMentorship}
          >
            {requestInProgress ? 'AGUARDE...' : 'ACEITAR MENTORIA'}
          </RoundedButton>
        </Actions>
      )}
      {currentStatus.status.value === 'canceled' &&
        !!currentStatus.description && (
          <CancellationReason>
            <span>Motivo do cancelamento: </span>
            {currentStatus.description}
          </CancellationReason>
        )}
      {currentStatus.status.value === 'expired' && (
        <RoundedButton
          universityColor={universityColor}
          onClick={handleClickAdjust}
        >
          AJUSTAR STATUS DA MENTORIA
        </RoundedButton>
      )}
      <FragmentFooter>
      {currentStatus.status.value === 'realized' && mentorship.score === 0 && (
        <ButtonFile
          universityColor={universityColor}
          onClick={handleClickEvaluate}
        >
          Avaliar Mentoria
        </ButtonFile>
      )}
        {mentorship.score > 0 && (
          <RatingContainer>
            <span>Avaliação:</span>
            {[...Array(5)].map((_, index) => (
              <StarIcon key={index} selected={index < mentorship.score} />
            ))}
          </RatingContainer>
        )}
        {hasDocument ? (
          <ButtonFile
            universityColor={universityColor}
            onClick={() => callModal(document?.file)}
          >
            Visualizar documento
          </ButtonFile>
        ) : null}
      </FragmentFooter>
      {currentStatus.status.value !== 'canceled' &&
        currentStatus.status.value !== 'expired' &&
        currentStatus.status.value !== 'realized' && (
          <CancelButton
            universityColor={universityColor}
            onClick={openCancelMentorshipModal}
          >
            Cancelar solicitação de mentoria
          </CancelButton>
        )}
    </Container>
  );
}
