import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  WrapperTips,
  BubbleTips,
  WrapperPhraseTips,
} from '../style';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

export default function ModalDissatisfied(props) {
  const {
    config,
    profileData,
    universityColor,
    modalOpenState,
    closeAllModal,
  } = props;

  return (
    <Wrapper visible={modalOpenState}>
      <Box>
        <Modal>
          <WrapperTitle alignIcon={config?.style?.alignIcon}>
            <IconPersonStyled
              universityColor={universityColor}
              src={IconPerson}
            />
            <BubbleDialog universityColor={universityColor}>
              <H4Styled>{profileData.name}, isso é normal!</H4Styled>
            </BubbleDialog>
          </WrapperTitle>
          <ParagraphStyled>
            <p>
              Nem sempre estamos felizes com a situação da nossa carreira, mas
              podemos mudar isso.
            </p>

            <p>
              Sou sua Mentora de Carreira e estou aqui para te ajudar a
              encontrar o seu lugar de potência no mundo. Então, aproveite essas
              dicas:
            </p>
          </ParagraphStyled>
          <WrapperTips>
            <WrapperPhraseTips>
              <BubbleTips />
              <p>
                Acesse o menu <strong>“Mercado”</strong> e identifique as
                atuações que tem mais match com o seu perfil
              </p>
            </WrapperPhraseTips>

            <WrapperPhraseTips>
              <BubbleTips />
              <p>
                No menu <strong>“Aprendizagem”</strong> explore as recomendações
                de cursos, feiras e eventos para deixar seu currículo mais
                atraente e você cada vez mais preparado para os desafios do
                mundo do trabalho
              </p>
            </WrapperPhraseTips>

            <WrapperPhraseTips>
              <BubbleTips />
              <p>
                Confira também as vagas disponíveis para você no menu{' '}
                <strong>“Vagas”</strong>, vagas exclusivas!
              </p>
            </WrapperPhraseTips>
          </WrapperTips>
          <Button
            type="button"
            universityColor={universityColor}
            onClick={closeAllModal}
          >
            OK, ENTENDI
          </Button>
        </Modal>
      </Box>
    </Wrapper>
  );
}
