import React, { useContext } from 'react';
import morningIcon from 'assets/mentorship/morning-white.svg';
import afternoonIcon from 'assets/mentorship/afternoon-white.svg';
import eveningIcon from 'assets/mentorship/evening-white.svg';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import smile from 'assets/mentorship/smile.svg';

import {
  Container,
  Footer,
  Legend,
  LegendIcon,
  SmileIcon,
  WeekDaySelector,
  ContentWrapper,
} from './styles';
import { NextButton } from '../styles';
import { useMentorship } from '../../../contexts/MentorshipContext';
import DaySelector from '../DaySelector';
import BaseLayoutContext from 'contexts/base-layout';

export default function Step1({ setStep, formRef }) {
  const { options, setPeriodPreferences, formAction } = useMentorship();
  const { universityColor } = useContext(BaseLayoutContext);

  const handleFormSubmit = (data) => {
    setPeriodPreferences(data.periods_preference);
    setStep(2);
  };

  return (
    <Container universityColor={universityColor}>
      <Form ref={formRef} onSubmit={handleFormSubmit}>
        <h1>
          {formAction === 'reschedule'
            ? 'Solicitar reagendamento'
            : 'Agendar mentoria'}
        </h1>
        <h2>
          {formAction === 'reschedule' ? 'Poxa, que pena!' : 'Dias e períodos'}
        </h2>
        <p>
          {formAction === 'reschedule'
            ? 'Selecione o melhor dia e período para que o seu mentor marque uma' +
              ' nova mentoria com você.'
            : 'Selecione os dias e períodos em que você estará disponível ' +
              'para conversar'}
        </p>
        <ContentWrapper>
          <div>
            <WeekDaySelector>
              {options &&
                options.week_days &&
                options.week_days.map((day) => (
                  <Scope
                    path={`periods_preference[${day.value}]`}
                    key={day.value}
                  >
                    <DaySelector day={day} />
                  </Scope>
                ))}
            </WeekDaySelector>
            <strong universityColor={universityColor}>Legenda:</strong>
            <Legend>
              <LegendIcon
                universityColor={universityColor}
                icon={morningIcon}
              />
              <p>Manhã</p>
              <LegendIcon
                universityColor={universityColor}
                icon={afternoonIcon}
              />
              <p>Tarde</p>
              <LegendIcon
                universityColor={universityColor}
                icon={eveningIcon}
              />
              <p>Noite</p>
            </Legend>
          </div>
          <div>
            <Footer>
              <SmileIcon src={smile} />
              <p>
                <span>Dica:</span> Você pode selecionar mais de um dia e quantos
                períodos quiser
              </p>
            </Footer>
          </div>
        </ContentWrapper>

        <NextButton universityColor={universityColor} type="submit" />
      </Form>
    </Container>
  );
}
