import { useContext, useState, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import {
  GamificationColumnContainer,
  Card,
  IconImg,
  Text,
  Infos,
  TextComposition,
  Button,
} from 'views/Profile/UIComponents/Gamification/Components/Cards/styled';
import BlankModalInformation from 'components/BlankInformationModal';
import useStepOneSortingJobOffers from '../../GenericComponents/GamificationJobOffers';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import InterestForm from 'views/MyProfile/components/Interests/InterestForm';
import PersonalityTest from 'views/MyProfile/components/PersonalityTest/PersonalityTest';
import BehavioralTest from 'views/MyProfile/components/BehavioralTest/BehavioralTest';
import { CurriculumContext } from 'contexts/CurriculumContext';

const gamificationConfig = {
  modalWidth: '366px',
  bubbleText: 'Atenção!',
  ParagraphText:
    'Senti falta de algumas informações para gerar o seu currículo. Complete o nível 1 no menu "Meu Perfil" para se candidatar a vaga! Abaixo, listei o seu progresso no preenchimento das informações:',
  buttonCloseX: true,
  style: {
    fontSize: '17px',
    lineHeight: '23px',
    maxWidth: '92%',
    textAlign: 'justify',
  },
};

export default function GamificationLevelOneIncompleteModal(props) {
  const { onCloseModal, interests, lifeInterests } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { fetchGamificationData } = useContext(ProfileContext);
  const {
    updateProfileState,
    updateAvatarState,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);
  const {
    universityColor,
    openModalWithContent,
    handleIsLoadingState,
    academic_profile,
    closeModal,
  } = useContext(BaseLayoutContext);
  const [profileData, setProfileData] = useState(academic_profile);

  const navigateToComponent = (key) => {
    switch (key) {
      case 'personal_info':
        onCloseModal();
        openModalWithContent(
          <ProfileForm
            setProfileData={setProfileData}
            handleIsLoadingState={handleIsLoadingState}
            universityColor={universityColor}
            updateProfileState={updateProfileState}
            updateAvatarState={updateAvatarState}
            closeModal={closeModal}
            fetchGamificationData={fetchGamificationData}
            profileData={profileData}
            handleShowSpecialNeeds={handleShowSpecialNeeds}
            {...props}
          />,
          'Edição Dados Pessoais'
        );
        break;
      case 'life_interests':
        onCloseModal();
        openModalWithContent(
          <InterestForm
            {...props}
            fetchGamificationData={fetchGamificationData}
            selectedInterests={lifeInterests}
            lifeInterest={true}
            closeModal={closeModal}
          />,
          'Interesses de Vida',
          null,
          () => null,
          false,
          'lifeInterest'
        );
        break;
      case 'career_interests':
        onCloseModal();
        openModalWithContent(
          <InterestForm
            {...props}
            fetchGamificationData={fetchGamificationData}
            selectedInterests={interests}
            lifeInterest={false}
            closeModal={closeModal}
          />,
          'Interesses de Carreira',
          null,
          () => null,
          false,
          'careerInterest'
        );
        break;
      case 'personality_attributes':
        onCloseModal();
        openModalWithContent(
          <PersonalityTest
            fetchGamificationData={fetchGamificationData}
            closeModal={closeModal}
            {...props}
          />,
          'Questionário de Personalidade',
          null,
          () => null,
          false,
          'personality'
        );
        break;
      case 'behavioral_attributes':
        onCloseModal();
        openModalWithContent(
          <BehavioralTest
            fetchGamificationData={fetchGamificationData}
            closeModal={closeModal}
            {...props}
          />,
          'Questionário de perfil comportamental',
          null,
          () => null,
          false,
          'behavioral'
        );
        break;
      default:
        break;
    }
  };

  const gamificationItems = useStepOneSortingJobOffers(navigateToComponent);

  useEffect(() => {
    if (gamificationItems.length > 0) {
      setModalOpen(true);
    }
  }, [gamificationItems]);

  const renderGamificationInfo = () => {
    return (
      <GamificationColumnContainer>
        {gamificationItems.map((item, index) => (
          <Card key={index}>
            <IconImg>{item.icon}</IconImg>
            <TextComposition>
              <Button universityColor={universityColor} onClick={item.onClick}>
                {item.title}
              </Button>
              <Infos>{item.info}</Infos>
            </TextComposition>
          </Card>
        ))}
      </GamificationColumnContainer>
    );
  };

  return (
    modalOpen && (
      <BlankModalInformation
        universityColor={universityColor}
        modalOpenState={true}
        config={gamificationConfig}
        buttonCancelFunction={onCloseModal}
        closeModalFunction={onCloseModal}
      >
        {renderGamificationInfo()}
      </BlankModalInformation>
    )
  );
}
