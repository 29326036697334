import React, { useContext, useEffect } from 'react';
import {
  Container,
  Footer,
  ListRequirements,
  Requirement,
  Status,
} from './styles';
import { NextButton } from '../styles';
import { useMentorship } from '../../../contexts/MentorshipContext';
import BaseLayoutContext from 'contexts/base-layout';

export default function Step0({ setStep }) {
  const { hasCurriculumFilled, hasFavoriteProfession } = useMentorship();
  const { universityColor } = useContext(BaseLayoutContext);

  function handleNextStep() {
    setStep(1);
  }

  useEffect(() => {
  }, [universityColor]);

  return (
    <Container universityColor={universityColor}>
      <h1>Você sabe o que é nosso programa de mentoria?</h1>
      <p>
        Fizemos esse momento especialmente para você! Aqui você tem a
        oportunidade de conversar com pessoas mais experientes, estimulando o
        seu desenvolvimento pessoal e profissional.
      </p>
      <p>Estamos quase lá!</p>
      <h2 universityColor={universityColor}>
        Confira os requisitos para agendar sua mentoria:
      </h2>

      <ListRequirements>
        <div style={{ display: 'flex', gap: '10px' }}>
          <Requirement universityColor={universityColor}>
            <div>1</div>
            <strong>Tenha o currículo do futuro</strong>
            <span>
              Preencha todas as informações obrigatórias no menu "Currículo do
              futuro".
            </span>
            <Status value={hasCurriculumFilled}>
              {hasCurriculumFilled === undefined
                ? 'Carregando...'
                : !!hasCurriculumFilled
                ? 'Concluído'
                : 'Pendente'}
            </Status>
          </Requirement>
          <Requirement universityColor={universityColor}>
            <div>2</div>
            <strong>Favorite ao menos 1 atuação</strong>
            <span>No menu "Mercado”.</span>
            <Status value={hasFavoriteProfession}>
              {hasFavoriteProfession === undefined
                ? 'Carregando...'
                : !!hasFavoriteProfession
                ? 'Concluído'
                : 'Pendente'}
            </Status>
          </Requirement>
        </div>
      </ListRequirements>
      <Footer>
        <p>
          Caso alguma dessas informações estejam pendentes, a sua mentoria
          poderá ser recusada pela equipe da sua instituição de ensino.
        </p>
        <NextButton
          universityColor={universityColor}
          onClick={handleNextStep}
        />
      </Footer>
    </Container>
  );
}
