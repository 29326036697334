import MentorshipModal from 'components/MentorshipModal';
import React, { useState, useContext } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { ActionButton, Container } from './styles';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalAdjustMentorshipStatus() {
  const [requestInProgress, setRequestInProgress] = useState(false);

  const {
    isAdjustMentorshipStatusModalOpen,
    closeAdjustMentorshipStatusModal,
    openCancelMentorshipModal,
    openDetailMentorshipModal,
    selectedMentorship,
  } = useMentorship();

  async function handleConfirmRealization() {
    setRequestInProgress(true);
    await openDetailMentorshipModal(selectedMentorship);
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal
      isOpen={isAdjustMentorshipStatusModalOpen}
      onClose={closeAdjustMentorshipStatusModal}
    >
      <Container>
        <h1>Ajuste o status da mentoria</h1>
        <strong>A sua mentoria foi realizada?</strong>
        <div>
          <ActionButton onClick={handleConfirmRealization}>
            {requestInProgress ? 'Aguarde...' : 'Sim, realizei a mentoria'}
          </ActionButton>
          <ActionButton onClick={openCancelMentorshipModal}>
            Não realizei a mentoria
          </ActionButton>
        </div>
      </Container>
    </MentorshipModal>
  );
}
