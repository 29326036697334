import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 65px;

  display: flex;
  justify-content: flex-start;

  background-color: transparent;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  scroll-behavior: smooth;

  overflow: hidden;
  position: relative;

  @media (max-width: 1052px) {
    button:nth-child(2) {
      margin: 20px 20px 0 0px;
    }
  }

  @media (max-width: 959px) {
    button:nth-child(2) {
      margin: 20px 20px 0 35px;
    }
  }

  @media (max-width: 800px) {
    button:nth-child(2) {
      margin: 20px 20px 0 0px;
    }
  }
`;

export const ButtonNav = styled.button`
  font-size: 15px;
  font-weight: ${(props) => (props.active ? `700` : '500')};
  background: none;
  border: none;
  border-bottom: ${(props) => (props.active ? `3px solid #009291` : 'none')};
  margin: 20px 20px 0 15px;
  padding-bottom: ${(props) => (props.active ? '16px' : '18px')};
  color: #606062;

  display: flex;
  align-items: flex-end;
  flex: none;
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};

  @media screen and (max-width: 1099px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1070px) {
    font-size: 14px;
  }
`;

export const BtArrow = styled.button`
  width: 40px;
  height: 100%;

  border: 1px solid;

  background-color: #fff;
  border: 1px solid #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  padding-top: 10px;
  position: sticky;
  right: ${(props) => props.right && '0'};
  left: ${(props) => props.left && '0'};

  display: none;

  @media (max-width: 1052px) {
    display: flex;
  }

  @media (max-width: 959px) {
    display: none;
  }

  @media (max-width: 800px) {
    display: flex;
  }
`;
