import React, {
  useContext,
  useState,
  useCallback,
  Fragment,
  useEffect,
} from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { format } from 'date-fns';
import { DoneAll, Warning } from '@material-ui/icons';

import Success from 'components/Typography/Success';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ThumbUp from '@material-ui/icons/ThumbUp';
import LocationOn from '@material-ui/icons/LocationOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PdfIcon from 'assets/img/pdfFile.png';
import DefaultAvatar from 'assets/img/placeholder.jpg';
import { getPDFname } from 'utils/getPDFname';
import BaseLayoutContext from '../../contexts/base-layout';
import ExtraCourseModal from './ExtraCourseModal';

import {
  AvatarItem,
  CurriculumLiked,
  LinkItems,
  AwaitingAnalysisText,
} from './styled';
import { getTalentExtraCourseDetail } from '../../services/university/students';

export const TalentsListItem = ({
  student,
  classes,
  openCurriculum,
  openLetter,
  company,
  liked,
  handleCheck,
  selectedStudents,
}) => {
  const {
    courses,
    email,
    name,
    mobile_phone: mobilePhone,
    professional_status: professionalStatus,
    professional_working_in_formation_area: workingInFormationArea,
    city,
    state,
    unity,
    university,
    analysis_requests: analysisRequests,
  } = student;

  const { openModalWithContent, openSnackbar } = useContext(BaseLayoutContext);

  function workingInFormationAreaResult() {
    if (professionalStatus === 'desempregado(a)') return null;
    if (workingInFormationArea === true) return '- Na Área de Formação';
    if (workingInFormationArea === false) return '- Fora da Área de Formação';
    return null;
  }

  const isWorkingInArea = workingInFormationAreaResult();

  const [analysisStatus, setAnalysisStatus] = useState(analysisRequests || []);
  const hasAwaitingAnalysis = analysisStatus.find(
    (item) => item.status.value === 'awaiting_analysis'
  );

  const handleUpdateAnalysisStatus = useCallback((status, id, response) => {
    const newAnalysisArray = [...analysisStatus];
    const indexOfUpdatedAnalysis = newAnalysisArray.findIndex(
      (item) => item.id === id
    );

    if (status === 'failed') {
      newAnalysisArray[indexOfUpdatedAnalysis].status = {
        label: 'Análise recusada',
        value: 'failed_analysis',
      };
      newAnalysisArray[indexOfUpdatedAnalysis].feedback = {
        created_by: response.created_by,
        updated_at: response.updated_at,
      };
    }

    if (status === 'approve') {
      newAnalysisArray[indexOfUpdatedAnalysis].status = {
        label: 'Análise aprovada',
        value: 'approved_analysis',
      };
      newAnalysisArray[indexOfUpdatedAnalysis].feedback = {
        created_by: response.created_by,
        updated_at: response.updated_at,
      };
    }

    setAnalysisStatus(newAnalysisArray);
  }, []);

  useEffect(() => {
    selectedStudents.includes(student.id);
  }, [selectedStudents]);

  async function handleExtracourseClick(extracourseId, feedback, analysisId) {
    const response = await getTalentExtraCourseDetail(extracourseId);

    if (response.message) {
      openSnackbar(
        `Falha ao tentar buscar dados da Atividade Extracurricular, por favor tente mais tarde. Se o problema persistir entre em contato com o suporte.`,
        true
      );
      return;
    }

    const feedbacks = response.analysis_requests
      .map((item) => item.feedback)
      .filter((item) => item !== null || undefined);

    const pdfChosen = {
      pdfURL: response.file,
      pdfName: response.file && getPDFname(response.file),
    };

    openModalWithContent(
      <ExtraCourseModal
        data={response}
        feedbacks={feedbacks}
        pdfChosen={pdfChosen}
        analysisId={analysisId}
        handleUpdateAnalysisStatus={handleUpdateAnalysisStatus}
      />,
      'Validação Atividade Extracurricular'
    );
  }

  return (
    <ExpansionPanel
      classes={{
        root: classes.noBoxShadow,
      }}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <FormControlLabel
            tabIndex={-1}
            control={
              <Checkbox
                classes={{
                  checked: classes.checkBox,
                }}
                color="default"
                checked={selectedStudents.includes(student)}
                onChange={(e) => {
                  handleCheck(student, e.target.checked);
                }}
              />
            }
          />

          {!company && (
            <AvatarItem item md={2} xs={12} style={{ marginRight: '1rem' }}>
              <img
                src={student.avatar ? student.avatar : DefaultAvatar}
                alt="Avatar"
              />
            </AvatarItem>
          )}
          <Grid
            item
            xs={12}
            md={hasAwaitingAnalysis ? 7 : 7}
            className={classes.talentsGrid}
          >
            <h5>{name}</h5>
            {liked && liked[student.id] && (
              <CurriculumLiked>
                <ThumbUp />
                <span>Currículo Curtido</span>
              </CurriculumLiked>
            )}
            <p>
              {professionalStatus} {isWorkingInArea}
            </p>
            {!!courses &&
              courses.map((course) => {
                return (
                  <p key={course} style={{ marginBottom: 0 }}>
                    {course.name} - {course.semester}
                    &ordm; período
                  </p>
                );
              })}
            <p>
              {email && (
                <span style={{ marginRight: '1rem' }}>
                  <MailOutlineIcon className={classes.talentsIcon} /> {email}
                </span>
              )}
              {mobilePhone && (
                <span style={{ display: 'inline-block' }}>
                  <PhoneIphoneIcon className={classes.talentsIcon} />{' '}
                  {mobilePhone}
                </span>
              )}
            </p>
            <p>
              <span style={{ marginRight: '1rem' }}>
                {((city && state) || unity) && (
                  <LocationOn className={classes.talentsIcon} />
                )}
                {city && state && (
                  <span>
                    {city}/{state} {(unity || university) && '|'}
                  </span>
                )}
                {unity && !company && ` Unidade: ${unity}`}
                {university && company && ` Universidade: ${university}`}
              </span>
              <span style={{ marginRight: '1rem' }} />
            </p>
          </Grid>
          {hasAwaitingAnalysis ? (
            <Grid item md={3} xs={12} className={classes.talentsGrid}>
              <AwaitingAnalysisText>Aguardando análise</AwaitingAnalysisText>
            </Grid>
          ) : null}
          <Grid item md={12} className={classes.popoverContent}>
            {liked && liked[student.id] && (
              <span className={classes.jobsDetailButton}>
                <small>
                  <Success>Currículo Curtido</Success>
                </small>
              </span>
            )}

            {!company && (
              <LinkItems>
                {student.has_curriculum && (
                  <div
                    className="linkItem"
                    onClick={() => openCurriculum(student)}
                  >
                    <img src={PdfIcon} alt="Visualizar Currículo" />
                    Visualizar Currículo
                  </div>
                )}
                {((company &&
                  student.has_cover_letter &&
                  student.curriculums &&
                  liked &&
                  liked[student.id]) ||
                  student.has_cover_letter) && (
                  <div className="linkItem" onClick={() => openLetter(student)}>
                    <img src={PdfIcon} alt="Visualizar Carta" />
                    Visualizar Carta de Apresentação
                  </div>
                )}
              </LinkItems>
            )}
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: classes.expansionDetailPanel,
        }}
      >
        {analysisStatus.length > 0 &&
          analysisStatus.map((item) => {
            return (
              <button
                key={item.id}
                type="button"
                onClick={() =>
                  handleExtracourseClick(
                    item.additional_course_id,
                    item.feedback,
                    item.id
                  )
                }
                className={classes.buttonNoStyle}
              >
                <div className={classes.gridTalents}>
                  <div className={classes.flexAlignCenter}>
                    {item.status.value === 'approved_analysis' ? (
                      <DoneAll
                        className={classes.analysisApprove}
                        style={{ marginRight: '0.5rem', fontSize: '1.7rem' }}
                      />
                    ) : item.status.value === 'awaiting_analysis' ? (
                      <Warning
                        className={classes.analysisRefused}
                        style={{ marginRight: '0.5rem', fontSize: '1.7rem' }}
                      />
                    ) : item.status.value === 'failed_analysis' ? (
                      <DoneAll
                        className={classes.analysisCheckedAndRefused}
                        style={{ marginRight: '0.5rem', fontSize: '1.7rem' }}
                      />
                    ) : null}

                    <p className={classes.titleTalents}>
                      {item.additional_course_name} - Tipo:{' '}
                      {item.additional_course_level.label}
                    </p>
                  </div>

                  <p
                    className={classes.subtitleTalents}
                    style={{ textAlign: 'left' }}
                  >
                    {item.status.value === 'awaiting_analysis'
                      ? `Solicitado em: ${format(
                          new Date(item.created_at),
                          'dd/MM/yyyy'
                        )}`
                      : item.status.value === 'approved_analysis'
                      ? `Validado por: ${item.feedback.created_by} em ${format(
                          new Date(item.feedback.updated_at),
                          'dd/MM/yyyy'
                        )}`
                      : item.status.value === 'failed_analysis'
                      ? `Recusado por: ${item.feedback.created_by} em ${format(
                          new Date(item.feedback.updated_at),
                          'dd/MM/yyyy'
                        )}`
                      : null}
                  </p>
                </div>
              </button>
            );
          })}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

TalentsListItem.defaultProps = {
  liked: false,
  company: false,
};

TalentsListItem.propTypes = {
  student: shape({
    course: arrayOf({
      name: string,
      semester: number,
    }),
  }).isRequired,
  classes: shape({}).isRequired,
  openCurriculum: func.isRequired,
  openLetter: func.isRequired,
  liked: bool,
  company: bool,
};
