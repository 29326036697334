import React, { useContext, useEffect, useState, Fragment } from 'react';

import {
  Container,
  Button,
  WrapperInfos,
  VisualIndicator,
  SimpleBar,
  ContainerButton,
  ButtonDelete,
  ButtonEdit,
  ListButton,
} from './style';

import Edifice from '../../../../../../assets/JobOffer/edifice.svg';
import User from '../../../../../../assets/JobOffer/user.svg';
import { JobOfferContext } from '../../../../Contexts/JobOfferContext';
import { getJobDetails } from '../../../../services';
import FinishModal from '../Modal/FinishModal';
import UnpublishModal from '../Modal/UnpublishModal';
import { jobOfferAdapter } from '../../../JobOfferForm/helpers/jobOfferAdapter';
import { jobOfferAdapterExternal } from '../../../ExternalJobOffer/helpers/jobOfferAdapterExternal';

import { JobDetailButton } from '../JobDetails';
import { EngineListCandidates } from 'components/ListCandidates/EngineListCandidates';

export default function JobCards(props) {
  const {
    jobName,
    openedSince,
    responsibleName,
    numberOfCandidates,
    jobs,
    jobID,
    limit,
    offSet,
    isPartnerOffer,
    seeMatch,
    setListJobsCompany,
    setCount,
  } = props;

  const {
    dataFunction,
    setExternalJobOffer,
    setFormValues,
    setCacheContext,
    data,
    currentJobFilter,
  } = useContext(JobOfferContext);

  const [expandedResponsible, setExpandedResponsible] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [modalUnpublish, setModalUnpublish] = useState(false);

  const strReduced = (str) => {
    if (str.length > 17) {
      return `${str.substr(0, 17)}...`;
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (data.isEditJob) {
      dataFunction.setLoadingModal(false);
      dataFunction.handleOpenPublishModal();
    }
  }, [data]);

  const handleClickEdit = async () => {
    dataFunction.setLoadingModal(true);

    const jobDetails = await getJobDetails(jobs.id);
    if (jobs.is_external === true) {
      setExternalJobOffer(true);
      setFormValues(await jobOfferAdapterExternal(jobDetails, setCacheContext));
    } else {
      setFormValues(await jobOfferAdapter(jobDetails, setCacheContext));
    }
  };

  const handleModalFinish = () => {
    setModalFinish(true);
  };

  const handleModalUnpublish = () => {
    setModalUnpublish(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleModalListContent = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Container>
      {openModal && (
        <EngineListCandidates
          controlModal={openModal}
          setControlModal={setOpenModal}
          jobs={jobs}
          isPartner={isPartnerOffer}
          publishedJobs={true}
          company={true}
          seeMatch={seeMatch}
        />
      )}

      <FinishModal
        setModalFinish={setModalFinish}
        isOpen={modalFinish}
        jobID={jobID}
        isPartnerOffer={isPartnerOffer}
        limit={limit}
        setListJobsCompany={setListJobsCompany}
        setJobsCount={setCount}
        companyFilters={currentJobFilter}
        dataFunction={dataFunction}
      />
      <UnpublishModal
        jobID={jobID}
        offSet={offSet}
        limit={limit}
        setModalUnpublish={setModalUnpublish}
        isOpen={modalUnpublish}
      />
      <WrapperInfos>
        <strong> {jobName} </strong>
        <span> {openedSince} </span>
        <span> {`CÓDIGO: ${jobID}`} </span>

        <VisualIndicator>
          <div className="icon">
            <img src={Edifice} alt="Icone Edifício" />
          </div>
          <p title={responsibleName}>
            {expandedResponsible || responsibleName.length <= 17
              ? responsibleName
              : strReduced(responsibleName)}
          </p>
          {!expandedResponsible && responsibleName.length > 17 && (
            <button
              onClick={() => {
                setExpandedResponsible(true);
              }}
            >
              ver mais
            </button>
          )}
        </VisualIndicator>
        <VisualIndicator>
          <div className="icon">
            <img src={User} alt="Icone Usuário" />
          </div>
          <p>
            {numberOfCandidates === 0 ? 'Nenhum estudante candidatado' : null}
            {numberOfCandidates === 1
              ? `${numberOfCandidates} estudante candidatado`
              : null}
            {numberOfCandidates > 1
              ? `${numberOfCandidates} estudantes candidatados`
              : null}
          </p>
          {numberOfCandidates ? (
            <ListButton onClick={handleModalListContent}>ver</ListButton>
          ) : null}
        </VisualIndicator>
        {jobs.is_partner_offer ? (
          <Fragment>
            <SimpleBar />

            <JobDetailButton
              jobID={jobID}
              isFromPartners={jobs.is_partner_offer}
              externalJobOffer={jobs.is_external}
              style={{
                backgroundColor: '#009291',
                color: '#FFFF',
                marginLeft: '0px',
                marginBottom: '5px',
                height: '30px',
              }}
            >
              Detalhes da Vaga
            </JobDetailButton>
          </Fragment>
        ) : (
          <Fragment>
            <ContainerButton>
              <Button onClick={handleModalFinish}>Finalizar Vaga</Button>
              <JobDetailButton
                jobID={jobID}
                isFromPartners={jobs.is_partner_offer}
                externalJobOffer={jobs.is_external}
              >
                Detalhes da Vaga
              </JobDetailButton>
            </ContainerButton>
            <SimpleBar />
            <ContainerButton>
              <ButtonDelete onClick={handleModalUnpublish}>
                DESPUBLICAR VAGA
              </ButtonDelete>
              <ButtonEdit onClick={handleClickEdit}>EDITAR</ButtonEdit>
            </ContainerButton>
          </Fragment>
        )}
      </WrapperInfos>
    </Container>
  );
}
