import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Pagination from 'components/CustomPagination';
import JobHistoryCards from '../../../../components/HistoryCards';
import SkeletonJobsCard from '../../../../components/JobsSkeleton';

import { Container, Content, Title } from './styled';
import FeedBackModal from '../../../../components/HistoryCards/FeedBackModal';
import GenericMessage from 'components/GenericMessage';

function RegisteredByInstitution({
  onPageChangeInstitution,
  currentJobFilter,
  activeTab,
  jobsCountInstitution,
  currentPageInstitution,
  setCurrentPageInstitution,
  limitInstitution,
  setLimitInstitution,
  jobsInstitution,
  setJobsInstitution,
  loadJobsInstitution,
  setJobsCountInstitution,
}) {
  const [feedback, setFeedback] = useState({ feedback: 'hello' });
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const skeletonCardsQuantity = useMemo(() => {
    return randomIntFromInterval(1, 4);
  }, []);

  const changeModalFeedbackState = useCallback(() => {
    setModalFeedbackOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalFeedbackOpen(false);
  }, []);

  useEffect(() => {
    if (currentJobFilter !== null) {
      setJobsInstitution(null);
      loadJobsInstitution(
        `limit=${limitInstitution}&offset=${
          (currentPageInstitution - 1) * limitInstitution || 0
        }`
      );
    } else {
      setJobsInstitution(null);
      loadJobsInstitution(`limit=${limitInstitution}&offset=0`);
    }
  }, [activeTab, currentJobFilter, currentPageInstitution, limitInstitution]);

  useEffect(() => {
    setCurrentPageInstitution(1);
  }, [limitInstitution, currentJobFilter, activeTab]);

  const renderItems = () => {
    if (jobsInstitution === null) {
      return (
        <Container>
          <Content>
            <SkeletonJobsCard quantity={skeletonCardsQuantity} />
          </Content>
        </Container>
      );
    }

    if (jobsInstitution?.length === 0) {
      return (
        <Container>
          <GenericMessage title="No momento, não há nenhuma vaga disponível." />
        </Container>
      );
    }

    if (jobsInstitution?.length > 0) {
      return (
        <Container>
          <Content>
            {jobsInstitution.map((jobs) => (
              <JobHistoryCards
                changeModalFeedbackState={changeModalFeedbackState}
                setFeedback={setFeedback}
                jobName={jobs.name}
                openedSince={jobs.opened_since}
                responsibleName={jobs.responsible_name}
                numberOfCandidates={jobs.number_of_candidates}
                status={jobs.status}
                jobID={jobs.id}
                jobs={jobs}
                setJobsInstitution={setJobsInstitution}
                universityFilters={currentJobFilter}
                setJobsCountInstitution={setJobsCountInstitution}
              />
            ))}
            <FeedBackModal
              limit={limitInstitution}
              currentPage={currentPageInstitution}
              feedback={feedback}
              isOpen={modalFeedbackOpen}
              closeModal={handleCloseModal}
              loadJobsData={loadJobsInstitution}
            />
          </Content>
          <Pagination
            className="pagination-bar"
            currentPage={currentPageInstitution}
            totalCount={jobsCountInstitution}
            limit={limitInstitution}
            onPageChange={onPageChangeInstitution}
            setLimit={setLimitInstitution}
            setCurrentPage={setCurrentPageInstitution}
          />
        </Container>
      );
    }
  };

  return <div>{renderItems()}</div>;
}

export default RegisteredByInstitution;
