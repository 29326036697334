import React, { useState, Fragment } from 'react';
import SearchableSelect from 'react-select';
import {
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  TextField,
  withStyles,
} from '@material-ui/core';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import pdf from 'assets/img/pdfFile.png';
import { func, number, shape } from 'prop-types';
import classNames from 'classnames';
import Button from 'components/CustomButtons/Button';
import HtmlEditor from 'components/HtmlEditor';
import FeedbackModal from './FeedbackModal';
import { FeedbackList } from './styled';
import { FeedbackCard } from '../../views/MyProfile/components/ExtraCourse/components/FeedbackCard';

function ExtraCourseModal({
  classes,
  data = {},
  feedbacks = [],
  pdfChosen,
  analysisId,
  handleUpdateAnalysisStatus,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [analysisStatus, setAnalysisStatus] = useState(false);

  const filteredAnalysis = data.analysis_requests.filter(
    (item) => item.feedback !== null || undefined
  );

  function setClose() {
    setIsOpen(false);
  }
  return (
    <Grid
      container
      className={classes.fullScreenGridContainer}
      justify="center"
    >
      <Grid item xs md={12} className={classes.fullScreenGrid}>
        <List className={classes.list}>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="course"
              id="course"
              type="text"
              label="Curso"
              value={data.course}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="area"
              id="area"
              type="text"
              label="Principal Conhecimento/Especialidade"
              value={data.area.label}
            />
          </ListItem>
          <ListItem>
            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="acquiredKnowledges" shrink>
                Outros conhecimentos e Competências Adquiridas
              </InputLabel>
              <SearchableSelect
                className={classNames([
                  classes.select,
                  classes.selectControlHeight,
                ])}
                isDisabled
                isMulti
                variant="filled"
                name="acquiredKnowledges"
                id="acquiredKnowledges"
                placeholder=" "
                value={data.acquired_knowledges}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="level"
              id="level"
              type="text"
              label="Nível do Curso"
              value={data.level.label}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="workload"
              id="workload"
              type="text"
              label="Carga horária"
              value={data.workload}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="institution"
              id="institution"
              type="text"
              label="Instituição"
              value={data.institution}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="startedAt"
              id="startedAt"
              type="text"
              label="Data de início"
              value={data.started_at}
            />
          </ListItem>
          <ListItem>
            <TextField
              className={classes.inputField}
              InputProps={{
                readOnly: true,
                className: classes.input,
                style: { backgroundColor: '#f5f5f5' },
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="endedAt"
              id="endedAt"
              type="text"
              label="Data de conclusão"
              value={data.ended_at}
            />
          </ListItem>
          <ListItem className={classes.availableCardBody}>
            <h5
              className={classes.listTitle}
              style={{ color: '#4F4F4F', fontWeight: 400 }}
            >
              Dissertação sobre o conteúdo e conhecimentos adquiridos
            </h5>
            <HtmlEditor loadValue={data.description} readOnly />
          </ListItem>
          {data.file ? (
            <ListItem>
              <button
                type="button"
                onClick={() => {
                  window.open(pdfChosen.pdfURL, '_blank');
                }}
                className={classes.pdfButton}
              >
                <img src={pdf} alt="Pdf file" style={{ marginRight: '1rem' }} />{' '}
                <span>{pdfChosen.pdfName}</span>
              </button>
            </ListItem>
          ) : null}
          {feedbacks.length > 0 ? (
            <Fragment>
              <ListItem>
                <h4 style={{ color: '#4F4F4F', fontWeight: 400 }}>
                  Feedbacks Anteriores
                </h4>
              </ListItem>
              <FeedbackCard analysisRequest={filteredAnalysis} />
            </Fragment>
          ) : (
            <Fragment>
              <ListItem>
                <h4 style={{ color: '#4F4F4F', fontWeight: 400 }}>
                  Feedbacks Anteriores
                </h4>
              </ListItem>
              <div style={{ width: '100%', padding: '0 1.5rem' }}>
                <FeedbackList>
                  <h4 style={{ textAlign: 'center' }}>
                    Ainda não há feedbacks
                  </h4>
                </FeedbackList>
              </div>
            </Fragment>
          )}

          <ListItem
            className={classes.extracourseModalListItem}
            style={{ marginTop: '3rem' }}
          >
            <Button
              type="button"
              className={classNames([
                classes.popUpButtonNot,
                classes.extracourseModalButtons,
                classes.talentsButton,
              ])}
              onClick={() => {
                setAnalysisStatus(false);
                setIsOpen(true);
              }}
            >
              RECUSAR ANÁLISE
            </Button>
            <Button
              type="button"
              className={classNames([
                classes.popUpButtonYes,
                classes.extracourseModalButtons,
              ])}
              onClick={() => {
                setAnalysisStatus(true);
                setIsOpen(true);
              }}
            >
              VALIDAR ANÁLISE
            </Button>
          </ListItem>
        </List>
      </Grid>
      <FeedbackModal
        isOpen={isOpen}
        setClose={setClose}
        analysisStatus={analysisStatus}
        id={analysisId}
        workloadInitialValue={data.workload}
        competencesInitialValues={data.acquired_knowledges}
        handleUpdateAnalysisStatus={handleUpdateAnalysisStatus}
      />
    </Grid>
  );
}

ExtraCourseModal.propTypes = {
  classes: shape.isRequired,
  data: shape.isRequired,
  pdfChosen: shape.isRequired,
  analysisId: number.isRequired,
  handleUpdateAnalysisStatus: func.isRequired,
  feedbacks: shape.isRequired,
};

export default withStyles(dashboardStyle)(ExtraCourseModal);
