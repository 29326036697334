import React from 'react';
import { func, shape } from 'prop-types';
import qs from 'querystring';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import ViewsHeader from 'components/ViewsHeader/ViewsHeader';
import OpenCurriculum from 'components/Curriculum/OpenCurriculum';
import TalentsList from 'components/TalentsList/TalentsList';
import Paginations from 'components/Pagination/Pagination';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import headerBackgroundImage from 'assets/img/students.jpg';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';

import mergeContextsHelper from 'utils/mergeContextsHelper';
import {
  getTalentsFilters,
  getFilteredTalents,
  viewStudent,
  getTalentsCitiesFilters,
} from 'services/company/students';
import { hasPartners } from 'services/company/partners';
import { getStudentCurriculumData } from 'services/company/curriculum';
import { getApplicationCoverLetter } from 'services/company/coverLetter';

class Talents extends React.Component {
  state = {
    isFirstRequest: true,
    talentsListState: {},
    limit: 20,
    pages: [],
    totalItems: 0,
    pageActive: 1,
    numOfPages: 0,
    filtersOptions: [],
    citiesOptions: [],
    filterTalentsQS: '',
    pagesQS: '?limit=20&offset=0',
    loading: false,
    loadingFilters: false,
    haveCourse: false,
    liked: {},
    requesting: false,
  };

  async componentDidMount() {
    this.setState({ loadingFilters: true });
    this.getFilters().then(() => {
      this.setState({ loadingFilters: false });
    });

    this.setState({ loading: true });
    await this.talentsList();

    this.setState({ loading: false });
  }

  getFilters = async () => {
    const { openSnackbar } = this.props;
    const { filterTalentsQS } = this.state;

    const { course_level } = filterTalentsQS;
    const filters = await getTalentsFilters(qs.stringify({ course_level }));

    if (filters.message) {
      openSnackbar(
        `Houve um problema ao carregar os filtros! Tente novamente mais tarde.`,
        true
      );
    }

    this.setState({
      filtersOptions: filters,
      haveCourse: filters.courses && filters.courses.length > 0,
    });
  };

  talentsList = async (pageFilterParams = '') => {
    const { filterTalentsQS, pagesQS } = this.state;
    const { openSnackbar, handleIsLoadingState } = this.props;

    const pageParams = pagesQS
      ? pagesQS.match(/\?.+\d$/g)[0].replace(/\?/g, '&')
      : '';
    const newLiked = {};

    if (pageFilterParams) {
      const response = await getFilteredTalents(
        qs.stringify(filterTalentsQS) + pageFilterParams
      );

      if (response.message) {
        openSnackbar(
          `Houve um problema ao carregar os estudantes, tente novamente em
           alguns minutos.`,
          true
        );
      } else {
        this.setState(
          {
            talentsListState: response,
            totalItems: response.count,
            loading: false,
            requesting: false,
          },
          () => this.setPages()
        );

        response.results.forEach(({ id, curriculums }) => {
          newLiked[id] =
            curriculums.length > 0 && curriculums[0].liked_by.length > 0;
        });

        const holder = document.querySelector('#container');
        holder.scrollIntoView();
      }
    } else {
      const response = await getFilteredTalents(
        qs.stringify(filterTalentsQS) + pageParams
      );

      if (response.message) {
        openSnackbar(
          `Houve um problema ao carregar os estudantes, tente novamente em
           alguns minutos.`,
          true
        );
      } else {
        this.setState(
          {
            talentsListState: response,
            totalItems: response.count,
            loading: false,
            requesting: false,
          },
          () => this.setPages()
        );

        response.results.forEach(({ id, curriculums }) => {
          newLiked[id] =
            curriculums.length > 0 && curriculums[0].liked_by.length > 0;
        });

        const holder = document.querySelector('#container');
        holder.scrollIntoView();
      }
    }

    this.setState({ loading: false, liked: newLiked });
    handleIsLoadingState(false);
  };

  handleConnection = async () => {
    const { openTalkModal, history } = this.props;
    const partners = await hasPartners();
    if (partners && !partners.exists) {
      openTalkModal(
        {
          description: (
            <span>
              <p style={{ marginBottom: '20px' }}>Ops,</p>
              <p>
                Para realizar o filtro de talentos, é necessário se conectar com
                ao menos uma instituição de ensino.
              </p>
              <p>
                Para isso, vá no menu <strong>Instituições de Ensino</strong>,
                escolha as que fazem mais sentido para o seu negócio e clique em{' '}
                <strong>Conectar</strong>.
              </p>
              <p>
                Pronto! Agora é só aguardar o Aceite das instituições de ensino
                para acessar o filtro de talentos!
              </p>
            </span>
          ),
          buttonText: 'Conectar-se',
        },
        () => history.push('/instituicoes/conexoes')
      );
    }
  };

  setPages = async () => {
    const { totalItems, limit, pageActive } = this.state;

    const numOfPages = Math.ceil(totalItems / limit);

    if (numOfPages > 1) {
      const newPages = [
        { text: 'ANTERIOR', onClick: this.handlePreviousPage },
        { text: 1, onClick: this.handlePageChange, active: pageActive === 1 },
      ];

      for (let i = 2; i <= numOfPages; i++) {
        newPages.push({
          text: i,
          onClick: this.handlePageChange,
          active: pageActive === i,
        });
      }

      newPages.push({ text: 'PRÓXIMO', onClick: this.handleNextPage });
      this.setState({ pages: newPages, numOfPages });
    } else {
      this.setState({ pages: [], numOfPages });
    }
  };

  handlePageChange = (e) => {
    e.persist();

    const { limit } = this.state;

    const newPage = parseInt(e.target.textContent, 10);
    const newPagesQS = `?limit=${limit}&offset=${(newPage - 1) * limit}`;

    this.setState(
      { pageActive: newPage, pagesQS: newPagesQS, loading: true },
      () => this.talentsList()
    );
  };

  handleNextPage = () => {
    const { talentsListState, pageActive, numOfPages } = this.state;

    if (pageActive !== numOfPages) {
      const newPage = pageActive + 1;
      const newPagesQS = talentsListState.next;

      return this.setState(
        { pageActive: newPage, pagesQS: newPagesQS, loading: true },
        () => this.talentsList()
      );
    }
    return null;
  };

  handlePreviousPage = () => {
    const { talentsListState, pageActive } = this.state;

    if (pageActive - 1 > 0) {
      const newPage = pageActive - 1;
      const newPagesQS = talentsListState.previous;

      return this.setState(
        { pageActive: newPage, pagesQS: newPagesQS, loading: true },
        () => this.talentsList()
      );
    }
    return null;
  };

  setfilterTalentsQS = async (filter, getNewFilters = false) => {
    const { limit } = this.state;

    this.setState({
      requesting: true,
    });

    if (filter.states && filter.states.length > 0) {
      const cities = await getTalentsCitiesFilters(
        qs.stringify({ states: filter.states })
      );
      this.setState({
        citiesOptions: cities,
      });
    } else {
      this.setState({
        citiesOptions: [],
      });
    }

    this.setState(
      {
        filterTalentsQS: filter,
        isFirstRequest: false,
        pageActive: 1,
        loading: true,
      },
      () => {
        this.talentsList(`&limit=${limit}&offset=0`);
        if (getNewFilters) this.getFilters();
      }
    );
  };

  setLimit = (newValue) => {
    this.setState(
      {
        limit: newValue,
        pagesQS: `?limit=${newValue}&offset=0`,
        loading: true,
      },
      () => {
        this.talentsList();
      }
    );
  };

  openLetter = async (student) => {
    const {
      handleIsLoadingState,
      openModalWithContent,
      openSnackbar,
      closeModal,
    } = this.props;
    const { id } = student;

    openModalWithContent(
      <CoverLetterContainer
        notStudent
        studentId={id}
        handleLoadingState={handleIsLoadingState}
        getApplicationCoverLetter={getApplicationCoverLetter}
        openSnackbar={openSnackbar}
        closeModal={closeModal}
        updateHasCoverLetter={this.updateHasCoverLetter}
        openModalWithContent={openModalWithContent}
      />
    );
  };

  openCurriculum = async (student) => {
    const { handleIsLoadingState, openModalWithContent, openSnackbar } =
      this.props;
    const { liked } = this.state;
    const { id } = student;

    handleIsLoadingState(true);

    await viewStudent(id);

    openModalWithContent(
      <OpenCurriculum
        studentId={id}
        getStudentCurriculumData={getStudentCurriculumData}
        openSnackbar={openSnackbar}
        handleIsLoadingState={handleIsLoadingState}
        like
        liked={liked[id]}
        updateLiked={this.updateLiked}
        applicationIsInteresting={liked[id]}
      />
    );
  };

  updateLiked = ({ id, curriculums }) => {
    const { liked } = this.state;

    const newLiked = { ...liked };
    newLiked[id] = curriculums.length > 0 && curriculums[0].liked_by.length > 0;

    this.setState({ liked: newLiked });
  };

  render() {
    const propsCopy = { ...this.props };
    delete propsCopy.classes;
    const { name: componentTitle } = propsCopy;
    const { classes, openTalkModal } = this.props;
    const {
      talentsListState,
      isFirstRequest,
      filtersOptions,
      limit,
      pages,
      loading,
      loadingFilters,
      haveCourse,
      liked,
      filterTalentsQS,
      totalItems,
      requesting,
      citiesOptions,
    } = this.state;

    return (
      <div id="container">
        <ViewsHeader
          title={componentTitle}
          backgroundImage={headerBackgroundImage}
        />
        <GridContainer>
          <Grid item xs md={12} sm={12}>
            {mergeContextsHelper(
              <TalentsList
                company
                talentsListState={talentsListState?.results}
                isFirstRequest={isFirstRequest}
                openCurriculum={this.openCurriculum}
                filtersOptions={filtersOptions}
                openLetter={this.openLetter}
                setfilterTalentsQS={this.setfilterTalentsQS}
                limit={limit}
                setLimit={this.setLimit}
                loading={loading}
                loadingFilters={loadingFilters}
                resultsCount={totalItems}
                haveCourse={haveCourse}
                liked={liked}
                requesting={requesting}
                filterTalentsQS={filterTalentsQS}
                citiesOptions={citiesOptions}
                openTalkModal={openTalkModal}
                updateLiked={this.updateLiked}
              />
            )}
          </Grid>
          <Grid item xs md={12} sm={12} className={classes.flexCenter}>
            <Paginations pages={pages} color="dnaColor" />
          </Grid>
        </GridContainer>
      </div>
    );
  }
}

Talents.propTypes = {
  classes: shape({}).isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  openModalWithContent: func.isRequired,
  closeModal: func.isRequired,
  openTalkModal: func.isRequired,
  history: shape({}).isRequired,
};

export default withStyles(dashboardStyle)(Talents);
