import styled from 'styled-components';
import calendarGreenIcon from 'assets/mentorship/calendar-green.svg';
import calendarWhiteIcon from 'assets/mentorship/calendar-white.svg';

export const CalendarIcon = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  justify-content: center;
  align-items: center;
  background-image: url(${calendarGreenIcon});
  background-repeat: no-repeat;
  background-position: center;
`;

export const SelectorItem = styled.div`
  width: 7.375rem;
  height: 8.5rem;
  border-radius: 1.0625rem;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  input {
    display: none;
  }

  input:checked + label {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'}; //fazerDepois
    border-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'}; // fazerDepois
    color: #fff;
    padding-bottom: 30px;
  }

  input:checked + label > div {
    margin-top: -2rem;
    position: revert;
    background-image: url(${calendarWhiteIcon});
  }
`;

export const SelectorItemLabel = styled.label`
  position: revert;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  text-align: center;
  color: #4b4b4d;
  font-size: 1.125rem;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  border-radius: 1.0625rem;
  line-height: 1.4375rem;
  border: 1.5px solid #eaeaea;
`;
