import React, { Fragment, useEffect } from 'react';
import { shape } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import networkingStyle from 'assets/jss/material-dashboard-pro-react/views/networkingStyle';

import { Grid } from '@material-ui/core';
import Card from 'components/Card/Card';
import { Mixpanel } from 'utils/mixpanel';

const StudentsTab = ({ classes }) => {
  useEffect(() => {
    Mixpanel.track('Menu Networking: Clicou na aba Estudantes');
  }, []);
  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs lg={10} sm={12} xl={8} className={classes.gridItem}>
          <Card className={classes.emptyCard}>
            Em breve, você poderá conectar com seus colegas da sua instituição
            de ensino, cursos e disciplinas e desfrutar do poder do Networking!
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

StudentsTab.propTypes = {
  classes: shape.isRequired,
};

export default withStyles(networkingStyle)(StudentsTab);
