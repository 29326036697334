import styled from 'styled-components';
import arrowRight from 'assets/Oportutinites/Setadireita.svg';

export const NextButton = styled.button`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 2.5rem;
  bottom: 2.5rem;
  border: none;
  padding: 0;
  cursor: pointer;
  background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'}
    url(${arrowRight}) no-repeat center;
`;

export const CancelMentorshipButton = styled.button`
  font-size: 15px;
  color: '#009291';
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: #fff;
  text-align: start;
  padding: 0;
  margin: 0;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
`;
