import axios from 'axios';
import dataProductsAPI from 'services/dataProductsApi';

const CancelToken = axios.CancelToken;
let cancelGetDataAccess;
let cancelGetMarketRelationship;
let cancelGetStudentsCurriculum;
let cancelGetSystemUsage;
let cancelGetJourneyStatus;
let cancelGetEmployedUnemployed;
let cancelGetStudentsWorkingInArea;
let cancelGetCareerObjective;
let cancelGetProfessionalStatusChange;

export const cancelStudentInTrainingRequests = () => {
  cancelGetDataAccess && cancelGetDataAccess();
  cancelGetMarketRelationship && cancelGetMarketRelationship();
  cancelGetStudentsCurriculum && cancelGetStudentsCurriculum();
  cancelGetSystemUsage && cancelGetSystemUsage();
  cancelGetJourneyStatus && cancelGetJourneyStatus();
  cancelGetEmployedUnemployed && cancelGetEmployedUnemployed();
  cancelGetStudentsWorkingInArea && cancelGetStudentsWorkingInArea();
  cancelGetCareerObjective && cancelGetCareerObjective();
  cancelGetProfessionalStatusChange && cancelGetProfessionalStatusChange();
};

let cancelGetJobsWidget;
let cancelGetJobsArea;
let cancelGetJobsFinished;

export const cancelJobsRequests = () => {
  cancelGetJobsWidget && cancelGetJobsWidget();
  cancelGetJobsArea && cancelGetJobsArea();
  cancelGetJobsFinished && cancelGetJobsFinished();
};


let cancelGetCompaniesData;

export const cancelCompaniesRequests = () => {
  cancelGetCompaniesData && cancelGetCompaniesData();
};

export const getAccessData = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/student-access?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetDataAccess = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getMarketRelationship = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/market-relationship?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetMarketRelationship = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStudentsCurriculum = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/curriculum-timeline?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetStudentsCurriculum = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSystemUsage = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/system-usage?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetSystemUsage = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getJourneyStatus = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/journey-status?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetJourneyStatus = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getEmployedUnemployed = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/employed-unemployed?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetEmployedUnemployed = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStudentsWorkingInArea = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/students-working-in-area?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetStudentsWorkingInArea = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCareerObjective = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/career-objective?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetCareerObjective = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getProfessionalStatusChange = async (status, params) => {
  const query = params ? `&${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/professional-status-change?status=${status}${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetProfessionalStatusChange = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOptionsStudent = async () => {
  try {
    const response = await dataProductsAPI.get(`/university/insights/options`);
    return response.data.result;
  } catch (error) {
    return error;
  }
};

export const getMeDataProducts = async (type) => {
  try {
    const response = await dataProductsAPI.get(`me/`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getJobsWidget = async (params) => {
  const query = params ? `?${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/jobs-charts${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetJobsWidget = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getJobsArea = async (params) => {
  const query = params ? `?${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/dashboard/jobs-by-area${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetJobsArea = c;
        }),
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getCompaniesData = async (params) => {
  const query = params ? `?${params}` : '';
  try {
    const response = await dataProductsAPI.get(
      `university/insights/companies${query}`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetCompaniesData = c;
        }),
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};
