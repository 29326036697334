import styled from 'styled-components';

export const FormInput = styled.div`
  display: flex;
  height: 38px;
  flex-direction: column;
  position: relative;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
    margin: 0 0 8px 0;
  }

  input {
    display: flex;
    justify-content: start;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: #606062;
    border: 0.64px solid ${(props) => (props.error ? '#DA0505' : '#c4c4c4')};

    &:focus {
      border: ${(props) =>
        props.error ? '0.64px solid #DA0505' : '2px solid #009291'};
    }

    &::placeholder {
      color: #cdcdcd;
    }
  }
`;

export const PredefinedValuesList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 7px 0 #00000026;
  max-height: 250px;
  width: fit-content;
  background-color: #fff;
  position: absolute;
  margin: 75px 0 0 0;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;

export const ListItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: ${(props) => (props.selected ? '#009291' : '#fff')};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: auto auto;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  position: relative;
  z-index: 3;

  span {
    text-align: center;
    font-size: 0.9375rem;
    font-weight: ${(props) => (props.selected ? '500' : '400')};
    line-height: 1.125rem;
    font-family: 'Roboto', sans-serif;
    color: ${(props) => (props.selected ? '#FFF' : '#4B4B4D')};
  }
`;
