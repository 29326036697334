import React, { useEffect, useMemo, useRef, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { useField } from '@unform/core';
import { FormInput, ListItem, PredefinedValuesList } from './styles';
import { handleFieldMask } from 'utils/validation';
import { maskTime } from 'utils/inputMasks';
import { leftPad } from 'utils/stringUtils';

registerLocale('ptBR', ptBR);

export default function TimePicker({
  name,
  label,
  validateForm,
  placeholder,
  onTouched,
  ...rest
}) {
  const ref = useRef(null);
  const [predefinedValuesOpen, setPredefinedValuesOpen] = useState(false);
  const [predefinedValues, setPredefinedValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [touched, setTouched] = useState(false);
  const optionsRef = useRef([]);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const allPredefinedValuesOptions = useMemo(
    () =>
      [...Array(24).keys()].reduce(
        (acc, value) => [
          ...acc,
          `${leftPad(value, 2)}:00`,
          `${leftPad(value, 2)}:15`,
          `${leftPad(value, 2)}:30`,
          `${leftPad(value, 2)}:45`,
        ],
        []
      ),
    []
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        predefinedValuesOpen
      ) {
        setPredefinedValuesOpen(false);
        if (validateForm) {
          validateForm();
        }
      }
    };
    document.addEventListener('click', handleClickOutside);
    const handleKey = (event) => {
      if (predefinedValuesOpen) {
        switch (event.key) {
          case 'Enter':
            ref.current.value = selectedValue;
            validateForm();
            setPredefinedValuesOpen(false);
            break;
          case 'ArrowDown':
            if (
              selectedValue !== predefinedValues[predefinedValues.length - 1]
            ) {
              const index = predefinedValues.indexOf(selectedValue);
              setSelectedValue(predefinedValues[index + 1]);
              optionsRef.current[index + 1].scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
              });
            }
            break;
          case 'ArrowUp':
            if (selectedValue !== predefinedValues[0]) {
              const index = predefinedValues.indexOf(selectedValue);
              setSelectedValue(predefinedValues[index - 1]);
              optionsRef.current[index - 1].scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
              });
            }
            break;
          case 'Escape':
            setPredefinedValuesOpen(false);
            break;
          default:
            break;
        }
      }
    };
    document.addEventListener('keydown', handleKey);
    return () => {
      document.removeEventListener('keydown', handleKey, false);
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [selectedValue, predefinedValuesOpen, predefinedValues]);

  useEffect(() => {
    setPredefinedValues(allPredefinedValuesOptions);
    optionsRef.current = optionsRef.current.slice(0, 96);
  }, []);

  useEffect(() => {
    const updateSelectedValue = () => {
      setSelectedValue(
        predefinedValues.length > 0 ? predefinedValues[0] : null
      );
    };
    updateSelectedValue();
  }, [predefinedValues]);

  return (
    <FormInput error={error !== undefined}>
      <label>{label}</label>
      <input
        ref={ref}
        defaultValue={defaultValue}
        placeholder={placeholder}
        maxLength={5}
        onFocus={() => {
          onTouched();
          setPredefinedValuesOpen(true);
          const value = ref.current.value;
          setPredefinedValues(
            allPredefinedValuesOptions.filter(
              (item) => item.substring(0, value.length) === value
            )
          );
        }}
        onChange={async (event) => {
          if (predefinedValuesOpen) {
            const value = maskTime(event.target.value);
            setPredefinedValues(
              allPredefinedValuesOptions.filter(
                (item) => item.substring(0, value.length) === value
              )
            );
            for (let index = 0; index < predefinedValues.length; index++) {
              if (
                predefinedValues[index].substring(0, value.length) === value
              ) {
                optionsRef.current[index].scrollIntoView();
                break;
              }
            }
          } else {
            setPredefinedValuesOpen(true);
          }
        }}
        onKeyUp={async (event) => {
          handleFieldMask(event, maskTime);
        }}
        {...rest}
      />
      {predefinedValuesOpen && (
        <PredefinedValuesList>
          {predefinedValues.map((value, index) => (
            <ListItem
              ref={(el) => (optionsRef.current[index] = el)}
              key={value}
              selected={selectedValue === value}
              onMouseEnter={() => {
                setSelectedValue(value);
              }}
              onClick={() => {
                ref.current.value = value;
                validateForm();
              }}
            >
              <span>{value}</span>
            </ListItem>
          ))}
        </PredefinedValuesList>
      )}
      {error && <span style={{ color: '#DA0505' }}>{error}</span>}
    </FormInput>
  );
}
