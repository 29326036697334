import styled from 'styled-components';
import selectedStar from 'assets/mentorship/small-selected-star.svg';
import unselectedStar from 'assets/mentorship/small-unselected-star.svg';

export const Header = styled.div`
  flex-direction: column;
  text-align: start;
  width: 720px;

  h1 {
    color: #009291;
    line-height: 24px;
    font-size: 21px;
    font-weight: 700;
    margin: 0;
    width: fit-content;
  }

  > p {
    font-size: 14px;
    line-height: 16px;
    color: #606062;
    font-weight: 400;
    margin: 11px 0;
  }
`;
export const Container = styled.div`
  max-width: 100%;
  padding: 0 2.5rem 2rem;

  > form {
    display: grid;
    flex-direction: column;
    grid-template: ${(props) =>
      ['realized', 'canceled', 'expired'].includes(props.status) &&
      `'date time' 'responsible responsible' 'description description'`};
    grid-template-columns: ${(props) =>
      !['realized', 'canceled', 'expired'].includes(props.status) && '1fr 1fr'};
    gap: 2rem;
    margin: 21px 0 0 0;

    > div {
      display: flex;
      flex-direction: column;

      label {
        font-size: 15px;
        line-height: 17px;
        color: #606062;
        font-weight: 700;
      }
    }

    > div.access_link {
      display: block;
    }
  }
`;

export const ModalHeader = styled.div``;

export const StudentNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 28px 0 28px 0;
  width: 100%;
  border-radius: 4px;
  flex-direction: column;

  strong {
    color: #606062;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    color: #4b4b4d;
    font-weight: 400;
    margin: 10px 0 0 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  margin: 0 0 3rem 0;
  width: fit-content;
`;

export const OutlinedButton = styled.button`
  background: #fff;
  border-radius: 4.74px;
  border: 0.947368px solid #009291;
  color: #009291;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  cursor: pointer;
  width: 190px;
  height: 40px;
  text-align: center;
  transition: 0.3s;

  &:hover {
    filter: brightness(90%);
  }
`;

export const RealizedMentorshipContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
  width: 100%;

  > strong {
    color: #009291;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }

  > p {
    color: #606062;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.5px;
    margin: 10px 0 0 0;
  }

  form {
    display: flex;
    flex-direction: column;

    strong {
      font-weight: 700;
      font-size: 15px;
      line-height: 17px;
      color: #606062;
      margin: 1rem 0 0 0;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #606062;
      margin: 4px 0 20px 0;
    }
  }
`;

export const FilledButton = styled.button`
  background: #009291;
  border-radius: 4.4px;
  border: none;
  color: #fff;
  font-size: 16px;
  line-height: 18.5px;
  font-weight: 700;
  cursor: pointer;
  width: fit-content;
  padding: 0 2.5rem;
  height: 37px;
  text-align: center;
  transition: 0.3s;
  margin: 23px 0 0 0;

  &:hover {
    filter: brightness(90%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #777777b2;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
`;

export const StarIcon = styled.div`
  height: 19px;
  width: 19px;
  background-size: cover;
  background-image: url(${(props) =>  props.selected ? selectedStar : unselectedStar});
  background-repeat: no-repeat;
  background-position: center;
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: description;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
  }

  div {
    margin-top: 0.5rem;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: #606062;
    background-color: #fafafa;
    border: 0.64px solid #c4c4c4;

    &::placeholder {
      color: #cdcdcd;
    }
  }
`;

export const DropArea = styled.div`
  width: 100%;
  border: 1px dashed #606062;
  height: 185px;
  border-radius: 8px;
  position: relative;
  display: ${({ selectedFile }) => (selectedFile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  > input {
    width: 100%;
    height: 185px;
    opacity: 0;
    z-index: 10;
  }

  > div {
    position: absolute;
    max-width: 450px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 14px;

    > img {
      margin-bottom: 1.2em;
    }

    .circle-area {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;

      background-color: ${(props) =>
        props.disabled ? '#E5E5E5' : props.universityColor || '#009291'};
    }
  }
`;

export const ButtonFile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 170px;
  height: 30px;
  padding: 10px;

  background-color: #009291;

  font-weight: 700;
  font-size: 12px;
  color: #fff;

  border: none;
  border-radius: 20px;

  cursor: pointer;
`

export const ContainerButton = styled.div`
  margin: 2rem 0 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`