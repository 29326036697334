import {
  BackgroundModals,
  ContainerModalFeedback,
  BodyModalFeedback,
  HeaderModalFeedback,
  ParagraphFeedback,
  ButtonCloseFeedback,
  ContainerContent,
  ContainerColumn,
  CustomTextParagraph,
} from './styled';
import ReactHtmlParser from 'react-html-parser';

export default function ModalFeedbackJobOffer({
  feedback,
  handleCloseModal,
  universityColor,
}) {
  return (
    <BackgroundModals>
      <ContainerModalFeedback>
        <HeaderModalFeedback universityColor={universityColor}>
          <ParagraphFeedback>Feedback da vaga</ParagraphFeedback>

          <ButtonCloseFeedback onClick={handleCloseModal}>
            X
          </ButtonCloseFeedback>
        </HeaderModalFeedback>

        <BodyModalFeedback>
          <CustomTextParagraph
            color="#6d6d6d"
            fontWeight="500"
            fontSize="14px"
            style={{ marginBottom: '5px' }}
          >
            Feedback recebido após a finalização da vaga:
          </CustomTextParagraph>

          <ContainerContent
            style={{
              marginTop: '0px',
              backgroundColor: '#fff',
              border: '1px solid #d0d0d0',
              borderRadius: '4px',
            }}
          >
            <ContainerColumn>
              {ReactHtmlParser(feedback)}
            </ContainerColumn>
          </ContainerContent>
        </BodyModalFeedback>
      </ContainerModalFeedback>
    </BackgroundModals>
  );
}
