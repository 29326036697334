import { IoLocationSharp, IoClose, IoImage } from 'react-icons/io5';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsFolder2Open, BsGrid } from 'react-icons/bs';
import JobBagIcon from 'assets/ComponentIcons/JobBagIcon';
import {
  MdOutlineMail,
  MdLocationOn,
  MdOutlineImageSearch,
} from 'react-icons/md';
import {
  AiOutlineFilePdf,
  AiFillCloseCircle,
  AiOutlineHeart,
  AiOutlineUnorderedList,
  AiOutlineEye,
} from 'react-icons/ai';
import { BsFillCheckCircleFill, BsImageAlt } from 'react-icons/bs';
import { ImLocation } from 'react-icons/im';
import FlagIcon from 'assets/ComponentIcons/FlagIcon';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import { FiTrash } from 'react-icons/fi';
import { RiAddLine } from 'react-icons/ri';
import { BiTrashAlt } from 'react-icons/bi';
import { FaRegEdit, FaDownload } from 'react-icons/fa';
import { CgFileDocument } from 'react-icons/cg';
import { HiOutlineUser, HiClock } from 'react-icons/hi';

export const useIcons = () => {
  return {
    IoLocationSharp,
    IoIosArrowDown,
    IoIosArrowUp,
    BsFolder2Open,
    JobBagIcon,
    MdOutlineMail,
    BsGrid,
    AiOutlineFilePdf,
    BsFillCheckCircleFill,
    AiFillCloseCircle,
    MdLocationOn,
    ImLocation,
    FlagIcon,
    AiOutlineHeart,
    AiOutlineUnorderedList,
    IoClose,
    PDFIcon,
    FiTrash,
    AiOutlineEye,
    RiAddLine,
    BiTrashAlt,
    FaRegEdit,
    IoImage,
    BsImageAlt,
    MdOutlineImageSearch,
    CgFileDocument,
    HiOutlineUser,
    HiClock,
    FaDownload,
  };
};
