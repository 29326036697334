import React, { Component, Fragment } from 'react';
import {
  SaveButtonProfileForm,
  CancelButtonProfileForm,
} from './ButtonsProfileForm';

import { Formik, FastField, Field } from 'formik';
import {
  FormHelperText,
  Grid,
  TextField,
  List,
  ListItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import classNames from 'classnames';
import {
  TitleDiversity,
  ImgIconDiv,
  CardDiversity,
  SubtitleDiversity,
  GenderLegend,
  ImageTip,
} from '../Styled';
import IconDiversity from './components/IconDiversity';
import DateTimePicker from 'components/DateTimePicker';
import withStyles from '@material-ui/core/styles/withStyles';
import { bool, func, shape, string } from 'prop-types';
import { maskInputPhone } from 'utils/inputMasks';
import {
  updateAvatar,
  removeAvatar,
  updateProfile,
  getOptionsProfile,
} from 'services/student/profile';
import { updateCurriculum } from 'services/student/curriculumData';
import {
  profileAdapter,
  profilePayloadAdapter,
} from 'adapters/student/profileAdapter';
import { specialInformationAdapter } from 'adapters/curriculum/specialInformationAdapter';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import SearchableSelect, { Creatable } from 'react-select';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import CardContent from '@material-ui/core/CardContent';
import { handleFieldMask } from 'utils/validation';
import { maskZipCode } from 'utils/inputMasks';
import { triggerPageView } from '../../../../utils/analytics';
import { getProfile } from '../../../../services/student/profile';
import useDefineColor from 'constants/colors';
import { zipCodeRequest } from 'services/zipCode';
import { AiOutlineSearch } from 'react-icons/ai';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { updateUTMParams } from 'utils/utmManagement';
import UploadedStudentAvatarCustom from 'components/UploadedStudentAvatarCustom/UploadedStudentAvatarCustom';
import { ProfileSchema } from './utils/schema';
import { emailBlackList } from 'services/login/recoverPassword';

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      genderOptions: [],
      disableCancelButton: true,
      maritalStatusOptions: [],
      genderIdentityOptions: [],
      colorOrRaceOptions: [],
      cepError: false,
      initialValues: {
        name: '',
        district: '',
        birth_date: '',
        id: null,
        avatar: '',
        avatarRemoved: false,
        email: '',
        mobilePhone: '',
        facebookInstagramUrl: '',
        portfolioUrl: '',
        linkedinUrl: '',
        state: null,
        city: null,
        address: null,
        addressNumber: null,
        zipCode: '',
        street: '',
        district: '',
        anotherDisability: '',
        showDisability: false,
        showColorOrRace: false,
        showGenderIdentity: false,
        disabled: false,
      },
    };
  }

  setGenderDefaltValue = (genderSelected) => {
    if (genderSelected.value === 'not_informed') {
      return {
        label: 'Prefiro não responder',
        value: 'not_informed',
      };
    }
    if (genderSelected.value) {
      return genderSelected;
    }
    return '';
  };

  setFile = (file) => {
    this.setState({ avatar: file });
  };

  setRemove = (file) => {
    this.setState({ avatarRemoved: file });
  };

  async UNSAFE_componentWillMount() {
    const { profileData } = this.props;

    const {
      name,
      avatar,
      email,
      mobile_phone,
      facebook_url,
      portfolio_url,
      linkedin_url,
      state,
      zip_code,
      city,
      district,
      address,
      address_complement,
      address_number,
      gender,
      marital_status,
      birth_date,
      special_needs,
      travel_and_change,
      anotherDisability,
      gender_identity,
      color_or_race,
      show_color_or_race,
      show_gender_identity,
      show_special_needs,
    } = profileData;

    this.setState({
      initialValues: {
        name,
        district,
        birth_date: birth_date || '',
        gender: gender.value ? gender : '',
        marital_status: marital_status.value ? marital_status : '',
        avatar,
        email,
        mobilePhone: mobile_phone ? maskInputPhone(mobile_phone) : '',
        facebookInstagramUrl: facebook_url,
        linkedinUrl: linkedin_url,
        portfolioUrl: portfolio_url,
        state: state ? state : null,
        city: city ? city : null,
        zipCode: zip_code,
        address,
        addressComplement: address_complement ? address_complement : '',
        addressNumber: address_number ? address_number : '',
        specialNeeds: special_needs,
        genderIdentity: gender_identity ? gender_identity : '',
        colorOrRace: color_or_race ? color_or_race : '',
        travel_and_change,
        anotherDisability,
        showGenderIdentity: show_gender_identity ? show_gender_identity : false,
        showColorOrRace: show_color_or_race ? show_color_or_race : false,
        showDisability: show_special_needs ? show_special_needs : false,
        disabled: false,
      },
    });
  }

  async componentDidMount() {
    const options = await getOptionsProfile();
    const newData = await getProfile();

    function verifyRequiredData() {
      if (
        !newData.academic_profile.name ||
        !newData.academic_profile.city ||
        !newData.academic_profile.state ||
        !newData.academic_profile.email ||
        !newData.academic_profile.mobile_phone ||
        !newData.academic_profile.gender_identity ||
        !newData.academic_profile.color_or_race ||
        !newData.academic_profile.zip_code
      ) {
        return true;
      } else {
        return false;
      }
    }
    this.setState({
      disableCancelButton: verifyRequiredData(),
      genderOptions: [
        {
          label: 'Masculino',
          value: 'male',
        },
        {
          label: 'Feminino',
          value: 'female',
        },
        {
          label: 'Prefiro não responder',
          value: 'not_informed',
        },
      ],
      maritalStatusOptions: options.marital_status,
      specialNeeds: options.special_needs,
      genderIdentityOptions: options.gender_identity,
      colorOrRaceOptions: options.color_or_race,
      zipCode: newData.academic_profile.zip_code,
      address: newData.academic_profile.address,
      addressComplement: newData.academic_profile.address_complement,
      addressNumber: newData.academic_profile.address_number,
      district: newData.academic_profile.district,
      state: newData.academic_profile.state,
      city: newData.academic_profile.city,
    });
  }

  upperName = (name) => {
    let formatName = '';

    name.split(' ').forEach((element) => {
      formatName += `${element.charAt(0).toUpperCase() + element.slice(1)} `;
    });
    return formatName;
  };

  handleFieldsAutoFill = (data, setFieldValue) => {
    setFieldValue('address', data.logradouro);
    setFieldValue('district', data.bairro);
    setFieldValue('state', data.uf);
    setFieldValue('city', data.localidade);
    setFieldValue('addressComplement', '');
    setFieldValue('addressNumber', '');

    this.setState({
      address: data.logradouro,
      district: data.bairro,
      state: data.uf,
      city: data.localidade,
      addressComplement: '',
      addressNumber: '',
    });
  };

  handleZipCodeChange = async (
    e,
    handleChange,
    setFieldValue,
    setFieldError
  ) => {
    handleChange(e);
    const zipCode = e.target.value;

    if (zipCode.length === 9) {
      try {
        await zipCodeRequest(zipCode).then((data) => {
          if (data.erro) {
            this.setState({ cepError: true });
            setFieldError('zipCode', 'CEP inválido');
            return;
          }
          this.setState(
            {
              zipCode: maskZipCode(zipCode),
            },
            () => {
              this.handleFieldsAutoFill(data, setFieldValue);
            }
          );
        });
      } catch (error) {
        return error;
      }
    } else if (zipCode.length < 9) {
      this.setState({ cepError: false });
    }
  };

  render() {
    const {
      classes,
      closeModal,
      updateProfileState,
      updateAvatarState,
      handleIsLoadingState,
      profileRequiredFields,
      handleShowSpecialNeeds,
      setClose,
      universityColor,
      setProfileData,
      fetchGamificationData,
      metadata,
    } = this.props;
    const {
      avatarRemoved,
      initialValues,
      genderOptions,
      disableCancelButton,
      genderIdentityOptions,
      colorOrRaceOptions,
      maritalStatusOptions,
      specialNeeds,
    } = this.state;
    const { avatar } = initialValues;
    const { cepError } = this.state;

    return (
      <Grid
        container
        justify="center"
        className={classes.fullScreenGridContainer}
      >
        <Grid item xs md={12} className={classes.fullScreenGrid}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={ProfileSchema}
            onSubmit={async (values, actions) => {
              handleIsLoadingState(true);
              this.setState({ disabled: true });

              const response = await emailBlackList({ email: values.email });
              const { is_blacklisted } = response;

              if (is_blacklisted) {
                customSnackbar(
                  'E-mail inválido. Informe outro endereço de e-mail.',
                  'error'
                );
                handleIsLoadingState(false);
                this.setState({ disabled: false });
                return;
              }

              actions.setSubmitting(true);
              values.name = this.upperName(values.name);
              const newProfile = await updateProfile(
                profilePayloadAdapter(values)
              );
              setProfileData(newProfile.academic_profile);
              const showDisabilityStatus = await updateCurriculum(
                specialInformationAdapter(values)
              );

              const newData = await getProfile();
              let newAvatar;
              let currentAvatar = newData.academic_profile.avatar;

              if (document.getElementById('avatar')?.src && !avatarRemoved) {
                if (this.state.avatar !== undefined && this.state.avatar !== currentAvatar) {
                  newAvatar = await updateAvatar(this.state.avatar);
                  newProfile.academic_profile.avatar = newAvatar.avatar;
                } else {
                  newProfile.academic_profile.avatar = currentAvatar;
                }
              } else if (avatar && avatarRemoved) {
                newAvatar = await removeAvatar();
                newProfile.academic_profile.avatar = null;
              }
              const avatarResponse =
                newAvatar && newAvatar.message ? newAvatar.message : null;

              const error =
                newProfile.message ||
                showDisabilityStatus.message ||
                avatarResponse;

              if (error) {
                customSnackbar(
                  `Erro ao gravar seus dados. Se o erro persistir, 
                  entre em contato com o suporte avisando 
                  sobre o erro: ${newAvatar.response.data[0]}
                `,
                  'error'
                );
                actions.setSubmitting(false);
                handleIsLoadingState(false);

                return;
              }

              if (newAvatar) {
                updateAvatarState(newAvatar);
              } else {
                updateAvatarState(values.avatar);
              }

              newProfile.academic_profile.specialNeeds = values.specialNeeds;
              updateProfileState(
                profileAdapter(newProfile.academic_profile),
                newProfile
              );
              newProfile.academic_profile.show_color_or_race =
                values.showColorOrRace;
              updateProfileState(
                profileAdapter(newProfile.academic_profile),
                newProfile
              );
              newProfile.academic_profile.show_gender_identity =
                values.showGenderIdentity;
              updateProfileState(
                profileAdapter(newProfile.academic_profile),
                newProfile
              );
              newProfile.academic_profile.show_special_needs =
                values.showDisability;
              updateProfileState(
                profileAdapter(newProfile.academic_profile),
                newProfile
              );
              newProfile.academic_profile.travel_and_change =
                values.travel_and_change;
              updateProfileState(
                profileAdapter(newProfile.academic_profile),
                newProfile
              );
              fetchGamificationData && (await fetchGamificationData());
              updateUTMParams(metadata, newProfile.academic_profile, true);
              setClose();
              actions.setSubmitting(false);
              handleIsLoadingState(false);
              setTimeout(() => {
                closeModal();
                customSnackbar(
                  'Dados pessoais salvos com sucesso!',
                  'confirmation'
                );
              }, 700);
              triggerPageView('student/perfil/cadastro');
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit} className={classes.fullWidth}>
                <List className={classes.list}>
                  <UploadedStudentAvatarCustom
                    setFile={this.setFile}
                    widthImageDisplay="200px"
                    heightImageDisplay="200px"
                    imageUrl={values.avatar}
                    handleChange={handleChange}
                    removeButton={(value) => {
                      this.setState({ avatarRemoved: value });
                    }}
                  />
                  <ImageTip>
                    <p>
                      <strong>Dica:</strong> Sua imagem deve ser menor que 1 MB.
                      Apenas os formatos JPG e PNG serão aceitos.
                    </p>
                  </ImageTip>
                </List>
                <Grid container justify="center" spacing={24}>
                  <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <Grid container justify="center" spacing={24}>
                        {profileRequiredFields && (
                          <Grid item xs={12} md={12}>
                            <Card className={classes.cardAlertWarning}>
                              <CardContent
                                className={classes.alertContentWarning}
                              >
                                <h3 className={classes.alertWarningTitle}>
                                  <Icon className={classes.iconWarning}>
                                    info_outline
                                  </Icon>
                                  Atenção
                                </h3>
                                <p>
                                  Antes de prosseguir, é necessário que você
                                  preencha <b> Nome e Sobrenome, </b>
                                  <b> Data de Nascimento. </b>
                                </p>
                              </CardContent>
                            </Card>
                          </Grid>
                        )}
                        <Grid item xs={12} md={6} sm={6}>
                          <FastField
                            autoFocus
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              FormLabelClasses: {
                                focused: classes.inputField,
                              },
                            }}
                            name="name"
                            variant="filled"
                            id="name"
                            label="Nome e Sobrenome"
                            placeholder="Informe apenas 1 nome e 1 sobrenome"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.name && !!touched.name}
                            value={values.name}
                            component={TextField}
                            required
                          />

                          {errors.name && touched.name && (
                            <Fragment>
                              <FormHelperText className={classes.helperText}>
                                {errors.name}
                              </FormHelperText>
                            </Fragment>
                          )}
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <DateTimePicker
                            className={
                              (classes.textFieldDatePicker, classes.inputField)
                            }
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              FormLabelClasses: {
                                focused: classes.inputField,
                              },
                            }}
                            variant="filled"
                            name="birth_date"
                            id="birth_date"
                            type="date"
                            label="Data de Nascimento"
                            placeholder="Ex.: 17/11/2018"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.birth_date}
                            error={errors.birth_date && touched.birth_date}
                            required
                          />
                          {errors.birth_date && touched.birth_date && (
                            <Fragment>
                              <FormHelperText className={classes.helperText}>
                                {errors.birth_date}
                              </FormHelperText>
                            </Fragment>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} sm={6}>
                          <FormControl
                            variant="filled"
                            style={{ width: '100%' }}
                          >
                            <InputLabel htmlFor="gender" shrink>
                              Sexo
                            </InputLabel>
                            <SearchableSelect
                              name="gender"
                              id="gender"
                              isClearable={false}
                              InputLabel={{
                                shrink: true,
                              }}
                              placeholder="Selecione"
                              isSearchable
                              classNamePrefix="react-select"
                              className={classNames([
                                classes.selectSection,
                                classes.fullWidth,
                              ])}
                              onBlur={(event) =>
                                setFieldTouched('gender', event.label)
                              }
                              onChange={(event) => {
                                if (event) {
                                  setFieldValue('gender', event);
                                } else {
                                  setFieldValue('gender', '');
                                }
                              }}
                              options={genderOptions || []}
                              defaultValue={this.setGenderDefaltValue(
                                values.gender
                              )}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} sm={6}>
                          <FormControl
                            variant="filled"
                            style={{ width: '100%' }}
                          >
                            <InputLabel htmlFor="marital_status" shrink>
                              Estado Cívil
                            </InputLabel>
                            <SearchableSelect
                              name="marital_status"
                              id="marital_status"
                              isClearable
                              InputLabel={{
                                shrink: true,
                              }}
                              placeholder="Selecione"
                              isSearchable
                              classNamePrefix="react-select"
                              className={classNames([
                                classes.selectSection,
                                classes.fullWidth,
                              ])}
                              onBlur={(event) =>
                                setFieldTouched('marital_status', event.label)
                              }
                              onChange={(event) => {
                                if (event) {
                                  setFieldValue('marital_status', event);
                                } else {
                                  setFieldValue('marital_status', '');
                                }
                              }}
                              options={maritalStatusOptions || []}
                              defaultValue={
                                values.marital_status.value &&
                                values.marital_status
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Fragment>
                          <Grid item xs={12} md={6}>
                            <FormControl
                              variant="filled"
                              style={{ width: '100%' }}
                            >
                              <InputLabel htmlFor="specialNeeds" shrink>
                                Selecione ou informe sua deficiência
                              </InputLabel>
                              <Creatable
                                name="special_needs"
                                id="special_needs"
                                isMulti
                                InputLabel={{
                                  shrink: true,
                                }}
                                placeholder="Selecione"
                                isSearchable
                                formatCreateLabel={(newDisability) =>
                                  `Adicionar ${newDisability}`
                                }
                                classNamePrefix="react-select"
                                className={classNames([
                                  classes.selectSection,
                                  classes.fullWidth,
                                  classes.selectSectionAutoHeight,
                                ])}
                                onBlur={(event) => {
                                  setFieldTouched('specialNeeds', event.label);
                                }}
                                onChange={(event) => {
                                  if (
                                    event.filter((item) => item.value === null)
                                      .length === 0
                                  ) {
                                    setFieldValue('anotherDisability', '');
                                  }
                                  if (event) {
                                    setFieldValue('specialNeeds', event);
                                    if (event.length === 0) {
                                      setFieldValue('showDisability', false);
                                    }
                                  } else {
                                    setFieldValue('specialNeeds', '');
                                    setFieldValue('anotherDisability', '');
                                  }
                                }}
                                options={specialNeeds || []}
                                defaultValue={
                                  values.specialNeeds.length > 0 &&
                                  values.specialNeeds
                                }
                              />
                            </FormControl>
                            {errors.special_needs && (
                              <FormHelperText className={classes.helperText}>
                                {
                                  errors.special_needs[
                                    errors.special_needs.length - 1
                                  ].label
                                }
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl
                              component="fieldset"
                              className={
                                (classes.formControl, classes.fullWidth)
                              }
                            >
                              <FormGroup
                                className={classes.group}
                                style={{ alignItems: 'center' }}
                              >
                                <FormControlLabel
                                  label={
                                    <Fragment>
                                      Desejo compartilhar essa informação em
                                      processos seletivos inclusivos e no meu
                                      currículo
                                    </Fragment>
                                  }
                                  labelPlacement="end"
                                  tabIndex={-1}
                                  control={
                                    <Checkbox
                                      classes={{
                                        checked:
                                          classes[
                                            `${useDefineColor(
                                              universityColor
                                            )}checkBox`
                                          ],
                                      }}
                                      disabled={
                                        values.specialNeeds.length === 0
                                      }
                                      color="default"
                                      checked={values.showDisability}
                                      onChange={(event) => {
                                        handleShowSpecialNeeds(
                                          event.target.checked
                                        ),
                                          setFieldValue(
                                            'showDisability',
                                            event.target.checked
                                          );
                                      }}
                                    />
                                  }
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                        </Fragment>

                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ position: 'relative' }}
                        >
                          <FastField
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                              endAdornment: (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '20px',
                                    transform: 'translateY(-50%)',
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <AiOutlineSearch size={24} />
                                </div>
                              ),
                            }}
                            name="zipCode"
                            placeholder="Digite aqui..."
                            label="CEP *"
                            variant="filled"
                            id="zipCode"
                            error={!!errors.zipCode}
                            component={TextField}
                            maxLength="9"
                            onPaste={(e) => {
                              this.handleZipCodeChange(
                                e,
                                handleChange,
                                setFieldValue,
                                setFieldError
                              );
                              this.setState({
                                zipCode: handleFieldMask(e, maskZipCode),
                              });
                            }}
                            onChange={(e) => {
                              this.setState({
                                zipCode: handleFieldMask(e, maskZipCode),
                              });
                              this.handleZipCodeChange(
                                e,
                                handleChange,
                                setFieldValue,
                                setFieldError
                              );
                            }}
                            onKeyUp={(e) => {
                              handleFieldMask(e, maskZipCode);
                            }}
                            value={this.state.zipCode || values.zipCode}
                          />
                          {errors.zipCode && (
                            <Fragment>
                              <FormHelperText className={classes.helperText}>
                                {errors.zipCode}
                              </FormHelperText>
                            </Fragment>
                          )}
                        </Grid>

                        {this.state.zipCode?.length >= 9 && !cepError ? (
                          <Fragment>
                            <Grid item xs={12} md={8}>
                              <Field
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="address"
                                label="Rua/Avenida *"
                                placeholder="Rua/Avenida"
                                variant="filled"
                                id="address"
                                error={!!errors.address}
                                disabled={false}
                                component={TextField}
                                onChange={(e) => {
                                  this.setState({ address: e.target.value });
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={this.state.address || values.address}
                              />
                              {errors.address && (
                                <Fragment>
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.address}
                                  </FormHelperText>
                                </Fragment>
                              )}
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Field
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="addressNumber"
                                label="Número *"
                                placeholder="Digite aqui..."
                                variant="filled"
                                id="addressNumber"
                                type="number"
                                min="0"
                                error={!!errors.addressNumber}
                                component={TextField}
                                onChange={(e) => {
                                  this.setState({
                                    addressNumber: e.target.value,
                                  });
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={
                                  this.state.addressNumber ||
                                  values.addressNumber
                                }
                              />
                              {errors.addressNumber && (
                                <Fragment>
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.addressNumber}
                                  </FormHelperText>
                                </Fragment>
                              )}
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Field
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="addressComplement"
                                label="Complemento"
                                placeholder="Complemento"
                                variant="filled"
                                id="addressComplement"
                                component={TextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.addressComplement}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Field
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="district"
                                label="Bairro *"
                                error={!!errors.district}
                                placeholder="Bairro"
                                variant="filled"
                                id="district"
                                component={TextField}
                                onChange={(e) => {
                                  this.setState({ district: e.target.value });
                                  handleChange(e);
                                }}
                                onBlur={handleBlur}
                                value={this.state.district || values.district}
                              />
                              {errors.district && (
                                <Fragment>
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.district}
                                  </FormHelperText>
                                </Fragment>
                              )}
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Field
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                  style: { backgroundColor: '#fff' },
                                  disabled: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="state"
                                label="UF *"
                                variant="filled"
                                id="state"
                                disabled
                                component={TextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={this.state.state || values.state}
                              />
                            </Grid>

                            <Grid item xs={12} md={8}>
                              <FastField
                                className={classes.inputField}
                                InputProps={{
                                  className: classes.input,
                                  style: { backgroundColor: '#fff' },
                                  disabled: true,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                  FormLabelClasses: {
                                    focused: classes.inputField,
                                  },
                                }}
                                name="city"
                                label="Cidade *"
                                placeholder="Cidade"
                                variant="filled"
                                disabled
                                id="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={TextField}
                                value={this.state.city || values.city}
                              />
                            </Grid>
                          </Fragment>
                        ) : (
                          <Grid item xs={12} md={8}></Grid>
                        )}

                        {/* E-mail */}
                        <Grid item xs={12} md={6}>
                          <FastField
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              FormLabelClasses: {
                                focused: classes.inputField,
                              },
                            }}
                            name="email"
                            variant="filled"
                            id="email"
                            label="E-mail Pessoal *"
                            placeholder="Informe um e-mail pessoal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.email && !!touched.email}
                            value={values.email}
                            component={TextField}
                          />
                          <Fragment>
                            {errors.email && (
                              <div style={{ display: 'flex' }}>
                                <FormHelperText
                                  className={classes.helperText}
                                  style={{
                                    textAlign: 'inherit',
                                    padding: '0px 3px',
                                  }}
                                >
                                  Informe seu melhor e-mail
                                </FormHelperText>
                                <FormHelperText className={classes.helperText}>
                                  {errors.email}
                                </FormHelperText>
                              </div>
                            )}
                          </Fragment>
                        </Grid>
                        {/* mobilePhone */}
                        <Grid item xs={12} md={6}>
                          <FastField
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                            }}
                            InputLabelProps={{
                              shrink: true,
                              FormLabelClasses: {
                                focused: classes.inputField,
                              },
                            }}
                            name="mobilePhone"
                            variant="filled"
                            id="mobilePhone"
                            label="Celular *"
                            placeholder="(11) 99163-8247"
                            onChange={(event) => {
                              event.target.value = maskInputPhone(
                                event.target.value
                              );
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            error={
                              !!errors.mobilePhone && !!touched.mobilePhone
                            }
                            value={values.mobilePhone}
                            component={TextField}
                          />
                          {!!errors.mobilePhone && !!touched.mobilePhone ? (
                            <FormHelperText className={classes.helperText}>
                              {errors.mobilePhone}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      </Grid>
                    </ListItem>

                    {/* linkedinURL */}
                    <ListItem className={classes.listItem}>
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        name="linkedinUrl"
                        variant="filled"
                        id="linkedinUrl"
                        label="Linkedin"
                        placeholder="Ex.: https://www.linkedin.com/in/aceleradoradecarreiras/"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.linkedinUrl && !!touched.linkedinUrl}
                        value={values.linkedinUrl}
                        component={TextField}
                      />
                      {!!errors.linkedinUrl && !!touched.linkedinUrl ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.linkedinUrl}
                        </FormHelperText>
                      ) : null}
                    </ListItem>

                    {/* facebookInstagramUrl */}
                    <ListItem className={classes.listItem}>
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        name="facebookInstagramUrl"
                        variant="filled"
                        id="facebookInstagramUrl"
                        label="Facebook/Instagram"
                        placeholder="Ex: https://www.facebook.com/ ou https://www.instagram.com/"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          errors.facebookInstagramUrl &&
                          touched.facebookInstagramUrl
                        }
                        value={values.facebookInstagramUrl}
                        component={TextField}
                      />
                      {errors.facebookInstagramUrl &&
                      touched.facebookInstagramUrl ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.facebookInstagramUrl}
                        </FormHelperText>
                      ) : null}
                    </ListItem>

                    {/* Portifolio */}
                    <ListItem className={classes.listItem}>
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        name="portfolioUrl"
                        variant="filled"
                        id="portfolioUrl"
                        label="Portfólio Geral"
                        placeholder="Ex: Github, GitLab, Behance, Dribbble..."
                        onChange={handleChange}
                        error={!!errors.portfolioUrl && !!touched.portfolioUrl}
                        onBlur={handleBlur}
                        value={values.portfolioUrl}
                        component={TextField}
                      />
                      {!!errors.portfolioUrl && !!touched.portfolioUrl ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.portfolioUrl}
                        </FormHelperText>
                      ) : null}
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <FormControl
                        component="fieldset"
                        className={(classes.formControl, classes.fullWidth)}
                      >
                        <FormGroup
                          className={classes.group}
                          style={{ alignItems: 'center' }}
                        >
                          <FormControlLabel
                            label={
                              <Fragment>
                                Disponibilidade para viagens e mudança de
                                cidade.
                              </Fragment>
                            }
                            labelPlacement="start"
                            tabIndex={-1}
                            control={
                              <Checkbox
                                classes={{
                                  checked:
                                    classes[
                                      `${useDefineColor(
                                        universityColor
                                      )}checkBox`
                                    ],
                                }}
                                color="default"
                                checked={values.travel_and_change}
                                onClick={(event) => {
                                  setFieldValue(
                                    'travel_and_change',
                                    event.target.checked
                                  );
                                }}
                              />
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <TitleDiversity universityColor={universityColor}>
                        Dados de Diversidade
                      </TitleDiversity>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <CardDiversity universityColor={universityColor}>
                        <ImgIconDiv>
                          <IconDiversity color={universityColor} />
                        </ImgIconDiv>
                        <p>
                          Caso deseje, você poderá informar sua identidade de
                          gênero, cor e raça/etnia. O uso desses dados serão
                          utilizados somente após seu consentimento, podendo ser
                          revogado a qualquer momento. Essas informações serão
                          utilizadas para permitir ações afirmativas pelas
                          empresas recrutadoras, promovendo a diversidade no
                          mercado de trabalho.
                        </p>
                      </CardDiversity>
                    </ListItem>
                    <SubtitleDiversity>
                      Com qual identidade de gênero você se identifica?
                    </SubtitleDiversity>
                    <GenderLegend>
                      <p>
                        <strong>Cisgênero:</strong> pessoa que se identifica com
                        o gênero (homem/mulher) atribuído no nascimento.
                      </p>
                      <p>
                        <strong>Transgênero:</strong> pessoa que se identifica
                        com algum gênero diferente do atribuído no nascimento.
                      </p>
                    </GenderLegend>
                    <ListItem
                      className={classes.listItem}
                      style={{ left: '2%', marginTop: 10 }}
                    >
                      <Grid container justify="center" spacing={24}>
                        <Grid xs={12} md={6} sm={6}>
                          <FormControl
                            variant="filled"
                            style={{ width: '100%' }}
                          >
                            <InputLabel htmlFor="genderIdentity" shrink>
                              Selecione*
                            </InputLabel>
                            <SearchableSelect
                              name="genderIdentity"
                              id="genderIdentity"
                              label="Selecione*"
                              isClearable={false}
                              InputLabel={{
                                shrink: true,
                              }}
                              placeholder="Selecione"
                              isSearchable
                              required
                              classNamePrefix="react-select"
                              error={
                                errors.genderIdentity && touched.genderIdentity
                              }
                              className={classNames([
                                classes.selectSection,
                                classes.fullWidth,
                              ])}
                              onBlur={(event) =>
                                setFieldTouched('genderIdentity', event.label)
                              }
                              onChange={(event) => {
                                if (event) {
                                  setFieldValue('genderIdentity', event);
                                } else {
                                  setFieldValue('genderIdentity', '');
                                }
                              }}
                              options={genderIdentityOptions || []}
                              value={values.genderIdentity}
                              defaultValue={
                                values.genderIdentity
                                  ? values.genderIdentity.value
                                  : null
                              }
                            />
                          </FormControl>
                          {errors.genderIdentity && touched.genderIdentity ? (
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.genderIdentity}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={6}
                          style={{
                            padding: '6px 20px',
                          }}
                        >
                          <FormControl
                            component="fieldset"
                            className={(classes.formControl, classes.fullWidth)}
                          >
                            <FormGroup
                              className={classes.group}
                              style={{
                                alignItems: 'center',
                              }}
                            >
                              <FormControlLabel
                                label={
                                  <Fragment>
                                    Desejo compartilhar essa informação em
                                    processos seletivos inclusivos
                                  </Fragment>
                                }
                                labelPlacement="end"
                                tabIndex={-1}
                                control={
                                  <Checkbox
                                    classes={{
                                      checked:
                                        classes[
                                          `${useDefineColor(
                                            universityColor
                                          )}checkBox`
                                        ],
                                    }}
                                    color="default"
                                    disabled={!values.genderIdentity}
                                    checked={values.showGenderIdentity}
                                    onChange={(event) => {
                                      handleShowSpecialNeeds(
                                        event.target.checked
                                      ),
                                        setFieldValue(
                                          'showGenderIdentity',
                                          event.target.checked
                                        );
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ListItem>

                    <SubtitleDiversity
                      style={{ marginTop: 15, marginBottom: 10 }}
                    >
                      Qual é a sua “cor ou raça/etnia”?
                    </SubtitleDiversity>
                    <ListItem
                      className={classes.listItem}
                      style={{ left: '2%' }}
                    >
                      <Grid container justify="center" spacing={24}>
                        <Grid xs={12} md={6} sm={6}>
                          <FormControl
                            variant="filled"
                            style={{ width: '100%' }}
                          >
                            <InputLabel htmlFor="colorOrRace" shrink>
                              Selecione*
                            </InputLabel>
                            <SearchableSelect
                              name="colorOrRace"
                              id="colorOrRace"
                              label="Selecione*"
                              isClearable={false}
                              InputProps={{
                                className: classes.input,
                              }}
                              InputLabel={{
                                shrink: true,
                              }}
                              placeholder="Selecione"
                              isSearchable
                              classNamePrefix="react-select"
                              required
                              className={classNames([
                                classes.selectSection,
                                classes.fullWidth,
                              ])}
                              error={errors.colorOrRace && touched.colorOrRace}
                              onBlur={(event) => {
                                setFieldTouched('colorOrRace', event.label);
                              }}
                              onChange={(event) => {
                                if (event) {
                                  setFieldValue('colorOrRace', event);
                                } else {
                                  setFieldValue('colorOrRace', null);
                                }
                              }}
                              options={colorOrRaceOptions || []}
                              value={values.colorOrRace}
                              defaultValue={
                                values.colorOrRace
                                  ? values.colorOrRace.value
                                  : null
                              }
                            />
                          </FormControl>
                          {errors.colorOrRace && touched.colorOrRace ? (
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.colorOrRace}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={6}
                          style={{
                            padding: '6px 20px',
                          }}
                        >
                          <FormControl
                            component="fieldset"
                            className={(classes.formControl, classes.fullWidth)}
                          >
                            <FormGroup
                              className={classes.group}
                              style={{ alignItems: 'center' }}
                            >
                              <FormControlLabel
                                label={
                                  <Fragment>
                                    Desejo compartilhar essa informação em
                                    processos seletivos inclusivos
                                  </Fragment>
                                }
                                labelPlacement="end"
                                tabIndex={-1}
                                control={
                                  <Checkbox
                                    classes={{
                                      checked:
                                        classes[
                                          `${useDefineColor(
                                            universityColor
                                          )}checkBox`
                                        ],
                                    }}
                                    color="default"
                                    checked={values.showColorOrRace}
                                    disabled={!values.colorOrRace}
                                    onChange={(event) => {
                                      handleShowSpecialNeeds(
                                        event.target.checked
                                      ),
                                        setFieldValue(
                                          'showColorOrRace',
                                          event.target.checked
                                        );
                                    }}
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ListItem>
                    {/* Save Button */}
                    <ListItem
                      className={classes.listItem}
                      style={{ flexDirection: 'row', padding: 25 }}
                    >
                      <CancelButtonProfileForm
                        disabled={disableCancelButton}
                        universityColor={universityColor}
                        onClick={() => this.props.closeModal()}
                        className={classes.saveButton}
                        style={{ width: 190, borderRadius: 25 }}
                      >
                        Cancelar
                      </CancelButtonProfileForm>
                      <SaveButtonProfileForm
                        disabled={this.state.disabled}
                        universityColor={universityColor}
                        style={{ width: 190, borderRadius: 25 }}
                        type="submit"
                        className={classes.saveButton}
                      >
                        Salvar
                      </SaveButtonProfileForm>
                    </ListItem>
                  </List>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

ProfileForm.defaultProps = {
  profileRequiredFields: undefined,
  isFromJobOffers: false,
  isMyProfile: false,
  setClose: () => {},
};

ProfileForm.propTypes = {
  setClose: func,
  isMyProfile: bool,
  isFromJobOffers: bool,
  studentProfileState: shape({}).isRequired,
  handleShowSpecialNeeds: func.isRequired,
  classes: shape({}).isRequired,
  closeModal: func.isRequired,
  updateProfileState: func.isRequired,
  updateAvatarState: func.isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  profileRequiredFields: func,
  universityColor: string,
};

export default withStyles(ProfessionalExperienceFormStyles)(ProfileForm);
