import styled, { keyframes } from 'styled-components';
import { IndicateStudentButton } from 'views/StaffPages/jobOpeningsRebuild/components/IndicationStudent';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

const getMatchColor = (match) => {
  if (match >= 0 && match <= 33.9) {
    return '#CF0000';
  } else if (match >= 34 && match <= 69.9) {
    return '#E4C000';
  } else if (match >= 70 && match <= 99.9) {
    return '#3392DB';
  } else if (match === 100) {
    return '#009660';
  } else {
    return '#009291';
  }
};

export const CircleMatch = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  border: 10px solid ${(props) => getMatchColor(props.match)};
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  ${(props) =>
    props.mediaQueryMaxWidth
      ? `@media (max-width: ${props.mediaQueryMaxWidth}){
      margin-bottom: 0px;
      margin-right: 0px;
    }`
      : ''}
`;

export const NumberMatchParagraph = styled.p`
  color: ${(props) => getMatchColor(props.match)};
  font-weight: bold;
  font-size: 18px;

  margin: 0;
`;

export const ParagraphMatch = styled.p`
  color: #606062;
  font-weight: 400;
  font-size: 12px;

  margin: 0;
`;

export const ContainerDataInformation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
`

export const ContainerDataExpired = styled.div`
  background-color: #d3eaec;
  color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border-radius: 20px;
  font-weight: 400;
`

export const ContainerParagraphMatch = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
  margin-top: 30px;
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const ContainerContent = styled.div`
  background-color: #f8fafc;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  margin-top: 30px;
  justify-content: space-between;

  ul {
    margin: 0;
    padding: 0 15px;
  }
`;

export const ReversedColumnOnMobileContainerContent = styled(ContainerContent)`
  gap: 50px;
  transition: 0.2s;

  > div {
    width: auto;
  }

  > div > p {
    line-height: 1.3em;
  }

  @media (max-width: 639px) {
    flex-direction: column-reverse;
    gap: 10px;
    transition: 0.2s;

    > div {
      align-items: center;
    }

    > div > p {
      text-align: center;
    }
  }
`;

export const JobTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 15px calc(5px + 1%);
  background-color: #f8fafc;
  margin-top: 30px;
  width: auto;

  @media (max-width: 639px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonDetailsModal = styled.button`
  width: auto;
  height: auto;
  padding: 10px;
  background-color: ${(props) => props.color || '#009291'};
  font-weight: 400;
  font-size: 1em;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  margin-top: 15px;
`;

export const ModalEnrollButton = styled(ButtonDetailsModal)`
  @media (max-width: 639px) {
    order: 1;
  }
`;

export const TextParagraph = styled.p`
  font-size: ${(props) => (props.isTitleDetails ? '1.375em' : '1.125em')};
  font-weight: ${(props) => (props.isTitleDetails ? 'bold' : '400')};
  color: ${(props) => (props.isOtherExternal ? '#005096' : '#606062')};;
  margin: 0;
  font-style: ${(props) => (props.isItalicStyle ? 'italic' : 'normal')};

  @media (max-width: 425px) {
    font-size: 14px;
  }

  a{
    color: #005096
  }
`;

export const TextCode = styled.p`
  font-size: 0.9em;
  font-weight: 400;
  color: #606062;
  margin: 0;
  font-style: normal;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const CustomTextParagraph = styled.p`
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}` : props.isCode ? '0.9em' : '1.125em'};
  font-weight: ${(props) =>
    props.fontWeight ? `${props.fontWeight}` : props.isBold ? 'bold' : '400'};
  color: ${(props) => (props.color ? `${props.color}` : '#606062')};
  margin: 0;
  font-style: ${(props) => (props.isItalicStyle ? 'italic' : 'normal')};
  text-align: ${(props) => (props.textAlign ? `${props.textAlign}` : 'start')};
`;

export const ListParagraph = styled.li`
  font-size: 1.125em;
  font-weight: 400;
  color: #606062;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: ${(props) => (props.isContentCenter ? 'center' : 'flex-start')};
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  margin-top: 20px;

  div {
    display: flex;
    gap: 5px;
  }

  img {
    width: 18px;
    height: 18px;
  }
`;

export const ContainerSimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  img {
    width: 18px;
    height: 18px;
  }
`;

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  gap: 20px;

  @media (max-width: 729px) {
    flex-direction: column;
    margin-top: 10px;
    gap: 4px;
  }

  @media (max-width: 475px) {
    padding: 0.7rem;
    padding-top: 0px;
    margin-top: 5px;
  }
`;

export const ContainerMatch = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;

  background-color: #009660;
  margin-right: 15px;
`;

export const ContainerMatchTopBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 172px;
  position: relative;

  .bottom {
    position: absolute;
    vertical-align: bottom;
    bottom: 0;
  }
`;

export const ContentIcon = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    opacity: 0.2;
  }
`;

export const ButtonTransparent = styled.button`
  width: auto;
  height: auto;
  background-color: transparent;
  font-weight: 400;
  font-size: 1em;
  color: ${(props) => props.color || '#009291'};
  border: none;
  cursor: pointer;
  text-decoration: underline;
`;

export const ContainerIconAndText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0rem 3rem 0rem;
  overflow-y: auto;
  max-height: 100%;
  width: 100%;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
`;

export const ContainerHeaderExternal = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const BackButton = styled.button`
  width: 35px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 6px;
  border: none;

  font-family: Roboto;
  font-size: 18px;

  margin-top: 1.5rem;
  margin-left: 1.5rem;
  padding: 0.3rem;
  gap: 0.5rem;

  cursor: pointer;
  position: relative;

  > p {
    position: absolute;
    left: 35px;

    margin: 0;
    color: ${(props) => props.color || '#009291'};
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    width: 100px;

    > p {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    transition: 0.3s;
  }

  > svg {
    fill: ${(props) => props.color || '#009291'};
    width: 25px;
    height: 25px;
    transition: fill 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    > p {
      display: inherit;
      opacity: 1;
    }

    width: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: red;
  gap: 1rem;
`;

export const FragmentColor = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 190px;
  background: linear-gradient(to bottom, ${(props) => props.color}, white);
`;

export const ContainerLogo = styled.div`
  margin-top: -6rem;
  width: 160px;
  height: 160px;
  border-radius: 100px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #cdcdcd;
  ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
  background-size: cover;
  background-position: center;

  img {
    width: 120px;
    height: auto;
  }
`;

export const TextName = styled.div`
  font-size: 23px;
  font-weight: 700;
  font-family: Roboto;
  color: #606062;
  line-height: 30px;
  text-align: center;
  padding: 0.5rem;
  text-transform: capitalize;
  text-decoration: capitalize;

  height: ${(props) => (props.card ? '76px' : 'unset')};

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const Box = styled.div`
  padding: ${(props) => (props.isDetailsMatch ? 'unset' : '1.5rem')};
  width: 100%;
  @media (max-width: 475px) {
    padding: 0.7rem;
  }
`;

export const ContainerScrollY = styled.div`
  height: 65vh;
  overflow-x: hidden;
  overflow-y: scroll !important;

  @media (max-width: 337px) {
    height: 60vh;
  }
`;

export const ButtonFavorite = styled.button`
  background-color: ${(props) => props.color};
  height: 37px;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 5px;
  justify-content: space-evenly;
  width: 100%;
  max-width: 225px;

  > svg {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 778px) {
    padding: 0.7rem;
    position: absolute;
    top: 11px;
    right: 11px;
  }

  @media (max-width: 778px) {
    margin-top: 15px;
    height: 3rem;
    max-width: 16rem;
    justify-content: center;
    gap: 8px;
  }
`;

export const BoxModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: #eeeeee;
  z-index: 1300;
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 259px)')};
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  height: 100%;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0rem;
  }
`;

export const ContainerTextLoading = styled.div`
  width: 300px;
  height: 50px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerLogoLoading = styled.div`
  width: 164px;
  height: 164px;
  border-radius: 50%;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerContentLoading = styled.div`
  width: 100%;
  height: 100px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerBackgroundModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #00000030;
  z-index: 1500;

  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;

  overflow: auto;

  @media (max-width: 690px) {
    align-items: flex-end !important;
  }
`;
export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: #fff;
  padding: 50px 60px;
  border-radius: 10px;

  gap: 4px;
  height: auto;
  margin-top: 12%;

  @media (max-width: 690px) {
    animation: ${slideIn} 0.3s ease-in-out;
    margin-bottom: 0px;
    top: 360px;
  }

  @media (max-width: 450px) {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const ParagraphDetailsMatch = styled.p`
  font-size: ${(props) => (props.isTitleMatch ? '1.3em' : '1.2em')};
  font-weight: ${(props) => (props.isTitleMatch ? 'bold' : '400')};
  text-align: start;
  color: #606062;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 1em;
  }
`;

export const Title = styled.h1`
  font-size: 1.3em;
  font-weight: 700;
  color: #606062;
  margin: 0px 6px;

  @media (max-width: 388px) {
    max-width: 230px;
  }
`;

export const TextDetailsMatch = styled.p`
  font-size: ${(props) => (props.isParagraph ? '1em' : '1.1em')};
  font-weight: ${(props) => (props.isParagraph ? '400' : 'bold')};
  text-align: start;
  color: #606062;
  margin: 0;

  @media (max-width: 425px) {
    font-size: 0.9em;
  }

  li {
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const ButtonClose = styled.button`
  background-color: #efeeec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  color: #606062;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;

  position: absolute;
  top: -17px;
  right: -18px;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  gap: ${(props) => (props.isDetailsMatch ? '30px' : 'unset')};

  @media (max-width: 475px) {
    padding: 0.7rem;
    padding-bottom: 0px;
  }
`;

export const IndicationRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IndicateButton = styled(IndicateStudentButton)`
  width: 148px;
  height: 36px;
  background: #009291;
  color: #fff;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

export const ButtonJobShare = styled.button`
  height: 36px;
  cursor: pointer;
  background-color: white;
  padding: 6px;
  color: #009291;
  border: solid 1px #009291;
  border-radius: 6px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
`;

export const BackgroundModals = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 690px) {
    align-items: flex-end !important;
  }
`;

export const ContainerModalFeedback = styled.div`
  overflow: hidden;
  background-color: #fff;
  margin-top: 3%;
  display: flex;
  align-items: start;
  flex-direction: column;
  z-index: 2;
  border-radius: 10px;
  max-width: 870px;

  height: auto;
  width: 100%;
  max-width: 670px;
`;

export const BodyModalFeedback = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 40px;
`;

export const HeaderModalFeedback = styled.div`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#fff'};

  width: 100%;
  height: 90px;
  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ParagraphFeedback = styled.p`
  margin: 0;
  font-size: 22px;
  font-weight: 700;

  color: #fff;
  height: unset;
`;

export const ButtonCloseFeedback = styled.button`
  background-color: #efeeec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: #606062;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;
