import { SkeletonBox } from '../../styles';
import React from 'react';
import { MentorshipSkeleton } from './styles';
import {
  Container,
  Divider,
  ProfileImage,
  ScheduleButton,
  StatusIndicator,
  ExpandIcon,
} from './styles';

export default function MentorshipListSkeleton() {
  return (
    <Container>
      {Array.from({ length: 3 }).map((value, index) => (
        <div style={{ width: '100%', flexDirection: 'column' }}>
          <MentorshipSkeleton>
            <ProfileImage />
            <div style={{ width: '100%' }}>
              <SkeletonBox style={{ width: '30%', height: '12px' }} />
              <div>
                <SkeletonBox style={{ width: '40%', height: '18px' }} />
                <ExpandIcon />
              </div>
              <SkeletonBox style={{ width: '30%', height: '13px' }} />
              <SkeletonBox style={{ width: '45%', height: '13px' }} />
            </div>
            <div
              style={{
                margin: '0 0 0 auto',
                display: 'flex',
                flexDirection: 'column',
                gap: '6px',
                alignItems: 'end',
              }}
            >
              <ScheduleButton />
              <StatusIndicator />
            </div>
          </MentorshipSkeleton>
          {index !== 2 && <Divider />}
        </div>
      ))}
    </Container>
  );
}
