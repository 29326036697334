import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;

  > h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }

  > strong {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    line-height: 23px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    color: #4b4b4d;
    margin: 1.5rem 0 0 0;
  }

  > div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0;
    gap: 1rem;
  }
`;

export const ActionButton = styled.button`
  width: 200px;
  max-width: 40%;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #fff;
  font-family: 'Roboto', sans-serif;
  height: 2.25rem;
  border-radius: 6px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  white-space: nowrap;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
