import React, { Fragment, useEffect, useState } from 'react';
import { useCsm } from '../../../contexts/CsmContext';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterContainer, FilterFlexBox, FilterGrid, SizedBox } from './styles';
import FilterModal from 'components/FilterModal';
import states from 'variables/states';
import { StudentCategory } from '../../../resources/defaultStudentCategories';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import Api from '../../../services/api';

const TabLabel = {
  PERSONAL_INFO: 'Informações pessoais',
  PROFESSIONAL_REQUIREMENTS: 'Requisitos profissionais',
  ACADEMIC_REQUIREMENTS: 'Requisitos acadêmicos',
  SKILLS: 'Skills',
};

export function StudentFilter({ loading, title }) {
  const {
    activeCategory,
    filterOptions,
    filterStudents,
    filterState,
    setFilterState,
    appliedFilters,
    setAppliedFilters,
    setFilterOptions,
    egressed,
  } = useCsm();

  const [isFetchingCities, setIsFetchingCities] = useState(false);

  let tabs = Object.values(TabLabel);
  if (activeCategory === StudentCategory.INACTIVE) {
    tabs = tabs.filter(
      (tab) => tab !== 'Status profissional' && tab !== 'Skills'
    );
  }

  function renderFilters() {
    return Object.values(TabLabel).reduce((acc, tab) => {
      if (tabs.includes(tab)) {
        acc.push(renderTab(tab));
      }
      return acc;
    }, []);
  }

  async function handleChangeStates(states) {
    if (states && states.length > 0) {
      setIsFetchingCities(true);
      const data = await Api.getCitiesOptions(activeCategory, states);
      if (filterState['cities']) {
        const newFilterState = { ...filterState };
        const responseCitiesValues = data.cities.map((city) => city.value);
        const newCities = newFilterState.cities.filter((city) =>
          responseCitiesValues.includes(city)
        );
        if (newCities.length > 0) {
          newFilterState.cities = newCities;
        } else {
          delete newFilterState.cities;
        }
        newFilterState.states = states;
        setFilterState(newFilterState);
      }
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
      setIsFetchingCities(false);
    } else {
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.states;
        delete newFilterState.cities;
        return newFilterState;
      });
    }
  }

  function handleApplyFilter() {
    filterStudents(
      queryParamsFromFilterObject(filterState, {
        commaSeparatedFields: ['periods', 'name', 'external_id', 'areas'],
      })
    );
  }

  function handleClearFilter() {
    filterStudents(null);
  }

  async function handleCloseFilter() {
    const hasStatesApplied =
      appliedFilters &&
      appliedFilters['states'] &&
      appliedFilters['states'].length > 0;
    if (hasStatesApplied) {
      const data = await Api.getCitiesOptions(
        activeCategory,
        appliedFilters['states'],
        egressed
      );
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
    }
  }

  useEffect(() => {
    setAppliedFilters({});
  }, [activeCategory]);

  function renderTab(tab) {
    switch (tab) {
      case TabLabel.PERSONAL_INFO:
        return (
          <FilterFlexBox>
            <FilterGrid>
              <Filter
                label="Filtrar por nome"
                name={'name'}
                type={FilterType.TEXT}
              />
              <Filter
                label="Código do estudante"
                name={'external_id'}
                type={FilterType.TEXT}
              />
              <Filter
                label="UF"
                name={'states'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.states}
                settings={{
                  onChange: handleChangeStates,
                }}
              />
              <Filter
                label="Cidade"
                name={'cities'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.cities}
                settings={{
                  disabled: !filterState['states'] || isFetchingCities,
                  loading: isFetchingCities,
                }}
              />
            </FilterGrid>
            <Filter
              label="Unidade/Polo"
              name={'unities'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.unities}
            />
            <Filter
              label="Sexo"
              name={'gender'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.gender}
            />
            {activeCategory !== StudentCategory.INACTIVE && (
              <Fragment>
                <strong>Diversidade</strong>
                <FilterGrid>
                  <Filter
                    label="PCD - Pessoa com deficiência"
                    name={'special_needs'}
                    type={FilterType.MULTI_SELECT}
                    options={filterOptions.specialNeeds}
                  />
                  <Filter
                    label="Cor/Raça"
                    name={'color_or_race'}
                    type={FilterType.MULTI_SELECT}
                    options={filterOptions.colorOrRace}
                  />
                </FilterGrid>
                <Filter
                  label="Identidade de gênero"
                  name={'gender_identity'}
                  type={FilterType.MULTI_SELECT}
                  options={filterOptions.genderIdentity}
                />
              </Fragment>
            )}
            <SizedBox height={'96px'} />
          </FilterFlexBox>
        );
      case TabLabel.PROFESSIONAL_REQUIREMENTS:
        return (
          <FilterFlexBox>
            <strong>Requisitos Profissionais</strong>
            <Filter
              label="Competências Técnicas"
              name={'areas'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.areas}
            />
            <Filter
              label="Setor de Experiência"
              name={'professional_experience_areas'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.professionalAreas}
            />
            <strong>Status profissional</strong>
            {activeCategory === StudentCategory.WORKING ? (
              <Filter
                label="Tipo de trabalho"
                name={'job_types'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.jobTypes}
              />
            ) : (
              <FilterGrid>
                <Filter
                  label="Status profissional"
                  name={'professional_status'}
                  type={FilterType.SINGLE_SELECT}
                  options={filterOptions.professionalStatus}
                />
                <Filter
                  label="Tipo de trabalho"
                  name={'job_types'}
                  type={FilterType.MULTI_SELECT}
                  options={filterOptions.jobTypes}
                />
              </FilterGrid>
            )}
            <Filter
              label="Interessado em"
              name={'professional_offers'}
              type={FilterType.CHECKBOX}
              options={filterOptions.professionalOffers}
            />
            <FilterGrid>
              <Filter
                label="Na área de formação"
                name={'working_in_formation_area'}
                type={FilterType.RADIO}
                options={filterOptions.workingInFormationArea}
                settings={{
                  wrap: true,
                }}
              />
            </FilterGrid>
            <FilterGrid>
              <Filter
                label="Possui carta de apresentação"
                name={'has_cover_letter'}
                type={FilterType.SINGLE_CHECKBOX}
              />
              {activeCategory !== StudentCategory.WITH_CURRICULUM &&
                activeCategory !== StudentCategory.DIRECT_HIRIGN && (
                  <Filter
                    label="Possui currículo"
                    name={'has_curriculum'}
                    type={FilterType.SINGLE_CHECKBOX}
                  />
                )}
            </FilterGrid>
          </FilterFlexBox>
        );
      case TabLabel.ACADEMIC_REQUIREMENTS:
        return (
          <FilterFlexBox>
            <FilterGrid>
              <Filter
                label="Grau de escolaridade"
                name={'course_level'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.courseLevel}
              />
              {/*academic progress filter is not usual but must be kept
               visible by commercial strategy*/}
              <Filter
                label="Rendimento acadêmico"
                name={'academic_progress'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.academicProgress}
              />
            </FilterGrid>
            {filterOptions.attendanceMode && (
              <Filter
                label="Modalidade"
                name={'attendance_mode'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.attendanceMode}
              />
            )}
            <FilterGrid>
              <Filter
                label="Curso"
                name={'courses'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.courses}
              />
              <Filter
                label="Período"
                name={'periods'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.periods}
              />
            </FilterGrid>
            <Filter
              label="Tipo de estudante"
              name={'student_status'}
              type={FilterType.TEXT}
              settings={{
                disabled: true,
                placeholder: egressed ? 'Egressos' : 'Matriculados',
              }}
            />
            <SizedBox height={'64px'} />
          </FilterFlexBox>
        );
      case TabLabel.SKILLS:
        return (
          <FilterFlexBox>
            <Filter
              label="Comportamental"
              name={'behavioral_attributes'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.behavioralAttributes}
            />
            <Filter
              label="Personalidade"
              name={'personality'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.personality}
              settings={{
                listHeight: '112px',
              }}
            />
            <SizedBox height={'96px'} />
          </FilterFlexBox>
        );
      default:
        return null;
    }
  }

  return (
    <FilterContainer title={title}>
      <FilterModal
        filterState={filterState}
        setFilterState={setFilterState}
        tabs={tabs}
        loading={loading}
        onApply={handleApplyFilter}
        onClean={handleClearFilter}
        onClose={handleCloseFilter}
        setAppliedFilters={setAppliedFilters}
        appliedFilters={appliedFilters}
      >
        {renderFilters()}
      </FilterModal>
    </FilterContainer>
  );
}
