import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  ContainerHeaderAndInformation,
  ContainerSelects,
  ContainerTerms,
} from './styled';
import { StepTitle } from '../styled';
import { Form } from '@unform/web';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import StepController from '../../StepController';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import UnformCheckbox from 'components/UnformFields/UnformCheckbox';
import Tooltip from 'components/NewTooltip';
import { HintInformation } from 'components/UnformFields/inputField/style';
import FairAndEventsService from '../../../Services/index';
import { useDebounce } from 'hooks/useDebounce';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';

export default function StepFour() {
  const formRef = useRef(null);
  const useRegisterFormInstance = useRegisterModalContext();
  const visulizationContextInstance = useVisualizationContext();

  const FormHandlersFactory = FormHandlers(
    useRegisterFormInstance,
    visulizationContextInstance
  );

  const { selectOptions, stepsData, formRefStepFour } = useRegisterFormInstance;
  const [relatedAreasOptions, setRelatedAreasOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(true);

  const debounceSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debounceSearchTerm.length > 2) {
      handleTextChange(debounceSearchTerm);
    }
  }, [debounceSearchTerm]);

  const handleSubmit = async (data) => {
    await FormHandlersFactory.handleSubmitForm(data, null, formRefStepFour);
  };

  const hintConfig = {
    text: `Uma competência está relacionada a 1 desses 5 tópicos:
    <strong>1</strong> - Idioma, <strong>2</strong> - Regulamentação/Legislação,
    <strong>3</strong> - Ferramenta/Técnica, <strong>4</strong> - Metodologia/Intervenção,
    <strong>5</strong> - Conhecimento Específico`,
    direction: 'top',
    isMultiLine: true,
    maxWidth: '500px',
  };

  const handleTextChange = async (event) => {
    const valueText = `label=${event}`;
    setRequestInProgress(true);
    const { associated_areas } = await FairAndEventsService.getAssociatedAreas(
      valueText
    );
    setRelatedAreasOptions(associated_areas);
    setRequestInProgress(false);
  };

  const handleChangeAssociatedAreas = (event, { action }) => {
    if (action === 'input-change') {
      setSearchTerm(event);
    }

    if (event.length === 0) {
      setRelatedAreasOptions([]);
    }
    return event;
  };

  useEffect(() => {
    if (stepsData?.associated_areas?.length > 0) {
      const getAllAssociatedAreas = async () => {
        const response = await FairAndEventsService.getAssociatedAreas();
        const { associated_areas } = response;
        setRelatedAreasOptions(associated_areas);
      };

      getAllAssociatedAreas();

      formRefStepFour?.current?.setFieldValue(
        'associated_areas',
        stepsData.associated_areas
      );
      setRelatedAreasOptions([]);
    }

    if (stepsData?.macro_areas?.length > 0) {
      formRefStepFour?.current?.setFieldValue(
        'macro_areas',
        stepsData.macro_areas
      );
    }

    if (stepsData?.courses?.length > 0) {
      formRefStepFour?.current?.setFieldValue('courses', stepsData.courses);
    }

    if (stepsData?.semesters?.length > 0) {
      formRefStepFour?.current?.setFieldValue('semesters', stepsData.semesters);
    }

    if (stepsData?.unities?.length > 0) {
      formRefStepFour?.current?.setFieldValue('unities', stepsData.unities);
    }
  }, [
    stepsData?.macro_areas,
    stepsData?.courses,
    stepsData?.semesters,
    stepsData?.unities,
  ]);

  return (
    <Container>
      <ContainerHeaderAndInformation>
        <StepTitle>Competências adquiridas</StepTitle>
        <p>
          Selecione até <strong>5 compêtencias técnicas</strong> ao consumir
          esse conteúdo.
          <Tooltip {...hintConfig}>
            <HintInformation>i</HintInformation>
          </Tooltip>
        </p>
      </ContainerHeaderAndInformation>

      <Form ref={formRefStepFour} onSubmit={handleSubmit}>
        <UnformSelect
          name="associated_areas"
          onInputChange={handleChangeAssociatedAreas}
          isMulti
          maxLimit={5}
          options={relatedAreasOptions}
          placeholder="Digite aqui"
          showNoOptionsMessage
          requestInProgress={requestInProgress}
        />

        <ContainerSelects>
          <StepTitle>Público-alvo</StepTitle>
          <div>
            <UnformSelect
              name="macro_areas"
              options={selectOptions.macro_areas}
              label="Áreas de atuação"
              isMulti
              placeholder="Todas"
              onChange={(event) =>
                FormHandlersFactory.handleMacroAreaChange(
                  event,
                  useRegisterFormInstance
                )
              }
            />
            <UnformSelect
              name="courses"
              options={selectOptions.course}
              label="Cursos relacionados"
              isMulti
              placeholder="Todos"
              onChange={(event) =>
                FormHandlersFactory.handleCourseChange(
                  event,
                  useRegisterFormInstance
                )
              }
            />
            <UnformSelect
              name="semesters"
              options={selectOptions.semesters}
              label="Período"
              isMulti
              placeholder="Todos"
            />
            <UnformSelect
              name="unities"
              options={selectOptions.unities}
              label="Unidades/Polos"
              isMulti
              placeholder="Todas"
            />
          </div>
        </ContainerSelects>

        <StepController />
      </Form>
    </Container>
  );
}
