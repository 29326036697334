import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  changeMentorshipStatus,
  checkElegibleMentorship,
  editMentorshipSolicitation,
  fetchOptions,
  fetchCurrentMentorship,
  sendMentorshipSolicitation,
  fetchMentorshipHistoric,
  evaluateMentorship,
} from 'services/student/mentorship';

export const MentorshipContext = createContext({});

export function MentorshipContextProvider({ children }) {
  const [hasCurriculumFilled, setHasCurriculumFilled] = useState();
  const [hasFavoriteProfession, setHasFavoriteProfession] = useState();
  const [options, setOptions] = useState({});
  const [
    isRequestMentorshipModalOpen,
    setIsRequestMentorshipModalOpen,
  ] = useState(false);
  const [
    isCancelMentorshipModalOpen,
    setIsCancelMentorshipModalOpen,
  ] = useState(false);
  const [
    isAdjustMentorshipStatusModalOpen,
    setIsAdjustMentorshipStatusModalOpen,
  ] = useState(false);
  const [
    isEvaluateMentorshipModalOpen,
    setIsEvaluateMentorshipModalOpen,
  ] = useState(false);
  const [currentMentorship, setCurrentMentorship] = useState(null);
  const [mentorshipHistoric, setMentorshipHistoric] = useState(null);
  const [formAction, setFormAction] = useState('save');
  const [selectedMentorship, setSelectedMentorship] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    checkElegibility();
    getOptions();
    getCurrentMentorship();
    getMentorshipHistoric();
  }, []);

  useEffect(() => {
    if (!!currentMentorship && currentMentorship.id) {
      const newForm = {
        periods_preference: [],
        desired_modality: currentMentorship.desired_modality.value,
        description: currentMentorship.status.description,
      };
      currentMentorship.periodspreference.forEach((period) => {
        newForm.periods_preference.push({
          favorite_day: period.favorite_day.value,
          favorite_periods: period.favorite_periods.map(
            (period) => period.value
          ),
        });
      });
      setForm(newForm);
    } else {
      setFormAction('save');
      setForm({
        periods_preference: [],
        desired_modality: 'indifferent',
        description: '',
      });
    }
  }, [currentMentorship, isRequestMentorshipModalOpen]);

  async function checkElegibility() {
    const data = await checkElegibleMentorship();
    setHasCurriculumFilled(data.has_curriculum_filled);
    setHasFavoriteProfession(data.has_favorite_profession);
  }

  async function getOptions() {
    const data = await fetchOptions();
    setOptions(data);
  }

  async function getCurrentMentorship() {
    const response = await fetchCurrentMentorship();
    setCurrentMentorship(response);
    setSelectedMentorship(response);
  }

  async function getMentorshipHistoric() {
    const response = await fetchMentorshipHistoric();
    setMentorshipHistoric(response);
  }

  const openRequestMentorshipModal = async () =>
    setIsRequestMentorshipModalOpen(true);

  const openCancelMentorshipModal = async () =>
    setIsCancelMentorshipModalOpen(true);

  const openAdjustMentorshipStatusModal = async () =>
    setIsAdjustMentorshipStatusModalOpen(true);

  const openEvaluateMentorshipModal = async () =>
    setIsEvaluateMentorshipModalOpen(true);

  function closeRequestMentorshipModal() {
    setIsRequestMentorshipModalOpen(false);
  }

  function closeCancelMentorshipModal() {
    setIsCancelMentorshipModalOpen(false);
  }

  function closeAdjustMentorshipStatusModal() {
    setIsAdjustMentorshipStatusModalOpen(false);
  }

  function closeEvaluateMentorshipModal() {
    setIsEvaluateMentorshipModalOpen(false);
  }

  async function handleSendSolicitation(desiredModality, description) {
    const response = await sendMentorshipSolicitation({
      ...form,
      desired_modality: desiredModality,
      description,
    });
    if (response.status === 201) {
      getCurrentMentorship();
      return true;
    }
    return false;
  }

  async function handleEditSolicitation(
    desiredModality,
    description,
    isReschedule = false
  ) {
    const data = {
      ...form,
      desired_modality: desiredModality,
      description,
    };
    if (isReschedule) {
      data.status = 'reschedule_request';
    }
    const response = await editMentorshipSolicitation(
      data,
      currentMentorship.id
    );
    if (response.status === 200) {
      getCurrentMentorship();
      return true;
    }
    return false;
  }

  async function handleChangeMentorshipStatus(status, description) {
    const response = await changeMentorshipStatus(
      {
        status,
        description: description || '',
      },
      selectedMentorship.id
    );
    if (response.status === 201) {
      if (selectedMentorship === currentMentorship) {
        getCurrentMentorship();
      } else {
        getMentorshipHistoric();
      }
      return true;
    }
    return false;
  }

  function setPeriodPreferences(periodPreferences) {
    const newForm = { ...form, periods_preference: [] };
    Object.keys(periodPreferences || {}).forEach((period) => {
      if (periodPreferences[period].favorite_day) {
        newForm.periods_preference.push({
          favorite_day: period,
          favorite_periods: periodPreferences[period].favorite_periods,
        });
      }
    });
    setForm(newForm);
  }

  const getModality = () => form.desired_modality;

  const getDescription = () => form.description;

  function isDaySelected(day) {
    return form.periods_preference.some(
      (period) => period.favorite_day === day
    );
  }

  function getSelectedPeriods(day) {
    const isDaySelected = form.periods_preference.find(
      (period) => period.favorite_day === day
    );
    if (isDaySelected) {
      return isDaySelected.favorite_periods;
    } else {
      return [];
    }
  }

  function setModalityAndDescription(modality, description) {
    const newForm = { ...form, desired_modality: modality, description };
    setForm(newForm);
  }

  function handleEditMentorship() {
    setFormAction('edit');
    openRequestMentorshipModal();
  }

  function handleRescheduleMentorship() {
    setFormAction('reschedule');
    openRequestMentorshipModal();
  }

  async function sendRatingMentorship(score) {
    const response = await evaluateMentorship(
      {
        score,
      },
      selectedMentorship.id
    );
    if (response.status === 200) {
      getMentorshipHistoric();
      return true;
    }
    return false;
  }

  return (
    <MentorshipContext.Provider
      value={{
        hasCurriculumFilled,
        hasFavoriteProfession,
        options,
        isRequestMentorshipModalOpen,
        openRequestMentorshipModal,
        closeRequestMentorshipModal,
        handleSendSolicitation,
        setPeriodPreferences,
        isDaySelected,
        getSelectedPeriods,
        getModality,
        setModalityAndDescription,
        getDescription,
        currentMentorship,
        handleEditMentorship,
        formAction,
        isCancelMentorshipModalOpen,
        closeCancelMentorshipModal,
        openCancelMentorshipModal,
        handleEditSolicitation,
        handleChangeMentorshipStatus,
        handleRescheduleMentorship,
        mentorshipHistoric,
        isAdjustMentorshipStatusModalOpen,
        openAdjustMentorshipStatusModal,
        closeAdjustMentorshipStatusModal,
        selectedMentorship,
        setSelectedMentorship,
        getMentorshipHistoric,
        getCurrentMentorship,
        isEvaluateMentorshipModalOpen,
        openEvaluateMentorshipModal,
        closeEvaluateMentorshipModal,
        sendRatingMentorship,
      }}
    >
      {children}
    </MentorshipContext.Provider>
  );
}

export const useMentorship = () => useContext(MentorshipContext);
