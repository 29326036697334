import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {
  Grid,
  List,
  ListSubheader,
  Divider,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DownloadIcon from '../../assets/img/icons/download.svg';
import {
  ButtonDownload,
  ImgIcon,
  ButtonContainer,
  Content,
  Footer,
} from './styled';
import { FilterList } from '@material-ui/icons/';
import CardComposition from 'components/CardComposition/CardComposition';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import withStyles from '@material-ui/core/styles/withStyles';
import { func, shape, bool, number } from 'prop-types';
import TalentsFiltersForm from './TalentsFiltersForm';
import TalentsCompanyFiltersForm from './TalentsCompanyFiltersForm';
import { TalentsListItem } from './TalentsListItem';
import { TalentsListItemCompany } from './TalentsListItemCompany';
import { getCurriculums } from '../../services/university/curriculum';
import { getCoverLetterForDownload } from '../../services/university/coverLetter';
import {
  getCurriculumsCompanyForDownload,
  getStudentCurriculumData,
} from '../../services/company/curriculum';
import { getCoverLetterForDownloadCompanies } from '../../services/company/coverLetter';

import qs from 'querystring';
import jszip from 'jszip';

import Curriculum from 'components/CurriculumModalGenerate/Curriculum';
import CoverLetter from '../../views/Student/Docs/CoverLetter/CoverLetterContainer/components/CoverLetter';
import Modal from './Modal';
import BaseLayoutContext from '../../contexts/base-layout';
import CurriculumModalGenerate from '../CurriculumModalGenerate';
import {
  registerAccessedCurriculum,
  registerDownloadedCurriculum,
} from 'services/student/registerActivityStudent';

function TalentsList(props) {
  const {
    classes,
    isFirstRequest,
    talentsListState,
    filtersOptions,
    setfilterTalentsQS,
    limit,
    setLimit,
    loading,
    loadingFilters,
    haveCourse,
    liked,
    filterTalentsQS,
    university,
    openLetter,
    company,
    requesting,
    resultsCount,
    citiesOptions,
    updateLiked,
  } = props;

  const [showFilter, setShowFilter] = useState(true);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [
    selectedStudentsCoverLetter,
    setSelectedStudentsCoverLetter,
  ] = useState([]);
  const [selectedStudentsCurriculum, setSelectedStudentsCurriculum] = useState(
    []
  );
  const [isDownloadFinished, setIsDownloadFinished] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedButton, setClickedButton] = useState('');
  const [unselectedAll, setUnselectedAll] = useState(false);
  const [studentId, setStudentId] = useState(null);
  const { openSnackbar, handleIsLoadingState } = useContext(BaseLayoutContext);

  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  function removeStudentId(student) {
    setSelectedStudents(selectedStudents.filter((value) => value !== student));
    setSelectedStudentsCurriculum(
      selectedStudentsCurriculum.filter((value) => value !== student.id)
    );
    setSelectedStudentsCoverLetter(
      selectedStudentsCoverLetter.filter((value) => value !== student.id)
    );
    setIsCheckedAll(false);
  }

  const handleCheck = async (student, isChecked) => {
    if (isChecked) {
      return setSelectedStudents([...selectedStudents, student]);
    }
    return removeStudentId(student);
  };

  function filterIdStudent() {
    const tempCoverLetter = [];
    const tempCurriculum = [];
    const isAllOrSingle = isCheckedAll ? talentsListState : selectedStudents;
    isAllOrSingle.filter((item) => {
      if (item.has_cover_letter) {
        tempCoverLetter.push(item.id);
      }
      if (item.has_curriculum) {
        tempCurriculum.push(item.id);
      }
    });
    return { tempCoverLetter, tempCurriculum };
  }

  useEffect(() => {
    if (isCheckedAll) {
      const checkListStudents = talentsListState.map((item) => {
        return item;
      });
      const { tempCoverLetter, tempCurriculum } = filterIdStudent();
      setSelectedStudents(checkListStudents);
      setSelectedStudentsCoverLetter(tempCoverLetter);
      setSelectedStudentsCurriculum(tempCurriculum);
    } else if (!isCheckedAll && unselectedAll === true) {
      setSelectedStudents([]);
      setSelectedStudentsCoverLetter([]);
      setSelectedStudentsCurriculum([]);
    }
  }, [isCheckedAll]);

  useEffect(() => {
    setSelectedStudents([]);
    setSelectedStudentsCoverLetter([]);
    setSelectedStudentsCurriculum([]);
    setIsCheckedAll(false);
  }, [talentsListState]);

  useEffect(() => {
    if (selectedStudents.length > 0) {
      const { tempCoverLetter, tempCurriculum } = filterIdStudent();
      setSelectedStudentsCoverLetter(tempCoverLetter);
      setSelectedStudentsCurriculum(tempCurriculum);
    } else {
      setSelectedStudentsCoverLetter([]);
      setSelectedStudentsCurriculum([]);
    }
  }, [selectedStudents]);

  function handleModal(button) {
    setClickedButton(button);
    setIsModalOpen(true);
    if (
      isModalOpen &&
      (selectedStudentsCurriculum || selectedStudentsCoverLetter)
    ) {
    }
  }

  const getTitle = () => {
    return `Aviso!`;
  };

  function pluralOrSingularTextModalCurriculum() {
    if (clickedButton === 'curriculum') {
      const textCurriculum =
        selectedStudentsCurriculum.length === 1 && selectedStudents.length === 1
          ? `O estudante selecionado possui currículo.`
          : selectedStudentsCurriculum.length <= 1 &&
            selectedStudents.length > 1
          ? `Dos ${selectedStudents.length} estudantes selecionados, ${selectedStudentsCurriculum.length} possui currículo.`
          : `Dos ${selectedStudents.length} estudantes selecionados, ${selectedStudentsCurriculum.length} possuem currículo.`;
      return textCurriculum;
    }
  }
  function pluralOrSingularTextModalCoverLetter() {
    if (clickedButton === 'coverLetter') {
      const textCoverLetter =
        selectedStudentsCoverLetter.length === 1 &&
        selectedStudents.length === 1
          ? `O estudante selecionado possui carta de apresentação.`
          : selectedStudentsCoverLetter.length === 1 &&
            selectedStudents.length > 1
          ? ` Dos ${selectedStudents.length} estudantes selecionados, ${selectedStudentsCoverLetter.length} possui carta de apresentação.`
          : ` Dos ${selectedStudents.length}  estudantes selecionados, ${selectedStudentsCoverLetter.length} possuem carta de apresentação.`;
      return textCoverLetter;
    }
  }

  const getText = () => {
    return clickedButton === 'curriculum'
      ? pluralOrSingularTextModalCurriculum()
      : pluralOrSingularTextModalCoverLetter();
  };

  async function studentsCurriculumQueryParams() {
    if (selectedStudentsCurriculum) {
      if (university) {
        const res = await getCurriculums(
          qs.stringify({ students: selectedStudentsCurriculum })
        );
        return res;
      }
      const res = await getCurriculumsCompanyForDownload(
        qs.stringify({ students: selectedStudentsCurriculum })
      );
      return res;
    }
  }

  async function studentsCoverLetterQueryParams() {
    if (selectedStudentsCoverLetter) {
      if (university) {
        const res = await getCoverLetterForDownload(
          qs.stringify({ students: selectedStudentsCoverLetter })
        );
        return res;
      }
      const res = await getCoverLetterForDownloadCompanies(
        qs.stringify({ students: selectedStudentsCoverLetter })
      );
      return res;
    }
  }

  function todayDate() {
    const today = new Date();
    const formatDate = today.toLocaleDateString();
    return formatDate;
  }

  async function downloadZippedCurriculum() {
    setIsDownloadFinished(false);
    setIsModalOpen(false);

    const studentsData = await studentsCurriculumQueryParams();
    const downloadedResumes = studentsData.map(
      (student) => student.curriculum_id
    );

    await registerDownloadedCurriculum(downloadedResumes);

    let JSZip = new jszip();
    studentsData.map((student) => {
      const MyDoc = <Curriculum curriculumData={student} />;

      const blob = pdf(MyDoc).toBlob();
      JSZip.file(
        `${student.academic_profile.name}-${student.academic_profile.external_id}.pdf`,
        blob,
        { binary: true }
      );
    });

    setIsDownloadFinished(true);
    return JSZip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `Currículos ${todayDate()}.zip`);
    });
  }

  async function downloadZippedCoverLetter() {
    setIsDownloadFinished(false);
    setIsModalOpen(false);

    const studentsCoverLetterData = await studentsCoverLetterQueryParams();
    let JSZip = new jszip();
    studentsCoverLetterData.map((coverLetter) => {
      const MyDoc = <CoverLetter coverLetter={coverLetter} />;
      const blob = pdf(MyDoc).toBlob();
      JSZip.file(
        `${coverLetter.student.name}-${coverLetter.student.external_id}.pdf`,
        blob,
        { binary: true }
      );
    });

    setIsDownloadFinished(true);
    return JSZip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `Cartas de Apresentação ${todayDate()}.zip`);
    });
  }

  function toggleFilterVisiblity() {
    setShowFilter(!showFilter);
  }

  const openCurriculum = useCallback(async (id) => {
    handleIsLoadingState(true);
    setStudentId(id);
    const response = await getStudentCurriculumData(id);

    if (response) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
      await registerAccessedCurriculum([response.curriculum_id]);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  return (
    <div>
      <Grid container justify="center">
        <Grid item md={8}>
          <CardComposition
            CardContent={() => (
              <Fragment>
                <List
                  subheader={
                    <ListSubheader
                      component="div"
                      className={classes.talentsListSubheader}
                    >
                      <FormControlLabel
                        style={{ marginLeft: '5px', fontSize: '17px' }}
                        tabIndex={-1}
                        control={
                          <Checkbox
                            style={{ top: '-6px' }}
                            classes={{
                              checked: classes.checkBox,
                            }}
                            color="default"
                            checked={isCheckedAll && !unselectedAll}
                            disabled={loading}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setIsCheckedAll(true);
                                setUnselectedAll(false);
                              } else {
                                setIsCheckedAll(false);
                                setUnselectedAll(true);
                              }
                            }}
                          />
                        }
                      />
                      <ButtonDownload
                        onClick={() => handleModal('curriculum')}
                        buttonWidth="19%"
                        disabled={
                          selectedStudentsCurriculum.length === 0 ||
                          !isDownloadFinished ||
                          loading
                        }
                      >
                        <ButtonContainer>
                          <ImgIcon src={DownloadIcon} />
                          Baixar Currículo
                        </ButtonContainer>
                      </ButtonDownload>
                      <ButtonDownload
                        onClick={() => handleModal('coverLetter')}
                        buttonWidth="31%"
                        disabled={
                          selectedStudentsCoverLetter.length === 0 ||
                          !isDownloadFinished ||
                          loading
                        }
                      >
                        <ButtonContainer>
                          <ImgIcon src={DownloadIcon} />
                          Baixar Carta de Apresentação
                        </ButtonContainer>
                      </ButtonDownload>
                      <div
                        className={`${classes.closeWelcomeMessage} ${classes.flex}`}
                      >
                        <FormControl className={classes.selectFormControl}>
                          <InputLabel
                            htmlFor="page-select"
                            className={classes.selectLabel}
                            classes={{
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            }}
                          >
                            Itens por página
                          </InputLabel>

                          <Select
                            value={limit}
                            fullWidth
                            onChange={(e) => setLimit(e.target.value)}
                            className={classes.selectInput}
                            inputProps={{
                              name: 'pageSelect',
                              id: 'page-select',
                            }}
                          >
                            {[20, 50, 100].map((value) => (
                              <MenuItem value={value}>{value}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <IconButton
                          className={classes.talentsFilterButton}
                          onClick={toggleFilterVisiblity}
                        >
                          <FilterList /> FILTRO
                        </IconButton>
                      </div>
                      <Divider height={1} />
                    </ListSubheader>
                  }
                >
                  {isModalOpen && (
                    <Modal
                      title={getTitle()}
                      handleCloseModal={() => setIsModalOpen(false)}
                    >
                      <Content>{getText()}</Content>
                      <Footer>
                        <button
                          onClick={() =>
                            clickedButton === 'curriculum'
                              ? downloadZippedCurriculum()
                              : downloadZippedCoverLetter()
                          }
                        >
                          {clickedButton === 'curriculum' &&
                            selectedStudentsCurriculum.length === 1 &&
                            'Baixar Currículo'}
                          {clickedButton === 'curriculum' &&
                            selectedStudentsCurriculum.length > 1 &&
                            'Baixar Currículos'}
                          {clickedButton === 'coverLetter' &&
                            selectedStudentsCoverLetter.length === 1 &&
                            'Baixar Carta de Apresentação'}
                          {clickedButton === 'coverLetter' &&
                            selectedStudentsCoverLetter.length > 1 &&
                            'Baixar Cartas de Apresentação'}
                        </button>
                      </Footer>
                    </Modal>
                  )}
                  {loading || requesting || !isDownloadFinished ? (
                    <div className={classes.flexCenter}>
                      <CircularProgress className={classes.loading} />
                    </div>
                  ) : talentsListState?.length > 0 ? (
                    <Fragment>
                      {talentsListState?.map((student) => {
                        if (company) {
                          return (
                            <div key={student.id}>
                              <TalentsListItemCompany
                                isCheckedAll={isCheckedAll}
                                selectedStudents={selectedStudents}
                                handleCheck={handleCheck}
                                liked={liked}
                                company={company}
                                student={student}
                                classes={classes}
                                openLetter={openLetter}
                                openCurriculum={openCurriculum}
                              />
                              <Divider height={1} />
                            </div>
                          );
                        }
                        return (
                          <div key={student.id}>
                            <TalentsListItem
                              selectedStudents={selectedStudents}
                              handleCheck={handleCheck}
                              isCheckedAll={isCheckedAll}
                              liked={liked}
                              company={company}
                              student={student}
                              classes={classes}
                              openLetter={openLetter}
                              openCurriculum={openCurriculum}
                            />
                            <Divider height={1} />
                          </div>
                        );
                      })}
                    </Fragment>
                  ) : (
                    <Grid
                      item
                      md={12}
                      justify="center"
                      style={{ display: 'flex' }}
                    >
                      <h5>
                        {isFirstRequest
                          ? 'Aguardando confirmação da Instituição de Ensino'
                          : 'O filtro aplicado não encontrou nenhum estudante'}
                        .{' '}
                      </h5>
                    </Grid>
                  )}
                </List>
              </Fragment>
            )}
          />
        </Grid>
        <Grid
          item
          md={4}
          style={{
            display: showFilter ? 'inline-block' : 'none',
            paddingLeft: 15,
            position: 'relative',
            zIndex: 10,
          }}
        >
          {university
            ? mergeContextsHelper(
                <TalentsFiltersForm
                  resultsCount={resultsCount}
                  requesting={requesting}
                  filtersOptions={filtersOptions}
                  filterTalentsQS={filterTalentsQS}
                  setfilterTalentsQS={setfilterTalentsQS}
                  haveCourse={haveCourse}
                  liked={liked}
                  university={university}
                  citiesOptions={citiesOptions}
                />
              )
            : mergeContextsHelper(
                <TalentsCompanyFiltersForm
                  resultsCount={resultsCount}
                  requesting={requesting}
                  filtersOptions={filtersOptions}
                  filterTalentsQS={filterTalentsQS}
                  loadingFilters={loadingFilters}
                  setfilterTalentsQS={setfilterTalentsQS}
                  haveCourse={haveCourse}
                  liked={liked}
                  university={university}
                  citiesOptions={citiesOptions}
                />
              )}
        </Grid>
      </Grid>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          studentId={studentId}
          liked={liked}
          updateLiked={updateLiked}
          showLike={false}
          showActions={false}
        />
      )}
    </div>
  );
}

TalentsList.defaultProps = {
  company: false,
  liked: false,
  loading: false,
  loadingFilters: false,
  university: false,
};

TalentsList.propTypes = {
  classes: shape.isRequired,
  isFirstRequest: bool.isRequired,
  talentsListState: shape([]).isRequired,
  filtersOptions: shape.isRequired,
  setfilterTalentsQS: func.isRequired,
  limit: number.isRequired,
  setLimit: func.isRequired,
  loading: bool,
  loadingFilters: bool,
  haveCourse: bool.isRequired,
  liked: bool,
  company: bool,
  filterTalentsQS: shape.isRequired,
  university: bool,
  openLetter: func.isRequired,
  resultsCount: shape.isRequired,
  requesting: bool.isRequired,
  citiesOptions: shape.isRequired,
};

export default withStyles(dashboardStyle)(TalentsList);
