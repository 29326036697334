import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { WrapperTitle, H3Title, WrapperAddress } from '../style';

import { JobOfferContext } from '../../../../JobOfferRebuild/Contexts/JobOfferContext';

import AuthContext from 'contexts/authentication';
import JobOfferPublicationTypeModal from 'views/JobOfferRebuild/Components/JobOfferForm/JobOfferPublicationTypeModal';
import { maskCnpj, maskInputPhone, maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';
import { handleFieldMask } from 'utils/validation';
import { Grid } from '@material-ui/core';

import { isStaffEmailExists } from 'services/companies';
import Input from 'components/UnformFields/inputField';

export default function StepOne(props) {
  const { setCompanyValues } = useContext(JobOfferContext);

  const universityColor = props?.location?.state?.universityColor || '#009291';

  const [organizationNumber, setOrganizationNumber] = useState(
    props?.location?.state?.organizationNumber
  );

  useEffect(() => {
    if (props.location && props.location.state) {
      setCompanyValues({
        organizationNumber: props.location.state.organizationNumber,
      });
    }
  }, []);

  const [openJobOfferModal, setOpenJobOfferModal] = useState(false);

  const [zipCodeError, setZipCodeError] = useState(false);
  const [inputBlur, setInputBlur] = useState(false);
  const [cepData, setCepData] = useState();

  const schema = Yup.object().shape({
    legal_name: Yup.string().required('Informação Obrigatória'),
    address: Yup.string().required('Informação Obrigatória'),
    address_number: Yup.string().required('Informação Obrigatória'),
    district: Yup.string().required('Informação Obrigatória'),
    zip_code: Yup.string().required('Informação Obrigatória'),
  });

  async function validateField(field) {
    try {
      const data = props.formRef?.current?.getData();
      await schema.validateAt(field, data, {
        abortEarly: false,
      });
      const errors = props?.formRef?.current?.getErrors();
      delete errors[field];
      props?.formRef?.current?.setErrors(errors);
    } catch (err) {
      const validationErrors = props.formRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        props?.formRef?.current?.setErrors(validationErrors);
      }
    }
  }

  useEffect(() => {
    if (!zipCodeError && cepData) {
      props?.formRef?.current?.setErrors({});
      props?.formRef?.current?.setFieldValue('address', cepData.logradouro);
      props?.formRef?.current?.setFieldValue('city', cepData.localidade);
      props?.formRef?.current?.setFieldValue('district', cepData.bairro);
      props?.formRef?.current?.setFieldValue('state', cepData.uf);
      props?.formRef?.current?.setFieldValue('zip_code', cepData.cep);
    }
  }, [zipCodeError, cepData]);

  const handleZipCode = async () => {
    setInputBlur(true);
    const value = props?.formRef?.current?.getFieldValue('zip_code');
    if (value.length > 0) {
      props?.formRef?.current?.setFieldError('zip_code', '');
    }
    if (value.length === 0) {
      props?.formRef?.current?.setFieldError(
        'zip_code',
        'Informação Obrigatória'
      );
    }

    if (value.length === 9) {
      try {
        await zipCodeRequest(value).then((data) => {
          if ((data && data.erro) || !data) {
            setZipCodeError(true);
            props?.formRef?.current?.setFieldError(
              'zip_code',
              'Digite um CEP válido'
            );
            return false;
          } else {
            setCepData(data);
            props?.formRef?.current?.setFieldError('zip_code', '');
            setZipCodeError(false);
            return true;
          }
        });
      } catch (e) {
        props?.formRef?.current?.setFieldValue('address', '');
        props?.formRef?.current?.setFieldValue('city', '');
        props?.formRef?.current?.setFieldValue('district', '');
        props?.formRef?.current?.setFieldValue('state', '');
        setZipCodeError(true);
      }
    } else {
      props?.formRef?.current?.setFieldValue('address', '');
      props?.formRef?.current?.setFieldValue('city', '');
      props?.formRef?.current?.setFieldValue('district', '');
      props?.formRef?.current?.setFieldValue('state', '');
      setZipCodeError(true);
    }
  };

  const handleBlurZipCode = async () => {
    props?.formRef?.current?.setErrors({});
    const value = props?.formRef?.current?.getFieldValue('zip_code');

    if (value.length < 9) {
      props?.formRef?.current?.setFieldError(
        'zip_code',
        'Digite um CEP válido'
      );
    }
  };

  const handleBlurEmail = async (event) => {
    props?.formRef?.current?.setErrors({});
    const value = event.target.value;
    const email = value.toLowerCase();

    if (email.length > 0) {
      const output = await isStaffEmailExists(email);
      const { exists } = output;

      if (exists) {
        return props?.formRef?.current?.setFieldError(
          'email',
          'E-mail já está em uso'
        );
      }
    }
  };

  const onBackEmail = async (event) => {
    props?.formRef?.current?.setErrors({});
    const email = event;

    if (email.length > 0) {
      const output = await isStaffEmailExists(email);
      const { exists } = output;

      if (exists) {
        return props?.formRef?.current?.setFieldError(
          'email',
          'E-mail já está em uso'
        );
      }
    }
  };

  useEffect(() => {
    if (props.objectSteps.email) {
      onBackEmail(props.objectSteps.email);
    }
    if (props.objectSteps.zip_code) {
      handleZipCode(props.objectSteps.zip_code);
    }
  }, [props.objectSteps.email, props.objectSteps.zip_code]);

  return (
    <Fragment>
      <JobOfferPublicationTypeModal
        openModal={openJobOfferModal}
        setOpenModal={setOpenJobOfferModal}
      />

      <AuthContext.Consumer>
        {({ routerOnUserLogin }) => {
          return (
            <>
              <WrapperTitle>
                <H3Title universityColor={universityColor}>
                  Dados da empresa
                </H3Title>
              </WrapperTitle>
              <Form ref={props.formRef} initialData={props.objectSteps}>
                <Input
                  universityColor={universityColor}
                  name="organization_number"
                  label="CNPJ*"
                  value={organizationNumber}
                  onChange={(e) => setOrganizationNumber(e?.target?.value)}
                  onKeyUp={(e) => {
                    handleFieldMask(e, maskCnpj);
                  }}
                />
                <Input
                  name="legal_name"
                  label="Razão Social*"
                  universityColor={universityColor}
                />
                <Input
                  universityColor={universityColor}
                  name="name"
                  label="Nome comercial (como sua empresa é conhecida no mercado)*"
                  onChange={() => validateField('name')}
                />

                <Input
                  name="email"
                  label="E-mail*"
                  autocomplete="new-mail"
                  onBlur={handleBlurEmail}
                  universityColor={universityColor}
                />

                <Input
                  universityColor={universityColor}
                  name="phone"
                  label="Telefone*"
                  onChange={() => validateField('phone')}
                  onKeyUp={(e) => {
                    handleFieldMask(e, maskInputPhone);
                  }}
                />

                <WrapperAddress>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={12}>
                      <Input
                        universityColor={universityColor}
                        name="zip_code"
                        placeholder="Ex.: 00000-000"
                        label="CEP *"
                        onKeyUp={(e) => {
                          handleFieldMask(e, maskZipCode);
                        }}
                        maxLength={9}
                        onChange={handleZipCode}
                        onBlur={handleBlurZipCode}
                        onSearchIcon={true}
                      />
                    </Grid>
                    {!zipCodeError && inputBlur && (
                      <Fragment>
                        <Grid item xs={12} md={8}>
                          <Input
                            universityColor={universityColor}
                            name="address"
                            label="Rua/Avenida *"
                            placeholder="Avenida/Rua"
                            onChange={() => validateField('address')}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Input
                            universityColor={universityColor}
                            name="address_number"
                            label="Número *"
                            placeholder="Digite aqui..."
                            type="number"
                            min="0"
                            onChange={() => validateField('address_number')}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Input
                            universityColor={universityColor}
                            name="address_complement"
                            label="Complemento"
                            placeholder="Digite aqui..."
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Input
                            universityColor={universityColor}
                            name="district"
                            label="Bairro *"
                            onChange={() => validateField('district')}
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Input
                            universityColor={universityColor}
                            name="city"
                            label="Cidade *"
                            disabled
                            onChange={() => validateField('city')}
                          />
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <Input
                            universityColor={universityColor}
                            name="state"
                            label="UF *"
                            onChange={() => validateField('state')}
                            disabled
                          />
                        </Grid>
                      </Fragment>
                    )}
                  </Grid>
                </WrapperAddress>
              </Form>
            </>
          );
        }}
      </AuthContext.Consumer>
    </Fragment>
  );
}
