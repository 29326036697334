import React, { Fragment, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
} from '@material-ui/core';
import SearchableSelect from 'react-select';
import { Formik } from 'formik';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles, {
  FilterContainer,
} from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

import { func, shape, bool } from 'prop-types';

function TalentsCompanyFiltersForm(props) {
  const {
    classes,
    filtersOptions,
    filterTalentsQS,
    loadingFilters,
    setfilterTalentsQS,
    haveCourse,
    liked,
    requesting,
    resultsCount,
    citiesOptions,
  } = props;

  const initialValues = {
    selectedCoursesFilter: [],
    selectedPeriods: [],
    selectedBehaviors: [],
    selectedExperienceAreas: [],
    professionalStatus: [],
    workingInFormationArea: [],
    professionalOffers: [],
    selectedPersonalities: [],
    selectedStudentStatus: [],
    selectedCourseLevel: [],
    selectedGenders: [],
    selectedAttendanceModes: [],
    location: [],
    attractionRelated: [],
    analysisStatus: [],
    couses: haveCourse,
    studentName: null,
    studentNameWasSearched: false,
    studentExternalId: null,
    studentExternalIdWasSearched: false,
    curriculum: null,
  };

  const [selectedCity, setSelectedCity] = useState([]);
  const hideFields = false;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      render={({ errors, touched, setFieldValue, setFieldTouched, values }) => (
        <List style={{ position: 'sticky', top: 0, zIndex: 0, paddingTop: 0 }}>
          <FilterContainer>
            <h4 style={{ marginTop: 0 }}>Filtro avançado</h4>
            <p>Talentos encontrados {resultsCount}</p>

            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedCoursesFilter &&
                      !!touched.selectedCoursesFilter,
                  })}
                >
                  Requisitos Acadêmicos:
                </strong>
              </p>
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <Fragment>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedUniversity"
                    shrink
                    error={
                      errors.selectedUniversity && touched.selectedUniversity
                    }
                  >
                    Instituição de Ensino
                  </InputLabel>
                  <SearchableSelect
                    name="selectedUniversity"
                    id="selectedUniversity"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedUniversity');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          university: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedUniversity', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter(
                            (item) => item !== 'university'
                          )
                        );
                      }
                    }}
                    options={filtersOptions.universities || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedUniversity &&
                  !!touched.selectedUniversity && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedUniversity}
                    </FormHelperText>
                  )}
              </Fragment>
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedCourseLevel"
                  shrink
                  error={
                    errors.selectedCourseLevel && touched.selectedCourseLevel
                  }
                >
                  Grau de Escolaridade
                </InputLabel>
                <SearchableSelect
                  name="selectedCourseLevel"
                  id="selectedCourseLevel"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedCourseLevel');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS(
                        {
                          ...filterTalentsQS,
                          course_level: event.map((item) => item.value),
                        },
                        true
                      );
                    } else {
                      setFieldValue('selectedCourseLevel', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'course_level'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.course_level || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedCourseLevel &&
                !!touched.selectedCourseLevel && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedCourseLevel}
                  </FormHelperText>
                )}
            </ListItem>
            <Fragment>
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedCoursesFilter"
                    shrink
                    error={
                      errors.selectedCoursesFilter &&
                      touched.selectedCoursesFilter
                    }
                  >
                    Curso
                  </InputLabel>
                  <SearchableSelect
                    name="selectedCoursesFilter"
                    id="selectedCoursesFilter"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedCoursesFilter');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          courses: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedCoursesFilter', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter((item) => item !== 'courses')
                        );
                      }
                    }}
                    options={filtersOptions.courses || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedCoursesFilter &&
                  !!touched.selectedCoursesFilter && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedCoursesFilter}
                    </FormHelperText>
                  )}
              </ListItem>
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedPeriods"
                    shrink
                    error={errors.selectedPeriods && touched.selectedPeriods}
                  >
                    Período
                  </InputLabel>
                  <SearchableSelect
                    name="selectedPeriods"
                    id="selectedPeriods"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedPeriods');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          periods: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedPeriods', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter((item) => item !== 'periods')
                        );
                      }
                    }}
                    options={filtersOptions.periods || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedPeriods && !!touched.selectedPeriods && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedPeriods}
                  </FormHelperText>
                )}
              </ListItem>
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedAttendanceModes"
                    shrink
                    error={
                      errors.selectedAttendanceModes &&
                      touched.selectedAttendanceModes
                    }
                  >
                    Modalidade
                  </InputLabel>
                  <SearchableSelect
                    name="selectedAttendanceModes"
                    id="selectedAttendanceModes"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedAttendanceModes');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          attendance_modes: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedAttendanceModes', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter(
                            (item) => item !== 'attendance_modes'
                          )
                        );
                      }
                    }}
                    options={filtersOptions.attendance_modes || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedAttendanceModes &&
                  !!touched.selectedAttendanceModes && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedAttendanceModes}
                    </FormHelperText>
                  )}
              </ListItem>
            </Fragment>
            {hideFields && (
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedStudentStatus"
                    shrink
                    error={
                      errors.selectedStudentStatus &&
                      touched.selectedStudentStatus
                    }
                  >
                    Progresso do Estudante
                  </InputLabel>
                  <SearchableSelect
                    name="selectedStudentStatus"
                    id="selectedStudentStatus"
                    isClearable
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedStudentStatus');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          student_status: event.value,
                        });
                      } else {
                        setFieldValue('selectedStudentStatus', null);
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          student_status: '',
                        });
                      }
                    }}
                    options={filtersOptions.student_status || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedStudentStatus &&
                  !!touched.selectedStudentStatus && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedStudentStatus}
                    </FormHelperText>
                  )}
              </ListItem>
            )}
            {hideFields && (
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedAcademicProgress"
                    shrink
                    error={
                      errors.selectedAcademicProgress &&
                      touched.selectedAcademicProgress
                    }
                  >
                    Rendimento Acadêmico
                  </InputLabel>
                  <SearchableSelect
                    name="selectedAcademicProgress"
                    id="selectedAcademicProgress"
                    isClearable
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    isDisabled={requesting}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedAcademicProgress');
                    }}
                    options={filtersOptions.academic_progress || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedAcademicProgress &&
                  !!touched.selectedAcademicProgress && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedAcademicProgress}
                    </FormHelperText>
                  )}
              </ListItem>
            )}

            <Divider height={1} className={classes.divider} />

            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]: !!errors.students && !!touched.students,
                  })}
                >
                  Dados do Estudante:
                </strong>
              </p>
            </ListItem>

            <Fragment>
              {[
                {
                  label: 'Com Currículo',
                  value: true,
                },
              ].map((checkField) => (
                <FormControl component="fieldset" fullWidth>
                  <FormGroup
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values[checkField.value]}
                          value
                          disabled={requesting || loadingFilters}
                          onChange={async (event) => {
                            if (!values[checkField.value]) {
                              setFieldValue(
                                checkField.value,
                                event.target.value
                              );
                              await setfilterTalentsQS({
                                ...filterTalentsQS,
                                curriculum: checkField.value,
                              });
                            } else {
                              setFieldValue(checkField.value, false);
                              delete filterTalentsQS.curriculum;
                              setfilterTalentsQS(filterTalentsQS);
                            }
                          }}
                          name={checkField.value}
                          classes={{
                            checked: classes[`checkBox`],
                          }}
                        />
                      }
                      label={checkField.label}
                    />
                  </FormGroup>
                </FormControl>
              ))}
            </Fragment>

            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedGenders"
                  shrink
                  error={errors.selectedGenders && touched.selectedGenders}
                >
                  Sexo:
                </InputLabel>
                <SearchableSelect
                  name="selectedGenders"
                  id="selectedGenders"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedGenders');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      await setfilterTalentsQS({
                        ...filterTalentsQS,
                        genders: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedGenders', null);
                      setfilterTalentsQS(
                        Object.keys(filterTalentsQS).filter(
                          (item) => item !== 'genders'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.genders || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedGenders && !!touched.selectedGenders && (
                <FormHelperText className={classes.helperText}>
                  {errors.selectedGenders}
                </FormHelperText>
              )}
            </ListItem>

            <Divider height={1} className={classes.divider} />

            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]: !!errors.location && !!touched.location,
                  })}
                >
                  Localização:
                </strong>
              </p>
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedState"
                  shrink
                  error={errors.selectedState && touched.selectedState}
                >
                  UF
                </InputLabel>
                <SearchableSelect
                  name="selectedState"
                  id="selectedState"
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedState');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      await setfilterTalentsQS({
                        ...filterTalentsQS,
                        states: event.map((item) => item.value),
                      });
                    } else {
                      setSelectedCity([]);
                      setfilterTalentsQS(
                        Object.keys(filterTalentsQS).filter(
                          (item) => item !== 'states' || 'cities'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.states || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedState && !!touched.selectedState && (
                <FormHelperText className={classes.helperText}>
                  {errors.selectedState}
                </FormHelperText>
              )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedCity"
                  shrink
                  error={errors.selectedCity && touched.selectedCity}
                >
                  Cidade
                </InputLabel>
                <SearchableSelect
                  name="selectedCity"
                  id="selectedCity"
                  value={selectedCity}
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={
                    citiesOptions && citiesOptions.length > 0
                      ? 'Selecione'
                      : 'Escolha um estado'
                  }
                  isSearchable
                  isDisabled={requesting || citiesOptions.length === 0}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]:
                      citiesOptions.length > 0 && !requesting,
                    [classes.selectControlHeight]:
                      requesting || citiesOptions.length === 0,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedCity');
                  }}
                  onChange={async (event) => {
                    setSelectedCity(event.value);
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        cities: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedCity', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter((item) => item !== 'cities')
                      );
                    }
                  }}
                  options={citiesOptions || []}
                  defaultValue={[]}
                />
              </FormControl>
            </ListItem>
            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedBehaviors && !!touched.selectedBehaviors,
                  })}
                >
                  Soft Skills:
                </strong>
              </p>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedBehaviors"
                  shrink
                  error={errors.selectedBehaviors && touched.selectedBehaviors}
                >
                  Comportamental
                </InputLabel>
                <SearchableSelect
                  name="selectedBehaviors"
                  id="selectedBehaviors"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedBehaviors');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        behavioral_attributes: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedBehaviors', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'behavioral_attributes'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.behavioral_attributes || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedBehaviors && !!touched.selectedBehaviors && (
                <FormHelperText className={classes.helperText}>
                  {errors.selectedBehaviors}
                </FormHelperText>
              )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedPersonalities"
                  shrink
                  error={
                    errors.selectedPersonalities &&
                    touched.selectedPersonalities
                  }
                >
                  Personalidade
                </InputLabel>
                <SearchableSelect
                  name="selectedPersonalities"
                  id="selectedPersonalities"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedPersonalities');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        personality: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedPersonalities', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter((item) => item !== 'personality')
                      );
                    }
                  }}
                  options={filtersOptions.personality || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedPersonalities &&
                !!touched.selectedPersonalities && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedPersonalities}
                  </FormHelperText>
                )}
            </ListItem>

            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedExperienceAreas &&
                      !!touched.selectedExperienceAreas,
                  })}
                >
                  Requisitos Profissionais:
                </strong>
              </p>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedExperienceAreas"
                  shrink
                  error={
                    errors.selectedExperienceAreas &&
                    touched.selectedExperienceAreas
                  }
                >
                  Setor de Experiência
                </InputLabel>
                <SearchableSelect
                  name="selectedExperienceAreas"
                  id="selectedExperienceAreas"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedExperienceAreas');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_experience_areas: event.map(
                          (item) => item.value
                        ),
                      });
                    } else {
                      setFieldValue('selectedExperienceAreas', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'professional_experience_areas'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.professional_experience_areas || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedExperienceAreas &&
                !!touched.selectedExperienceAreas && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedBehaviors}
                  </FormHelperText>
                )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="professionalStatus"
                  shrink
                  error={
                    errors.professionalStatus && touched.professionalStatus
                  }
                >
                  Status Profissional
                </InputLabel>
                <SearchableSelect
                  name="professionalStatus"
                  id="professionalStatus"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('professionalStatus');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_statuses: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('professionalStatus', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'professional_statuses'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.professional_status || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.professionalStatus && !!touched.professionalStatus && (
                <FormHelperText className={classes.helperText}>
                  {errors.professionalStatus}
                </FormHelperText>
              )}
            </ListItem>

            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="professionalOffers"
                  shrink
                  error={
                    errors.professionalOffers && touched.professionalOffers
                  }
                >
                  Disponibilidade de Trabalho
                </InputLabel>
                <SearchableSelect
                  name="professionalOffers"
                  id="professionalOffers"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                  menuPlacement="top"
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('professionalOffers');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_offers: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('professionalOffers', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'professional_offers'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.professional_offers || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.professionalOffers && !!touched.professionalOffers && (
                <FormHelperText className={classes.helperText}>
                  {errors.professionalOffers}
                </FormHelperText>
              )}
            </ListItem>
            {filtersOptions.knowledge && (
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedKnowledge"
                    shrink
                    error={
                      errors.selectedKnowledge && touched.selectedKnowledge
                    }
                  >
                    Competências Técnicas
                  </InputLabel>
                  <SearchableSelect
                    name="selectedKnowledge"
                    id="selectedKnowledge"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder={loadingFilters ? 'Carregando...' : 'Selecione'}
                    isSearchable
                    menuPlacement="top"
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedKnowledge');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          knowledge: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedKnowledge', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter((item) => item !== 'knowledge')
                        );
                      }
                    }}
                    options={filtersOptions.knowledge || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedKnowledge && !!touched.selectedKnowledge && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedKnowledge}
                  </FormHelperText>
                )}
              </ListItem>
            )}

            {liked && (
              <Fragment>
                <Divider height={1} className={classes.divider} />
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <p>
                    <strong
                      className={classNames({
                        [classes.error]:
                          !!errors.selectedCoursesStatus &&
                          !!touched.selectedCoursesStatus,
                      })}
                    >
                      Status do Currículo:
                    </strong>
                  </p>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedCoursesStatus"
                      shrink
                      error={
                        errors.selectedCoursesStatus &&
                        touched.selectedCoursesStatus
                      }
                    >
                      Curtidas
                    </InputLabel>
                    <SearchableSelect
                      name="selectedCoursesStatus"
                      id="selectedCoursesStatus"
                      isClearable
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder={
                        loadingFilters ? 'Carregando...' : 'Selecione'
                      }
                      isSearchable
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedCoursesStatus');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            curriculum_like: event.value,
                          });
                        } else {
                          setFieldValue('selectedCoursesStatus', null);
                          delete filterTalentsQS.curriculum_like;
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                          });
                        }
                      }}
                      options={filtersOptions.curriculum_like || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedCoursesStatus &&
                    !!touched.selectedCoursesStatus && (
                      <FormHelperText className={classes.helperText}>
                        {errors.selectedCoursesStatus}
                      </FormHelperText>
                    )}
                </ListItem>
              </Fragment>
            )}
          </FilterContainer>
        </List>
      )}
    />
  );
}

TalentsCompanyFiltersForm.propTypes = {
  setfilterTalentsQS: func.isRequired,
  university: bool.isRequired,
  classes: shape({}).isRequired,
  haveCourse: bool.isRequired,
  liked: bool.isRequired,
  filtersOptions: shape.isRequired,
  filterTalentsQS: shape.isRequired,
  resultsCount: shape.isRequired,
  requesting: bool.isRequired,
  citiesOptions: shape.isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(
  TalentsCompanyFiltersForm
);
