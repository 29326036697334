import React, { useEffect, useState, useContext } from 'react';
import { bool, func } from 'prop-types';

import {
  getPersonalityTestQuestions,
  applyPersonalityTest,
  getPersonalityNextDate,
} from 'services/student/quizzes';
import splitArray from 'utils/splitArray';

import withStyles from '@material-ui/core/styles/withStyles';
import dragDropTestStyle from 'assets/jss/material-dashboard-pro-react/views/dragDropTestStyle';

import DragDropTest from 'components/DragDropTest/DragDropTest';
import { Mixpanel } from 'utils/mixpanel';
import { triggerHotjarEvent } from 'utils/hotjar';
import { triggerPageView } from '../../../../utils/analytics';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

const PersonalityTest = (props) => {
  const [testOptions, setTestOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);

  const {
    handleIsLoadingState,
    closeModal,
    isFromJobOffers,
    setClose,
    isMyProfile,
    metadata,
    setCardData,
    cardDataKey,
    fetchGamificationData,
    updatePersonalityState,
  } = props;
  useEffect(() => {
    handleIsLoadingState(true);
    const fetchPersonaltyData = async () => {
      const options = await getPersonalityTestQuestions();

      if (options.message) {
        customSnackbar(
          'Falha ao carregar o teste! Tente novamente mais tarde.',
          'error'
        );
        props.closeModal();
      } else {
        setTestOptions(splitArray(options, 6));
        setFixedOptions(splitArray(options, 6));
      }

      handleIsLoadingState(false);

      if (metadata?.university_slug !== 'uniexemplo') {
        triggerHotjarEvent('personality');
      }
    };
    Mixpanel.track('Abriu Teste de Personalidade');
    fetchPersonaltyData();
  }, []);

  const handleTestOptions = (newTestOptions) => {
    setTestOptions(newTestOptions);
  };

  const sendTestData = async (testData) => {
    handleIsLoadingState(true);
    const response = await applyPersonalityTest(testData);
    const { next_date } = await getPersonalityNextDate();

    if (response.message) {
      customSnackbar(
        'Falha ao finalizar o teste, tente novamente mais tarde.',
        'error'
      );
    } else {
      updatePersonalityState && updatePersonalityState(response);
      const data = {
        is_done: true,
        is_enabled: false,
        next_date: next_date,
        results: response,
      };

      cardDataKey && setCardData((prev) => ({ ...prev, [cardDataKey]: data }));
      await fetchGamificationData();
      customSnackbar(
        `
        Questionário preenchido com sucesso! Continue firme 
        para ter um currículo cada vez mais completo.
      `,
        'confirmation'
      );
      if (isFromJobOffers || isMyProfile) setClose();

      Mixpanel.track('Salvou Teste de Personalidade');
      triggerPageView('student/testes/personalidade/cadastro');
    }
    closeModal();
    handleIsLoadingState(false);
  };

  return (
    <DragDropTest
      headerText="Arraste e ordene, em ordem de importância, os adjetivos que melhor descrevem você."
      subHeaderText="Coloque em 1º lugar o adjetivo mais compatível com o seu perfil, e em último lugar, o de menor compatibilidade com o seu jeito de ser."
      totalSteps={6}
      optionsPerPage={4}
      testOptions={testOptions}
      fixedOptions={fixedOptions}
      openSnackbar={customSnackbar}
      handleTestOptions={handleTestOptions}
      snackbarErrorMessage="Selecione todas as características"
      sendTestData={sendTestData}
      footerText={
        '* Este questionário não se configura um teste psicológico. As decisões a respeito da escolha profissional devem, preferencialmente, contemplar avaliações de perfil conduzidas por um profissional habilitado.'
      }
    />
  );
};

PersonalityTest.defaultProps = {
  isFromJobOffers: false,
  isMyProfile: false,
  setClose: undefined,
};

PersonalityTest.propTypes = {
  handleIsLoadingState: func.isRequired,
  closeModal: func.isRequired,
  updatePersonalityState: func.isRequired,
  isFromJobOffers: bool,
  setClose: func,
  isMyProfile: bool,
};

export default withStyles(dragDropTestStyle)(PersonalityTest);
