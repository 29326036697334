import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  Dropdown,
  DropdownHeader,
  DropdownHeaderIcon,
  List,
  ListItem,
} from './styles';
import { useField } from '@unform/core';
import { useMentorship } from '../../../contexts/MentorshipContext';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalitySelect() {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedModality, setSelectedModality] = useState('indifferent');
  const { options, getModality, formAction } = useMentorship();
  const { registerField, fieldName } = useField('desired_modality');
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref,
      getValue: () => {
        return selectedModality;
      },
      clearValue: () => {
        setSelectedModality('indifferent');
      },
      setValue: (ref, value) => {
        setSelectedModality(value);
      },
    });
  }, [fieldName, registerField, selectedModality]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      } else {
        toggle();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    setSelectedModality(getModality());
  }, []);

  const toggle = () => setOpen(!open);

  return (
    <Dropdown ref={ref} open={open}>
      <DropdownHeader>
        <p>
          {options.modalities.find((m) => m.value === selectedModality).label}
        </p>
        <DropdownHeaderIcon open={open} />
      </DropdownHeader>
      {open && (
        <List>
          {options.modalities.map((modality) => (
            <ListItem
              universityColor={universityColor}
              key={modality.value}
              onClick={() => setSelectedModality(modality.value)}
              selected={selectedModality === modality.value}
              type="button"
            >
              <span>{modality.label}</span>
            </ListItem>
          ))}
        </List>
      )}
    </Dropdown>
  );
}
