import { GenericInternalTabs } from 'components/GenericNavegation';
import { MainContainer, Content, Header } from '../Styles/BaseComponent';
import { useState, useEffect, useContext } from 'react';
import { StudentsTab } from './InternalTabs/Student';
import { JobsTab } from './InternalTabs/Jobs';
import { CompanyTab } from './InternalTabs/Companys';
import {
  cancelCompaniesRequests,
  cancelJobsRequests,
  cancelStudentInTrainingRequests,
  getMeDataProducts,
  getOptionsStudent,
} from '../Services';
import { DashboardContext } from '../Context';
import { FilterStudentDashboard } from '../UiComponents/Filters/FilterStudentDashboard';
import { FilterJobsDashboard } from '../UiComponents/Filters/FilterJobsDashboard';
import { InsightsExport } from '../UiComponents/InsightExport';

export function DashboardView({ staffRole }) {
  const [activeInternalTab, setActiveInternalTab] = useState(0);
  const { setFetchMe, setQueryFilter, setActiveSubTab, loading } =
    useContext(DashboardContext);
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  const Tabs = [
    {
      activeTab: 0,
      component: <StudentsTab />,
    },
    {
      activeTab: 1,
      component: <JobsTab />,
    },
    {
      activeTab: 2,
      component: <CompanyTab />,
    },
  ];

  const Filters = [
    {
      activeTab: 0,
      component: (
        <FilterStudentDashboard
          setFilterState={setFilterState}
          filterOptions={filterOptions}
          filterState={filterState}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          staffRole={staffRole}
          loading={loading}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <FilterJobsDashboard
          setFilterState={setFilterState}
          filterOptions={filterOptions}
          filterState={filterState}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          staffRole={staffRole}
          loading={loading}

        />
      ),
    },
    {
      activeTab: 2,
      component: <InsightsExport />,
    },
  ];

  const handleChangeTab = async (value) => {
    if (value !== 0) {
      cancelStudentInTrainingRequests();
    }

    if (value !== 1) {
      cancelJobsRequests();
    }

    if (value !== 2) {
      cancelCompaniesRequests();
    }

    setQueryFilter(null);
    setFilterState({});
    setAppliedFilters({});
    setActiveInternalTab(value);
    setActiveSubTab(0);
  };

  const handleMe = async () => {
    const response = await getMeDataProducts();
    setFetchMe(response.success);
  };

  useEffect(() => {
    handleMe();
  }, []);

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs?.component;
  };

  const renderFilters = (step) => {
    const returnFilters = Filters?.find((tab) => tab?.activeTab === step);
    return returnFilters?.component;
  };

  async function getOptions() {
    const options = await getOptionsStudent();
    setFilterOptions(options);
  }

  useEffect(() => {
    if (activeInternalTab === 0) {
      getOptions();
    }
  }, []);

  return (
    <MainContainer>
      <Header>
        <GenericInternalTabs
          activeTab={activeInternalTab}
          handleChange={handleChangeTab}
          tabs={['Estudantes', 'Vagas', 'Empresas']}
        />
        {renderFilters(activeInternalTab)}
      </Header>
      <Content removePd={activeInternalTab === 2}>
        {renderPrincipalTabs(activeInternalTab)}
      </Content>
    </MainContainer>
  );
}
