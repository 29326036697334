import PeriodSelector from '../PeriodSelector';
import React, { useEffect, useState, useContext } from 'react';
import { CalendarIcon, SelectorItem, SelectorItemLabel } from './styles';
import { useField } from '@unform/core';
import { useMentorship } from '../../../contexts/MentorshipContext';
import BaseLayoutContext from 'contexts/base-layout';

import Calendar from 'assets/ComponentIcons/Calendar';

export default function DaySelector({ day }) {
  const ref = React.useRef(null);
  const { isDaySelected } = useMentorship();
  const { registerField, fieldName } = useField('favorite_day');
  const [selected, setSelected] = useState(false);

  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    if (isDaySelected(day.value)) {
      setSelected(true);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      getValue: () => selected,
      clearValue: () => setSelected(false),
      setValue: (value) => setSelected(value),
    });
  }, [fieldName, registerField, selected]);

  return (
    <SelectorItem universityColor={universityColor}>
      <input
        ref={ref}
        type="checkbox"
        checked={selected}
        value={day.value}
        id={day.value}
        onChange={() => setSelected(!selected)}
      />
      <SelectorItemLabel for={day.value}>
        <Calendar color={selected === true ? '#fff' : universityColor} />
        <span>{day.label}</span>
      </SelectorItemLabel>
      <PeriodSelector isDaySelected={selected} day={day.value} />
    </SelectorItem>
  );
}
