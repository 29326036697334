import styled from 'styled-components';
export const Container = styled.div`
  width: 720px;
  max-width: 100%;

  h1 {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    line-height: 1.4375rem;
    font-size: 1.375rem;
    font-family: Roboto, sans-serif;
    margin-top: 2.5rem;
    margin-bottom: 0;
    font-weight: bold;
    padding: 0 2.5rem;
    @media (max-width: 600px) {
      font-size: 18px;
      text-align: center;
    }
  }

  h2 {
    color: #606062;
    line-height: 1.5625rem;
    font-size: 1.25rem;
    font-family: Roboto, sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 0;
    font-weight: bold;
    padding: 0 2.5rem;
    @media (max-width: 600px) {
      font-size: 16px;
      text-align: center;
    }
  }

  p {
    color: #4b4b4d;
    line-height: 1.1875rem;
    font-size: 1rem;
    font-family: Roboto, sans-serif;
    margin-top: 0.3rem;
    padding: 0 2.5rem;
    @media (max-width: 600px) {
      font-size: 14px;
      text-align: center;
    }
  }

  strong {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    line-height: 1.25rem;
    font-weight: 600;
    font-size: 0.875rem;
    font-family: Roboto, sans-serif;
    padding: 0 2.5rem;
  }
`;

export const WeekDaySelector = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.125rem;
  max-width: 430px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2.5rem;
  @media (max-width: 600px) {
    margin-left: 0;
    padding: 0 2.5rem;
  }
`;

export const Legend = styled.div`
  display: flex;
  gap: 0.3125rem;
  margin: 0.5rem 0 0 1rem;
  align-items: center;
  height: 2.5rem;
  padding: 0 2.5rem;

  p {
    margin: 0 0.9375rem 0 0;
    font-size: 0.9375rem;
    font-family: Roboto, sans-serif;
    line-height: 1.125rem;
    padding: 0;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const LegendIcon = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.625rem;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  align-items: center;
  display: flex;
  background-image: url(${(props) => props.icon});
`;

export const Footer = styled.div`
  margin: 2.5rem 0 2.5rem 2.5rem;
  height: 2.5rem;
  width: 80%;
  border-radius: 5px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  @media (max-width: 600px) {
    border-radius: 5px;
    margin: 0 auto;
    max-width: 80%;
  }

  p {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    width: 100%;
    padding: 0;
    @media (max-width: 600px) {
      font-size: 12px;
      text-align: left;
      padding: 0 16px 0 0;
    }
    @media (max-width: 350px) {
      font-size: 11px;
    }
  }

  span {
    margin: 0;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
`;

export const SmileIcon = styled.img`
  margin: 0 1rem 0 1rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  @media (max-width: 350px) {
    margin: 0 0.5rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    strong {
      display: none;
    }
  }
`;
