import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 454px;
  padding: 0 2.5rem;
  height: auto;
  margin: 0 auto 2.5rem;

  strong {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    width: 100%;
    font-weight: 700;
    margin: 0.5rem 0 0 0;
    color: #009291;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    width: 100%;
    font-weight: normal;
    color: #606062;
    margin: 12px 0 0 0;
  }

  textarea {
    margin: 31px 0 0 0;
    width: 100%;
    resize: none;
    border-color: #c4c4c4;
    border-radius: 4.5px;
    padding: 0.5rem;
    color: #000;
  }
`;

export const SendButton = styled.button`
  width: 100px;
  background-color: #009291;
  color: #fff;
  height: 31.4px;
  border-radius: 4.2px;
  font-size: 17px;
  line-height: 20px;
  border: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
