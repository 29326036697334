import React, { useState, useEffect } from 'react';
import { PermissionsContainer } from './style';
import { CheckboxInput } from 'components/UnformFields/Style/BaseInputs';

export default function Permissions(props) {
  const { universityColor, options, setPermissionsOptions } = props;

  const translates = {
    accept_be_indicated:
      'Aceito ser indicado a vagas de trabalho pelos meus professores e pela minha Instituição de Ensino',
  };

  const [itens, setItens] = useState([]);

  const defineItens = () => {
    const newItens = [];
    options.map((item) =>
      Object.keys(item).map((key) => {
        let obj = {
          label: translates[key],
          value: item[key],
          key: key,
        };
        newItens.push(obj);
      })
    );
    setItens(newItens);
  };

  useEffect(() => {
    defineItens();
  }, [options]);

  const onChangeValue = async (key) => {
    const copyOptions = [];
    await new Promise((resolve) => {
      options.map((item) => {
        item.hasOwnProperty(key) && (item[key] = !item[key]);
        copyOptions.push(item);
        copyOptions.length == options.length && resolve();
      });
    });
    setPermissionsOptions(copyOptions);
  };

  return (
    <>
      <PermissionsContainer universityColor={universityColor}>
        {itens.map(({ label, value, key }) => (
          <>
            <div className="flex">
              <div className="checkBoxContainer">
                <CheckboxInput
                  type="checkbox"
                  value={value}
                  defaultChecked={value}
                  onChange={() => onChangeValue(key)}
                />
              </div>
              <span>{label}</span>
            </div>
          </>
        ))}
      </PermissionsContainer>
    </>
  );
}
