import React from 'react';
import {
  Card, ContentStatus,
  Paragraph, Header,
  ContentIcon, InformationsJob,
  Information, ContentButtons,
  ButtonJobOffers, ButtonDetailsJobOffers,
  ContainerStatusCard, ParagraphStatusCard,
  ParagraphTitle,
} from './styled';
import SpinnerLoading from 'components/SpinnerLoading';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';
import moment from 'moment';
import FavoriteJobsButtons from '../GenericComponents/FavoriteJobsButtons';
import MatchComponent from './MatchComponent';
import { ContainerStar } from '../styled';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

export default function GenericCardsJobOffers(props) {
  const {
    universityColor,
    code, name, dataPublish,
    companyName, finallyDate,
    jobModality, shift, location,
    match, onClickButton, label,
    labelTwo, onClickDetailsButton,
    isExternalJobs, like, onClickLike,
    isFavorite, status, processingAsFavorite,
    processingCandidature,
  } = props;
  const {
    IconSuitcase,
    IconLocation,
    IconCompany,
    IconHouse,
    IconDate,
  } = wrapperIconsJobOffers();

  const formatShift = (shift) => {
    if (shift?.includes('Indiferente') || shift?.includes('indifferent')) {
      return 'Turno indiferente';
    }
    return shift?.length > 1 ? shift?.join('/') : shift;
  };

  return (
    <Card style={{ height: isExternalJobs ? '270px' : '420px' }} match={match}>
      <Header>
        {(match > 0 || match === 0) && (
          <MatchComponent match={match} universityColor={universityColor} />
        )}

        {processingAsFavorite ? (
          <ContainerStar>
            <SpinnerLoading />
          </ContainerStar>
        ) : like ? (
          <FavoriteJobsButtons
            universityColor={universityColor}
            onClick={onClickLike}
            isFavorite={isFavorite}
          />
        ) : null}

        <Paragraph>CÓDIGO: {code}</Paragraph>
        <ParagraphTitle>{name}</ParagraphTitle>
      </Header>

      {status && (
        <ContainerStatusCard universityColor={universityColor}>
          <ParagraphStatusCard universityColor={universityColor}>
            {status}
          </ParagraphStatusCard>
        </ContainerStatusCard>
      )}

      {dataPublish && (
        <ContentStatus universityColor={universityColor}>
          <Paragraph universityColor={universityColor}>{dataPublish}</Paragraph>
        </ContentStatus>
      )}

      <InformationsJob>
        {companyName && (
          <Information>
            <ContentIcon universityColor={universityColor}>
              <IconCompany color={universityColor} />
            </ContentIcon>
            <ParagraphTitle isName universityColor={universityColor} bold>
              {companyName}
            </ParagraphTitle>
          </Information>
        )}

        {finallyDate && (
          <Information>
            <ContentIcon universityColor={universityColor}>
              <IconDate color={universityColor} />
            </ContentIcon>
            <Paragraph isDate>
              Inscreva-se até {moment(finallyDate).format('DD/MM/YYYY')}
            </Paragraph>
          </Information>
        )}

        {jobModality && (
          <Information>
            <ContentIcon universityColor={universityColor}>
              <IconHouse color={universityColor} />
            </ContentIcon>
            <Paragraph>{jobModality}</Paragraph>
          </Information>
        )}

        {shift && (
          <Information>
            <ContentIcon universityColor={universityColor}>
              <IconSuitcase color={universityColor} />
            </ContentIcon>
            <Paragraph>
              {formatShift(shift)}
            </Paragraph>
          </Information>
        )}

        {location && jobModality !== 'Remoto' && (
          <Information>
            <ContentIcon universityColor={universityColor}>
              <IconLocation color={universityColor} />
            </ContentIcon>
            <Paragraph>{location}</Paragraph>
          </Information>
        )}
      </InformationsJob>

      <ContentButtons>
        <ButtonDetailsJobOffers
          universityColor={universityColor}
          onClick={onClickDetailsButton}
          disabled={processingCandidature}
        >
          {labelTwo}
        </ButtonDetailsJobOffers>

        <ButtonJobOffers
          universityColor={universityColor}
          onClick={onClickButton}
          disabled={processingCandidature}
        >
          {processingCandidature ? <FilterLoading /> : label}
        </ButtonJobOffers>
      </ContentButtons>
    </Card>
  );
}
