import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  fetchMentorship,
  fetchRequestedMentorships,
  scheduleMentorship,
  changeMentorshipStatus,
  fetchScheduledMentorships,
  fetchHistoricMentorships,
  rescheduleMentorship,
  sendDocumentMentorship,
} from 'services/university/mentorship';

export const MentorshipContext = createContext({});

export function MentorshipContextProvider({ children }) {
  const [requestedMentorships, setRequestedMentorships] = useState(null);
  const [scheduledMentorships, setScheduledMentorships] = useState(null);
  const [historicMentorships, setHistoricMentorships] = useState(null);
  const [formAction, setFormAction] = useState('save');

  const [
    isScheduleMentorshipModalOpen,
    setIsScheduleMentorshipModalOpen,
  ] = useState(false);
  const [
    isCancelMentorshipModalOpen,
    setIsCancelMentorshipModalOpen,
  ] = useState(false);
  const [
    isDetailMentorshipModalOpen,
    setIsDetailMentorshipModalOpen,
  ] = useState(false);
  const [
    isAdjustMentorshipStatusModalOpen,
    setIsAdjustMentorshipStatusModalOpen,
  ] = useState(false);
  const [selectedMentorship, setSelectedMentorship] = useState(null);
  const [form, setForm] = useState(null);

  useEffect(() => {
    getScheduledMentorships();
    getHistoricMentorships();
  }, []);

  async function getRequestedMentorships() {
    const response = await fetchRequestedMentorships();
    setRequestedMentorships(response);
  }

  async function getScheduledMentorships() {
    const response = await fetchScheduledMentorships();
    setScheduledMentorships(response);
  }

  async function getHistoricMentorships() {
    const response = await fetchHistoricMentorships();
    setHistoricMentorships(response);
  }

  const closeScheduleMentorshipModal = () => {
    setSelectedMentorship(null);
    setIsScheduleMentorshipModalOpen(false);
    setFormAction('save');
    cleanForm();
  };

  const sendScheduleMentorship = async (data) => {
    const response = await scheduleMentorship(selectedMentorship.id, {
      status: 'scheduled',
      ...form,
      ...data,
    });
    if (response.status === 201) {
      getRequestedMentorships();
      getScheduledMentorships();
      return true;
    }
    return false;
  };

  const sendRescheduleMentorship = async (data) => {
    const newData = {
      status: 'rescheduled',
      ...form,
      ...data,
    };
    const response = await rescheduleMentorship(selectedMentorship.id, newData);
    if (response.status === 200) {
      getScheduledMentorships();
      return true;
    }
    return false;
  };

  const openScheduleMentorshipModal = async (mentorship) => {
    mentorship.details = await fetchMentorship(mentorship.id);
    setSelectedMentorship(mentorship);
    setIsScheduleMentorshipModalOpen(true);
  };

  function closeCancelMentorshipModal() {
    setIsCancelMentorshipModalOpen(false);
    setSelectedMentorship(null);
  }

  const openCancelMentorshipModal = async () => {
    setIsScheduleMentorshipModalOpen(false);
    setIsDetailMentorshipModalOpen(false);
    setIsAdjustMentorshipStatusModalOpen(false);
    setIsCancelMentorshipModalOpen(true);
  };

  const openRescheduleMentorshipModal = async (mentorship) => {
    let scheduled;
    if (mentorship.id) {
      mentorship.details = await fetchMentorship(mentorship.id);
      scheduled = mentorship.details.scheduled;
      setSelectedMentorship(mentorship);
    } else {
      scheduled = selectedMentorship.details.scheduled;
    }
    const newForm = {
      access_link: scheduled.access_link,
      address: scheduled.address,
      address_number: scheduled.address_number,
      address_complement: scheduled.address_complement,
      city: scheduled.city,
      state: scheduled.state,
      zip_code: scheduled.zip_code,
      district: scheduled.district,
      start_at: scheduled.start_at,
      description: scheduled.description,
    };
    setFormAction('reschedule');
    setForm(newForm);
    setIsDetailMentorshipModalOpen(false);
    setIsScheduleMentorshipModalOpen(true);
  };

  function closeDetailMentorshipModal() {
    setIsDetailMentorshipModalOpen(false);
    setSelectedMentorship(null);
  }

  function closeAdjustMentorshipStatusModal() {
    setIsAdjustMentorshipStatusModalOpen(false);
    setSelectedMentorship(null);
  }

  const openDetailMentorshipModal = async (mentorship) => {
    if (
      selectedMentorship === null ||
      mentorship.id !== selectedMentorship.id
    ) {
      mentorship.details = await fetchMentorship(mentorship.id);
      setSelectedMentorship(mentorship);
    }
    setIsAdjustMentorshipStatusModalOpen(false);
    setIsDetailMentorshipModalOpen(true);
  };

  const openAdjustMentorshipStatusModal = async (mentorship) => {
    mentorship.details = await fetchMentorship(mentorship.id);
    setSelectedMentorship(mentorship);
    setIsAdjustMentorshipStatusModalOpen(true);
  };

  function saveFormStep1(data) {
    const newForm = { ...form, ...data };
    if (data.access_link) {
      delete newForm.address;
      delete newForm.address_number;
      delete newForm.address_complement;
      delete newForm.city;
      delete newForm.state;
      delete newForm.zip_code;
      delete newForm.district;
    } else {
      delete newForm.access_link;
    }
    setForm(newForm);
  }

  function cleanForm() {
    setForm(null);
  }

  async function handleChangeMentorshipStatus({
    status,
    feedback,
    description,
    document = null,
  }) {
    let returnDocument;
    const response = await changeMentorshipStatus(
      {
        status,
        feedback,
        description,
      },
      selectedMentorship.id
    );
    if (document) {
      const formData = new FormData();
      formData.append('document', document);
      const responseDocument = await sendDocumentMentorship(
        formData,
        selectedMentorship.id
      );
      returnDocument = responseDocument.status === 201;
    } else {
      returnDocument = true;
    }

    getRequestedMentorships();
    getScheduledMentorships();
    getHistoricMentorships();
    return response.status === 201 && returnDocument;
  }

  return (
    <MentorshipContext.Provider
      value={{
        requestedMentorships,
        isScheduleMentorshipModalOpen,
        closeScheduleMentorshipModal,
        openScheduleMentorshipModal,
        selectedMentorship,
        saveFormStep1,
        sendScheduleMentorship,
        form,
        isCancelMentorshipModalOpen,
        isDetailMentorshipModalOpen,
        closeCancelMentorshipModal,
        openCancelMentorshipModal,
        handleChangeMentorshipStatus,
        getRequestedMentorships,
        getScheduledMentorships,
        getHistoricMentorships,
        scheduledMentorships,
        historicMentorships,
        closeDetailMentorshipModal,
        openDetailMentorshipModal,
        openRescheduleMentorshipModal,
        formAction,
        sendRescheduleMentorship,
        isAdjustMentorshipStatusModalOpen,
        closeAdjustMentorshipStatusModal,
        openAdjustMentorshipStatusModal,
      }}
    >
      {children}
    </MentorshipContext.Provider>
  );
}

export const useMentorship = () => useContext(MentorshipContext);
