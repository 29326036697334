import { formatNumber } from 'utils/formatNumber';
import { DataAreaBig } from '../../Styles/BaseComponent';
import {
  returnIcons,
  returnInformations,
  returnText,
} from '../../Utils/formatDataGraphics';
import Tooltip from 'components/NewTooltip';
import { RiInformationLine } from 'react-icons/ri';
import { WidgetSkeleton } from '../Skeletons/WidgetSkeleton';

export function BigStatWidget({ data, countSkeleton, padding }) {
  const returnContent = () => {
    if (data === null) {
      return countSkeleton.map(() => <WidgetSkeleton />);
    }

    if (data?.length > 0) {
      return data?.map((item) => (
        <DataAreaBig padding={padding}>
          <div className="icon-side">
            <div className="background-icon">{returnIcons[item.name]}</div>
          </div>
          <div className="data-side">
            <p>
              {`${returnText[item.name]} `}
              <span>
                <Tooltip
                  direction={'top'}
                  isMultiLine={true}
                  minWidth={'211px'}
                  text={returnInformations[item.name]}
                  colorSchema="black"
                >
                  <RiInformationLine />
                </Tooltip>
              </span>
            </p>
            <div className="value-information">
              <strong>{formatNumber(item.value)}</strong>
            </div>
          </div>
        </DataAreaBig>
      ));
    }
  };

  return <>{returnContent()}</>;
}
