import React, { useRef, useContext, useState } from 'react';
import * as Yup from 'yup';
import LogoAceleradora from 'assets/img/logo-workalove.svg';
import { Form } from '@unform/web';
import AuthContext from 'contexts/authentication';
import {
  sendEmail,
  emailExists,
  sendUniversityEmail,
} from 'services/login/recoverPassword';
import {
  Titles,
  ButtonLogin,
  ContainerButtonBack,
  FragmentContainer,
  ContainerInCenter,
  ContainerWorkalove,
  Footer,
  ContainerForgotPassword,
} from '../styles';
import BaseLayoutContext from 'contexts/base-layout';
import ModalInformation from 'components/informationModal';
import {
  configModalBlackList,
  configModalEmailNotFoundCompany,
} from '../Utils/styleConfig';
import LoadingLogin from '../UIComponents/Loading';
import VectorArrowBackSvg from '../UIComponents/VectorArrowBack';
import InputUsernameLogin from '../UIComponents/InputUsername';
import { login } from 'services/login/authenticate';
import logoWorkaloveOrange from 'assets/img/icons/logoWorkaloveLaranja.png';
import { Info } from 'assets/ImgsLogin/IconsLogin';

export default function LoginForgotPassword(props) {
  const {
    setShowForgotPassword,
    showForgotPassword,
    isCompanyConfig,
    isStudentLogin,
    isUniversityLogin,
    setContentLogin,
    logo,
    universityColor,
    setModalEmailSent,
    setEmail,
    showSendEmailSuccess,
    expiredToken,
    universityName,
  } = props;

  const formForgotPassword = useRef();

  const [modalUserNotFound, setModalUserNotFound] = useState(false);
  const [modalEmailNotFound, setModalEmailNotFound] = useState(false);
  const [modalBlacklisted, setModalBlacklisted] = useState(false);
  const [
    modalUniversityUserNotFound,
    setModalUniversityUserNotFound,
  ] = useState(false);

  const [username, setUsername] = useState(null);

  const [loading, setLoading] = useState(false);

  function handleBackLogin() {
    setShowForgotPassword(false);
    setContentLogin(true);
    formForgotPassword.current.reset();
  }

  const handleCloseModalUserNotFound = () => {
    setUsername(null);
    setModalUserNotFound(false);
    formForgotPassword.current.reset();
  };

  const handleCloseModalBlackList = () => {
    setModalBlacklisted(false);
    formForgotPassword.current.reset();
  };

  const handleCloseModalEmailNotFoundCompany = () => {
    setModalEmailNotFound(false);
    formForgotPassword.current.reset();
  };

  const handleCloseModalEmailNotFoundStudent = () => {
    setModalEmailNotFound(false);
    setUsername(null);
    formForgotPassword.current.reset();
  };

  const handleCloseModalUniversityUserNotFoundCompany = () => {
    setModalUniversityUserNotFound(false);
    formForgotPassword.current.reset();
  };

  const handleSubmitLogin = async (data) => {
    if (isCompanyConfig) {
      try {
        formForgotPassword.current.setErrors({});

        const sendEmailSchema = Yup.object().shape({
          email: Yup.string()
            .email('Preencha um e-mail válido')
            .required('Campo obrigatório'),
        });

        const validit = await sendEmailSchema.validate(data, {
          abortEarly: false,
        });

        if (validit && data.email) {
          setLoading(true);
          const response = await sendEmail(data);
          setLoading(false);

          if (response.status === 'OK') {
            setShowForgotPassword(false);
            setModalEmailSent(true);
          } else {
            setModalEmailNotFound(true);
          }
        }
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formForgotPassword.current.setErrors(validationErrors);
        }
      }
    }

    if (isStudentLogin) {
      try {
        formForgotPassword.current.setErrors({});

        const sendUsernameSchema = Yup.object().shape({
          username: Yup.string().required('Campo obrigatório'),
        });

        const validit = await sendUsernameSchema.validate(data, {
          abortEarly: false,
        });

        data.university = universityName;

        if (validit && data.username) {
          setLoading(true);
          const response = await emailExists(data);
          setLoading(false);
          const { email, exists, name, is_blacklisted } = response;

          if (exists) {
            if (is_blacklisted) {
              setModalBlacklisted(true);
              return;
            }
            if (!is_blacklisted) {
              setModalBlacklisted(false);
              setUsername(name);

              if (!email) {
                setModalEmailNotFound(true);
                return;
              }

              if (email) {
                setLoading(true);
                const sendResponse = await sendEmail({ email: email });
                setLoading(false);

                if (sendResponse.status === 'OK') {
                  setShowForgotPassword(false);
                  setEmail(email);
                  setModalEmailSent(true);
                }
              }
            }
          }
          if (!exists) {
            setUsername(data.username);
            setModalUserNotFound(true);
            return;
          }
        }
      } catch (err) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formForgotPassword.current.setErrors(validationErrors);
        }
      }
    }

    if (isUniversityLogin) {
      const validit = async () => {
        try {
          formForgotPassword.current.setErrors({});

          const sendUsernameSchema = Yup.object().shape({
            username: Yup.string().required('Campo obrigatório'),
          });

          return await sendUsernameSchema.validate(data, {
            abortEarly: false,
          });
        } catch (err) {
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach((error) => {
              validationErrors[error.path] = error.message;
            });
            formForgotPassword.current.setErrors(validationErrors);
          }
          return false;
        }
      };

      if (validit() && data.username) {
        try {
          setLoading(true);
          const response = await sendUniversityEmail(data);
          setLoading(false);
          if (response.status == 'OK') {
            showSendEmailSuccess();
          }
        } catch (e) {
          setLoading(false);
          setModalUniversityUserNotFound(true);
        }
      }
    }
  };

  const configModalUserNotFound = {
    bubbleText: `Aviso!`,
    ParagraphText: `
      Infelizmente não identificamos esse registro
      <strong>${username}</strong> na base da Workalove. <br/>
      Entre em contato com a sua
      <strong>Instituição de Ensino</strong> e solicite a
      integração dos seus dados na Workalove!
    `,
    style: {
      fontSize: '16px',
      lineHeight: '25px',
    },
    buttonConfirmText: `ENTENDI`,
  };

  const configModalEmailNotFoundStudent = {
    modalHeight: 450,
    bubbleText: `Aviso!`,
    ParagraphText: `
      Olá ${username}! <br/>
      Infelizmente não identificamos nenhum e-mail relacionado a
      esse número de registro. 
      <br />
      <br />
      Entre em contato com a sua <strong>Instituição de Ensino</strong> e solicite a
      atualização de seu cadastro na plataforma Workalove!
    `,
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '25px',
    },
    buttonConfirmText: `ENTENDI`,
  };

  const configModalUserNotFoundUniversity = {
    modalHeight: 450,
    bubbleText: `Aviso!`,
    ParagraphText: `
      Infelizmente não identificamos nenhum usuário com esse nome no sistema. 
      <br />
      <br />
      Por favor, verifique se o nome de usuário informado está correto.
    `,
    style: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '25px',
    },
    buttonConfirmText: `ENTENDI`,
  };

  return (
    <ContainerForgotPassword student={isStudentLogin}>
      {isStudentLogin && modalUserNotFound && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={modalUserNotFound}
          config={configModalUserNotFound}
          buttonConfirmFunction={handleCloseModalUserNotFound}
        />
      )}

      {isStudentLogin && modalBlacklisted && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={modalBlacklisted}
          config={configModalBlackList}
          buttonConfirmFunction={handleCloseModalBlackList}
        />
      )}

      {isStudentLogin && modalEmailNotFound && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={modalEmailNotFound}
          config={configModalEmailNotFoundStudent}
          buttonConfirmFunction={handleCloseModalEmailNotFoundStudent}
        />
      )}

      {isCompanyConfig && modalEmailNotFound && (
        <ModalInformation
          modalOpenState={modalEmailNotFound}
          config={configModalEmailNotFoundCompany}
          buttonConfirmFunction={handleCloseModalEmailNotFoundCompany}
        />
      )}

      {isUniversityLogin && modalUniversityUserNotFound && (
        <ModalInformation
          modalOpenState={modalUniversityUserNotFound}
          config={configModalUserNotFoundUniversity}
          buttonConfirmFunction={handleCloseModalUniversityUserNotFoundCompany}
        />
      )}

      <FragmentContainer>
        {isStudentLogin && (
          <ContainerInCenter>
            <img src={logo} alt="" />
          </ContainerInCenter>
        )}
        {(isCompanyConfig || isUniversityLogin) && (
          <ContainerInCenter>
            {expiredToken ? (
              <div className="infoIcon">
                <Info />
              </div>
            ) : (
              <img src={logoWorkaloveOrange} alt="" />
            )}
          </ContainerInCenter>
        )}
        {!expiredToken && (
          <ContainerButtonBack onClick={handleBackLogin}>
            <VectorArrowBackSvg color={universityColor || '#009291'} />
          </ContainerButtonBack>
        )}
        <Titles
          isPassword
          style={{ marginTop: '20px' }}
          marginBottom={'4px'}
          color={isUniversityLogin && '#606062'}
          lineHeight={isUniversityLogin && '28px'}
        >
          {expiredToken ? (
            <strong>Ops!</strong>
          ) : (
            <strong>
              {isUniversityLogin ? 'Redefinir' : 'Recuperar'} senha
            </strong>
          )}
          {expiredToken ? (
            <span className="subtitle">
              Este link expirou. Para redefinir sua senha, informe seu nome de
              usuário para que possamos enviar novamente as instruções.
            </span>
          ) : (
            <span className={isUniversityLogin && 'subtitle'}>
              {`Para iniciar, digite o ${
                isUniversityLogin
                  ? 'nome de usuário.'
                  : isStudentLogin
                  ? 'login cadastrado.'
                  : 'e-mail cadastrado.'
              }`}
            </span>
          )}
        </Titles>

        <div className="form">
          <Form ref={formForgotPassword} onSubmit={handleSubmitLogin}>
            {isCompanyConfig && (
              <InputUsernameLogin
                universityColor={universityColor}
                name="email"
                label="E-mail"
                placeholder="workalove@workalove.com"
              />
            )}
            {isStudentLogin && (
              <InputUsernameLogin
                name="username"
                label="Login"
                universityColor={universityColor || '#009291'}
                placeholder="Digite aqui..."
              />
            )}
            {isUniversityLogin && (
              <InputUsernameLogin
                name="username"
                label="Usuário"
                universityColor={'#009291'}
                placeholder="Digite aqui..."
              />
            )}
            <ContainerInCenter>
              <ButtonLogin
                color={universityColor || '#009291'}
                disabled={loading}
                type="submit"
              >
                {loading ? <LoadingLogin color="white" /> : 'Enviar'}
              </ButtonLogin>
            </ContainerInCenter>
          </Form>
        </div>
      </FragmentContainer>
      <ContainerWorkalove isChooseUser>
        <Footer>
          By <strong>Workalove</strong>
        </Footer>
      </ContainerWorkalove>
    </ContainerForgotPassword>
  );
}
