import MentorshipModal from 'components/MentorshipModal';
import React, { useRef, useState } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { Container, SendButton } from './styles';
import SuccessModal from 'components/SuccessModal';

export default function ModalCancelMentorship() {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const descriptionRef = useRef(null);

  const {
    isCancelMentorshipModalOpen,
    closeCancelMentorshipModal,
    handleChangeMentorshipStatus,
  } = useMentorship();

  function handleClose() {
    setShowSuccessMessage(false);
    closeCancelMentorshipModal();
  }

  async function onCancelMentorship() {
    setRequestInProgress(true);
    const success = await handleChangeMentorshipStatus({
      status: 'canceled',
      description: descriptionRef.current.value,
    });
    if (success) {
      setShowSuccessMessage(true);
    }
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal isOpen={isCancelMentorshipModalOpen} onClose={handleClose}>
      {showSuccessMessage ? (
        <SuccessModal title="Mentoria cancelada com sucesso!" />
      ) : (
        <Container>
          <strong>Mentoria cancelada</strong>
          <span>Nos conte o motivo do cancelamento da mentoria:</span>
          <textarea
            name="description"
            rows="5"
            maxLength="500"
            placeholder={'Digite aqui...'}
            ref={descriptionRef}
          />
          <SendButton 
            onClick={onCancelMentorship} 
            disabled={requestInProgress}
          >
            {requestInProgress ? 'ENVIANDO...' : 'ENVIAR'}
          </SendButton>
        </Container>
      )}
    </MentorshipModal>
  );
}
