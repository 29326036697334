import MentorshipModal from 'components/MentorshipModal';
import React, { useState, useContext } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { ActionButton, Container } from './styles';
import SuccessModal from 'components/SuccessModal';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalAdjustMentorshipStatus() {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const {universityColor} = useContext(BaseLayoutContext)

  const {
    isAdjustMentorshipStatusModalOpen,
    closeAdjustMentorshipStatusModal,
    openCancelMentorshipModal,
    openEvaluateMentorshipModal,
    handleChangeMentorshipStatus,
  } = useMentorship();

  function handleClose() {
    setShowSuccessMessage(false);
    closeAdjustMentorshipStatusModal();
  }

  function handleCancel() {
    closeAdjustMentorshipStatusModal();
    openCancelMentorshipModal();
  }

  async function handleEvaluate() {
    setRequestInProgress(true);
    const success = await handleChangeMentorshipStatus('realized');
    if (success) {
      closeAdjustMentorshipStatusModal();
      openEvaluateMentorshipModal();
    }
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal
      isOpen={isAdjustMentorshipStatusModalOpen}
      onClose={handleClose}
    >
      {showSuccessMessage ? (
        <SuccessModal title="Mentoria cancelada com sucesso!" />
      ) : (
        <Container universityColor={universityColor}>
          <h1>Ajuste o status da mentoria</h1>
          <strong>A sua mentoria foi realizada?</strong>
          <div>
            <ActionButton 
              universityColor={universityColor} 
              onClick={handleEvaluate} 
              disabled={requestInProgress}
            >
              {requestInProgress ? 'Salvando...' : 'Sim, realizei a mentoria'}
            </ActionButton>
            
            <ActionButton 
              universityColor={universityColor} 
              onClick={handleCancel} 
              disabled={requestInProgress}
            >
              Não tive mentoria
            </ActionButton>
          </div>
        </Container>
      )}
    </MentorshipModal>
  );
}
