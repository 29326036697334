import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;

  > form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  > .hiddenInput {
    opacity: 0;
  }
`;

export const ContainerPromenience = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ContainerPromenienceImage = styled.div`
  display: flex;
  margin-top: 55px;
  align-items: center;
  justify-content: center;
  width: 200px;
  background-color: #f9f9f9;
  height: 100px;
  border: 1px dashed gray;
  margin-top: 55px;
  position: relative;

  > .removeImage {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #00000033;
    right: 0;
    cursor: pointer;
    top: 0;
  }

  > .unSelectedImage {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .selectedImage {
    width: 195px;
    height: 92px;
    object-fit: cover;
    position: relative;
  }
`;

export const ContainerPromenienceInformations = styled.div`
  margin-top: 45px;
  margin-right: 50px;
  color: #606062;
  h4 {
    font-weight: 600;
  }

  > p {
    max-width: 265px;
    font-weight: 400;
  }
`;

export const UploadImageButton = styled.span`
  display: flex;
  align-items: center;
  color: #009291;
  font-weight: bold;
  margin-top: 8px;
  gap: 5px;
  cursor: pointer;
`;
