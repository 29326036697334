export function IconDate({ color }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.34017 8.31201H4.00684C3.2715 8.31201 2.6735 8.91001 2.6735 9.64535V10.9787C2.6735 11.714 3.2715 12.312 4.00684 12.312H5.34017C6.0755 12.312 6.6735 11.714 6.6735 10.9787V9.64535C6.6735 8.91001 6.0755 8.31201 5.34017 8.31201ZM4.00684 10.9787V9.64535H5.34017V10.9787H4.00684ZM12.6735 1.64535H12.0068V0.978678C12.0068 0.610678 11.7088 0.312012 11.3402 0.312012C10.9715 0.312012 10.6735 0.610678 10.6735 0.978678V1.64535H5.34017V0.978678C5.34017 0.610678 5.04217 0.312012 4.6735 0.312012C4.30484 0.312012 4.00684 0.610678 4.00684 0.978678V1.64535H3.34017C1.50217 1.64535 0.00683594 3.14068 0.00683594 4.97868V12.9787C0.00683594 14.8167 1.50217 16.312 3.34017 16.312H12.6735C14.5115 16.312 16.0068 14.8167 16.0068 12.9787V4.97868C16.0068 3.14068 14.5115 1.64535 12.6735 1.64535ZM3.34017 2.97868H12.6735C13.7762 2.97868 14.6735 3.87601 14.6735 4.97868V5.64535H1.34017V4.97868C1.34017 3.87601 2.2375 2.97868 3.34017 2.97868ZM12.6735 14.9787H3.34017C2.2375 14.9787 1.34017 14.0813 1.34017 12.9787V6.97868H14.6735V12.9787C14.6735 14.0813 13.7762 14.9787 12.6735 14.9787Z"
        fill={color}
      />
    </svg>
  );
}
