import { authenticatedAPI } from 'services/api';

export const fetchRequestedMentorships = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/university/mentorships`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchScheduledMentorships = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/university/mentorships/scheduled`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchHistoricMentorships = async () => {
  const response = await authenticatedAPI
    .get(`api/internal/university/mentorships/historic`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchMentorship = async (id) => {
  const response = await authenticatedAPI
    .get(`api/internal/university/mentorships/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const fetchStudentProfile = async (id) => {
  const response = await authenticatedAPI
    .get(`api/internal/university/mentorships/${id}/student`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export const scheduleMentorship = async (id, data) => {
  const response = await authenticatedAPI
    .post(`api/internal/university/mentorships/${id}/to-schedule`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const changeMentorshipStatus = async (data, id) => {
  const response = await authenticatedAPI
    .post(`api/internal/university/mentorships/${id}/status`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const rescheduleMentorship = async (id, data) => {
  const response = await authenticatedAPI
    .put(`api/internal/university/mentorships/${id}/to-schedule`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export const sendDocumentMentorship = async (data, id) => {
  const response = await authenticatedAPI
    .post(`api/internal/university/mentorships/${id}/document`, data)
    .then((res) => res)
    .catch((error) => error);
  return response;
};
