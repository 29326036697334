import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@material-ui/core';

import paginationStyle from 'assets/jss/material-dashboard-pro-react/components/paginationStyle';

function Pagination({ ...props }) {
  const { classes, pages, color, limit, setLimit } = props;
  const [pagesSlice, setPagesSlice] = useState(pages);

  useEffect(() => {
    let pagesSliceTemp = [];
    let pagesTemp = pages;
    const index = pagesTemp.filter((page, index, arr) => {
      return page.active === true;
    });
    if (index.length > -1 && index[0] && index[0].text) {
      if (pagesTemp.length >= 20) {
        let indexId = index[0].text;
        let begin = 0;
        let end = 0;
        begin = indexId - 1;
        end = indexId + 5;
        if (indexId === 1) {
          begin = indexId;
        }
        pagesSliceTemp = pagesTemp.slice(begin, end);
        pagesSliceTemp.unshift(pagesTemp.shift());
        pagesSliceTemp.push(pagesTemp.pop());
        setPagesSlice(pagesSliceTemp);
      } else {
        setPagesSlice(pages);
      }
    }
  }, [pages]);

  return (
    <Grid container fullWidth direction="column" alignItems="center">
      <ul className={classes.pagination}>
        {pagesSlice.map((prop, key) => {
          const paginationLink = cx({
            [classes.paginationLink]: true,
            [classes[color]]: prop.active,
            [classes.disabled]: prop.disabled,
          });
          return (
            <li className={classes.paginationItem} key={key}>
              {prop.onClick !== undefined ? (
                <Button onClick={prop.onClick} className={paginationLink}>
                  {prop.text}
                </Button>
              ) : (
                <Button
                  onClick={() => console.log(`you've clicked ${prop.text}`)}
                  className={paginationLink}
                >
                  {prop.text}
                </Button>
              )}
            </li>
          );
        })}
      </ul>
      {limit && setLimit && pages.length > 0 && (
        <FormControl className={classes.selectFormControl}>
          <InputLabel
            htmlFor="page-select"
            className={classes.selectLabel}
            classes={{
              root: classes.cssLabel,
              focused: classes.cssFocused,
            }}
          >
            Itens por página
          </InputLabel>

          <Select
            value={limit}
            fullWidth
            onChange={(e) => setLimit(e.target.value)}
            className={classes.selectInput}
            inputProps={{
              name: 'pageSelect',
              id: 'page-select',
            }}
          >
            {[20, 50, 100].map((value) => (
              <MenuItem value={value}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
}

Pagination.defaultProps = {
  color: 'primary',
  limit: null,
  setLimit: null,
};

Pagination.propTypes = {
  classes: PropTypes.shape.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(['PREV', 'NEXT', '...', 'ANTERIOR', 'PRÓXIMO']),
      ]).isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'dnaColor',
  ]),
  limit: PropTypes.bool,
  setLimit: PropTypes.func,
};

export default withStyles(paginationStyle)(Pagination);
