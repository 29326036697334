import { css } from 'styled-components';

export const positions = {
  top: () => top,
  right: () => right,
  bottom: () => bottom,
  left: () => left,
};

export const colorSchemas = {
  dnaGreen: {
    span: () => dnaGreen,
    arrow: () => `border-color: transparent transparent #009291 transparent `,
  },
  black: {
    span: () => black,
    arrow: () =>
      `border-color: transparent transparent rgba(0,0,0,0.7) transparent `,
  },
};

const arrow = css`
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-style: solid;
  border-width: 0 5px 10px 5px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const top = css`
  bottom: 100%;
  left: 50%;
  transform: ${(props) =>
    props.positionX < 0
      ? `translateX(calc(-50% + ${Math.abs(props.positionX)}px))`
      : `translateX(-50%)`};
  margin-bottom: ${(props) => props.spacing};

  @media screen and (max-width: 640px) {
    transform: ${(props) =>
      props.positionX < 0
        ? `translateX(calc(-50% + ${Math.abs(props.positionX)}px + 2vw))`
        : `translateX(-50%)`};
  }

  :after {
    ${arrow}
    left: 50%;
    top: 104%;
    transform: translate(
        calc(
          -40% + ${(props) => props.arrowOffset} - ${(props) => (props.positionX < 0 ? Math.abs(props.positionX) + 'px' : 0 + 'px')}
        ),
        -40%
      )
      rotate(-180deg);

    @media screen and (max-width: 640px) {
      transform: translate(
          calc(
            -40% + ${(props) => props.arrowOffset} - ${(props) =>
                props.positionX < 0
                  ? Math.abs(props.positionX) + 'px'
                  : 0 + 'px'} - 2vw
          ),
          -40%
        )
        rotate(-180deg);
    }
    ${(props) => colorSchemas[props.colorSchema].arrow}
  }
`;

const right = css`
  top: 50%;
  left: 100%;
  transform: translatey(-50%);
  margin-left: ${(props) => props.spacing};

  :before {
    ${arrow}
    left: -1%;
    top: 50%;
    transform: translate(-60%, calc(-50% + ${(props) => props.arrowOffset}))
      rotate(-90deg);
    ${(props) => colorSchemas[props.colorSchema].arrow}
  }
`;

const bottom = css`
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: ${(props) => props.spacing};

  :before {
    ${arrow}
    left: 50%;
    top: -6%;
    transform: translate(calc(-40% + ${(props) => props.arrowOffset}), -60%)
      rotate(0deg);
    ${(props) => colorSchemas[props.colorSchema].arrow}
  }
`;

const left = css`
  top: 80%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: ${(props) => props.spacing};

  :after {
    ${arrow}
    left: 100%;
    top: 50%;
    transform: translate(-40%, calc(-50% + ${(props) => props.arrowOffset}))
      rotate(-31deg);
    ${(props) => colorSchemas[props.colorSchema].arrow}
  }
`;

const dnaGreen = css`
  background-color: #009291;
  border: 1px solid #009291;
  color: white;
`;
const black = css`
  background-color: #000000BF;
  color: white;
`;
