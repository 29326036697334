import React, { useContext, useEffect, useState } from 'react';
import TabJobCompany from '../../components/Tabs';
import { Container, Content } from './styled';
import JobsInProgress from './components/Tabs/JobsInProgress';
import PublishedByPartners from './components/Tabs/PublishedByPartners';
import {
  getJobsIesFilters,
  getJobsPartnersFilters,
  getJobsOpenings,
  getJobsPartners,
} from '../../services';
import { JobOfferContext } from '../../context/jobOfferContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { formatNumber } from '../../../../../utils/formatNumber';

import useIsMaster from '../../hooks/useIsMaster';

export let isMasterLet;

export default function PublishedJobsTabs(props) {
  const { countMyJobs, countJobsPartners, countJobs, role } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [jobsCount, setJobsCount] = useState(0);

  const { isMaster } = useIsMaster();

  isMasterLet = isMaster;

  const {
    setCurrentJobFilter,
    currentJobFilter,
    setDataFunctions,
    setListJobs,
    listJobs,
  } = useContext(JobOfferContext);

  useEffect(() => {
    setCurrentJobFilter(null);
    setListJobs(null);
    switch (activeTab) {
      case 0:
        getJobsIesFilters().then((data) => {
          setFilterOptions(data);
        });
        break;
      case 1:
        getJobsPartnersFilters().then((data) => {
          setFilterOptions(data);
        });
        break;
    }
  }, [activeTab]);

  useEffect(() => {
    if (currentPage === 1 && currentJobFilter === null) {
      setListJobs(null);
      countJobs();
      loadJobsData(`limit=${limit}&offset=${(currentPage - 1) * limit || 0}`);
    }
  }, [activeTab, currentJobFilter, currentPage]);

  useEffect(() => {
    if (currentJobFilter !== null) {
      setListJobs(null);
      countJobs();
      loadJobsData(`limit=${limit}&offset=${(currentPage - 1) * limit || 0}`);
    }
  }, [currentJobFilter, currentPage, limit]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limit, currentJobFilter, activeTab]);

  const handlePageChange = async (page, queryParams = '') => {
    const newPage = `limit=${limit}&offset=${
      (page - 1) * limit || 0
    }&${queryParams}`;
    await loadJobsData(newPage);
    await countJobs();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setDataFunctions({ handlePageChange });
  }, []);

  const loadJobsData = async (page) => {
    let data;
    const existFilters =
      currentJobFilter && Object.keys(currentJobFilter).length > 0;
    if (existFilters) {
      const queryParams = queryParamsFromFilterObject(currentJobFilter, {
        commaSeparatedFields: ['created_by_university'],
      });
      if (activeTab === 0) {
        data = await getJobsOpenings(`${page}&${queryParams}`);
      } else {
        data = await getJobsPartners(`${page}&${queryParams}`);
      }
    } else {
      if (activeTab === 0) {
        data = await getJobsOpenings(page);
      } else {
        data = await getJobsPartners(page);
      }
    }
    const { results, count } = data;
    setListJobs(results);
    setJobsCount(count);
  };

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    setJobsCount(value === 0 ? countMyJobs : countJobsPartners);
  };

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <JobsInProgress
          jobs={listJobs}
          setLimit={setLimit}
          limit={limit}
          onPageChange={onPageChange}
          currentPage={currentPage}
          jobsCount={jobsCount}
          setCurrentPage={setCurrentPage}
          activeTab={activeTab}
          setListJobs={setListJobs}
          universityFilters={currentJobFilter}
          countJobs={countJobs}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <PublishedByPartners
          jobs={listJobs}
          setLimit={setLimit}
          limit={limit}
          onPageChange={onPageChange}
          currentPage={currentPage}
          jobsCount={jobsCount}
          setCurrentPage={setCurrentPage}
          role={role}
          universityFilters={currentJobFilter}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  return (
    <Container>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={
            isMaster
              ? [
                  `Cadastradas pelo Grupo (${
                    activeTab === 0
                      ? formatNumber(jobsCount)
                      : formatNumber(countMyJobs)
                  })`,
                  `Cadastradas pelas Instituições (${
                    activeTab === 1
                      ? formatNumber(jobsCount)
                      : formatNumber(countJobsPartners)
                  })`,
                ]
              : [
                  `Minhas Vagas (${
                    activeTab === 0
                      ? formatNumber(jobsCount)
                      : formatNumber(countMyJobs)
                  })`,
                  `Vagas Publicadas por Parceiros (${
                    activeTab === 1
                      ? formatNumber(jobsCount)
                      : formatNumber(countJobsPartners)
                  })`,
                ]
          }
          filterOptions={filterOptions}
        />
        {renderPrincipalTabs(activeTab)}
      </Content>
    </Container>
  );
}
