import React, { useContext } from 'react';
import Icon from '@material-ui/core/Icon';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardIcon from 'components/Card/CardIcon';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import withStyles from '@material-ui/core/styles/withStyles';
import { InfoOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import HelpButtonStudent from '../../components/HelpButtonStudent';

import { func, shape, string, oneOfType, number, bool } from 'prop-types';
import ClassNames from 'classnames';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import BaseLayoutContext from 'contexts/base-layout';
import MyProfileContext from 'contexts/my-profile';
import useDefineColor from 'constants/colors';

import { FooterText } from './styles';

const CardComposition = (props) => {
  const {
    classes,
    className,
    cardTitleText,
    cardSubtitle,
    footerText,
    CardContent,
    emptyStateText,
    FooterContent,
    iconPath,
    iconDescription,
    infoFunction,
    HelpModalContent,
    helpModalTitle,
    colorScheme,
    customStyle,
    cardIconStyle,
    cardHeaderStyle,
    isIndicator,
    iconComponent,
    emptyStateComponent,
    smallSubtitle,
    verifyCurriculum,
  } = props;
  // TODO: Fix this, the only way I find to not send various styles whos
  // whos not implemented on TrailProgressCard and strike errors on js console.
  if (
    isIndicator ||
    (CardContent && CardContent.displayName === 'WithStyles(TrailProgressCard)')
  ) {
    const propsCopy = { ...props };
    delete propsCopy.classes;
    props = propsCopy;
  }

  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <BaseLayoutContext.Consumer>
      {(baseLayoutContext) => {
        return (
          <MyProfileContext.Consumer>
            {(myProfileContext) => {
              return (
                <>
                  <Card
                    className={
                      colorScheme
                        ? (classes.card,
                          classes[colorScheme],
                          ClassNames(className))
                        : ClassNames(className, classes.card)
                    }
                    style={customStyle && customStyle}
                  >
                    <CardHeader stats className={cardHeaderStyle || ''}>
                      {iconPath && (
                        <CardIcon
                          color={
                            colorScheme
                              ? colorScheme
                              : useDefineColor(universityColor) || 'primary'
                          }
                          className={classes.cardIcon}
                        >
                          <Icon className={classes.icon}>
                            <img src={iconPath} alt={iconDescription} />
                          </Icon>
                        </CardIcon>
                      )}
                      {!iconPath && iconDescription && (
                        <CardIcon
                          color={
                            colorScheme
                              ? colorScheme
                              : useDefineColor(universityColor) || 'primary'
                          }
                          className={cardIconStyle || classes.cardIcon}
                          style={isIndicator ? { marginRight: 0 } : null}
                        >
                          <h1>{iconDescription}</h1>
                        </CardIcon>
                      )}
                      {iconComponent && (
                        <CardIcon
                          color={
                            colorScheme
                              ? colorScheme
                              : useDefineColor(universityColor) || 'primary'
                          }
                          className={classes.cardIcon}
                        >
                          <Icon className={classes.svgIcons}>
                            {iconComponent}
                          </Icon>
                        </CardIcon>
                      )}
                      <div className={classes.flexColumn}>
                        {cardTitleText && (
                          <h3 className={classes.cardTitle}>{cardTitleText}</h3>
                        )}
                        {cardSubtitle &&
                          (smallSubtitle ? (
                            <small>{cardSubtitle}</small>
                          ) : (
                            <h4 className={classes.cardSubtitle}>
                              {cardSubtitle}
                            </h4>
                          ))}
                      </div>
                      {infoFunction && (
                        <IconButton
                          className={classes.infoIcon}
                          onClick={infoFunction}
                        >
                          <InfoOutlined />
                        </IconButton>
                      )}
                    </CardHeader>
                    {verifyCurriculum && (
                      <HelpButtonStudent
                        footerText={footerText}
                        {...props}
                        {...baseLayoutContext}
                        {...myProfileContext}
                        helpModalTitle={helpModalTitle}
                        HelpModalContent={HelpModalContent}
                      />
                    )}
                    <CardBody>
                      {CardContent ? (
                        <CardContent
                          {...props}
                          {...baseLayoutContext}
                          {...myProfileContext}
                        />
                      ) : emptyStateText ? (
                        <p className={classes.emptyStateBox}>
                          {emptyStateText || ''}
                        </p>
                      ) : (
                        emptyStateComponent
                      )}
                    </CardBody>
                    {FooterContent ? (
                      <CardFooter>
                        <FooterContent
                          {...props}
                          {...baseLayoutContext}
                          {...myProfileContext}
                        />
                      </CardFooter>
                    ) : null}
                    {footerText && <FooterText>{footerText}</FooterText>}
                  </Card>
                </>
              );
            }}
          </MyProfileContext.Consumer>
        );
      }}
    </BaseLayoutContext.Consumer>
  );
};

CardComposition.propTypes = {
  classes: shape.isRequired,
  iconPath: string,
  iconDescription: oneOfType([string, number]),
  cardTitleText: oneOfType([string, shape]),
  cardSubtitle: string,
  footerText: string,
  cardContent: shape,
  footerContent: shape,
  emptyStateText: string,
  openModalWithContentFn: func,
  helpContent: shape,
  helpTitle: string,
  colorScheme: string,
  customStyle: shape,
  iconComponent: func,
  emptyStateComponent: func,
  smallSubtitle: bool,
  curriculumFilled: bool,
};

export default withStyles(dashboardStyle)(CardComposition);
