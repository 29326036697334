import Axios from 'axios';
import { authenticatedAPI } from 'services/api';

class HttpRequest {
  constructor(api) {
    this.api = api;
    this.external = Axios.create();
  }

  async get(url, config = {}) {
    try {
      const response = await this.api.get(url, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async externalGet(url, config = {}) {
    try {
      const response = await this.external.get(url, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async post(url, data, config = {}) {
    try {
      const response = await this.api.post(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async delete(url, config = {}) {
    try {
      const response = await this.api.delete(url, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async externalPost(url, data, config = {}) {
    try {
      const response = await this.external.post(url, data, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async patch(url, data, config = {}) {
    try {
      const response = await this.api.patch(url, data, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async externalPatch(url, data, config = {}) {
    try {
      const response = await this.external.patch(url, data, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  async put(url, data, config = {}) {
    try {
      const response = await this.api.put(url, data, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }
  async externalPut(url, data, config = {}) {
    try {
      const response = await this.external.put(url, data, config);
      return response.data;
    } catch (error) {
      return error;
    }
  }
}

const httpRequest = new HttpRequest(authenticatedAPI);

export default httpRequest;
