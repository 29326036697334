import React, { useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid } from '../../Styles/StyleFilter';
import FilterModal from 'components/FilterModal';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { DashboardContext } from '../../Context';
import { useContext } from 'react';
import { RiInformationLine } from 'react-icons/ri';
import {
  Alert,
  SpanError,
  AlertDiv,
} from 'views/StaffPages/Dashboard/Styles/BaseComponent';
import * as Yup from 'yup';

export function FilterStudentDashboard({
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  zIndex = 999999,
  loading = false,
  staffRole,
}) {
  const { setQueryFilter, activeSubTab } = useContext(DashboardContext);
  const [errorDate, setErrorDate] = useState(false);
  const [alert, setAlert] = useState(false);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        start_date: Yup.date(),
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  function renderFilterContent() {
    return [
      <FilterGrid>
        <Filter
          name={['start_date', 'end_date']}
          label={'Período das informações'}
          type={FilterType.DATE_RANGE}
          maxDate={new Date()}
          settings={{
            placeholder: ['Data inicial', 'Data final'],
            onChange: () => {
              setAlert(true);
              setErrorDate(false);
            },
          }}
        />
        <AlertDiv>
          <Alert display={alert}>
            <RiInformationLine />A filtragem de dados por data tem efeito apenas
            na seção de <strong>Dados de acesso dos estudantes</strong>
          </Alert>
          <SpanError display={errorDate}>
            A data final precisa ser maior do que a data inicial.
          </SpanError>
        </AlertDiv>
        {staffRole === 'educationalGroup' && (
          <Filter
            label={'Universidade'}
            name={'university'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.university}
          />
        )}
        <Filter
          label={'Área de atuação'}
          name={'macro_area'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.macro_area}
        />
        <Filter
          label={'Curso'}
          name={'academic_course'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.academic_course}
        />
        <div>
          <Filter
            label={'Modalidade'}
            name={'modality'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.modality}
          />
          {activeSubTab === 0 ? (
            <Filter
              label={'Período'}
              name={'semester'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.semester}
            />
          ) : (
            <Filter
              label={'Faixa etária'}
              name={'age'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.age}
            />
          )}
        </div>
        {staffRole !== 'educationalGroup' && (
          <Filter
            label={'Unidade'}
            name={'unity'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.unity}
          />
        )}
        {activeSubTab === 0 && (
          <Filter
            label={'Faixa etária'}
            name={'age'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.age}
          />
        )}
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const isValid = await validateData(filters);
    if (!isValid) return false;
    const existFilters = filters && Object.keys(filters).length > 0;

    if (!existFilters) {
      onClearFilters();
      return;
    }

    if (existFilters) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
    }
  }

  async function onClearFilters() {
    setQueryFilter('');
    setErrorDate(false);
    setAlert(false);
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={zIndex}
      loading={loading}
      validateDate={true}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
