import backgroundMentoring from 'assets/img/mentorship-background.jpg';
import React, { useEffect, useState } from 'react';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import ScheduledMentorshipTab from './components/ScheduledMentorshipTab';
import HistoricMentorshipTab from './components/HistoricMentorshipTab';
import { MentorshipContextProvider } from './contexts/MentorshipContext';

export default function Mentorship({ handleIsLoadingState }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);
  return (
    <MentorshipContextProvider>
      <CompanyViewsHeader
        title="Mentoria de Carreira"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundMentoring}
        tabs={[`Mentorias Agendadas`, `Histórico de Mentorias`]}
      />

      {activeTab === 0 && <ScheduledMentorshipTab />}
      {activeTab === 1 && <HistoricMentorshipTab />}
    </MentorshipContextProvider>
  );
}
