import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Container,
  ModalityLegend,
  ModalitySelectContainer,
  StudentNoteContainer,
} from './styles';
import { CancelMentorshipButton, Footer, NextButton } from '../styles';
import Input from '../Input';
import { Form } from '@unform/web';
import ModalitySelect from '../ModalitySelect';
import { maskZipCode } from 'utils/inputMasks';
import { handleFieldMask } from 'utils/validation';
import { zipCodeRequest } from 'services/zipCode';
import * as Yup from 'yup';
import StepIndicator from 'components/StepIndicator';
import { useMentorship } from '../../../contexts/MentorshipContext';

export default function Step1({ setStep }) {
  const formRef = useRef(null);
  const [selectedModality, setSelectedModality] = useState('indifferent');
  const [isZipCodeFetched, setIsZipCodeFetched] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [modalityError, setModalityError] = useState(null);

  const {
    saveFormStep1,
    form,
    selectedMentorship,
    openCancelMentorshipModal,
  } = useMentorship();

  useEffect(() => {
    if (modalityError !== null && selectedModality === 'indifferent') {
      setModalityError(true);
    } else {
      setModalityError(false);
    }
  }, [selectedModality]);

  useEffect(() => {
    if (formRef.current && !!form) {
      if (!!form.access_link) {
        setSelectedModality('remote');
      } else if (form.zip_code) {
        setSelectedModality('presential');
        setIsZipCodeFetched(true);
      }
      setFormLoaded(true);
    } else if (selectedMentorship) {
      const studentPreferredModality =
        selectedMentorship.details.desired_modality.value;
      setSelectedModality(studentPreferredModality);
    }
  }, []);

  useEffect(() => {
    if (formLoaded) {
      formRef.current.setData({
        zip_code: form.zip_code || '',
        city: form.city || '',
        state: form.state || '',
        district: form.district || '',
        address: form.address || '',
        address_number: form.address_number || '',
        address_complement: form.address_complement || '',
        access_link: form.access_link || '',
      });
    }
  }, [formLoaded]);

  async function validatePresential(data) {
    const schema = Yup.object().shape({
      zip_code: Yup.string()
        .required('Este campo é obrigatório')
        .test(
          'zip_code',
          'CEP inválido',
          (value) => value && value.length === 9
        )
        .test('zip_code', 'CEP inválido', async (value) => {
          const response = await zipCodeRequest(value);
          return response.erro === undefined;
        }),
      address: Yup.string().required('Este campo é obrigatório'),
      district: Yup.string().required('Este campo é obrigatório'),
      city: Yup.string().required('Este campo é obrigatório'),
      state: Yup.string().required('Este campo é obrigatório'),
      address_number: Yup.string().required('Este campo é obrigatório'),
    });
    return await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function validateOnline(data) {
    const re = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:\/?#[\]@!$&'()*+,;=]+$/gm;
    const schema = Yup.object().shape({
      access_link: Yup.string().matches(re, 'URL inválida'),
    });
    return await schema.validate(data, {
      abortEarly: false,
    });
  }

  async function handleSubmit(data) {
    try {
      let validatedData;
      switch (selectedModality) {
        case 'presential':
          validatedData = await validatePresential(data);
          break;
        case 'remote':
          validatedData = await validateOnline(data);
          if (!data.access_link.includes('http')) {
            validatedData.access_link = `http://${data.access_link}`;
          }
          break;
        case 'indifferent':
          setModalityError(true);
          return;
      }
      formRef.current.setErrors({});
      setStep(2);
      saveFormStep1(validatedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function handleZipCodeChange(e) {
    const value = e.target.value;
    if (value.length === 9) {
      const response = await zipCodeRequest(value);
      if (response.erro) {
        formRef.current.setFieldError('zip_code', 'CEP inválido');
      } else {
        setIsZipCodeFetched(true);
        formRef.current.setErrors({});
        formRef.current.setData({
          address: response.logradouro,
          district: response.bairro,
          city: response.localidade,
          state: response.uf,
        });
      }
    }
  }

  return (
    <Fragment>
      <div style={{ padding: '0 3rem', width: '100%' }}>
        <StepIndicator
          count={2}
          currentStep={1}
          onStepChange={async () => {
            const data = formRef?.current?.getData();
            await handleSubmit(data);
          }}
        />
      </div>
      <Container
        style={{ padding: '0 3rem', width: '100%' }}
        modality={selectedModality}
        isZipCodeFetched={isZipCodeFetched}
      >
        <h1>Agendamento de Mentoria</h1>
        <p>
          Analise as informações enviadas pelo estudante e defina o melhor dia e
          horário para realizar a mentoria. Caso seja necessário, cancele a
          mentoria e justifique.
        </p>
        {selectedMentorship && selectedMentorship.details.status.description && (
          <StudentNoteContainer>
            <strong>Observações do estudante</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: selectedMentorship.details.status.description,
              }}
            />
          </StudentNoteContainer>
        )}
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalitySelectContainer modality={selectedModality}>
            <label>Modalidade</label>
            <ModalitySelect
              selectedModality={selectedModality}
              setSelectedModality={setSelectedModality}
              formRef={formRef}
              error={modalityError}
            />
            {modalityError && (
              <span style={{ color: '#DA0505' }}>Este campo é obrigatório</span>
            )}
            <ModalityLegend>
              Preferência do estudante:{' '}
              <span>
                {selectedMentorship &&
                  selectedMentorship.details.desired_modality.label}
              </span>
            </ModalityLegend>
          </ModalitySelectContainer>
          {selectedModality === 'presential' && (
            <Input
              name={'zip_code'}
              label={'CEP'}
              className={'zip_code'}
              autofocus
              maxlength={9}
              onKeyUp={async (event) => {
                handleFieldMask(event, maskZipCode);
                await handleZipCodeChange(event);
              }}
              style={{ gridArea: 'cep' }}
            />
          )}
          {selectedModality === 'remote' && (
            <Input name={'access_link'} label={'Link da mentoria'} autofocus />
          )}
          {selectedModality === 'presential' && isZipCodeFetched && (
            <Fragment>
              <Input
                name={'address'}
                label={'Avenida/Rua'}
                style={{ gridArea: 'address' }}
              />
              <Input
                name={'address_number'}
                label={'Número'}
                style={{ gridArea: 'address_number' }}
                autofocus
              />
              <Input
                name={'address_complement'}
                label={'Complemento'}
                style={{ gridArea: 'address_complement' }}
              />
              <Input
                name={'district'}
                label={'Bairro'}
                style={{ gridArea: 'district' }}
              />
              <Input
                name={'city'}
                label={'Cidade'}
                style={{ gridArea: 'city' }}
              />
              <Input
                name={'state'}
                label={'UF'}
                style={{ gridArea: 'state' }}
              />
            </Fragment>
          )}
          <Footer style={{ gridArea: 'footer' }}>
            <CancelMentorshipButton
              onClick={openCancelMentorshipModal}
              type="button"
            >
              Cancelar solicitação de mentoria
            </CancelMentorshipButton>
            <NextButton type="submit" />
          </Footer>
        </Form>
      </Container>
    </Fragment>
  );
}
