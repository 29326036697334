import React, { Component, useContext } from 'react';
import Button from 'components/CustomButtons/Button.jsx';

import { Grid, List, ListItem, Switch } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { object, func, array } from 'prop-types';

import { getSettings, saveSettings } from 'services/student/notifications';
import { ButtonConfig } from './Buttons';

import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

class ConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isSubmitting: false,
    };
  }

  async componentDidMount() {
    this.setState({ options: this.props.options });
    this.props.setNotificationsOptions(this.props.options);
    this.props.handleIsLoadingState(false);
  }

  updateNotifications = (target) => {
    const newOptions = this.state.options.map((item) => {
      if (item.value === target.value) {
        item.enabled = !item.enabled;
      }
      return item;
    });
    this.setState({ options: newOptions });
    this.props.setNotificationsOptions(newOptions);
  };

  handleSubmit = async () => {
    this.setState({ isSubmitting: true });
    const notificationsEnabled = this.state.options.filter(
      (item) => item.enabled
    );
    const response = await this.props.saveSettingsFn({
      notifications_enabled: notificationsEnabled.map((item) => item.value),
    });

    if (response.message) {
      this.props.openSnackbar(
        'Error ao salvar as configurações, tente novamente mais tarde!',
        true
      );
    } else {
      this.props.openSnackbar('Configurações salvas com sucesso!');
      this.setState({ isSubmitting: false });
    }
  };

  verifyPermission = (item) => {
    if (item.value === 'company_invited') {
      return item.visible && item.visible_student;
    } else {
      return item.visible_student;
    }
  };

  render() {
    const { classes, universityColor } = this.props;

    return (
      this.state.options.length !== 0 && (
        <Grid item xs md={12} className={classes.fullScreenGrid}>
          <form onSubmit={this.handleSubmit}>
            <List className={classes.list}>
              {this.state.options.map(
                (item) =>
                  this.verifyPermission(item) && (
                    <ListItem
                      className={`${classes.listItem} ${classes.listFlex}`}
                      key={item.value}
                    >
                      <h4 className={classes.fieldLabel}>{item.label}</h4>

                      <Switch
                        checked={item.enabled}
                        onChange={(event) =>
                          this.updateNotifications(event.target)
                        }
                        value={item.value}
                        classes={{
                          switchBase:
                            classes[
                              `${useDefineColor(universityColor)}SwitchBase`
                            ],
                          checked: item.enabled
                            ? classes[
                                `${useDefineColor(universityColor)}Checked`
                              ]
                            : classes[
                                `${useDefineColor(universityColor)}NoChecked`
                              ],
                          bar:
                            item.enabled &&
                            classes[`${useDefineColor(universityColor)}Bar`],
                        }}
                      />
                    </ListItem>
                  )
              )}
            </List>
          </form>
        </Grid>
      )
    );
  }
}

ConfigurationForm.defaultProps = {
  getSettingsFn: getSettings,
  saveSettingsFn: saveSettings,
};

ConfigurationForm.propTypes = {
  classes: object,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  getSettingsFn: func.isRequired,
  saveSettingsFn: func.isRequired,
  setNotificationsOptions: func.isRequired,
  options: array.isRequired,
};
export default withStyles(ProfessionalExperienceFormStyles)(ConfigurationForm);
