import MentorshipModal from 'components/MentorshipModal';
import React, { Fragment, useRef, useState, useContext } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { Container, HeaderModal, ResponseButton, SendButton } from './styles';
import SuccessModal from 'components/SuccessModal';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalCancelMentorship() {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [userResponse, setUserResponse] = useState(undefined);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const descriptionRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);

  const {
    isCancelMentorshipModalOpen,
    closeCancelMentorshipModal,
    handleChangeMentorshipStatus,
  } = useMentorship();

  function handleClose() {
    setUserResponse(undefined);
    setShowSuccessMessage(false);
    closeCancelMentorshipModal();
  }

  async function onCancelMentorship() {
    setRequestInProgress(true);
    const success = await handleChangeMentorshipStatus(
      'canceled',
      descriptionRef.current.value
    );
    if (success) {
      setShowSuccessMessage(true);
    }
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal
      isOpen={isCancelMentorshipModalOpen}
      onClose={handleClose}
      header={
        !showSuccessMessage && (
          <HeaderModal>
            <h1>Você tem certeza que deseja cancelar a mentoria?</h1>
          </HeaderModal>
        )
      }
    >
      {showSuccessMessage ? (
        <SuccessModal title="Mentoria cancelada com sucesso!" />
      ) : (
        <Container>
          <div>
            <ResponseButton
              universityColor={universityColor}
              state={userResponse}
              onClick={() => setUserResponse(true)}
            >
              Sim, quero cancelar
            </ResponseButton>
            <ResponseButton
              universityColor={universityColor}
              onClick={handleClose}
              state={userResponse === undefined ? userResponse : !userResponse}
            >
              Não quero cancelar
            </ResponseButton>
          </div>
          {!!userResponse && (
            <Fragment>
              <span>Nos conte o motivo do cancelamento da mentoria:</span>
              <textarea
                name="description"
                rows="5"
                maxLength="500"
                ref={descriptionRef}
                placeholder="Digite aqui..."
              />
              <p>opcional</p>
              <SendButton
                universityColor={universityColor}
                onClick={onCancelMentorship}
                disabled={requestInProgress}
              >
                {requestInProgress ? 'ENVIANDO...' : 'ENVIAR'}
              </SendButton>
            </Fragment>
          )}
        </Container>
      )}
    </MentorshipModal>
  );
}
