import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  Box,
  Content,
  WrapperTitle,
  ContainerButton,
  WrapperMessage,
  WhiteButton,
  GreenButton,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { ModalFinalization } from './FinalizationVacancy/modalFinalization';

export default function FinishModal(props) {
  const {
    isOpen,
    setModalFinish,
    jobID,
    isPartnerOffer,
    limit,
    status,
    setListJobsCompany,
    setListJobsCompanyHistory,
    setJobsCount,
    companyFilters,
    dataFunction,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef();

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setModalFinish(false);
    }
  };

  const closeModal = () => {
    setModalFinish(false);
    setOpenModal(false);
  };

  const handleModal = () => {
    setOpenModal(true);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <ModalFinalization
        company={true}
        visible={openModal}
        controlModal={setOpenModal}
        setModalFinish={setOpenModal}
        jobID={jobID}
        limit={limit}
        status={status}
        isPartnerOffer={isPartnerOffer}
        closeModal={closeModal}
        setListJobsCompany={setListJobsCompany}
        setListJobsCompanyHistory={setListJobsCompanyHistory}
        setJobsCount={setJobsCount}
        companyFilters={companyFilters}
        dataFunction={dataFunction}
      />
      <Box ref={modalRef} onClick={closeModalClick}>
        <Content size="variant">
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <div className="bubble-dialog">
              <span> Atenção! </span>
            </div>
          </WrapperTitle>
          <WrapperMessage>
            Tem certeza que deseja finalizar a vaga?
          </WrapperMessage>
          <ContainerButton>
            <WhiteButton onClick={closeModal} size="finish">
              NÃO
            </WhiteButton>
            <GreenButton closeModal={closeModal} onClick={handleModal}>
              SIM
            </GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  ) : null;
}
