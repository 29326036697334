import React, { Fragment } from 'react';
import { shape, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { Room, BusinessCenter } from '@material-ui/icons/';

const InvitesAddress = (props) => {
  const { address: PropAdress, styleClass, vacancies } = props;
  const {
    address,
    addressNumber,
    addressComplement,
    district,
    zipCode,
    city,
    state,
  } = PropAdress;
  return (
    <div className={styleClass} style={{ marginTop: '20px' }}>
      <Room />
      <span>
        <strong>Endereço:</strong>
        <br />
        {address && `${address}, `}
        {addressNumber && (
          <Fragment>
            {addressNumber ? `${addressNumber}, ` : null}
            {addressComplement ? `${addressComplement}, ` : null} {district}
            <br />
            {zipCode && `${zipCode}, ` || null}
          </Fragment>
        )}
        {city ? ` ${city} ` : null}
        {city && state ? '-' : ''}
        {state ? ` ${state} ` : null}


      </span>
      <span
        style={{
          margin: '0px 0px 18px 46px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <BusinessCenter />
        Vagas publicadas: {vacancies}
      </span>
    </div>
  );
};

InvitesAddress.propTypes = {
  address: shape.isRequired,
  styleClass: string.isRequired,
};

export default withStyles(dashboardStyle)(InvitesAddress);
