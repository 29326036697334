import styled from 'styled-components';

export const Main = styled.div`
  display: block;
  position: fixed;
  z-index: 10000;
  padding-top: 50vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 150%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  color: #a1a1a1;
`;
export const ModalContent = styled.div`
  transform: translateY(-50%) !important;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 20px 0;
  border: 1px solid #888;
  width: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 20px;
  font-size: 14px;
  padding: 20px 30px;
`;
export const ModalHeader = styled.div`
  border-bottom: 1px solid #b5b4b5;
  font-size: 21px;
  font-weight: bold;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ModalBody = styled.div`
  p {
    color: #a1a1a1;
  }
  width: 100%;
  .buttonDiv {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  button {
    object-position: right bottom;
    padding: 16px 16px;
    color: #009291;
    border: 1px transparent;
    background-color: transparent;
    border-radius: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s linear;
    letter-spacing: 0.5px;
    :hover {
      background-color: rgba(0, 146, 145, 0.3);
    }
  }
`;
export const CloseButton = styled.span`
  color: #b7b6b6;
  float: right;
  font-size: 28px;
  font-weight: bold;

  :hover {
    color: rgb(47, 145, 144);
    text-decoration: none;
    cursor: pointer;
  }
`;
