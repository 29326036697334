import React, { Fragment, useState, useContext } from 'react';
import { func, shape, string } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import networkingStyle from 'assets/jss/material-dashboard-pro-react/views/networkingStyle';

import { Grid } from '@material-ui/core';
import CardComposition from 'components/CardComposition/CardComposition';
import Carrousel from './components/Carrousel/Carrousel';
import PartnerDetailDialog from './components/PartnerDetailDialog';
import Companies from './components/Companies';
import BaseLayoutContext from 'contexts/base-layout';

const CompaniesTab = ({
  classes,
  curriculumLikes,
  curriculumViews,
  curriculumConnected,
  companyDetail,
  setCompanyDetail,
  universityColor,
}) => {
  const [visibleDetail, setVisibleDetail] = useState(false);

  function showDetail(company) {
    setCompanyDetail(company);
    setVisibleDetail(true);
  }

  return (
    <Fragment>
      <Grid container justify="center">
        <Grid item xs lg={10} sm={12} xl={8} className={classes.gridItem}>
          <h4 className={classes.viewTitle}>
            Agora é hora de você construir e manter conectado à sua rede de
            contatos profissionais
          </h4>
        </Grid>
        <Grid item xs lg={10} sm={12} xl={8} className={classes.gridItem}>
          <CardComposition
            cardTitleText="Empresas Parceiras"
            cardSubtitle="Empresas parceiras da sua instituição de ensino"
            CardContent={curriculumConnected.length > 0 ? Carrousel : null}
            invites={curriculumConnected}
            content={Companies}
            universityColor={universityColor}
            classes={classes}
            showDetail={showDetail}
            emptyStateText="Sua instituição de ensino ainda não possui parceiros"
          />
        </Grid>
        <Grid item xs={12} lg={10} xl={8} className={classes.gridItem}>
          <CardComposition
            cardTitleText="Views"
            cardSubtitle="Empresas que visualizaram seu currículo"
            CardContent={curriculumViews.length > 0 ? Carrousel : null}
            invites={curriculumViews}
            content={Companies}
            classes={classes}
            universityColor={universityColor}
            showDetail={showDetail}
            emptyStateText="Nenhuma empresa visualizou seu currículo ainda"
          />
        </Grid>
        <Grid item xs={12} lg={10} xl={8} className={classes.gridItem}>
          <CardComposition
            cardTitleText="Curtidas"
            cardSubtitle="Empresas que curtiram seu currículo"
            CardContent={curriculumLikes.length > 0 ? Carrousel : null}
            invites={curriculumLikes}
            content={Companies}
            universityColor={universityColor}
            classes={classes}
            showDetail={showDetail}
            emptyStateText="Nenhuma empresa curtiu seu currículo ainda"
          />
        </Grid>
      </Grid>
      <PartnerDetailDialog
        company={companyDetail}
        setVisibleDialog={setVisibleDetail}
        visibleDialog={visibleDetail}
      />
    </Fragment>
  );
};

CompaniesTab.propTypes = {
  classes: shape.isRequired,
  curriculumLikes: shape.isRequired,
  curriculumViews: shape.isRequired,
  curriculumConnected: shape.isRequired,
  companyDetail: shape.isRequired,
  setCompanyDetail: func.isRequired,
  universityColor: string,
};

export default withStyles(networkingStyle)(CompaniesTab);
