import React from 'react';
import { FilterDatePeriodContainer } from '../../styles';
import { CustomDatePicker } from './CustomDatePicker';
import { useFilterContext } from 'components/FilterModal/contexts/FilterContext';
import { ErrorWrapper } from 'components/UnformFields/SimpleDatePicker/styled';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export function FilterDateRange({
  name,
  placeholder,
  onChange,
  startYear,
  maxDate,
}) {
  const { errorDate } = useFilterContext();
  return (
    <FilterDatePeriodContainer>
      <div>
        <CustomDatePicker
          name={name[0]}
          placeholder={placeholder !== null ? placeholder[0] : 'DD/MM/YYYY'}
          onChange={onChange}
          startYear={startYear}
          maxDate={maxDate}
        />
        <CustomDatePicker
          name={name[1]}
          placeholder={placeholder !== null ? placeholder[1] : 'DD/MM/YYYY'}
          onChange={onChange}
          startYear={startYear}
        />
      </div>
      {errorDate && (
        <ErrorWrapper style={{ marginTop: 'auto' }}>
          <>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />A data final
            precisa ser maior do que a data inicial.
          </>
        </ErrorWrapper>
      )}
    </FilterDatePeriodContainer>
  );
}
