import styled, { keyframes } from 'styled-components';

export const Content = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  button,
  div,
  label,
  input,
  textarea,
  select {
    font-family: 'Roboto', sans-serif;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  border: none;
  border-radius: 21.5px;
  background-color: #009291;
  color: #fff;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;
  margin: 3rem 0 3rem 0;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 1rem;

  &:hover {
    filter: brightness(90%);
  }
`;

export const ContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  margin: 2rem 0 0 0;
  width: 720px;

  strong {
    margin: 3rem 0 0 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #009291;
    max-width: 582px;
    text-align: center;
  }
`;

export const Container = styled.div`
  margin: 3rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  > strong {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    color: #009291;
    width: 720px;
    text-align: center;
  }

  > span {
    font-size: 14px;
    line-height: 17px;
    color: #606062;
    font-weight: 400;
    margin-top: 10px;
    width: 720px;
    text-align: center;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: #cdcdcd;
`;

function getStatusBackgroundColor(status) {
  switch (status) {
    case 'requested':
      return '#DEFDFD;';
    case 'realized':
      return '#D9FFE3';
    case 'accepted':
      return '#D9FFE3';
    case 'reschedule_request':
      return '#FFFDED';
    case 'canceled':
      return '#FFEDED';
    case 'scheduled':
      return '#FFFDED';
    case 'rescheduled':
      return '#FFFDED';
    default:
      // expired
      return '#F4F4F4';
  }
}

function getStatusColor(status) {
  switch (status) {
    case 'requested':
      return '#009291';
    case 'realized':
      return '#068E2C';
    case 'accepted':
      return '#068E2C';
    case 'reschedule_request':
      return '#E9B600';
    case 'scheduled':
      return '#E9B600';
    case 'rescheduled':
      return '#E9B600';
    case 'canceled':
      return '#DA0505';
    default:
      // expired
      return '#606062';
  }
}

export const StatusIndicator = styled.div`
  background-color: ${(props) => getStatusBackgroundColor(props.status)};
  color: ${(props) => getStatusColor(props.status)};
  border-radius: 3.39px;
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 400;
  height: 23px;
  align-items: center;
  justify-content: center;
  color: ${(props) => getStatusColor(props.status)};
  width: fit-content;
  margin: 0 0 20px 0;
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const SkeletonBox = styled.div`
  display: inline-block;
  height: ${(props) => props.height || '14px'};
  width: ${(props) => props.width || '80%'};
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: ${(props) => props.marginTop || '0'};
`;
