import React, { useEffect, useRef } from 'react';
import JoditEditor from 'jodit-react';
import './jodit.css';
import { useField } from '@unform/core';

export default function HtmlEditor({
  name,
  value,
  setValue,
  placeholder,
  autoFocus,
  height = '10em',
  minHeight = '200px',
  ...rest
}) {
  const ref = useRef(null);
  const { registerField, fieldName } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const config = {
    theme: 'summer',
    style: {
      'color-text': 'red',
      colorBorder: 'black',
      'color-panel': 'blue',
    },
    placeholder: placeholder,
    language: 'pt_br',
    readonly: false,
    height: height,
    minHeight: minHeight,
    buttons: ['bold', 'italic', 'underline'],
    buttonsMD: ['bold', 'italic', 'underline'],
    buttonsSM: ['bold', 'italic', 'underline'],
    buttonsXS: ['bold', 'italic', 'underline'],
    removeButtons: [
      'source',
      '|',
      '|',
      'ul',
      'ol',
      '|',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      '|',
      'image',
      'table',
      '|',
      'left',
      'center',
      'right',
      'justify',
      '|',
      'undo',
      'redo',
      '|',
      'hr',
      'eraser',
      'fullsize',
    ],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: true,
    allowResizeX: false,
    allowResizeY: false,
    toolbarSticky: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    tabIndex: 0,
    limitChars: 1000,
    autofocus: autoFocus,
    defaultActionOnPaste: 'insert_only_text',
  };

  return (
    <div className="test" {...rest}>
      <JoditEditor
        ref={ref}
        value={value}
        config={config}
        onBlur={setValue}
        defaultValue={''}
      />
    </div>
  );
}
