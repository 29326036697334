import React, { createContext, useContext } from 'react';
import { FilterType } from '../components/Filter';

export const FilterContext = createContext({});

export const FilterContextProvider = ({
  children,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  errorDate,
  setErrorDate,
  validateDate,
  ...rest
}) => {
  function updateList(option, name) {
    const newFilterState = { ...filterState };
    if (newFilterState[name]) {
      const isOptionInList = newFilterState[name].includes(option);
      if (isOptionInList) {
        newFilterState[name] = newFilterState[name].filter(
          (item) => item !== option
        );
        if (newFilterState[name].length === 0) {
          delete newFilterState[name];
        }
      } else {
        newFilterState[name] = [...newFilterState[name], option];
      }
    } else {
      newFilterState[name] = [option];
    }
    setFilterState(() => newFilterState);
  }

  function updateSingle(value, name) {
    const newFilterState = { ...filterState };
    const newValue = value;
    if (
      (typeof newValue === 'string' && newValue === '') ||
      newValue === null
    ) {
      delete newFilterState[name];
    } else {
      newFilterState[name] = newValue;
    }
    setFilterState(() => newFilterState);
  }

  function updateDate(value, name) {
    const newFilterState = { ...filterState };
    if (value) {
      newFilterState[name] = value;
    } else {
      setErrorDate(false)
      delete newFilterState[name];
    }
    setFilterState(() => newFilterState);
  }

  function updateProgressRange(value, name) {
    const newFilterState = { ...filterState };
    if (value) {
      newFilterState[name] = value;      
    } else {
      delete newFilterState[name];
    }
    setFilterState(() => newFilterState);
  }

  function updateFilterState(option, name, type) {
    switch (type) {
      case FilterType.CHECKBOX:
      case FilterType.MULTI_SELECT:
      case FilterType.MULTI_TEXT:
        updateList(option, name);
        break;
      case FilterType.TEXT:
      case FilterType.RADIO:
      case FilterType.SINGLE_CHECKBOX:
      case FilterType.SINGLE_SELECT:
        updateSingle(option, name);
        break;
      case FilterType.PROGRESS_RANGE:
        updateProgressRange(option, name);
        break;
      case FilterType.DATE_RANGE:
        updateDate(option, name);
        break;
    }
  }

  return (
    <FilterContext.Provider
      value={{
        filterState,
        setFilterState,
        updateFilterState,
        appliedFilters,
        setAppliedFilters,
        errorDate,
        ...rest,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
