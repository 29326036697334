import { BaseContainer } from './style';
import { GenericInternalSwitch } from 'components/GenericInternalSwitch';
import { useState, useCallback } from 'react';
import { EnrolledTab } from './EnrolledTab';
import { GraduatesTab } from './GraduatesTab';
import { CompaniesTab } from './CompaniesTab';

export function EngineLoginConfig({ setNoScroll }) {
  const [activeTabs, setActiveTabs] = useState(0);

  const getSettings = useCallback(async (type) => {
    const response = await getSettings(type);
    setDataEnrolled(response);
    return response;
  }, []);

  const Tabs = [
    {
      activeSwitch: 0,
      component: (
        <EnrolledTab fetchEnrolled={getSettings} setNoScroll={setNoScroll} />
      ),
    },
    {
      activeSwitch: 1,
      component: (
        <GraduatesTab fetchGraduates={getSettings} setNoScroll={setNoScroll} />
      ),
    },
    {
      activeSwitch: 2,
      component: (
        <CompaniesTab fetchGraduates={getSettings} setNoScroll={setNoScroll} />
      ),
    },
  ];

  const handleChangeTab = (value) => {
    setActiveTabs(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSwitch === step);
    return returnTabs.component;
  };

  return (
    <BaseContainer>
      <GenericInternalSwitch
        activeTab={activeTabs}
        handleChange={handleChangeTab}
        tabs={[
          'Em formação<br/>(matriculados)'
          , 
          `Formados<br/>(egressos)`,
          `Empresas`
        ]}
      />
      {renderPrincipalTabs(activeTabs)}
    </BaseContainer>
  );
}
