import styled from 'styled-components';

import SearchableSelect from 'react-select';
import { InputLabel } from '@material-ui/core';

export const CustomSelect = styled(SearchableSelect)`
  ${(props) => props.error && { border: '1px solid red' }}
`;
export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.span`
  text-align: right;
  font-size: 12px;
  color: red;
`;
