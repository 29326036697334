import React, { useContext, useEffect, useState, useRef } from 'react';

import {
  WrapperMoreInfo,
  WrapperHeader,
  ImgHeader,
  WrapperContent,
  WrapperStatus,
  StarActivityImg,
  DivStar,
  Wrapper,
  WrapperStatusPublished,
  ReactionWrapper,
  Reaction,
  WrapperTitles,
  Title,
  SubTitle,
  WrapperInformartions,
  Container,
  GridSide,
  ItemWrapper,
  GrayBox,
  Circle,
} from './style';
import GreenStar from '../../../../../../assets/Oportutinites/GreenStar.svg';
import published from '../../../../../../assets/Oportutinites/published.svg';
import expired from '../../../../../../assets/Oportutinites/expired.svg';
import deleted from '../../../../../../assets/Oportutinites/deleted.svg';
import Calendar from '../../../../../../assets/Oportutinites/Calendar.svg';
import Alvo from '../../../../../../assets/Oportutinites/Alvo.svg';
import event from '../../../../../../assets/Oportutinites/event.svg';
import Group10 from '../../../../../../assets/Oportutinites/Group10.svg';
import chapeu from '../../../../../../assets/Oportutinites/chapeu.svg';
import person from '../../../../../../assets/Oportutinites/person.svg';
import checked from '../../../../../../assets/Oportutinites/checked.svg';
import InformationModal from '../../InformationModal';

import ReactHtmlParser from 'react-html-parser';

import { ModalX } from '../../../Styles/Styled';
import moment from 'moment';
import { unPublishAttraction } from '../../../../../../services/university/events';
import ConfirmDespublish from './ConfirmDespublish';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import Modal from '../../modal';
import LoadingModal from '../../loadingModal';
import ContainerButtonsModal from './ButtonsModal';
import { useIcons } from 'views/StaffPages/Opportunities/hooks/useIcons';
import { useEducationalGroupLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsEducationalGroup';
import { useLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsOpportunities';
import {
  CardImgDiv,
  EventCardImg,
  CardImg,
} from 'views/StaffPages/Opportunities/Styles/PublishedOpportunities';

export default function MoreInformationModal({
  isOpen,
  children,
  setModalOpen,
  item,
  setItem,
  handleClickEdit,
  setMacroModalOpen,
  learningPublishedByTheIEs,
  learningPublishedByTheGroup,
  visionGroup,
}) {
  const setState = () => {
    if (setItem) {
      setItem([]);
    }
    setModalOpen(false);
  };

  const {
    setModalIsOpen,
    setStep,
    setOptionsId,
    setSelectOption,
    setFormData,
    stepOneRef,
    setModalContent,
    educationalGroup,
  } = useContext(OportunitiesContext);

  const { AiOutlineLink, AiOutlineStar, BiLike, BiDislike } = useIcons();

  const {
    getEducationalGroupAllEvents,
    getEducationalGroupAllProgram,
    getEducationalGroupAllCourses,
    getEducationalGroupAllArticles,
    getEducationalGroupAllEbooks,
    getEducationalGroupAllTrails,
    getEducationalGroupAllSeries,
    getEducationalGroupAllAnnouncements,
    getEducationalGroupAllChallenges,
  } = useEducationalGroupLearningAPICalls();

  const {
    getAllEvents,
    getAllPrograms,
    getAllTrails,
    getAllArticles,
    getAllSeries,
    getAllEbooks,
    getAllChallenges,
    getAllAnnouncements,
    getAllCourses,
  } = useLearningAPICalls();

  useEffect(() => {
    if (isOpen === false) {
      stepOneRef.current && stepOneRef.current.reset();
      setFormData({});
      setSelectOption('');
      setOptionsId('');
      setStep(0);
      setModalIsOpen(false);
      setModalContent(null);
    }
  }, [isOpen]);

  const [modalOpen, setOpen] = useState(false);
  const [modalSucess, setModalSucess] = useState(false);
  const [objectModalSucess, setObjectModalSucess] = useState({
    header: 'Tudo certo!',
    msg: 'Oportunidade despublicada',
    icon: 'success',
  });
  const [loadingModal, setLoadingModal] = useState(false);

  const modalRefOpen = useRef();
  const modalBlack = useRef();

  const returnImageStatus = (item) => {
    if (item.is_published === false) {
      return deleted;
    }
    if (item.is_published === true && item.status === null) {
      return published;
    }
    if (item.status === 'Expirada') {
      return expired;
    }

    return published;
  };

  const returnTitleEvent = {
    event: 'Tipo de Evento:',
    program: 'Tipo de Programa:',
    course: 'Tipo de curso:',
  };

  const returnSubTitleEvent = () => {
    if (item.type === 'event') {
      return item.modality === 'online' ? 'Online' : 'Presencial';
    }
    if (item.type === 'program') {
      if (item.category && item.category.label) {
        return item.category && item.category.label;
      }
      return 'Não informado';
    }
    if (item.type === 'course') {
      if (item.course_category && item.course_category.label) {
        return item.course_category && item.course_category.label;
      }
      return 'Não informado';
    }
    return 'Não informado';
  };

  const returnPublicationFormat = {
    access_link: 'Link de acesso',
    video: 'Vídeos sequenciais',
    attraction_document: 'Arquivo PDF',
  };

  const BooleanReturnTitle = () => {
    if (item.type === 'event') {
      return true;
    }
    if (item.type === 'program') {
      return true;
    }
    if (item.type === 'course') {
      return true;
    }
    return false;
  };

  const ScrollPosition = () => {
    if (modalRefOpen) {
      modalRefOpen.current.scrollIntoView({ alignToTop: true });
    }
  };

  const closeModal = (e) => {
    if (modalBlack.current === e.target) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      ScrollPosition();
    }
  }, [isOpen]);

  const findCurrentReload = [
    {
      type: 'event',
      getOpportunity: getAllEvents,
    },
    {
      type: 'program',
      getOpportunity: getAllPrograms,
    },
    {
      type: 'course',
      getOpportunity: getAllCourses,
    },
    {
      type: 'article',
      getOpportunity: getAllArticles,
    },
    {
      type: 'e_book',
      getOpportunity: getAllEbooks,
    },
    {
      type: 'trail',
      getOpportunity: getAllTrails,
    },
    {
      type: 'serie',
      getOpportunity: getAllSeries,
    },
    {
      type: 'announcements',
      getOpportunity: getAllAnnouncements,
    },
    {
      type: 'challenge',
      getOpportunity: getAllChallenges,
    },
  ];

  const findEducationalGroupCurrentReload = [
    {
      type: 'event',
      getOpportunity: getEducationalGroupAllEvents,
    },
    {
      type: 'program',
      getOpportunity: getEducationalGroupAllProgram,
    },
    {
      type: 'course',
      getOpportunity: getEducationalGroupAllCourses,
    },
    {
      type: 'article',
      getOpportunity: getEducationalGroupAllArticles,
    },
    {
      type: 'e_book',
      getOpportunity: getEducationalGroupAllEbooks,
    },
    {
      type: 'trail',
      getOpportunity: getEducationalGroupAllTrails,
    },
    {
      type: 'serie',
      getOpportunity: getEducationalGroupAllSeries,
    },
    {
      type: 'announcements',
      getOpportunity: getEducationalGroupAllAnnouncements,
    },
    {
      type: 'challenge',
      getOpportunity: getEducationalGroupAllChallenges,
    },
  ];

  const handleConfirm = async (item) => {
    if (item && learningPublishedByTheGroup) {
      const returned = findEducationalGroupCurrentReload.find(
        (option) => option.type === item.type
      );
      setOpen(false);
      setModalSucess(true);
      await unPublishAttraction(item, item?.is_created_by_educational_group);

      await returned.getOpportunity(true);
    }

    if (item && learningPublishedByTheIEs) {
      const returned = findCurrentReload.find(
        (option) => option.type === item.type
      );
      setOpen(false);
      setModalSucess(true);
      await unPublishAttraction(item, item?.is_created_by_educational_group);

      await returned.getOpportunity(educationalGroup, true);
    }
  };

  const returnButtons = (item) => {
    if (
      visionGroup &&
      learningPublishedByTheIEs &&
      !item?.is_created_by_educational_group
    ) {
      return (
        <ContainerButtonsModal
          showUnpublishButton={
            !visionGroup && item.is_published && item.created_by_admin === false
          }
          showEditButton={
            !visionGroup && item.is_published && item.created_by_admin === false
          }
          funcUnpublish={() => setOpen(true)}
          funcEdit={() => handleClickEdit(item, loadingModal, setLoadingModal)}
        />
      );
    }

    if (
      visionGroup &&
      learningPublishedByTheGroup &&
      item?.is_created_by_educational_group
    ) {
      return (
        <ContainerButtonsModal
          showUnpublishButton={
            visionGroup && item.is_published && item.created_by_admin === false
          }
          showEditButton={
            visionGroup && item.is_published && item.created_by_admin === false
          }
          funcUnpublish={() => setOpen(true)}
          funcEdit={() => handleClickEdit(item, loadingModal, setLoadingModal)}
        />
      );
    }

    if (!visionGroup && !item?.is_created_by_educational_group) {
      return (
        <ContainerButtonsModal
          showUnpublishButton={
            item.is_published && item.created_by_admin === false
          }
          showEditButton={
            item.is_published &&
            item.is_published &&
            item.created_by_admin === false
          }
          funcUnpublish={() => setOpen(true)}
          funcEdit={() => handleClickEdit(item, loadingModal, setLoadingModal)}
        />
      );
    }
  };

  const isLogo = (imageURL) => {
    return imageURL && imageURL.includes('logos/') ? true : false;
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <div className="box" ref={modalBlack} onMouseDown={closeModal}>
        <WrapperMoreInfo ref={modalRefOpen} isOpen={isOpen}>
          <WrapperHeader>
            {isLogo(item.attraction_image) ? (
              <CardImgDiv isModal>
                <EventCardImg
                  src={item.attraction_image}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1050';
                  }}
                />
              </CardImgDiv>
            ) : (
              <CardImg
                isModal
                src={
                  item.attraction_image ||
                  'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080'
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080';
                }}
              />
            )}

            {/* <ImgHeader
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src =
                  'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080';
              }}
              src={
                item.attraction_image ||
                'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080'
              }
            /> */}

            <Circle>
              <ModalX onClick={setState} />
            </Circle>
            <Modal />

            {item.total_reaction && (
              <ReactionWrapper>
                <Reaction>
                  <BiLike size={20} />
                  {item.total_reaction.total_liked}
                </Reaction>

                <Reaction>
                  <BiDislike size={20} />
                  {item.total_reaction.total_disliked}
                </Reaction>
              </ReactionWrapper>
            )}
          </WrapperHeader>
          {!children && (
            <WrapperContent>
              <WrapperStatus>
                {item.validity && (
                  <Wrapper>
                    <DivStar>
                      <StarActivityImg src={GreenStar} />
                    </DivStar>
                    <p style={{ marginTop: '10px', marginLeft: '5px' }}>
                      {item.validity.label}
                    </p>
                  </Wrapper>
                )}
                <WrapperStatusPublished>
                  <img
                    style={{ marginRight: '5px' }}
                    src={returnImageStatus(item)}
                  />
                  {item.status}
                </WrapperStatusPublished>
              </WrapperStatus>

              <InformationModal
                informationHeader={
                  'Tem certeza que deseja despublicar essa oportunidade de aprendizagem?'
                }
                errorMsg={'Você não poderá recuperar a publicação'}
                type={'Information'}
                isOpen={modalOpen}
                setModalOpen={setOpen}
                children={
                  <ConfirmDespublish
                    functionConfirm={() => handleConfirm(item)}
                    functionCancel={() => setOpen(false)}
                  />
                }
              />

              <InformationModal
                informationHeader={objectModalSucess.header}
                errorMsg={objectModalSucess.msg}
                type={objectModalSucess.icon}
                isOpen={modalSucess}
                setModalOpen={setModalSucess}
                closeOtherModal={setState}
              />

              <LoadingModal isOpen={loadingModal} />

              <WrapperTitles>
                <Title>{item.name}</Title>
                <SubTitle>
                  {ReactHtmlParser(
                    item.description.replace(/style="[^"]*"/g, '')
                  )}
                </SubTitle>
              </WrapperTitles>

              <WrapperInformartions>
                <div style={{ display: 'flex' }}>
                  <GridSide>
                    <ItemWrapper>
                      {BooleanReturnTitle() && (
                        <div className="container">
                          <img src={event} alt="icon-evento" />
                          <div className="text-wrapper">
                            <strong> {returnTitleEvent[item.type]} </strong>
                            <p> {returnSubTitleEvent()} </p>
                          </div>
                        </div>
                      )}
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={Calendar} alt="icon-calendario" />
                        <div className="text-wrapper">
                          <strong> Publicado em: </strong>
                          <p>
                            {' '}
                            {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={Calendar} alt="icon-calendario" />
                        <div className="text-wrapper">
                          <strong> Data de realização: </strong>
                          <p>
                            {item.deadline_registration
                              ? moment(item.deadline_registration).format(
                                  'DD/MM/YYYY'
                                )
                              : 'Não definido'}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    {item.type_access && (
                      <ItemWrapper>
                        <div className="container">
                          <img src={Group10} alt="icon-altoFaltante" />
                          <div className="text-wrapper">
                            <strong> Tipo de publicação: </strong>
                            <p> {returnPublicationFormat[item.type_access]} </p>
                          </div>
                        </div>
                      </ItemWrapper>
                    )}
                  </GridSide>

                  <GridSide>
                    <ItemWrapper>
                      <div className="container">
                        <img src={Alvo} alt="icon-alvo" />
                        <div className="text-wrapper">
                          <strong> Público-alvo </strong>
                          <button onClick={() => setMacroModalOpen(true)}>
                            Clique para ver
                          </button>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={chapeu} alt="icon-chapeu" />
                        <div className="text-wrapper">
                          <strong> Postado por: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.name
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={person} alt="icon-person" />
                        <div className="text-wrapper">
                          <strong> Responsável: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.user
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>
                  </GridSide>
                </div>
              </WrapperInformartions>

              <div style={{ display: 'flex' }}>
                <GrayBox>
                  <img style={{ width: '24px' }} src={checked} />
                  <p>
                    {`Concluído por ${
                      item.total_reaction && item.total_reaction.total_finished
                    } 
                      ${
                        item.total_reaction &&
                        item.total_reaction.total_finished === 1
                          ? 'estudante'
                          : 'estudantes'
                      }`}
                  </p>
                </GrayBox>

                <GrayBox>
                  <i>
                    <AiOutlineStar size={25} color="#3c4858" />
                  </i>
                  <p>
                    {`Favoritado ${
                      item.total_reaction &&
                      item.total_reaction.total_recommended
                    } 
                      ${
                        item.total_reaction &&
                        item.total_reaction.total_recommended === 1
                          ? 'vez'
                          : 'vezes'
                      }`}
                  </p>
                </GrayBox>

                <GrayBox>
                  <i>
                    <AiOutlineLink size={25} color="#3c4858" />
                  </i>
                  <p>
                    {`${
                      item.total_reaction && item.total_reaction.total_clicked
                    } 
                      ${
                        item.total_reaction &&
                        item.total_reaction.total_clicked === 1
                          ? 'estudante direcionado ao link cadastrado'
                          : 'estudantes direcionados ao link cadastrado'
                      }`}
                  </p>
                </GrayBox>
              </div>
              {returnButtons(item)}
            </WrapperContent>
          )}

          {children && children}
        </WrapperMoreInfo>
      </div>
    </Container>
  ) : null;
}
