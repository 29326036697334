import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import {
  BackButton,
  ButtonsDiv,
  CalendarIcon,
  Container,
  DateTimeContainer,
  DayContainer,
  DescriptionContainer,
  PeriodIcon,
  PeriodPreferences,
  PeriodsContainer,
  PeriodsLegend,
  SendButton,
} from './styles';
import StepIndicator from 'components/StepIndicator';
import { CancelMentorshipButton, Footer } from '../styles';
import { useMentorship } from '../../../contexts/MentorshipContext';
import DatePickerCustom from '../DatePickerCustom';
import MaskedInput from 'react-maskedinput';
import TimePicker from '../TimePicker';
import HtmlEditor from '../../../../../Student/Mentorship/components/ModalRequestMentorship/HtmlEditor';
import * as Yup from 'yup';
import { Divider } from '../../../styles';
import { leftPad } from 'utils/stringUtils';

export default function Step2({ setStep }) {
  const formRef = useRef(null);
  const {
    selectedMentorship,
    sendScheduleMentorship,
    openCancelMentorshipModal,
    formAction,
    sendRescheduleMentorship,
  } = useMentorship();
  const [startDate, setStartDate] = useState(new Date());
  const [periodsPreference, setPeriodsPreference] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [descriptionAutofocus, setDescriptionAutofocus] = useState(false);
  const [timeTouched, setTimeTouched] = useState(false);

  useEffect(() => {
    if (selectedMentorship) {
      const studentPeriods = selectedMentorship.details.periodspreference;
      const preferredDays = dayOptions.filter((day) =>
        studentPeriods.some((period) => period.favorite_day.value === day.value)
      );
      const newPeriodsPreference = preferredDays.map((day) => {
        return {
          day,
          periods:
            studentPeriods.find(
              (period) => period.favorite_day.value === day.value
            ).favorite_periods || [],
        };
      });
      if (formAction === 'reschedule') {
        const details = selectedMentorship.details;
        formRef.current.setData({
          description: details.status.description,
          time: timeToString(new Date(details.scheduled.start_at)),
        });
        setStartDate(new Date(details.scheduled.start_at));
      } else {
        setStartDate(new Date());
      }
      setPeriodsPreference(newPeriodsPreference);
    }
  }, [selectedMentorship]);

  function timeToString(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${leftPad(hours, 2)}:${leftPad(minutes, 2)}`;
  }

  const dayOptions = [
    {
      value: 'monday',
      label: 'Segunda',
    },
    {
      value: 'tuesday',
      label: 'Terça',
    },
    {
      value: 'wednesday',
      label: 'Quarta',
    },
    {
      value: 'thursday',
      label: 'Quinta',
    },
    {
      value: 'friday',
      label: 'Sexta',
    },
    {
      value: 'saturday',
      label: 'Sábado',
    },
  ];

  const validateTime = (time) => {
    if (time.split(':').length !== 2) {
      return false;
    }
    const [hours, minutes] = time.split(':');
    return (
      Number(hours) >= 0 &&
      Number(hours) <= 23 &&
      Number(minutes) >= 0 &&
      Number(minutes) <= 59
    );
  };

  const validateForm = async () => {
    try {
      const data = formRef?.current?.getData();
      const schema = Yup.object().shape({
        date: Yup.date()
          .required('Este campo é obrigatório')
          .typeError('Este campo é obrigatório'),
        time:
          timeTouched &&
          Yup.string()
            .length(5, 'Horário inválida')
            .required('Este campo é obrigatório')
            .test('invalidTime', 'Horário inválido', (value) => {
              return validateTime(value);
            })
            .test('timeIsPast', 'O horário deve ser no futuro', (value) => {
              const [hours, minutes] = value.split(':');
              const date = data.date;
              if (date instanceof Date && validateTime(value)) {
                date.setHours(hours);
                date.setMinutes(minutes);
                return date > new Date();
              }
              return true;
            }),
      });
      const validatedData = await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      const validatedData = await validateForm(data);
      if (validatedData) {
        const date = validatedData.date;
        const hours = validatedData.time.split(':')[0];
        const minutes = validatedData.time.split(':')[1];
        date.setHours(hours, minutes, 0, 0);
        setRequestInProgress(true);
        let success;
        if (formAction === 'reschedule') {
          success = await sendRescheduleMentorship({
            start_at: date.toISOString(),
            description: validatedData.description,
          });
        } else {
          success = await sendScheduleMentorship({
            start_at: date.toISOString(),
            description: validatedData.description,
          });
        }
        setRequestInProgress(false);
        if (success) {
          setStep(3);
        }
      }
    } catch (err) {}
  };

  return (
    <Fragment>
      <div style={{ padding: '0 3rem' }}>
        <StepIndicator
          count={2}
          currentStep={2}
          onStepChange={async (value) => {
            if (value === 1) {
              setStep(value);
            }
          }}
        />
      </div>
      <Container style={{ padding: '0 3rem' }}>
        <h1>Agende um dia e um horário para realizar a mentoria:</h1>
        {periodsPreference.length > 0 && (
          <Fragment>
            <p>Preferências do estudante:</p>
            <PeriodPreferences>
              {periodsPreference.map((p) => (
                <DayContainer key={p.day.value}>
                  <CalendarIcon />
                  <strong>{p.day.label}</strong>
                  {p.periods.length > 0 && (
                    <PeriodsContainer>
                      {p.periods.map((period) => (
                        <PeriodIcon period={period.value} key={period.value} />
                      ))}
                    </PeriodsContainer>
                  )}
                </DayContainer>
              ))}
            </PeriodPreferences>
            <PeriodsLegend>
              <strong>Legenda: </strong>
              <PeriodIcon period="morning" />
              <span>Manhã</span>
              <PeriodIcon period="afternoon" />
              <span>Tarde</span>
              <PeriodIcon period="night" />
              <span>Noite</span>
            </PeriodsLegend>
          </Fragment>
        )}
        <Form ref={formRef}>
          <DateTimeContainer>
            <DatePickerCustom
              label="Data"
              name={'date'}
              placeholder={'Selecione'}
              date={startDate}
              setDate={setStartDate}
              validateForm={validateForm}
              customInput={
                <MaskedInput
                  value={startDate}
                  placeholder="dd/MM/yyyy"
                  mask="11/11/1111"
                  maskstring="dd/mm/yyyy"
                />
              }
            />
            <TimePicker
              name={'time'}
              label={'Horário'}
              placeholder={'Selecione'}
              validateForm={validateForm}
              onTouched={() => setTimeTouched(true)}
              onBlur={() => setDescriptionAutofocus(true)}
            />
          </DateTimeContainer>
          <Divider />
          <DescriptionContainer>
            <label>Informações para mentoria (Opcional)</label>
            <HtmlEditor
              name={'description'}
              placeholder={
                'Passe para o estudante informações que você ' +
                'considera importante para a realização da mentoria'
              }
              autoFocus={descriptionAutofocus}
            />
          </DescriptionContainer>
          <Footer>
            <BackButton onClick={() => setStep(1)} type="button" />
            <ButtonsDiv>
              <SendButton
                type="button"
                disabled={requestInProgress}
                onClick={handleSubmit}
              >
                {requestInProgress ? 'Enviando...' : 'Enviar agendamento'}
              </SendButton>
              <CancelMentorshipButton
                style={{
                  width: '100%',
                  textAlign: 'right',
                  marginTop: '10px',
                  marginLeft: '0',
                }}
                type="button"
                onClick={openCancelMentorshipModal}
              >
                Cancelar solicitação de mentoria
              </CancelMentorshipButton>
            </ButtonsDiv>
          </Footer>
        </Form>
      </Container>
    </Fragment>
  );
}
