import GenericInternalTab from 'components/GenericInternalTab';
import {
  Container,
  InsideInformations,
  Header,
  Content,
  Box,
  ContainerBottomActions,
  Divider,
} from './styled';

import { useState } from 'react';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import BottomActions from './BottomActions';
import AboutEvent from './Tabs/AboutEvent';
import Programation from './Tabs/Programation';
import Competencies from './Tabs/Competencies';
import AboutEventDay from './Tabs/AboutEventDay';
import TargetAudienceModal from '../TargetAudienceModal';

export default function DetailsModal() {
  const [activeTab, setActiveTab] = useState(0);
  const [targetAudienceModalOpen, setTargetAudienceModalOpen] = useState(false);

  const {
    detailsModalOpen,
    setDetailsModalOpen,
    detailsObject,
  } = useVisualizationContext();

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const handleClickClose = () => {
    setDetailsModalOpen(false);
  };

  const { event_schedule, associated_areas, about_event_day } = detailsObject;

  const openTargetAudienceModal = () => {
    setTargetAudienceModalOpen(true);
  };

  const hasCompetencies = associated_areas?.length > 0;

  let tabs = [];
  let componentTabs = {};

  if (about_event_day) {
    tabs.push('Dia do evento');
    componentTabs[tabs.length - 1] = <AboutEventDay />;
  }

  tabs.push('Sobre o evento');
  componentTabs[tabs.length - 1] = (
    <AboutEvent openTargetAudienceModal={openTargetAudienceModal} />
  );

  if (event_schedule) {
    tabs.push('Programação');
    componentTabs[tabs.length - 1] = <Programation />;
  }

  if (hasCompetencies) {
    tabs.push('Competências');
    componentTabs[tabs.length - 1] = <Competencies />;
  }

  if (!detailsModalOpen) {
    return null;
  }

  const componentActiveTab = componentTabs[activeTab];

  const closeModalTargetAudience = () => {
    setTargetAudienceModalOpen(false);
  };

  return (
    <Container>
      <Box>
        <TargetAudienceModal
          open={targetAudienceModalOpen}
          closeModal={closeModalTargetAudience}
        />
        <Content>
          <Header>
            <CloseButton onClick={handleClickClose} />
          </Header>

          <GenericInternalTab
            activeTab={activeTab}
            tabs={tabs}
            handleTabChange={handleTabChange}
          />

          <InsideInformations>{componentActiveTab}</InsideInformations>
          <Divider />

          <ContainerBottomActions>
            <BottomActions />
          </ContainerBottomActions>
        </Content>
      </Box>
    </Container>
  );
}
