import React, { useState, useEffect } from 'react';

import {
  Container,
  Wrapper,
  SubTitle,
  PreviewButton,
  GridContainerTitle,
  ModelEmail,
  Period,
  PreviewEmail,
} from './styled';

import {
  interviewInvitation,
  interviewReschedule,
  interviewCancellation,
  finishAndFeedbackJob,
  tomorrowNotificationInterview,
  notificationJobs,
  getIesEmailConfig,
  invitationAccess,
  finishTheCurriculum,
  profileChampion,
  internshipMailPreview,
  eventRememberOnline,
  eventRememberPresential,
  eventCertificate,
  eventRemoveStudent,
  eventCanceled,
  indicationStudent
} from '../services/services';

import PreviewModal from './PreviewModal';
import ListEmail from '../Components/ListEmail';
import Tabs from '@material-ui/core/Tabs';

export default function StudentsPage({ props, setNoScroll }) {
  const [html, setHtml] = useState();
  const [typeList, setTypeList] = useState('activationStudent');
  const [previewModalVisibility, setPreviewModalVisibility] = useState(false);
  const [activationEmail, setActivationEmail] = useState();
  const [loading, setLoading] = useState(true);
  const [actIvationStudent, setActiveIvationStudent] = useState(false);

  const setToActivation = () => {
    setTypeList('activationStudent');
  };

  const setToEngagement = () => {
    setTypeList('engagement');
  };

  const setToRecruitment = () => {
    setTypeList('recruitment');
  };

  const setToInternship = () => {
    setTypeList('internship');
  };

  const setToEvents = () => {
    setTypeList('events');
  };

  const fetchEmail = async (fetchEmailFunction) => {
    const changeTypeModal = await fetchEmailFunction();
    setHtml(changeTypeModal);
    setPreviewModalVisibility(true);
    setNoScroll(true);
  };

  const getActivationSendingEmail = async () => {
    const listActivation = await getIesEmailConfig();
    if (
      listActivation.enabled_email_invitation_to_access_inactive_students !==
        null &&
      listActivation.platform_launched === true
    ) {
      setActiveIvationStudent(true);
    } else {
      setActiveIvationStudent(false);
    }
    setActivationEmail(listActivation);
    setLoading(false);
  };

  useEffect(() => {
    getActivationSendingEmail();
  }, []);

  useEffect(() => {
    getActivationSendingEmail();
  }, [typeList]);

  const activationStudentList = [
    {
      typeEmail: 'Convite de acesso',
      period: 'A cada 30 dias',
      fetchPreview: () => fetchEmail(invitationAccess),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_invitation_to_access_inactive_students
          ? activationEmail.enabled_email_invitation_to_access_inactive_students
          : false,
      control: 'enabled_email_invitation_to_access_inactive_students',
      active: actIvationStudent,
    },
    {
      typeEmail:
        'Novas vagas alinhadas ao perfil do estudante e vagas finalizadas',
      period: 'Toda segunda-feira',
      fetchPreview: () => fetchEmail(notificationJobs),
      enabled:
        activationEmail && activationEmail.enabled_email_new_jobs_on_profile
          ? activationEmail.enabled_email_new_jobs_on_profile
          : false,
      control: 'enabled_email_new_jobs_on_profile',
      active: true,
    },
  ];

  const engagementList = [
    {
      typeEmail: 'Convite para finalizar o currículo',
      period: 'A cada 15 dias',
      fetchPreview: () => fetchEmail(finishTheCurriculum),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_invitation_finalize_curriculum
          ? activationEmail.enabled_email_invitation_finalize_curriculum
          : false,
      control: 'enabled_email_invitation_finalize_curriculum',
      active: true,
    },
    {
      typeEmail: 'Estímulo para se tornar um perfil campeão',
      period: 'A cada 60 dias sem atualizar currículo',
      fetchPreview: () => fetchEmail(profileChampion),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_invitation_to_be_a_champion_profile
          ? activationEmail.enabled_email_invitation_to_be_a_champion_profile
          : false,
      control: 'enabled_email_invitation_to_be_a_champion_profile',
      active: true,
    },
  ];

  const recruitmentList = [
    {
      typeEmail: 'Entrevista agendada',
      period: 'Quando existem atualizações',
      fetchPreview: () => fetchEmail(interviewInvitation),
      enabled:
        activationEmail && activationEmail.enabled_email_scheduled_interview
          ? activationEmail.enabled_email_scheduled_interview
          : false,
      control: 'enabled_email_scheduled_interview',
      active: true,
    },
    {
      typeEmail: 'Entrevista reagendada',
      period: 'Quando existem atualizações',
      fetchPreview: () => fetchEmail(interviewReschedule),
      enabled:
        activationEmail && activationEmail.enabled_email_rescheduled_interview
          ? activationEmail.enabled_email_rescheduled_interview
          : false,
      control: 'enabled_email_rescheduled_interview',
      active: true,
    },
    {
      typeEmail: 'Entrevista cancelada',
      period: 'Quando existem atualizações',
      fetchPreview: () => fetchEmail(interviewCancellation),
      enabled:
        activationEmail && activationEmail.enabled_email_canceled_interview
          ? activationEmail.enabled_email_canceled_interview
          : false,
      control: 'enabled_email_canceled_interview',
      active: true,
    },
    {
      typeEmail: 'Lembrete de entrevista agendada',
      period: 'Dia anterior da entrevista',
      fetchPreview: () => fetchEmail(tomorrowNotificationInterview),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_scheduled_interview_notification
          ? activationEmail.enabled_email_scheduled_interview_notification
          : false,
      control: 'enabled_email_scheduled_interview_notification',
      active: true,
    },
    {
      typeEmail: 'Informativo de vaga finalizada',
      period: 'Quando existem atualizações',
      fetchPreview: () => fetchEmail(finishAndFeedbackJob),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_job_finished_notification
          ? activationEmail.enabled_email_job_finished_notification
          : false,
      control: 'enabled_email_job_finished_notification',
      active: true,
    },
    {
      typeEmail: 'Indicação a uma vaga',
      period: 'De acordo com as atualizações',
      fetchPreview: () => fetchEmail(indicationStudent),
      enabled:
        activationEmail &&
        activationEmail.enabled_indication_email        
          ? activationEmail.enabled_indication_email
          : false,
      control: 'enabled_indication_email',
      active: true,
    },
  ];

  const internshipList = [
    {
      typeEmail: 'Dados aprovados',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview('enabled_internship_approved_data')
        ),
      enabled:
        activationEmail && activationEmail.enabled_internship_approved_data
          ? activationEmail.enabled_internship_approved_data
          : false,
      control: 'enabled_internship_approved_data',
      active: true,
    },
    {
      typeEmail: 'Dados com pendência',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview('enabled_internship_pending_data')
        ),
      enabled:
        activationEmail && activationEmail.enabled_internship_pending_data
          ? activationEmail.enabled_internship_pending_data
          : false,
      control: 'enabled_internship_pending_data',
      active: true,
    },
    {
      typeEmail: 'Termo de compromisso pendente',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview('enabled_internship_pending_agreement_document')
        ),
      enabled:
        activationEmail &&
        activationEmail.enabled_internship_pending_agreement_document
          ? activationEmail.enabled_internship_pending_agreement_document
          : false,
      control: 'enabled_internship_pending_agreement_document',
      active: true,
    },
    {
      typeEmail: 'Documento aprovado',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview('enabled_internship_approved_document')
        ),
      enabled:
        activationEmail && activationEmail.enabled_internship_approved_document
          ? activationEmail.enabled_internship_approved_document
          : false,
      control: 'enabled_internship_approved_document',
      active: true,
    },
    {
      typeEmail: 'Documento com pendência',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview('enabled_internship_pending_document')
        ),
      enabled:
        activationEmail && activationEmail.enabled_internship_pending_document
          ? activationEmail.enabled_internship_pending_document
          : false,
      control: 'enabled_internship_pending_document',
      active: true,
    },
    {
      typeEmail: 'Documento de finalização',
      period: 'Quando existem atualizações',
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview(
            'enabled_internship_pending_rescission_document'
          )
        ),
      enabled:
        activationEmail &&
        activationEmail.enabled_internship_pending_rescission_document
          ? activationEmail.enabled_internship_pending_rescission_document
          : false,
      control: 'enabled_internship_pending_rescission_document',
      active: true,
    },
    {
      typeEmail: 'Expiração de estágio',
      period: '7 dias antes da expiração',
      enabled:
        activationEmail &&
        activationEmail.enabled_internship_expiration_alert
          ? activationEmail.enabled_internship_expiration_alert
          : false,
      control: 'enabled_internship_expiration_alert',
      active: true,
      fetchPreview: () =>
        fetchEmail(() =>
          internshipMailPreview(
            'enabled_internship_expiration_alert'
          )
        ),
    },
  ];
  const academicEventList = [
    {
      typeEmail: 'Lembrete: data se aproximando - evento presencial',
      period: 'Dia anterior ao evento',
      fetchPreview: () => fetchEmail(eventRememberPresential),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_academic_event_remember_presential
          ? activationEmail.enabled_email_academic_event_remember_presential
          : false,
      control: 'enabled_email_academic_event_remember_presential',
      active: true,
    },
    {
      typeEmail: 'Lembrete: data se aproximando - evento online',
      period: 'Dia anterior ao evento',
      fetchPreview: () => fetchEmail(eventRememberOnline),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_academic_event_remember_online
          ? activationEmail.enabled_email_academic_event_remember_online
          : false,
      control: 'enabled_email_academic_event_remember_online',
      active: true,
    },
    {
      typeEmail: 'E-mail de certificado',
      period: 'Ao finalizar o evento',
      fetchPreview: () => fetchEmail(eventCertificate),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_academic_event_certification
          ? activationEmail.enabled_email_academic_event_certification
          : false,
      control: 'enabled_email_academic_event_certification',
      active: true,
    },
    {
      typeEmail: 'Estudante retirado da lista de participação',
      period: 'De acordo com as atualizações',
      fetchPreview: () => fetchEmail(eventRemoveStudent),
      enabled:
        activationEmail && activationEmail.enabled_email_academic_remove_student
          ? activationEmail.enabled_email_academic_remove_student
          : false,
      control: 'enabled_email_academic_remove_student',
      active: true,
    },
    {
      typeEmail: 'Evento cancelado',
      period: 'De acordo com as atualizações',
      fetchPreview: () => fetchEmail(eventCanceled),
      enabled:
        activationEmail &&
        activationEmail.enabled_email_invitation_canceled_event
          ? activationEmail.enabled_email_invitation_canceled_event
          : false,
      control: 'enabled_email_invitation_canceled_event',
      active: true,
    },
  ];
  return (
    <div>
      {previewModalVisibility && (
        <PreviewModal
          html={html}
          closeModal={() => {
            setPreviewModalVisibility(false);
            setNoScroll(false);
          }}
        />
      )}
      <Container>
        <Wrapper>
          <SubTitle>
            <Tabs
              scrollButtons="on"
              scrollable
            >
              <PreviewButton
                onClick={setToActivation}xd
                active={typeList === 'activationStudent'}
              >
                Ativação de estudantes
              </PreviewButton>
              <PreviewButton
                onClick={setToEngagement}
                active={typeList === 'engagement'}
              >
                Engajamento
              </PreviewButton>
              <PreviewButton
                onClick={setToRecruitment}
                active={typeList === 'recruitment'}
              >
                Recrutamento e seleção
              </PreviewButton>
              <PreviewButton
                onClick={setToInternship}
                active={typeList === 'internship'}
              >
                Estágio
              </PreviewButton>

              <PreviewButton
                onClick={setToEvents}
                active={typeList === 'events'}
              >
                Feiras e Eventos
              </PreviewButton>
            </Tabs>
          </SubTitle>
        </Wrapper>
        <Wrapper>
          <GridContainerTitle>
            <ModelEmail> Modelo de e-mail </ModelEmail>
            <Period> Periodicidade </Period>
            <PreviewEmail> Ações </PreviewEmail>
          </GridContainerTitle>

          {!loading &&
            typeList === 'activationStudent' &&
            activationStudentList.map((item) => (
              <ListEmail
                typeList={typeList}
                item={item}
                getActivationSendingEmail={getActivationSendingEmail}
              />
            ))}
          {!loading &&
            typeList === 'engagement' &&
            engagementList.map((item) => (
              <ListEmail
                typeList={typeList}
                item={item}
                getActivationSendingEmail={getActivationSendingEmail}
              />
            ))}
          {!loading &&
            typeList === 'recruitment' &&
            recruitmentList &&
            recruitmentList.map((item) => (
              <ListEmail
                typeList={typeList}
                item={item}
                getActivationSendingEmail={getActivationSendingEmail}
              />
            ))}
          {!loading &&
            typeList === 'internship' &&
            internshipList &&
            internshipList.map((item) => (
              <ListEmail
                typeList={typeList}
                item={item}
                getActivationSendingEmail={getActivationSendingEmail}
              />
            ))}
          {!loading &&
            typeList === 'events' &&
            academicEventList &&
            academicEventList.map((item) => (
              <ListEmail
                typeList={typeList}
                item={item}
                getActivationSendingEmail={getActivationSendingEmail}
              />
            ))}
        </Wrapper>
      </Container>
    </div>
  );
}
