import styled from 'styled-components';
import nextIcon from 'assets/mentorship/next.svg';
import previousIcon from 'assets/mentorship/previous.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
    margin: 0 0 8px 0;
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.isNext ? `url(${nextIcon})` : `url(${previousIcon})`};
  background-repeat: no-repeat;
  background-position: center;
  height: 32px;
  width: 22px;
  background-size: contain;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
