import styled from 'styled-components';

const defaultColor = '#00929120';

export const FlexContainer = styled.div`
  display: flex;
`

export const TabButton = styled.button`
  padding: 18px;
  border: 1px solid
    ${({ active, universityColor }) =>
      active ? (universityColor ? universityColor : '#009291') : '#E5E5E5'};
  background-color: ${({ active, universityColor }) =>
    active
      ? universityColor
        ? universityColor + '20'
        : defaultColor
      : '#fff'};
  color: ${({ active, universityColor }) =>
    active ? (universityColor ? universityColor : '#009291') : '#606062'};
  font-weight: ${(props) => (props.active ? '700' : '400')};
  font-size: 15px;
  cursor: pointer;
  
  & > div {
    display: flex;
  }

  transition: 0.3s;

  :first-child {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  :last-child {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  @media (min-width: 400px) and (max-width: 470px) {
    font-size: 14px;
    padding: 14px;

    transition: 0.3s;
  }

  @media (max-width: 401px) {
    font-size: 12px;
    padding: 11px;

    transition: 0.3s;
  }
`;
