import styled from 'styled-components';
import editIcon from 'assets/mentorship/edit.svg';

export const Dropdown = styled.div`
  margin-top: 0.5rem;
  height: 38px;
  border-radius: 4px;
  border: 0.64px solid
    ${(props) =>
      props.disabled ? '#cdcdcd' : props.error ? '#DA0505' : '#c4c4c4'};
  background-color: ${(props) => (props.disabled ? '#fafafa' : '#fff')};
`;

export const DropdownHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100%;
  padding: 0 0.625rem;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  background-image: url(${(props) => !props.disabled && editIcon});
  background-repeat: no-repeat;
  background-position: 96%;

  p {
    font-weight: 400;
    color: #4b4b4d;
    font-size: 15px;
    margin: 0;
  }

  position: relative;
`;

export const List = styled.div`
  margin: 12px 0 0 0;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 #00000026;
`;

export const ListItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: auto auto;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  position: relative;
  z-index: 3;

  &:hover {
    background-color: #009291;

    span {
      color: #fff;
    }
  }

  span {
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.125rem;
    font-family: 'Roboto', sans-serif;
    color: ${(props) => (props.selected ? '#009291' : '#4B4B4D')};
  }
`;
