import React from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import {
  Grid,
  ListItem,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ThumbUp from '@material-ui/icons/ThumbUp';
import LocationOn from '@material-ui/icons/LocationOn';
import PdfIcon from 'assets/img/pdfFile.png';
import DefaultAvatar from 'assets/img/placeholder.jpg';
import { AvatarItem, CurriculumLiked, LinkItems } from './styled';

export const TalentsListItemCompany = ({
  student,
  classes,
  openLetter,
  company,
  liked,
  handleCheck,
  selectedStudents,
  openCurriculum,
}) => {
  const {
    courses,
    email,
    name,
    mobile_phone: mobilePhone,
    professional_status: professionalStatus,
    professional_working_in_formation_area: workingInFormationArea,
    city,
    state,
    unity,
    university,
  } = student;

  function workingInFormationAreaResult() {
    if (professionalStatus === 'desempregado(a)') return null;
    if (workingInFormationArea === true) return '- Na Área de Formação';
    if (workingInFormationArea === false) return '- Fora da Área de Formação';
    return null;
  }

  const isWorkingInArea = workingInFormationAreaResult();

  return (
    <ListItem>
      <Grid container>
        <FormControlLabel
          tabIndex={-1}
          control={
            <Checkbox
              classes={{
                checked: classes.checkBox,
              }}
              color="default"
              checked={selectedStudents.includes(student)}
              onChange={(e) => {
                handleCheck(student, e.target.checked);
              }}
            />
          }
        />
        {!company && (
          <AvatarItem item md={2} xs={12} xl={12}>
            <img
              src={student.avatar ? student.avatar : DefaultAvatar}
              alt="Avatar"
            />
          </AvatarItem>
        )}
        <Grid item md={8} className={classes.talentsGrid}>
          <h5>{name}</h5>

          <p>
            {professionalStatus} {isWorkingInArea}
          </p>
          {!!courses &&
            courses.map((course, index) => {
              return (
                <p key={index} style={{ marginBottom: 0 }}>
                  {course.name} - {course.semester}
                  &ordm; período
                </p>
              );
            })}
          <p>
            {email && (
              <span style={{ marginRight: '1rem' }}>
                <MailOutlineIcon className={classes.talentsIcon} /> {email}
              </span>
            )}
            {mobilePhone && (
              <span style={{ display: 'inline-block' }}>
                <PhoneIphoneIcon className={classes.talentsIcon} />{' '}
                {mobilePhone}
              </span>
            )}
          </p>
          <p>
            <span style={{ marginRight: '1rem' }}>
              {((city && state) || unity) && (
                <LocationOn className={classes.talentsIcon} />
              )}
              {city && state && (
                <span>
                  {city}/{state} {(unity || university) && '|'}
                </span>
              )}
              {unity && !company && ` Unidade: ${unity}`}
              {university && company && ` Universidade: ${university}`}
            </span>
            <span style={{ marginRight: '1rem' }} />
          </p>
          {!company && (
            <LinkItems>
              {student.has_curriculum && (
                <div
                  className="linkItem"
                  onClick={() => openCurriculum(student.id)}
                >
                  <img src={PdfIcon} alt="Visualizar Currículo" />
                  Visualizar Currículo
                </div>
              )}
              {((company &&
                student.has_cover_letter &&
                student.curriculums &&
                liked &&
                liked[student.id]) ||
                student.has_cover_letter) && (
                <div className="linkItem" onClick={() => openLetter(student)}>
                  <img src={PdfIcon} alt="Visualizar Carta" />
                  Visualizar Carta de Apresentação
                </div>
              )}
            </LinkItems>
          )}
        </Grid>
        {company && (
          <Grid item md={3} className={classes.talentsListAction}>
            {student.has_curriculum && (
              <Button
                style={{ color: '#009291' }}
                className={classes.talentsButtonConnect}
                onClick={() => openCurriculum(student.id)}
              >
                Visualizar Currículo
              </Button>
            )}

            {((company &&
              student.has_cover_letter &&
              student.curriculums &&
              liked &&
              liked[student.id]) ||
              student.has_cover_letter) && (
              <Button
                style={{ color: '#009291' }}
                className={classes.talentsButtonConnect}
                onClick={() => openLetter(student)}
              >
                Carta de Apresentação
              </Button>
            )}
            {liked && liked[student.id] && (
              <CurriculumLiked>
                <ThumbUp />
                <span>Currículo Curtido</span>
              </CurriculumLiked>
            )}
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
};

TalentsListItemCompany.defaultProps = {
  liked: false,
  company: false,
};

TalentsListItemCompany.propTypes = {
  student: shape({
    course: arrayOf({
      name: string,
      semester: number,
    }),
  }).isRequired,
  classes: shape({}).isRequired,
  openCurriculum: func.isRequired,
  openLetter: func.isRequired,
  liked: bool,
  company: bool,
};
