import styled from 'styled-components';
import leftArrow from 'assets/Oportutinites/Setaesquerda.svg';
//title, go back and send mentorship
export const Container = styled.div`
  width: 720px;
  max-width: 100%;

  h1 {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    line-height: 1.4375rem;
    font-size: 1.375rem;
    font-family: Roboto, sans-serif;
    margin-top: 2.5rem;
    margin-bottom: 0;
    font-weight: bold;
    position: revert;
    padding: 0 2.5rem;
    @media (max-width: 600px) {
      font-size: 18px;
      text-align: center;
      padding: 0;
    }
  }

  h2 {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    line-height: 1.0625rem;
    font-size: 0.9375rem;
    font-family: Roboto, sans-serif;
    margin-top: 1.5rem;
    margin-bottom: 5px;
    font-weight: 700;
    position: revert;
    padding: 0 2.5rem;
    @media (max-width: 600px) {
      font-size: 14px;
      padding: 0 2.5rem;
    }
  }
`;

export const ButtonSend = styled.button`
  color: #fff;
  position: absolute;
  right: 2.5rem;
  bottom: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-weight: 700;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 2.5rem;
  bottom: 2.5rem;
  border: none;
  padding: 0;
  cursor: pointer;
  background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'}
    url(${leftArrow}) no-repeat center;
`;
