import React, { Fragment, useEffect, useContext } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import MentorshipCard from '../MentorshipCard';
import { LoadingCard, TabContainer, TabContent } from '../../styles';
import ModalAdjustMentorshipStatus from '../ModalAdjustMentorshipStatus';
import ModalCancelMentorship from '../ModalCancelMentorship';
import ModalEvaluateMentorship from '../ModalEvaluateMentorship';
import BaseLayoutContext from 'contexts/base-layout';
import GenericMessage from 'components/GenericMessage';

export default function HistoricMentorshipTab() {
  const { mentorshipHistoric, getMentorshipHistoric } = useMentorship();
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    getMentorshipHistoric();
  }, []);

  return (
    <TabContainer>
      <p>
        Confira todas as mentorias que já foram expiradas, realizadas ou
        canceladas.
      </p>
      {mentorshipHistoric === null ? (
        <LoadingCard>Carregando...</LoadingCard>
      ) : (
        <TabContent universityColor={universityColor}>
          {mentorshipHistoric.length === 0 ? (
            <GenericMessage
              title="Não identificamos nenhuma Mentoria no histórico"
              style={{ marginTop: '0px', gap: '0px' }}
            />
          ) : (
            <Fragment>
              <h1 style={{ textAlign: 'center' }}>Histórico de Mentorias</h1>
              <div>
                {mentorshipHistoric.map((mentorship) => {
                  return (
                    <MentorshipCard
                      key={mentorship.id}
                      mentorship={mentorship}
                    />
                  );
                })}
              </div>
            </Fragment>
          )}
        </TabContent>
      )}
      <ModalAdjustMentorshipStatus />
      <ModalCancelMentorship />
      <ModalEvaluateMentorship />
    </TabContainer>
  );
}
