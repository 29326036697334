import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  width: 720px;
  height: auto;
  padding: 0 2rem;
  background-color: #fff;
  border-radius: 10px;
`;

export const Content = styled.div``;

export const AbandonedCourses = styled.div`
  position: absolute;
  background: #fffded;
  border-radius: 4px;
  margin-top: 3px;
  color: #e9b600;
  margin-left: 90px;
  width: 215px;
  font-size: 12px;
  & img {
    margin-top: 6px;
    width: 31px;
    height: 31px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 5px;
  background-color: #cdcdcd;
`;
