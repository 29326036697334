import React, { Fragment, useState } from 'react';
import { object, func, string, array, bool, number } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import pdf from '../../assets/img/pdfFile.png';
import IconButton from '@material-ui/core/IconButton';

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Switch,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  CircularProgress,
  Checkbox,
} from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp';
import VisibilitySharpIcon from '@material-ui/icons/VisibilitySharp';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import {
  LocalLibraryOutlined,
  BusinessCenter,
  ExpandMore,
  LocationOn,
  Person,
} from '@material-ui/icons/';
import Button from 'components/CustomButtons/Button';
import { Button as ButtonMaterial } from '@material-ui/core';
import IcoEditWhite from 'assets/img/icons/icoEditWhite.svg';

import CompanyPreRegisterForm from 'views/StaffPages/CompanyPreRegister/CompanyPreRegisterForm';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import Grid from '@material-ui/core/Grid';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InvitesAddress from './InvitesAddress';
import StatusInformation from './StatusInformation';

import { HeadingSpan } from './Styled';
import { getCompanyInvitesFilesUtils } from '../../services/university/companyInvite.jsx';

function InvitesAccordion(props) {
  const {
    classes,
    data,
    dataReceived,
    handleCancelInvite,
    handleInviteReceivedAnswer,
    openDnaDialog,
    closeModal,
    openSnackbar,
    openModalWithContent,
    handleIsLoadingState,
    handleUpdateState,
    setExpansivePanel,
    expandedState,
    sendInvite,
    loading,
    limit,
    setLimit,
    received,
    handleConnect,
    connecting,
    role,
    updateDataReceived,
    showModalEditCompanyInternship,
    setModalEditCompanyInternship,
    inviteModalEdit,
    setModaInviteEdit,
    controlLoading,
    currentTab,
    ids,
    handleIds,
    checkedAll,
    changeConnections,
    disabledButtons,
  } = props;

  const viewPdf = async (invite, file) => {
    let params = `action=download&document=${file}&companyinviteid=${invite.id}`;
    const response = await getCompanyInvitesFilesUtils(params);
    let base64 = response.value;
    let novaJanela = window.open(
      '',
      'PDF',
      'dependent=yes,locationbar=no,scrollbars=no,menubar=no,resizable,screenX=50,screenY=50,width=850,height=800'
    );
    novaJanela.document.write(
      '<html><title>' +
        response.file_name +
        '</title><body><object width=100% height=100% type="application/pdf" data="data:application/pdf;base64,' +
        base64 +
        '"><embed type="application/pdf" src="data:application/pdf;base64,' +
        base64 +
        '"></embed></object></body></html>'
    );
    novaJanela.window.focus();
  };

  const downloadPdf = async (invite, file) => {
    let params = `action=download&document=${file}&companyinviteid=${invite.id}`;
    const response = await getCompanyInvitesFilesUtils(params);
    const linkSource = 'data:application/pdf;base64,' + response.value;
    const downloadLink = document.createElement('a');
    const fileName = response.file_name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const openModal = (invite) => {
    const inviteTemp = invite;
    setModaInviteEdit && setModaInviteEdit(inviteTemp);
    setModalEditCompanyInternship && setModalEditCompanyInternship(true);
  };

  const renderizeName = (invite) => {
    const { companyPresentation, name, initials } = invite;

    if (companyPresentation || initials) {
      return <HeadingSpan> {companyPresentation || initials} </HeadingSpan>;
    }
    if (name) {
      return <HeadingSpan> {name} </HeadingSpan>;
    }
    return <HeadingSpan> Não informado </HeadingSpan>;
  };

  return (
    <Fragment>
      <ExpansionPanel expanded={false} elevation={0}>
        <ExpansionPanelSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{
            content: classes.contentExpansionPanel,
          }}
        >
          {role === 'company' && (
            <span className={classes.headingTitle}>Nome</span>
          )}
          {setLimit && role === 'company' && (
            <FormControl
              className={classes.selectFormControl}
              style={{ marginLeft: 'auto' }}
            >
              <InputLabel
                htmlFor="page-select"
                className={classes.selectLabel}
                classes={{
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                }}
              >
                Itens por página
              </InputLabel>

              <Select
                value={limit}
                fullWidth
                onChange={(e) => setLimit(e.target.value)}
                className={classes.selectInput}
                inputProps={{
                  name: 'pageSelect',
                  id: 'page-select',
                }}
              >
                {[20, 50, 100].map((value) => (
                  <MenuItem value={value}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {role === 'company' && (
            <span className={classes.headingTitle}>Status</span>
          )}

          {role === 'university' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {data && data.length > 0 && (
                <div>
                  <Checkbox
                    style={{
                      color: checkedAll ? '#2f9190' : '',
                    }}
                    checked={checkedAll}
                    onChange={(e) => {
                      handleIds &&
                        handleIds(
                          null,
                          {
                            all: !checkedAll,
                            checked: currentTab === 0 ? 'sent' : 'received',
                          },
                          true
                        );
                    }}
                  />
                  {`Selecionar todos ${checkedAll ? `(${ids.length})` : ''}`}
                </div>
              )}

              {data && data.length > 0 && (
                <div
                  style={{
                    gap: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    maxHeight: '55px',
                  }}
                >
                  <ButtonMaterial
                    disabled={currentTab === 0 ? disabledButtons.disabledButtonDesconnectSent: disabledButtons.disabledButtonDesconnectReceived}
                    className={classes.buttonStatusInformation}
                    style={{
                      boxShadow: 'none',
                    }}
                    onClick={() =>
                      changeConnections(
                        {
                          lote: true,
                          origin: currentTab === 0 ? 'sent' : 'received',
                          currentStatus: 'accepted',
                          newStatus: 'rejected',
                        },
                        null
                      )
                    }
                  >
                    {loading && (
                      <Fragment>
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      </Fragment>
                    )}
                    {!loading && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignContent: 'center',
                          alignItems: 'center',
                          textTransform: 'none',
                        }}
                      >
                        <p style={{ margin: '0 0 0px' }}>Desconectar</p>
                      </div>
                    )}
                  </ButtonMaterial>
                  <ButtonMaterial
                    className={classes.buttonStatusInformation}
                    disabled={currentTab === 0 ? disabledButtons.disabledButtonConectSent: disabledButtons.disabledButtonConectReceived}
                    style={{
                      boxShadow: 'none',
                    }}
                    onClick={() =>
                      changeConnections(
                        {
                          lote: true,
                          origin: currentTab === 0 ? 'sent' : 'received',
                          currentStatus: 'rejected',
                          newStatus: 'accepted',
                        },
                        null
                      )
                    }
                  >
                    {loading && (
                      <Fragment>
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      </Fragment>
                    )}
                    {!loading && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignContent: 'center',
                          alignItems: 'center',
                          textTransform: 'none',
                        }}
                      >
                        <p style={{ margin: '0 0 0px' }}>Conectar</p>
                      </div>
                    )}
                  </ButtonMaterial>
                </div>
              )}
            </div>
          )}
        </ExpansionPanelSummary>
      </ExpansionPanel>

      {loading ? (
        <div className={classes.flexCenterLoading}>
          <CircularProgress className={classes.loading} />
        </div>
      ) : (
        // Company
        !!data &&
        data.map((invite) =>
          role === 'company' ? (
            <Grid container>
              <Grid item className={classes.companyInstitution} xs={12} md={12}>
                <div className={classes.itemInstitution}>
                  <div style={{ display: 'flex', margin: '0px 0px 8px' }}>
                    <AccountBalanceIcon />
                    <span
                      className={classes.itemInstitutionName}
                      style={{ textTransform: 'uppercase' }}
                    >
                      {renderizeName(invite)}
                    </span>
                  </div>
                  <div className={classes.itemInstitutionDetails}>
                    <LocationOn className={classes.mediumIcon} />
                    <span
                      className={classes.heading}
                    >{`Unidades: ${new Intl.NumberFormat('pt-BR').format(
                      invite.unities
                    )}`}</span>{' '}
                  </div>
                </div>
                <div>
                  <StatusInformation
                    invite={invite}
                    classes={classes}
                    sendInvite={sendInvite}
                    loading={loading}
                    received={received}
                    handleIsLoadingState={handleIsLoadingState}
                    role={role}
                    currentTab={currentTab}
                    ids={ids}
                    changeConnections={changeConnections}
                  />

                  {handleConnect && (
                    <Button
                      round
                      onClick={(event) => {
                        event.stopPropagation();
                        handleConnect(invite.id);
                      }}
                      className={classes.transparentButton}
                      style={{ marginTop: '1.5rem' }}
                      disabled={connecting}
                    >
                      Conectar
                    </Button>
                  )}

                  {handleInviteReceivedAnswer && invite.isAnswerable && (
                    <FormControlLabel
                      style={{ maxWidth: '8rem', marginTop: '18px' }}
                      checked={invite.isAccepted}
                      onChange={handleInviteReceivedAnswer}
                      className={classes.editButton}
                      value="top"
                      control={
                        <Switch
                          classes={{
                            checked: classes.switchColor,
                            bar: classes.track,
                          }}
                          id={String(invite.id)}
                        />
                      }
                      label={invite.isAccepted ? 'Desconectar' : 'Conectar'}
                      labelPlacement="end"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          ) : (
            //University
            <ExpansionPanel
              key={invite.id}
              expanded={expandedState === `panel${invite.id}`}
              elevation={0}
              classes={{
                expanded: classes.expandedItem,
              }}
            >
              <ExpansionPanelSummary
                IconButtonProps={{
                  onClick: () => setExpansivePanel(`panel${invite.id}`),
                }}
                aria-controls={`panel${invite.id}bh-content`}
                id={`panel${invite.id}bh-header`}
                classes={{ content: classes.contentExpansionPanel }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    gap: '70px',
                    justifyContent: 'inherit',
                    width: '100%',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '0 0 auto',
                      }}
                    >
                      <div
                        style={{
                          marginRight: '10px',
                          fill: 'green',
                          color: 'green',
                        }}
                      >
                        <Checkbox
                          style={{
                            color:
                              ids && ids.includes(invite.id) ? '#2f9190' : '',
                          }}
                          id={invite.id}
                          checked={ids && ids.includes(invite.id)}
                          onChange={(e) => {
                            handleIds &&
                              handleIds(
                                invite.id,
                                {
                                  checked:
                                    currentTab === 0 ? 'sent' : 'received',
                                },
                                false
                              );
                          }}
                        />
                      </div>
                      <div className={classes.availableCardBody}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              fontWeight: 400,
                              color: '#4b4b4d',
                            }}
                          >
                            {renderizeName(invite)}
                          </span>
                          <IconButton
                            onClick={(e) => {
                              setExpansivePanel(`panel${invite.id}`);
                            }}
                            size="small"
                            aria-controls={`panel${invite.id}bh-content`}
                            id={`panel${invite.id}bh-header`}
                            classes={{ content: classes.contentExpansionPanel }}
                          >
                            <ExpandMore
                              style={{
                                transform:
                                  expandedState === `panel${invite.id}`
                                    ? 'rotate(180deg)'
                                    : '',
                                backgroundColor: '#F5F5F5',
                                borderRadius: '200px',
                                color: '#606062',
                              }}
                            />
                          </IconButton>
                        </div>

                        {!(expandedState === `panel${invite.id}`) && (
                          <div>
                            <small
                              className={classes.flexAlignCenter}
                              style={{ marginTop: 10 }}
                            >
                              <div>
                                <div className={classes.containerInfos}>
                                  {(invite.address.city || invite.address.state) && (
                                    <div>
                                      <LocationOn
                                        className={classes.smallIcon}
                                      />
                                      {invite.address.city !== '' ? invite.address.city : ''}
                                      {invite.address.city !== '' && invite.address.state !== '' ? ' - ': ''}
                                      {invite.address.state !== '' ? `${invite.address.state}`: ''}
                                    </div>
                                  )}
                                  <div style={{ marginLeft: 10 }}>
                                    <BusinessCenter
                                      className={classes.smallIcon}
                                    />
                                    {`Vagas Publicadas: ${new Intl.NumberFormat(
                                      'pt-BR'
                                    ).format(invite.companyVacancies)}`}
                                  </div>
                                </div>
                              </div>
                              <div>
                                {received == true &&
                                  !invite.isRejected &&
                                  !invite.isCanceled && (
                                    <div
                                      className={classes.internshipInformation}
                                    >
                                      <InsertDriveFileSharpIcon
                                        className={classes.smallIcon}
                                      />
                                      {invite.agreementDocumentOneFile.label ||
                                      invite.agreementDocumentTwoFile.label ? (
                                        <div className={classes.miniSelection}>
                                          <div className={classes.miniInfos}>
                                            <p>Convênio: </p>
                                            <div>
                                              {invite.mandatory_internship ==
                                                true && (
                                                <span>
                                                  {' '}
                                                  Estagio Obrigatório
                                                </span>
                                              )}
                                            </div>
                                            {invite.mandatory_internship ==
                                              true &&
                                            invite.non_mandatory_internship ==
                                              true
                                              ? '|'
                                              : ''}
                                            <div>
                                              {invite.non_mandatory_internship ==
                                                true && (
                                                <span>
                                                  {' '}
                                                  Estagio não Obrigatório
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() => openModal(invite)}
                                        >
                                          <span>
                                            {' '}
                                            Adicionar informações de estágio{' '}
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </small>
                            {invite.responsible_user && (
                              <small className={classes.flelignCenter}>
                                <Person className={classes.smallIcon} />
                                {invite.responsible_user}
                              </small>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!(expandedState === `panel${invite.id}`) && (
                    <div>
                      <StatusInformation
                        invite={invite}
                        classes={classes}
                        sendInvite={sendInvite}
                        loading={loading}
                        received={received}
                        handleIsLoadingState={handleIsLoadingState}
                        role={role}
                        currentTab={currentTab}
                        ids={ids}
                        changeConnections={changeConnections}
                      />
                    </div>
                  )}

                  {handleConnect && (
                    <Button
                      round
                      onClick={(event) => {
                        event.stopPropagation();
                        handleConnect(invite.id);
                      }}
                      className={classes.transparentButton}
                      disabled={connecting}
                    >
                      Conectar
                    </Button>
                  )}
                </div>
              </ExpansionPanelSummary>

              <div
                style={{
                  right: '450px',
                }}
              >
                <ExpansionPanelDetails
                  classes={{ root: classes.expansionDetailPanel }}
                >
                  <div
                    style={{
                      marginLeft: '60px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        {invite.organizationNumber && (
                          <span className={classes.cnpj}>
                            CNPJ: {invite.organizationNumber}
                          </span>
                        )}
                        {invite.responsible_user && (
                          <div style={{ marginTop: 4 }}>
                            <Person className={classes.smallIcon} />
                            <b>Usuário Responsável:</b>{' '}
                            {invite.responsible_user}
                          </div>
                        )}
                        <br />
                        <div>
                          {invite.address.state && (
                            <InvitesAddress
                              address={invite.address}
                              vacancies={invite.companyVacancies}
                              styleClass={classes.inviteInfoContainer}
                            />
                          )}
                        </div>
                      </div>

                      {expandedState === `panel${invite.id}` && (
                        <div>
                          <StatusInformation
                            invite={invite}
                            classes={classes}
                            sendInvite={sendInvite}
                            loading={loading}
                            received={received}
                            handleIsLoadingState={handleIsLoadingState}
                            role={role}
                            currentTab={currentTab}
                            ids={ids}
                            changeConnections={changeConnections}
                          />
                        </div>
                      )}
                    </div>
                    {(invite.staff.name || invite.staff.email) && (
                      <Fragment>
                        <Divider className={classes.divider} />
                        <div className={classes.inviteInfoContainer}>
                          <MailOutlineIcon />
                          <span>
                            <strong>Responsável:</strong>
                            <br />
                            {invite.staff.name} <br />
                            <a href={`mailto:${invite.staff.email}`}>
                              {invite.staff.email}
                            </a>
                            <br />
                            {role === 'university' && invite.staff.phone}
                          </span>
                        </div>
                      </Fragment>
                    )}

                    {/* Received Component */}
                    {invite.agreementDocumentOneFile.label === null &&
                      invite.agreementDocumentTwoFile.label === null &&
                      received &&
                      !invite.isRejected &&
                      !invite.isCanceled && (
                        <Fragment>
                          <Divider className={classes.divider} />
                          <div className={classes.inviteInfoContainer}>
                            <InsertDriveFileSharpIcon />
                            <span>
                              <strong>Informações sobre o estágio:</strong>
                              <div className={classes.infoInternship}>
                                <button
                                  className={classes.openModalStyles}
                                  onClick={() => openModal(invite)}
                                >
                                  Adicionar informações de estágio
                                </button>
                              </div>
                            </span>
                          </div>
                        </Fragment>
                      )}

                    {invite.agreementDocumentOneFile.label ||
                    invite.agreementDocumentTwoFile.label ? (
                      <Fragment>
                        <Divider className={classes.divider} />
                        <div className={classes.inviteInfoContainer}>
                          <InsertDriveFileSharpIcon />
                          <span>
                            <strong>Informações sobre o estágio:</strong>
                            <div className={classes.infoInternship}>
                              {invite.agreementDocumentOneFile.label && (
                                <div className={classes.wrapperInfos}>
                                  <div className={classes.boxLabel}>
                                    <img src={pdf} alt="Pdf file" />
                                    <button
                                      className={classes.buttonDownload}
                                      onClick={() =>
                                        downloadPdf(
                                          invite,
                                          'agreement_document_one_file'
                                        )
                                      }
                                    >
                                      {invite.agreementDocumentOneFile.label}
                                    </button>
                                  </div>
                                  <div className={classes.containerButtons}>
                                    <button
                                      onClick={() =>
                                        viewPdf(
                                          invite,
                                          'agreement_document_one_file'
                                        )
                                      }
                                    >
                                      <VisibilitySharpIcon />
                                    </button>
                                    <button
                                      onClick={() =>
                                        downloadPdf(
                                          invite,
                                          'agreement_document_one_file'
                                        )
                                      }
                                    >
                                      <GetAppSharpIcon />
                                    </button>
                                  </div>
                                </div>
                              )}

                              {invite.agreementDocumentTwoFile.label && (
                                <div className={classes.wrapperInfos}>
                                  <div className={classes.boxLabel}>
                                    <img src={pdf} alt="Pdf file" />
                                    <button
                                      className={classes.buttonDownload}
                                      onClick={() =>
                                        downloadPdf(
                                          invite,
                                          'agreement_document_two_file'
                                        )
                                      }
                                    >
                                      {invite.agreementDocumentTwoFile.label}
                                    </button>
                                  </div>
                                  <div className={classes.containerButtons}>
                                    <button
                                      onClick={() =>
                                        viewPdf(
                                          invite,
                                          'agreement_document_two_file'
                                        )
                                      }
                                    >
                                      <VisibilitySharpIcon />
                                    </button>
                                    <button
                                      onClick={() =>
                                        downloadPdf(
                                          invite,
                                          'agreement_document_two_file'
                                        )
                                      }
                                    >
                                      <GetAppSharpIcon />
                                    </button>
                                  </div>
                                </div>
                              )}
                              <div className={classes.spanSelection}>
                                <div className={classes.convenioInfo}>
                                  <p>Convênio: </p>
                                  <div>
                                    {invite.mandatory_internship == true && (
                                      <span> Estagio Obrigatório</span>
                                    )}
                                  </div>
                                  {invite.mandatory_internship == true &&
                                  invite.non_mandatory_internship == true
                                    ? '|'
                                    : ''}
                                  <div>
                                    {invite.non_mandatory_internship ==
                                      true && (
                                      <span> Estagio não Obrigatório</span>
                                    )}
                                  </div>
                                </div>

                                {received && (
                                  <div>
                                    {(invite.agreementDocumentOneFile.label ||
                                      invite.agreementDocumentTwoFile
                                        .label) && (
                                      <Button
                                        round
                                        variant="fab"
                                        className={classes.editButtonReceived}
                                        onClick={() => openModal(invite)}
                                      >
                                        <img src={IcoEditWhite} alt="Editar" />
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </span>
                        </div>
                      </Fragment>
                    ) : null}

                    {invite.activitiesSectors && invite.interests ? (
                      <Fragment>
                        <Divider className={classes.divider} />
                        <div className={classes.inviteInfoContainer}>
                          <LocalLibraryOutlined />
                          <span>
                            <strong>Tipo de Atividade: </strong>
                            {invite.activitiesSectors.map(
                              (activity, index) =>
                                `${activity.label}${
                                  invite.activitiesSectors.length !== ++index
                                    ? ', '
                                    : ''
                                }`
                            )}
                            <br />
                            <br />
                            <strong>Desafios da Empresa: </strong>
                            {invite.challenges}
                            <br />
                            <br />
                            <strong>Principais interesses: </strong>
                            {invite.interests.map(
                              (interest, index) =>
                                ` ${interest} ${
                                  invite.interests.length !== ++index
                                    ? '| '
                                    : ''
                                } `
                            )}
                            <br />
                            <br />
                            <strong>
                              Essa empresa possui convênio de estágio
                              obrigatório?{' '}
                            </strong>
                            {invite.mandatory_internship ? 'Sim' : 'Não'}
                            <br />
                            <br />
                            <strong>
                              Essa empresa possui convênio de estágio não
                              obrigatório?{' '}
                            </strong>
                            {invite.non_mandatory_internship ? 'Sim' : 'Não'}
                          </span>
                        </div>
                      </Fragment>
                    ) : null}
                    {handleUpdateState && invite.isEditable && (
                      <Button
                        round
                        variant="fab"
                        className={classes.editButton}
                        onClick={() =>
                          openModalWithContent(
                            <CompanyPreRegisterForm
                              company={invite}
                              isEdit
                              minimalForm={role === 'university'}
                              handleIsLoadingStateFn={handleIsLoadingState}
                              openSnackbarFn={openSnackbar}
                              closeModalFn={closeModal}
                              updateCompaniesStateFn={handleUpdateState}
                              openDnaDialogFn={openDnaDialog}
                            />,
                            'Edição de empresa pré-cadastrada'
                          )
                        }
                      >
                        <img src={IcoEditWhite} alt="Editar" />
                      </Button>
                    )}
                    {handleCancelInvite && !invite.isCanceled && (
                      <Button
                        link
                        onClick={() => {
                          openDnaDialog(
                            'Cancelar convite',
                            'Você tem certeza que deseja cancelar esse convite? Essa ação não pode ser desfeita.',
                            'Sim, cancelar!',
                            async () => {
                              handleCancelInvite(invite.id);
                              closeModal();
                              openSnackbar('Convite cancelado com sucesso!');
                            }
                          );
                        }}
                        aria-label="Cancelar"
                        className={classNames([
                          classes.editButton,
                          classes.cancelButton,
                        ])}
                      >
                        Cancelar
                      </Button>
                    )}
                  </div>
                </ExpansionPanelDetails>
              </div>
            </ExpansionPanel>
          )
        )
      )}
      {(!data || data.length === 0) && (
        <h4 style={{ color: '#9ca4b7' }}>Nenhum convite foi encontrado.</h4>
      )}
    </Fragment>
  );
}

InvitesAccordion.propTypes = {
  classes: object,
  data: array,
  handleInviteReceivedAnswer: func,
  handleCancelInvite: func,
  openDnaDialog: func,
  closeModal: func,
  openSnackbar: func,
  openModalWithContent: func,
  handleIsLoadingState: func,
  handleUpdateState: func,
  setExpansivePanel: func,
  expandedState: string,
  sendInvite: func,
  loading: bool,
  limit: number,
  setLimit: func,
  received: bool,
  updateDataReceived: func,
  showModalEditCompanyInternship: bool,
  setModalEditCompanyInternship: func,
  controlLoading: func,
};
export default withStyles(dashboardStyle)(InvitesAccordion);
