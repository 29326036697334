import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import URLS from 'constants/urls';
import { authenticatedRoutes, openRoutesMerged } from 'routes/index';
import AuthContext from 'contexts/authentication';
import { isAuthenticated } from 'services/login/authenticate';
import { logout } from 'services/login/logout';
import { getTerm } from 'services/acceptanceTerm';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'services/queryClient';
import { triggerPageView } from '../../utils/analytics';
import { getExtraCourses } from '../../services/student/extraCourses';
import urls from '../../constants/urls';
import { cancelRequests } from 'services/student/curriculumData';
import { cancelRequestsProfile } from 'services/student/profile';
import subdomainService from 'services/subdomain.service';
import { defaultsSubdomain } from 'constants/subdomains';
import { updateUTMParams } from 'utils/utmManagement';
import octadesk, { removeOctadesk } from 'utils/octadesk';

class Authentication extends React.Component {
  state = {
    isLogged: false,
    routes: [],
    initialized: false,
    userData: {},
    userOriginUrl: null,
    globalState: null,
    isInvalidAuthenticationToken: false,
    metadata: null,
    redirectOnLogin: null,
  };

  async UNSAFE_componentWillMount() {
    const authenticated = await isAuthenticated();
    if (authenticated.isLogged) {
      if (authenticated.data.metadata.role === 'student') {
        const isStudentIes = subdomainService.verifyStudentIes(
          authenticated.data
        );
        const redirect = subdomainService.getRedirect();
        if (redirect && !isStudentIes) {
          this.setState({ isInvalidAuthenticationToken: true });
          this.setState({ initialized: true });
          this.setPageView();
          return this.onUserLogout();
        }
        const extra_courses = await getExtraCourses();
        authenticated.data.extra_courses = extra_courses;
      } else if (authenticated.data.metadata.role === 'university') {
        const subdomain = subdomainService.getSubdomain();
        const redirect = subdomainService.getRedirect();
        if (
          redirect &&
          subdomain !== defaultsSubdomain.university &&
          !subdomain.includes(defaultsSubdomain.workability)
        ) {
          this.setState({ isInvalidAuthenticationToken: true });
          this.setState({ initialized: true });
          this.setPageView();
          return this.onUserLogout();
        }
      } else if (authenticated.data.metadata.role === 'company') {
        const subdomain = subdomainService.getSubdomain();
        const redirect = subdomainService.getRedirect();
        if (
          redirect &&
          subdomain !== defaultsSubdomain.company &&
          !subdomain.includes(defaultsSubdomain.workability)
        ) {
          this.setState({ isInvalidAuthenticationToken: true });
          this.setState({ initialized: true });
          this.setPageView();
          return this.onUserLogout();
        }
      }
    }
    if (!authenticated.isLogged) {
      this.setState({
        routes: openRoutesMerged,
      });
    } else if (authenticated.isLogged) {
      this.onUserLogin(authenticated.data, authenticated.originUrl, window.location.href.split('?')[1]);
      this.setState({ metadata: authenticated.data.metadata });
    } else if (
      authenticated.data.data.code === 'INVALID_AUTHENTICATION_TOKEN'
    ) {
      this.setState({ isInvalidAuthenticationToken: true });
    }

    this.setState({ initialized: true });

    this.setPageView();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { userData, isLogged, globalState } = this.state;
    if (isLogged && userData !== nextState.userData) {
      return false;
    }
    if (isLogged && globalState !== nextState.globalState) {
      return false;
    }

    return true;
  }

  setUserData = async (userData) => {
    this.setState({
      userData: {
        ...this.state.userData,
        ...userData,
      },
    });
  };

  onUserLogin = async (data, originUrl, originParams) => {
    let acceptTerm;

    data.metadata.use_technical_support ? octadesk() : removeOctadesk();

    if (data.metadata.role !== 'university') {
      acceptTerm = await getTerm(data.metadata);
    }

    this.setState(
      {
        isLogged: true,
        userData: data,
        userOriginUrl: originUrl,
        globalState: {
          has_watched_tour: data.metadata.has_watched_tour,
          acceptTerm: acceptTerm && acceptTerm.is_assign,
          workInAreaModal: !data.metadata.is_working_area_answered,
          termUrl: acceptTerm && acceptTerm.file_term,
          curriculumFilled:
            data.academic_profile && data.academic_profile.curriculum_filled,
          has_changed_password: data.has_changed_password,
          loginUserName:
            data.academic_profile && data.academic_profile.external_id,
          staffRole: data.metadata.staff_role,
        },
        metadata: data.metadata,
      },
      () => {
        if (this.state.isLogged) {
          this.setState({ routes: authenticatedRoutes(this.state.userData) });
        } else {
          this.setState({ routes: openRoutesMerged });
        }
      }
    );

    if (data.params && data.params.id && data.params.uuid) {
      const newPath = `${urls.URL_FRONT}/#/feiras-e-eventos/${data.params.id}/${data.params.uuid}`;
      window.open(newPath, '_parent');
    }

    if (data.params && data.params.eventId) {
      let newPath = `${urls.URL_FRONT}/#/aprendizagem/${data.params.universidade}/compartilhar/evento/${data.params.eventId}`;
    }

    if (data.params && data.params.universidade && data.params.id) {
      window.open(
        `${urls.URL_FRONT}/#/vaga/${data.params.universidade}/${data.params.id}`,
        '_parent'
      );
    }

    if (originParams) {
      const searchParams = new URLSearchParams(originParams);
      const redirectMenu = searchParams.get('redirect_to');
      if (redirectMenu) {
        window.open(
          `${urls.URL_FRONT}/#/${redirectMenu}`,
          '_parent'
        );
      }
    }
  };

  onUserLogout = async () => {
    const userRole = this.state.userData?.metadata?.role ?? '';
    const userUniversity =
      this.state.userData?.metadata?.university_slug ??
      subdomainService.getSubdomain();
    const studentType = this.state.userData?.metadata?.academic_status;
    const response = await logout();
    cancelRequests();
    cancelRequestsProfile();
    this.setState(
      {
        isLogged: false,
        userData: [],
        userOriginUrl: null,
        globalState: null,
        routes: openRoutesMerged,
        metadata: null,
      },
      () => {
        if (response.origin_url) {
          window.location = response.origin_url;
        } else {
          if (userRole !== 'student') {
            let url = URLS.REDIRECT_URLS[userRole];
            window.location.hash = url;
          }

          if (userRole === 'student') {
            let role = studentType;
            let url = URLS.REDIRECT_URLS[role];
            url = url.replace(':universidade', userUniversity);
            window.location.hash = url;
          }
        }
      }
    );
  };

  setPageView = () => {
    const pathLocation = window.location.hash.replace('#', '');

    const { userData } = this.state;
    if (pathLocation !== '/') {
      if (
        pathLocation &&
        userData.metadata &&
        !pathLocation.includes('login')
      ) {
        triggerPageView(pathLocation, userData.metadata.role);
      } else if (pathLocation && !userData.metadata)
        triggerPageView(pathLocation);
    } else if (userData && userData.metadata && userData.metadata.role) {
      triggerPageView(userData.metadata.role);
    }
  };

  setGlobalState = (data) => {
    const { globalState } = this.state;

    this.setState({ globalState: { ...globalState, ...data } });
  };

  render() {
    const {
      isLogged,
      userData,
      initialized,
      globalState,
      isInvalidAuthenticationToken,
      routes,
    } = this.state;

    if (
      isLogged &&
      userData?.metadata?.role === 'student' &&
      updateUTMParams(userData.metadata, userData.academic_profile)
    ) {
      return null;
    }

    const history = createBrowserHistory();

    history.listen(() => {
      this.setPageView();
    });

    return (
      initialized && (
        <AuthContext.Provider
          value={{
            routerOnUserLogin: this.onUserLogin,
            routerOnUserLogout: this.onUserLogout,
            isLogged,
            metadata: this.state.metadata,
            isInvalidAuthenticationToken,
            setUserData: this.setUserData,
          }}
        >
          <QueryClientProvider client={queryClient}>
            <Switch>
              {routes.map((prop, key) => {
                if (prop.redirect)
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );

                const renderComponent = (props) => {
                  const Component = prop.component;
                  return (
                    <prop.baseComponent
                      {...userData}
                      {...prop}
                      {...props}
                      globalState={globalState}
                      setGlobalState={this.setGlobalState}
                      setUserData={this.setUserData}
                      childComponent={(props) => <Component {...props} />}
                      routes={routes}
                    />
                  );
                };

                return (
                  <Route
                    exact
                    path={prop.path}
                    component={renderComponent}
                    key={key}
                  />
                );
              })}
            </Switch>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthContext.Provider>
      )
    );
  }
}

export default Authentication;
