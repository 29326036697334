import { useState, useEffect, useRef, useContext } from 'react';
import { Form } from '@unform/web';

import primaryLike from 'assets/student/primaryLike.svg';
import secundaryLike from 'assets/student/secundaryLike.svg';
import secundaryDeslike from 'assets/student/secundaryDeslike.svg';
import primaryDeslike from 'assets/student/primaryDeslike.svg';
import primaryStar from 'assets/student/primaryStar.svg';
import secundaryStar from 'assets/student/secundaryStar.svg';
import {
  cancelRecommendation,
  deleteReaction,
  getDetails,
  newReaction,
  newRecommend,
  updateReaction,
} from 'views/Student/Learning/Services';
import RadioFeedBack from '../../Screens/ExtensionProject/components/ListProjectExtesion/components/reactions/radioInput';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';

export function ReactButtons({
  id,
  item,

  listingType,
  isFinished,
  learningCountControl,
  scrollUp,
  modalView,
  setControlViewDetails,
}) {
  const [optionValue, setOptionValue] = useState('');
  const [optionValueRecommended, setOptionValueRecommended] = useState('');
  const formRef = useRef();
  const [reactions, setReactions] = useState({});

  const {
    getAvailableLearnings,
    getFavoritesLearnings,
  } = useAPICallsLearning();

  const { setLearnings, setFavoritesLearning, queryFilter } = useContext(
    LearningContext
  );

  const handleReact = async () => {
    const response = await getDetails(item.id);
    const { reactions } = response.data;
    setReactions(reactions);
  };

  useEffect(() => {
    setOptionValue('');
    setOptionValueRecommended('');
    if (formRef.current) {
      if (item.reactions.liked) {
        setOptionValue('liked');
        formRef.current.setFieldValue('type', 'liked');
      }
      if (item.reactions.disliked) {
        setOptionValue('disliked');
        formRef.current.setFieldValue('type', 'disliked');
      }
      if (item.is_recommended) {
        setOptionValueRecommended('recommended');
        formRef.current.setFieldValue('recommended', 'recommended');
      }
    }
  }, [item, id]);

  const handleChange = async (e) => {
    const value = e.target.value;

    if (e.target.value === optionValue) {
      const response = await getDetails(item.id);
      const { reactions } = response.data;

      await deleteReaction(id, reactions.id);
      setOptionValue('');
      setReactions({});
      return;
    }
    setOptionValue(value);
    formRef.current.submitForm();
  };

  const handleSubmit = async (data) => {
    handleReact();
    if (reactions.id) {
      await updateReaction(id, reactions.id, { ...data });
      setReactions({});
      return;
    }
    return await newReaction(id, { ...data });
  };

  const handleChangeRecommended = async () => {
    if (optionValueRecommended === '') {
      setOptionValueRecommended('recommended');
      await newRecommend(id);
      if (listingType === 'Available') {
        learningCountControl();
        setLearnings(null);
        getAvailableLearnings(false, 0, queryFilter);
        setControlViewDetails && setControlViewDetails(false);
        scrollUp();
        return;
      }

      if (listingType === 'Favorite') {
        learningCountControl();
        setFavoritesLearning(null);
        getFavoritesLearnings(false, 0, queryFilter);
        scrollUp();

        return;
      }
    }
    await cancelRecommendation(id);
    setOptionValueRecommended('');
    if (listingType === 'Available') {
      learningCountControl();
      setLearnings(null);
      getAvailableLearnings(false, 0, queryFilter);
      scrollUp();
      return;
    }

    if (listingType === 'Favorite') {
      learningCountControl();
      setFavoritesLearning(null);
      getFavoritesLearnings(false, 0, queryFilter);
      setControlViewDetails && setControlViewDetails(false);
      scrollUp();
      return;
    }
  };

  const Options = [
    {
      id: Math.floor(Math.random() * 1000),
      value: 'liked',
      label: 'yes',
      type: 'radio',
      color: '#009291',
      imgPrimary: primaryLike,
      imgSecundary: secundaryLike,
      tooltipTitle: 'Curtir',
    },
    {
      id: Math.floor(Math.random() * 1000),
      value: 'disliked',
      label: 'no',
      type: 'radio',
      color: '#D26464',
      imgPrimary: primaryDeslike,
      imgSecundary: secundaryDeslike,
      tooltipTitle: 'Não tenho interesse',
    },
  ];

  const OptionRecommended = [
    {
      id: Math.floor(Math.random() * 1000),
      value: 'recommended',
      label: 'no',
      type: 'checkbox',
      color: '#FFC328',
      imgPrimary: primaryStar,
      imgSecundary: secundaryStar,
      tooltipTitle: 'Favoritar',
    },
  ];

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {OptionRecommended && !isFinished && (
          <RadioFeedBack
            name="recommended"
            options={OptionRecommended}
            onChange={handleChangeRecommended}
            optionValue={optionValueRecommended}
            setOptionValue={setOptionValueRecommended}
          />
        )}
        {Options && !modalView && (
          <RadioFeedBack
            name="type"
            options={Options}
            onClick={handleChange}
            optionValue={optionValue}
            setOptionValue={setOptionValue}
          />
        )}
      </div>
    </Form>
  );
}
