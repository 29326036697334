import React, { useContext, useRef, useState, useEffect } from 'react';
import logoWorkaloveColor from 'assets/img/icons/logoWorkaloveColor.svg';
import LogosAnimated from '../UIComponents/LogosAnimated';
import { Form } from '@unform/web';
import { errors } from 'constants/errors';

import LoginForgotPassword from './ForgotPassword';

import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  Paragraph,
  ButtonLogin,
  ContainerButtonFooter,
  ButtonRegister,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  BoxLogos,
  ButtonPassword,
  Footer,
  ContainerImage,
  ButtonOutlined,
  CreateAccountDiv,
} from '../styles';
import AuthContext from 'contexts/authentication';
import BaseLayoutContext from 'contexts/base-layout';
import { login } from 'services/login/authenticate';
import CreateAccountCompany from './BaseLogin/CreateAccountCompany';
import InputUsernameLogin from '../UIComponents/InputUsername';
import InputPasswordLogin from '../UIComponents/InputPassword';
import EmailPassword from './BaseLogin/EmailPassword';
import subdomainService from 'services/subdomain.service';
import { defaultsSubdomain } from 'constants/subdomains';
import {
  getInfoStudent,
  getUniversityLogos,
} from 'services/login/authenticate';
import ImageSkeleton from '../styles/ImageSkeleton';
import data from 'views/Student/Learning/Utils/workaData/data';

export default function LoginCompany({ match, university }) {
  const { routerOnUserLogin, isInvalidAuthenticationToken } = useContext(
    AuthContext
  );
  const { openSnackbar, handleIsLoadingState } = useContext(BaseLayoutContext);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [openModalInformation, setOpenModalInformatio] = useState(false);
  const [contentLogin, setContentLogin] = useState(true);
  const [modalEmailSent, setModalEmailSent] = useState(false);
  const dnaColor = '#009291';
  const formLoginCompany = useRef();
  const subdomain = subdomainService.getSubdomain();
  const urlCompany = '/login/empresa';
  const [dataInfos, setDataInfos] = useState(null);
  const [universityColor, setUniversityColor] = useState(dnaColor);
  const [logo, setLogo] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [skeleton, setSkeleton] = useState(false);

  const universityName = match.params.universidade ?? university;

  useEffect(() => {
    fetchInfos('enrolled');
  }, [universityName]);

  useEffect(() => {
    getLogo(universityName);
  }, [universityName]);

  function handleForgotPasswordClick() {
    setShowForgotPassword(true);
    setContentLogin(false);
  }

  function handleCreateAccount() {
    setShowCreateAccount(true);
    setContentLogin(false);
  }

  const handleLogin = async (data) => {
    handleIsLoadingState(true);
    let loginCompany = await login(
      subdomain.toLocaleLowerCase() === defaultsSubdomain.company
        ? urlCompany
        : match.path,
      data
    );

    if (loginCompany.isLogged) {
      if (match.params?.universidade && match.params?.id) {
        loginCompany.data['params'] = match.params;
      }
      routerOnUserLogin(
        loginCompany.data,
        undefined,
        window.location.href.split('?')[1]
      );
    } else {
      if (loginCompany.data.detail) {
        handleIsLoadingState(false);
        return;
      }
      const message = errors.hasOwnProperty(loginCompany.data.code)
        ? errors[loginCompany.data.code]
        : 'Não foi possível realizar o seu login. Tente novamente';
      handleIsLoadingState(false);
      openSnackbar(message, true);
    }
  };

  const fetchInfos = async (typeLogin) => {
    if (typeLogin) {
      try {
        setSkeleton(true);
        const response = await getInfoStudent(universityName, typeLogin);
        setDataInfos(response);
        setSkeleton(false);
      } catch (err) {
        setSkeleton(false);
        setDataInfos(null);
      }
    }
  };

  const getLogo = async (param) => {
    try {
      const response = await getUniversityLogos(param);
      if (!response.data?.logo) {
        throw new Error('Logo does not exist');
      }
      setUniversityColor(response.data.university_color);
      setLogo(response.data.logo);
      setBackgroundImage(response.data.company_background_image);
    } catch (e) {
      setLogo(logoWorkaloveColor);
      setUniversityColor(dnaColor);
    }
  };

  const returnImageLogin = (image) => {
    if (skeleton === true) {
      return <ImageSkeleton />;
    }

    if (image) {
      return <ContainerImage image={image} />;
    }

    if (!image) {
      return (
        <ContainerAllLogos>
          <LogosAnimated color={universityColor} />
          <div>
            <Titles isLogo>
              <strong>Encontre os melhores estudantes para suas vagas.</strong>
            </Titles>
            <Paragraph>
              Acesse o banco de dados das instituições de <br /> ensino mais
              inovadoras do país e contrate <br /> mais rápido os melhores
              estudantes.
            </Paragraph>
          </div>
        </ContainerAllLogos>
      );
    }
  };

  return (
    <Container color={universityColor}>
      <BoxLogos>{returnImageLogin(backgroundImage)}</BoxLogos>

      {contentLogin === false && showCreateAccount && (
        <CreateAccountCompany
          match={match}
          setContentLogin={setContentLogin}
          showCreateAccount={showCreateAccount}
          setShowCreateAccount={setShowCreateAccount}
          openModalInformation={openModalInformation}
          logo={logo}
          universityColor={universityColor}
        />
      )}

      {contentLogin === false && showForgotPassword && (
        <LoginForgotPassword
          isCompanyConfig
          modalEmailSent={modalEmailSent}
          setModalEmailSent={setModalEmailSent}
          contentLogin={contentLogin}
          setContentLogin={setContentLogin}
          showForgotPassword={showForgotPassword}
          setShowForgotPassword={setShowForgotPassword}
          universityColor={universityColor}
        />
      )}

      {modalEmailSent && (
        <EmailPassword
          modalEmailSent={modalEmailSent}
          setModalEmailSent={setModalEmailSent}
          setContentLogin={setContentLogin}
          showForgotPassword={showForgotPassword}
        />
      )}

      {contentLogin && (
        <ContainerLogin contentImage={dataInfos?.image?.file}>
          <FragmentContainer>
            <ContainerInCenter logoWidth="260px" isCompany>
              <img src={logo} alt="" />
            </ContainerInCenter>
            <Titles>
              <p>Seja bem-vindo!</p>
              <p>Acesse sua conta.</p>
            </Titles>
            <div className="form">
              <Form ref={formLoginCompany} onSubmit={handleLogin}>
                <InputUsernameLogin
                  name="username"
                  label="E-mail de acesso"
                  universityColor={universityColor}
                  placeholder="Digite aqui..."
                />

                <InputPasswordLogin
                  name="password"
                  label="Senha"
                  type="password"
                  universityColor={universityColor}
                  placeholder="Digite aqui..."
                />
                <span></span>
                <ButtonPassword
                  color={universityColor}
                  onClick={handleForgotPasswordClick}
                  type="button"
                >
                  Esqueceu sua senha ?
                </ButtonPassword>
                <div className="button-login-container">
                  <ButtonLogin color={universityColor} type="submit">
                    Entrar
                  </ButtonLogin>
                </div>
              </Form>
            </div>
            <CreateAccountDiv isCompany>
              <div className="divider">
                <hr />
                <span>OU</span>
                <hr />
              </div>
              <ButtonOutlined
                color={universityColor}
                onClick={handleCreateAccount}
              >
                Criar conta
              </ButtonOutlined>
            </CreateAccountDiv>
          </FragmentContainer>

          <ContainerWorkalove>
            <Footer>
              By <strong>Workalove</strong>
            </Footer>
          </ContainerWorkalove>
        </ContainerLogin>
      )}
    </Container>
  );
}
