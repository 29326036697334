import MentorshipModal from 'components/MentorshipModal';
import React, { useState, useContext } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { ActionButton, Container, StarButton } from './styles';
import InformationModal from '../InformationModal';
import BaseLayoutContext from 'contexts/base-layout';
import { P } from 'views/JobOfferRebuild/Components/Header/style';

export default function ModalEvaluateMentorship() {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [value, setValue] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const {universityColor} = useContext(BaseLayoutContext)

  const {
    isEvaluateMentorshipModalOpen,
    closeEvaluateMentorshipModal,
    sendRatingMentorship,
  } = useMentorship();

  function handleClose() {
    setShowSuccessMessage(false);
    setValue(0);
    closeEvaluateMentorshipModal();
  }

  async function handleEvaluate() {
    setRequestInProgress(true);
    const success = await sendRatingMentorship(value);
    if (success) {
      setShowSuccessMessage(true);
    }
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal
      isOpen={isEvaluateMentorshipModalOpen}
      onClose={handleClose}
    >
    {showSuccessMessage ? (
      <InformationModal
        title="Avaliação enviada"
        description="Agradecemos pela sua colaboração!"
        universityColor={universityColor}
      />
      ) : (
        <Container>
          <h1>O que você achou da sua mentoria?</h1>
          <strong>Escolha de 1 a 5 estrelas para classificar</strong>
          <div>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <StarButton
                  key={ratingValue}
                  selected={ratingValue <= value}
                  onClick={() => setValue(ratingValue)}
                />
              );
            })}
          </div>
          {value > 0 && (
            <ActionButton 
              universityColor={universityColor} 
              disabled={requestInProgress} 
              onClick={handleEvaluate}
            >
              {requestInProgress ? 'Enviando...' : 'Enviar avaliação'}
            </ActionButton>
          )}
        </Container>
      )}
    </MentorshipModal>
  );
}
