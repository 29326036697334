import React, { useEffect, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import MentorshipModal from 'components/MentorshipModal';
import SuccessModal from 'components/SuccessModal';
import { useMentorship } from '../../contexts/MentorshipContext';

export default function ScheduleMentorshipModal() {
  const {
    isScheduleMentorshipModalOpen,
    closeScheduleMentorshipModal,
  } = useMentorship();
  const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, [isScheduleMentorshipModalOpen]);

  function CurrentStep() {
    switch (step) {
      case 1:
        return <Step1 setStep={setStep} />;
      case 2:
        return <Step2 setStep={setStep} />;
      default:
        return (
          <SuccessModal
            title={'Mentoria agendada com sucesso!'}
            description={'Aguarde a confirmação do estudante'}
          />
        );
    }
  }

  return (
    <MentorshipModal
      isOpen={isScheduleMentorshipModalOpen}
      onClose={closeScheduleMentorshipModal}
      maxHeight={'95vh'}
    >
      <CurrentStep />
    </MentorshipModal>
  );
}
