const networkingStyle = (theme) => ({
  listGrid: {
    overflow: 'hidden',
    marginBottom: 15,
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  list: {
    display: 'flex',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '0 20px 15px 20px',
    padding: 10,
    width: 150,
    height: 150,
    background: '#E5E5E5',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 100,
    [theme.breakpoints.down('md')]: {
      margin: '0 10px',
      width: 100,
      height: 100,
      backgroundSize: 50,
    },
  },
  avatarText: {
    textAlign: 'center',
    color: '#3C4858',
  },
  directionButtons: {
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  arrowIcons: {
    width: 48,
    height: 48,
    color: '#999',
  },
  connectButton: {
    boxShadow: 'none',
    marginBottom: 0,
  },
  viewTitle: {
    margin: '34px 0 55px',
    textAlign: 'center',
  },
  emptyCard: {
    padding: '30px',
    textAlign: 'center',
    color: '#555',
    fontSize: '16px',
    marginTop: '35px',
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      padding: '0 20px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px',
    },
  },
  viewsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default networkingStyle;
