import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: ${({ formActived }) => (formActived ? 'flex-start' : 'center')};
  justify-content: center;
  z-index: 2000;
  overflow-y: auto;
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const SimpleButton = styled.button`
  background-color: #009291;
  width: 134px;
  height: auto;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: bold;
  color: #fff;

  border: none;
  border-radius: 3px;

  white-space: nowrap;

  cursor: pointer;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 673px;

  background-color: #ffffff;
  border-radius: 10px;

  position: relative;
`;

export const ContainerContent = styled.div`
  padding: 10px 20px;
`;

export const TopBar = styled.div`
  height: 85px;
  width: 100%;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background-color: #009291;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 46px;

  .title {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  cursor: pointer;
`;

export const SelectBox = styled.div`
  padding: 20px 0 10px;
  padding-bottom: ${({ formActived }) => formActived && '0px'};
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: ${(props) => (props.isStepTwo ? 'center' : 'flex-end')};
  justify-content: ${(props) =>
    props.isStepTwo ? 'space-between' : 'flex-end'};

  width: 100%;
  height: auto;

  padding-bottom: 30px;
`;

export const IconImg = styled.img`
  width: auto;
  height: auto;
`;

export const ButtonNextStep = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  border: none;
  border-radius: 50%;

  width: 40px;
  height: 40px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.disabled &&
    css`
      background-color: #dfdcdc;
    `}
`;

export const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
  color: #606062;

  padding: 0 46px;
  padding-bottom: 41px;

  .send-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 146px;
    height: 38px;
    border-radius: 3px;
    border: none;

    background-color: #009291;

    font-size: 15px;
    font-weight: 700;
    color: #fff;

    cursor: pointer;
  }

  .container-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;

    margin-top: 33px;
  }
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;

  flex-direction: ${(props) => (props.isData ? 'row' : 'column')};
  gap: ${(props) => (props.isData ? '30px' : 'unset')};
`;

export const ContainerForms = styled.div``;

export const Phrase = styled.h3`
  margin: 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 30px;
`;

const JobHeaderTab = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    backgroundColor: '#009291',
    height: 3,
    zIndex: 2,
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: 'none',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssLabel: {
    '&$cssFocused': {
      color: '#009291',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cssFocused: {},
});

export default JobHeaderTab;

export const Container = styled.div`
  display: flex;
  position: relative;
  padding: 0 0 0 20px;

  .custom-indicator {
    background-color: #009291;
  }
`;

export const ParagraphFeedback = styled.p`
  color: #6d6d6d;
  margin: 0 0 5px;
  font-size: 14px;
  text-align: left;
`;
