import React, { useEffect, useState, useContext } from 'react';

import Gamification from './UIComponents/Gamification/Gamification';
import GenericSection from './UIComponents/GenericSection/GenericSection';
import ProfileHeaderStudent from './UIComponents/ProfileHeader/ProfileHeaderStudent';
import BaseLayoutContext from 'contexts/base-layout';

import {
  FlexItem,
  ContentBody,
  FlexContainer,
  ContainerCurriculumItems,
  ContainerAreas,
  AlertArea,
} from './styles/BaseLayouSection';

import { getLanguages } from 'services/student/languages';

import { InterestsContent } from './UIComponents/GenericSection/CardContent/Interests';
import { PersonalityContent } from './UIComponents/GenericSection/CardContent/Personality';
import { BehavioralContent } from './UIComponents/GenericSection/CardContent/Behavioral';
import { ExperiencesContent } from './UIComponents/GenericSection/CardContent/Experiences';
import { ValuesContent } from './UIComponents/GenericSection/CardContent/Values';
import { LanguagesContent } from './UIComponents/GenericSection/CardContent/Languages';
import { ExtraCoursesContent } from './UIComponents/GenericSection/CardContent/ExtraCourses';
import { PortfolioContent } from './UIComponents/GenericSection/CardContent/Portfolio';
import { CompetencesContent } from './UIComponents/GenericSection/CardContent/Competences';

import InterestHelpContent from 'views/MyProfile/components/Interests/InterestHelpContent';
import LanguageHelpContent from 'views/MyProfile/components/language/LanguageHelpContent';
import ValuesTestHelpContent from 'views/MyProfile/components/ValuesTest/ValuesTestHelpContent';
import ExtraCoursesHelpContent from 'views/MyProfile/components/ExtraCourse/ExtraCoursesHelpContent';
import BehavioralTestHelpContent from 'views/MyProfile/components/BehavioralTest/BehavioralTestHelpContent';
import PersonalityTestHelpContent from 'views/MyProfile/components/PersonalityTest/PersonalityTestHelpContent';
import PortfolioProjectsHelpContent from 'views/MyProfile/components/PortfolioProjects/PortfolioProjectsHelpContent';
import TechnicalCompetenceHelpContent from 'views/MyProfile/components/TechnicalCompetence/TechnicalCompetenceHelpContent';
import ProfessionalExperienceHelpContent from 'views/MyProfile/components/Experiences/ProfessionalExperienceHelpContent';

import { wrapperIconsProfile } from './Utils/wrapperIcons';
import { AcademicInformations } from './UIComponents/StudentsAcademicInformations/AcademicInformations';
import { PersonalInformations } from './UIComponents/StudentsAcademicInformations/PersonalInformations';
import ProfileProvider from './Context/ProfileContext';
import { getExtraCourses } from 'services/student/extraCourses';
import SkeletonGamification from './UIComponents/GenericSection/SkeletonSection';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import { getCurriculum } from 'services/student/curriculumData';
import { checkCurriculumFilled } from 'services/student/profile';

import OrganizationModalComponent from './UIComponents/OrganizationModalComponent';
import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';
import { PublicProfileLink } from './UIComponents/StudentsAcademicInformations/PublicProfileLink';

export default function (props) {
  const {
    handleIsLoadingState,
    interests,
    life_interests,
    quizzes,
    life_experience,
    projects,
    professional_experience,
    technical_competences,
    academic_profile,
    professional_status,
  } = props;

  const {
    IconInterests,
    IconCompetence,
    IconProjectsPortfolio,
    IconExperiences,
    IconCapacitation,
    IconLanguage,
    IconValues,
    IconBehavioral,
    IconPersonality,
  } = wrapperIconsProfile();

  const { universityColor } = useContext(BaseLayoutContext);
  const [languagesState, setLanguagesState] = useState([]);
  const [extraCourses, setExtraCourses] = useState([]);
  const [profileData, setProfileData] = useState(academic_profile);
  const [showRaceOrColor, setShowRaceOrColor] = useState(false);
  const [showDisability, setShowDisability] = useState(false);
  const [showIdentityGender, setShowIdentityGender] = useState(false);
  const [gamificationData, setGamificationData] = useState(null);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const [modalReward, setModalRewards] = useState(false);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  function modalFlow() {}

  const getLang = async () => {
    const resLanguages = await getLanguages();
    setLanguagesState(resLanguages);
  };

  const handleExtraCourses = async () => {
    const resExtraCourses = await getExtraCourses();
    setExtraCourses(resExtraCourses);
  };

  const fetchCurriculum = async () => {
    handleIsLoadingState(true);
    setModalRewards(false);
    const curriculum = await getCurriculum();
    const { data } = curriculum;
    if (data !== null) {
      setCurriculumData(data);
    }
  };

  const handleCurriculum = async () => {
    const status = await checkCurriculumFilled();
    if (status) {
      await fetchCurriculum();
      setGenerateCurriculumModal(true);
    }
  };

  useEffect(() => {
    if (generateCurriculumModal === true) {
      registerAccessedCurriculum([curriculumData.curriculum_id]);
    }
  }, [generateCurriculumModal]);

  useEffect(() => {
    getLang();
    handleExtraCourses();
  }, []);

  useEffect(() => {
    setCardData((prev) => ({ ...prev, ['languages']: languagesState }));
  }, [languagesState]);

  useEffect(() => {
    setCardData((prev) => ({ ...prev, ['extraCourses']: extraCourses }));
  }, [extraCourses]);

  const [cardData, setCardData] = useState({
    careerInterests: interests,
    lifeInterests: life_interests,
    personality: quizzes.personality,
    behavioral: quizzes.behavioral,
    languages: languagesState,
    values: quizzes.values,
    extraCourses: extraCourses,
    lifeExperience: life_experience,
    careerExperience: professional_experience,
    portfolio: projects,
    technicalCompetences: technical_competences,
  });

  const [sections, setSections] = useState([
    {
      title: 'Interesses*',
      cardDataKey: 'careerInterests',
      cardDataKeyTwo: 'lifeInterests',
      iconSection: <IconInterests color={universityColor} />,
      time: true,
      content: InterestsContent,
      helpContent: <InterestHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Sua personalidade*',
      cardDataKey: 'personality',
      iconSection: <IconPersonality color={universityColor} />,
      time: true,
      content: PersonalityContent,
      helpContent: (
        <PersonalityTestHelpContent universityColor={universityColor} />
      ),
    },
    {
      title: 'Perfil Comportamental*',
      cardDataKey: 'behavioral',
      iconSection: <IconBehavioral color={universityColor} />,
      time: true,
      content: BehavioralContent,
      helpContent: (
        <BehavioralTestHelpContent universityColor={universityColor} />
      ),
    },
  ]);

  const [visibleItemsSection, setVisibleItemsSection] = useState([
    {
      title: 'Seus Valores',
      cardDataKey: 'values',
      iconSection: <IconValues color={universityColor} />,
      time: true,
      content: ValuesContent,
      helpContent: <ValuesTestHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Idiomas',
      cardDataKey: 'languages',
      iconSection: <IconLanguage color={universityColor} />,
      time: true,
      content: LanguagesContent,
      helpContent: <LanguageHelpContent universityColor={universityColor} />,
    },
    {
      title: 'Formação e Capacitação',
      cardDataKey: 'extraCourses',
      iconSection: <IconCapacitation color={universityColor} />,
      info: `Selecione as 3 Formações e Certificações desejáveis, caso contrário, serão apresentadas as 3 últimas cadastradas.`,
      time: false,
      content: ExtraCoursesContent,
      helpContent: (
        <ExtraCoursesHelpContent universityColor={universityColor} />
      ),
    },
    {
      title: 'Experiências',
      cardDataKey: 'careerExperience',
      cardDataKeyTwo: 'lifeExperience',
      iconSection: <IconExperiences color={universityColor} />,
      info: `Selecione as 3 Experiências desejáveis, caso contrário, serão apresentadas as 3 últimas cadastradas.`,
      time: false,
      content: ExperiencesContent,
      helpContent: (
        <ProfessionalExperienceHelpContent universityColor={universityColor} />
      ),
    },
    {
      title: 'Portfólio de projetos',
      iconSection: <IconProjectsPortfolio color={universityColor} />,
      info: `Selecione os 3 Portfólios desejáveis, caso contrário, serão apresentados os 3 últimos cadastrados.`,
      cardDataKey: 'portfolio',
      time: false,
      content: PortfolioContent,
      helpContent: (
        <PortfolioProjectsHelpContent universityColor={universityColor} />
      ),
    },
  ]);

  useEffect(() => {
    if (technical_competences.average > 0) {
      const competenceSection = {
        title: 'Competências',
        iconSection: <IconCompetence color={universityColor} />,
        info: 'Sua formação acadêmica foi transformada em Competências de Mercado',
        cardDataKey: 'technicalCompetences',
        time: false,
        content: CompetencesContent,
        helpContent: (
          <TechnicalCompetenceHelpContent universityColor={universityColor} />
        ),
      };
      setVisibleItemsSection([...visibleItemsSection, competenceSection]);
    }
  }, [technical_competences]);

  const renderMapLevelTwo = (array) => {
    if (gamificationData === null) {
      return <SkeletonGamification />;
    }
    return array.map((item) => (
      <FlexItem>
        <GenericSection
          info={item.info}
          time={item.time}
          title={item.title}
          cardData={cardData}
          CardContent={item.content}
          setCardData={setCardData}
          IconSection={item.iconSection}
          cardDataKey={item.cardDataKey}
          cardDataKeyTwo={item.cardDataKeyTwo}
          helpContent={item.helpContent}
          shouldHideItem={gamificationData?.profile_level === 1}
          {...props}
        />
      </FlexItem>
    ));
  };

  return (
    <ProfileProvider
      gamificationData={gamificationData}
      setGamificationData={setGamificationData}
      handleCurriculum={handleCurriculum}
      modalReward={modalReward}
      setModalRewards={setModalRewards}
    >
      <OrganizationModalComponent
        universityColor={universityColor}
        curriculumData={curriculumData}
        profileData={profileData}
        {...props}
      />

      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          setModalRewards={setModalRewards}
          profileData={profileData}
          showActions={false}
        />
      )}
      <ProfileHeaderStudent
        profileData={profileData}
        setProfileData={setProfileData}
        showRaceOrColor={showRaceOrColor}
        setShowRaceOrColor={setShowRaceOrColor}
        showDisability={showDisability}
        setShowDisability={setShowDisability}
        showIdentityGender={showIdentityGender}
        setShowIdentityGender={setShowIdentityGender}
        {...props}
      />
      <ContentBody>
        <Gamification {...props} />
        <FlexContainer>
          <ContainerCurriculumItems>
            {sections.map((item) => (
              <GenericSection
                info={item.info}
                time={item.time}
                title={item.title}
                cardData={cardData}
                CardContent={item.content}
                setCardData={setCardData}
                IconSection={item.iconSection}
                cardDataKey={item.cardDataKey}
                cardDataKeyTwo={item.cardDataKeyTwo}
                helpContent={item.helpContent}
                shouldHideItem={false}
                {...props}
              />
            ))}
            <AlertArea display={gamificationData?.profile_level === 1}>
              <p>
                Para desbloquear as sessões abaixo,{' '}
                <strong> conclua todas as etapas do nível 1.</strong>
              </p>
            </AlertArea>
            {renderMapLevelTwo(visibleItemsSection)}
          </ContainerCurriculumItems>

          <ContainerAreas>
            <PublicProfileLink />
            <PersonalInformations
              profileData={profileData}
              setProfileData={setProfileData}
              {...props}
            />
            <AcademicInformations
              universityColor={universityColor}
              {...props}
            />
          </ContainerAreas>
        </FlexContainer>
      </ContentBody>
    </ProfileProvider>
  );
}
