import styled from 'styled-components';

import arrowRight from 'assets/Oportutinites/Setadireita.svg';
import arrowLeft from 'assets/Oportutinites/Setaesquerda.svg';

export const WrapperRegister = styled.div`
  width: 70%;
  background-color: #fff;
  padding: ${({ succesRegister }) => (succesRegister ? '2em' : '5em')};
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  height: ${({ succesRegister }) => (succesRegister ? '250px' : 'auto')};
  margin-top: 200px;
  padding-bottom: ${({ succesRegister }) => (succesRegister ? '0px' : '150px')};
`;

export const WrapperTitle = styled.div``;

export const H3Title = styled.h3`
  color: ${({ universityColor }) => universityColor};
  margin-bottom: 1.5em;
  font-weight: 600;
  font-size: 24px;
`;

export const WrapperPasswords = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5em;
`;

export const WrapperAddress = styled.div`
  display: flex;
  gap: 1rem;
`;

export const IconTextErrorAddress = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;
export const IconSearchAddress = styled.div`
  position: absolute;
  top: 45px;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const InputVerifyPassword = styled.input`
  border: 1px solid #d0d0d0;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 4px;
  width: 100%;
  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const Label = styled.label`
  color: #6d6d6d;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 2px;
`;

export const WrapperVerifyPassword = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const ParagraphError = styled.p`
  color: #f00;
`;
export const ContainsH5 = styled.h5`
  color: ${({ universityColor }) => universityColor};
  font-weight: bold;
`;

export const WrapperVerifications = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 5px;
    > p {
      margin: 0;
    }
  }
`;

export const DivCommunication = styled.div`
  background-color: #eaeaea;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
  border-radius: 4px;
`;

export const StyledP = styled.p`
  margin-top: 7px;
`;

export const DivButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
`;

export const ButtonSave = styled.button`
  background-color: ${({ universityColor }) => universityColor};
  border: none;
  width: 240px;
  height: 50px;
  color: #fff;
  font-size: 15px;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
    color: #6d6d6d;
  }
`;

export const ButtonNext = styled.button`
  background-color: ${({ universityColor }) => universityColor};
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  background-image: url(${arrowRight});
  background-position: center;
  background-repeat: no-repeat;
`;

export const ButtonPrevious = styled.button`
  background-color: ${({ universityColor }) => universityColor};
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  background-image: url(${arrowLeft});
  background-position: center;
  background-repeat: no-repeat;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 12px;
  position: absolute;
  width: 85%;
  bottom: 20px;
`;

export const HeaderContainer = styled.div`
  background-color: ${(props) => props.universityColor || '#009291'};
  position: absolute;
  top: -70px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const HeaderText = styled.h3`
  color: #fff;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`;

export const IconCircle = styled.div`
  background-color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconText = styled.p`
  font-size: 16px;
  color: #fff;
`;

export const Button = styled.button`
  background-color: ${({ universityColor }) => universityColor};
  border: none;
  height: 40px;
  color: #fff;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  width: ${({ width }) => width};
`;
