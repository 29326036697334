import styled from 'styled-components';
import searchIcon from 'assets/mentorship/search.svg';

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
  }

  input {
    margin-top: 0.5rem;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: #606062;
    border: 0.64px solid ${(props) => (props.error ? '#DA0505' : '#c4c4c4')};

    &.zip_code {
      background-image: url(${searchIcon});
      background-repeat: no-repeat;
      background-position: 96%;
    }

    &::placeholder {
      color: #cdcdcd;
    }
  }
`;
