import React, { useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import './Reactpicker.css';
import { useField } from '@unform/core';
import { ActionButton, Container } from './styles';
import { addYears, getMonth, getYear } from 'date-fns';
import Select from './Select';
import { createGlobalStyle } from 'styled-components';

registerLocale('ptBR', ptBR);

export default function DatePickerCustom({
  placeholder,
  label,
  name,
  date,
  setDate,
  validateForm,
  ...rest
}) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (validateForm) {
      validateForm(error);
    }
  }, [date]);

  function range(start, end) {
    return Array.apply(start, Array(end - start + 1)).map(
      (element, index) => index + start
    );
  }

  const years = range(getYear(new Date()), getYear(new Date()) + 50, 1).map(
    (year) => {
      return { label: year, value: year };
    }
  );

  const months = [
    { value: 'january', label: 'Janeiro' },
    { value: 'february', label: 'Fevereiro' },
    { value: 'march', label: 'Março' },
    { value: 'april', label: 'Abril' },
    { value: 'may', label: 'Maio' },
    { value: 'june', label: 'Junho' },
    { value: 'july', label: 'Julho' },
    { value: 'august', label: 'Agosto' },
    { value: 'september', label: 'Setembro' },
    { value: 'october', label: 'Outubro' },
    { value: 'november', label: 'Novembro' },
    { value: 'december', label: 'Dezembro' },
  ];

  const DatePickerWrapperStyles = createGlobalStyle`
    .react-datepicker__input-container input {
        height: 39px;
    }
`;

  return (
    <Container>
      {label && <label>{label}</label>}
      <DatePicker
        className={
          error !== undefined
            ? 'react-datepicker__input-container ' +
              'react-datepicker__input-container-error'
            : 'react-datepicker__input-container red-border'
        }
        ref={datepickerRef}
        locale="ptBR"
        isClearable
        dateFormat="dd/MM/yyyy"
        minDate={new Date()}
        maxDate={addYears(new Date(), 50)}
        showDisabledMonthNavigation
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1).toUpperCase()}
        selected={date}
        placeholderText={placeholder}
        onChange={(date) => {
          setDate(date);
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: '10px 0',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <ActionButton
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />
            <Select
              options={months}
              selected={months[getMonth(date)].value}
              setValue={changeMonth}
              style={{ width: '100px' }}
            />
            <Select
              isYear
              options={years}
              selected={getYear(date)}
              setValue={changeYear}
              style={{ width: '60px' }}
              backgroundColor={'#FFF'}
            />
            <ActionButton
              type="button"
              isNext
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </div>
        )}
        {...rest}
      />
      <DatePickerWrapperStyles />
      {error && <span style={{ color: '#DA0505' }}>{error}</span>}
    </Container>
  );
}
