import React, { useEffect, useContext, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';

import {
  BubbleDialog,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  CardContainer,
  IconCards,
  CardItem,
  CardText,
  CloseButton,
  MissingCourseAlert,
} from './style';
import { IoClose } from 'react-icons/io5';
import WalWhat from 'assets/img/walWhat.svg';
import IconProfile from 'assets/student/profileIcon.svg';
import { BiLike } from 'react-icons/bi';
// Components
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import { Mixpanel } from 'utils/mixpanel';
import InterestForm from '../Interests/InterestForm';
import BehavioralTest from '../BehavioralTest/BehavioralTest';
import PersonalityTest from '../PersonalityTest/PersonalityTest';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

// Custom Styled Components
import BehavioralIcon from 'assets/ComponentIcons/CurriculumModalIcons/BehavioralIcon';
import ProfileIcon from 'assets/ComponentIcons/CurriculumModalIcons/ProfileIcon';
import { checkBasicInformations } from 'services/student/profile';
import SkeletonModal from './Skeleton';
import { CurriculumContext } from 'contexts/CurriculumContext';

export const CurriculumModal = (props) => {
  const {
    isOpen,
    setClose,
    lifeInterestList,
    interestList,
    studentProfileState,
    setCurriculumFilled,
    academicProfile,
    closeModal,
    handleIsLoadingState,
  } = props;
  const { name, city, state, email, mobilePhone, courses } = studentProfileState
    ? studentProfileState
    : {};
  const {
    fetchGamificationData,
    openModalWithContent,
    universityColor,
  } = useContext(ProfileContext);
  const [profileData, setProfileData] = useState(academicProfile);

  const [pendingItems, setPendingItems] = useState(null);

  const {
    updateAvatarState,
    updateProfileState,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  useEffect(() => {
    if (isOpen) Mixpanel.track('Apresentou modal de pendências no currículo');
  }, [isOpen]);

  useEffect(() => {
    const getBasicInformations = async () => {
      const response = await checkBasicInformations();
      setPendingItems(response);
    };
    getBasicInformations();
  }, []);

  useEffect(() => {
    if (
      !pendingItems?.personal_data &&
      !pendingItems?.life_interests &&
      !pendingItems?.career_interests &&
      pendingItems?.behavioral_test &&
      pendingItems?.personality_test
    ) {
      !!setCurriculumFilled && setCurriculumFilled(true);
      setClose();
    }
  }, [pendingItems]);

  const allCurriculumConditionsMet =
    pendingItems?.personal_data &&
    pendingItems?.life_interests &&
    pendingItems?.career_interests &&
    pendingItems?.behavioral_test &&
    pendingItems?.personality_test;

  return (
    <Wrapper visible={isOpen}>
      {pendingItems === null ? (
        <SkeletonModal />
      ) : (
        <Box>
          <Modal>
            <CloseButton onClick={() => setClose()}>
              <IoClose />
            </CloseButton>
            <WrapperTitle>
              <IconPersonStyled
                universityColor={universityColor}
                src={WalWhat}
                style={{ marginBottom: '13px' }}
              />
              <BubbleDialog universityColor={universityColor}>
                {allCurriculumConditionsMet && courses?.length === 0 ? (
                  <H4Styled>Atenção!</H4Styled>
                ) : (
                  <H4Styled>Ooops!</H4Styled>
                )}
              </BubbleDialog>
            </WrapperTitle>
            {allCurriculumConditionsMet && courses?.length === 0 ? (
              <ParagraphStyled>
                Você não possui curso definido. Por favor, entre em contato com
                sua Instituição de Ensino para fornecer essa informação e
                completar seu perfil.
              </ParagraphStyled>
            ) : (
              <ParagraphStyled>
                Senti falta de algumas informações para gerar o seu currículo.
                Abaixo fiz uma listinha pra te ajudar a preencher as informações
                que estão faltando. Bora lá?!
              </ParagraphStyled>
            )}

            <CardContainer>
              {!pendingItems?.personal_data && (
                <CardItem>
                  <ProfileIcon
                    color={universityColor || '#009291'}
                    src={IconProfile}
                    style={{ marginBottom: '14px' }}
                  />
                  <div
                    type="button"
                    onClick={() =>
                      openModalWithContent(
                        <ProfileForm
                          setProfileData={setProfileData}
                          handleIsLoadingState={handleIsLoadingState}
                          universityColor={universityColor}
                          updateProfileState={updateProfileState}
                          updateAvatarState={updateAvatarState}
                          closeModal={closeModal}
                          fetchGamificationData={fetchGamificationData}
                          profileData={profileData}
                          handleShowSpecialNeeds={handleShowSpecialNeeds}
                          {...props}
                        />,
                        'Edição Dados Pessoais'
                      )
                    }
                  >
                    <CardText>Dados pessoais</CardText>
                    <span>
                      {!name && city && state && email && mobilePhone
                        ? ' Nome'
                        : !name && ' Nome |'}
                      {!city && name && state && email && mobilePhone
                        ? ' Cidade'
                        : !city && ' Cidade |'}
                      {!state && city && name && email && mobilePhone
                        ? ' Estado'
                        : !state && ' Estado |'}
                      {!email && name && city && state && mobilePhone
                        ? ' E-mail'
                        : !email && ' E-mail |'}
                      {!mobilePhone && ' Celular'}
                    </span>
                  </div>
                </CardItem>
              )}

              {!pendingItems?.life_interests && (
                <CardItem universityColor={universityColor}>
                  <i>
                    <BiLike color="#fff" size={30} />
                  </i>
                  <CardText
                    type="button"
                    onClick={() =>
                      openModalWithContent(
                        <InterestForm
                          {...props}
                          fetchGamificationData={fetchGamificationData}
                          selectedInterests={lifeInterestList}
                          lifeInterest
                        />,
                        'Interesses de Vida',
                        null,
                        () => null,
                        false,
                        'lifeInterest'
                      )
                    }
                  >
                    Interesses de vida
                  </CardText>
                </CardItem>
              )}

              {!pendingItems?.career_interests && (
                <CardItem universityColor={universityColor}>
                  <i>
                    <BiLike color="#fff" size={30} />
                  </i>
                  <CardText
                    type="button"
                    onClick={() =>
                      openModalWithContent(
                        <InterestForm
                          {...props}
                          fetchGamificationData={fetchGamificationData}
                          selectedInterests={interestList}
                        />,
                        'Interesses de Carreira',
                        null,
                        () => null,
                        false,
                        'careerInterest'
                      )
                    }
                  >
                    Interesses de carreira
                  </CardText>
                </CardItem>
              )}

              {!pendingItems?.behavioral_test && (
                <CardItem>
                  <BehavioralIcon color={universityColor || '#009291'} />
                  <CardText
                    type="button"
                    onClick={() => {
                      openModalWithContent(
                        <BehavioralTest
                          fetchGamificationData={fetchGamificationData}
                          {...props}
                        />,
                        'Questionário de perfil comportamental',
                        null,
                        () => null,
                        false,
                        'behavioral'
                      );
                      setClose();
                    }}
                  >
                    Perfil comportamental
                  </CardText>
                </CardItem>
              )}

              {!pendingItems?.personality_test && (
                <CardItem>
                  <BehavioralIcon color={universityColor || '#009291'} />
                  <CardText
                    type="button"
                    onClick={() => {
                      openModalWithContent(
                        <PersonalityTest
                          fetchGamificationData={fetchGamificationData}
                          {...props}
                        />,
                        'Questionário de Personalidade',
                        null,
                        () => null,
                        false,
                        'personality'
                      );
                      setClose();
                    }}
                  >
                    Sua personalidade
                  </CardText>
                </CardItem>
              )}
            </CardContainer>
            {!allCurriculumConditionsMet && courses?.length === 0 && (
              <MissingCourseAlert>
                <strong>Atenção:</strong> Você não possui curso definido. Por
                favor, informe sua instituição para completar seu perfil.
              </MissingCourseAlert>
            )}
          </Modal>
        </Box>
      )}
    </Wrapper>
  );
};

CurriculumModal.defaultProps = {
  isMyProfile: false,
  isFromJobOffers: false,
  personalityTestIsDone: false,
  behavioralTestIsDone: false,
};

CurriculumModal.propTypes = {
  isMyProfile: bool,
  isFromJobOffers: bool,
  interests: arrayOf({
    label: string,
    value: number,
  }).isRequired,
  life_interests: arrayOf({
    label: string,
    value: number,
  }).isRequired,
  personalityTestIsDone: bool,
  behavioralTestIsDone: bool,
  studentProfileState: shape({}).isRequired,
  interestList: arrayOf({}).isRequired,
  lifeInterestList: arrayOf({}).isRequired,
  openModalWithContent: func.isRequired,
  setClose: func.isRequired,
  isOpen: bool.isRequired,
};
