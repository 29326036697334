import styled from 'styled-components';

import morningIcon from 'assets/mentorship/morning.svg';
import morningActiveIcon from 'assets/mentorship/morning-active.svg';
import afternoonIcon from 'assets/mentorship/afternoon.svg';
import afternoonActiveIcon from 'assets/mentorship/afternoon-active.svg';
import eveningIcon from 'assets/mentorship/evening.svg';
import eveningActiveIcon from 'assets/mentorship/evening-active.svg';

import dropdownIcon from 'assets/mentorship/dropdown.svg';
import activeDropdownIcon from 'assets/mentorship/dropdown-active.svg';

export const Container = styled.div`
  background-color: #fff;
  height: 1.5rem;
  width: 6.5rem;
  border-radius: 0.9375rem;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-direction: column;
  margin: -2.9375rem auto 0;
  z-index: 2;
  display: ${(props) => (props.isDaySelected ? 'flex' : 'none')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  margin: auto auto;
  gap: 0.375rem;
  position: relative;

  p {
    margin: 0 0 0 -0.5rem;
    height: 1.5rem;
    font-weight: 400;
    color: #4b4b4d;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9375rem;
    padding-top: 0.15rem;
  }
`;

export const DropdownIcon = styled.div`
  position: absolute;
  right: 1rem;
  width: 9px;
  height: 6px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.open ? activeDropdownIcon : dropdownIcon});
`;

function getIcon(period, selected) {
  if (selected) {
    switch (period) {
      case 'morning':
        return morningActiveIcon;
      case 'afternoon':
        return afternoonActiveIcon;
      default:
        return eveningActiveIcon;
    }
  } else {
    switch (period) {
      case 'morning':
        return morningIcon;
      case 'afternoon':
        return afternoonIcon;
      default:
        return eveningIcon;
    }
  }
}

export const ItemIcon = styled.div`
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
`;

export const List = styled.div`
  margin-top: 0.375rem;
  width: 7.5rem;
  height: 6.875rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  z-index: 2;
  margin-left: 1rem;
`;

export const ListItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: auto auto;
  padding: 0.5rem 1rem;
  gap: 0.375rem;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }

  span {
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.125rem;
    font-family: 'Roboto', sans-serif;
    color: ${(props) => (props.selected ? props.universityColor : '#4B4B4D')};
  }
`;

export const PeriodsList = styled.div`
  position: absolute;
  left: 0.5rem;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.375rem;
  width: 70px;
`;

export const PeriodIcon = styled.div`
  margin: 0;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-image: url(${(props) => getIcon(props.period, false)});
  background-size: contain;
`;
