import styled from 'styled-components';
//modal mentorship scheduling
export const Container = styled.div`
  width: 720px;
  max-width: 100%;
  z-index: 2000;

  h1 {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    line-height: 1.4375rem;
    font-size: 1.375rem;
    font-family: Roboto, sans-serif;
    margin: 0.5rem 0 10px 0;
    font-weight: bold;
    padding: 0 2.5rem 0 2.5rem;
  }

  > p {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 400;
    color: #4b4b4d;
    text-align: justify;
    padding: 0 2.5rem 0 2.5rem;

    &:first-of-type {
      margin-bottom: 46px;
      max-width: 645px;
    }

    &:last-of-type {
      line-height: 1rem;
      color: #8b8b8b;
      margin-bottom: 0.5rem;
    }
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    color: #4b4b4d;
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0 0 2.5rem 0;
    font-weight: 400;
    padding: 0 2.5rem 0 2.5rem;
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 18px;
      text-align: center;
    }

    p {
      font-size: 14px;
      text-align: center;

      &:nth-child(3n) {
        font-size: 14px;
      }

      &:last-of-type {
        font-size: 14px;
        text-align: left;
      }
    }

    h2 {
      font-size: 16px;
      text-align: center;
      color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
    }
  }
`;

export const ListRequirements = styled.div`
  display: flex;
  gap: 2.5rem;
  width: 100%;
  padding: 0 2.5rem 0 2.5rem;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    padding: 0 2.5rem 0 2.5rem;
  }
`;

export const Requirement = styled.div`
  position: relative;
  height: 14rem;
  width: 14rem;
  border-radius: 0.625rem;
  border-color: #cdcdcd;
  border-style: solid;
  border-width: 1px;
  padding: 1.375rem;

  div:first-of-type {
    display: flex;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
    color: #fff;
    font-weight: 700;
    background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    margin-bottom: 0.875rem;
  }

  strong {
    font-family: 'Roboto', sans-serif;
    font-size: 1.125rem;
    color: #4b4b4d;
    line-height: 1.375rem;
    font-weight: 600;
  }

  span {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: #8b8b8b;
    line-height: 110%;
    margin-top: 0.6875rem;
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    gap: 2.5rem;
    div:first-of-type {
      height: 1.25rem;
      width: 1.25rem;
      font-size: 12px;
    }

    strong {
      font-size: 14px;
    }

    span {
      font-size: 10px;
    }
  }
`;

export const Status = styled.div`
  position: absolute;
  bottom: 0;
  margin-top: auto;
  margin-bottom: 1.25rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${(props) =>
    props.value === undefined
      ? '#8B8B8B'
      : !!props.value
      ? '#068E2C'
      : '#DA0505'};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5rem;

  p {
    font-size: 0.875rem;
    color: #8b8b8b;
    line-height: 1rem;
    max-width: 470px;
    text-align: justify;
    padding: 0;
  }

  @media (max-width: 600px) {
    gap: 1rem;
    flex-direction: column;
  }
`;
