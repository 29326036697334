import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  width: 720px;
  height: auto;
  padding: 0 2rem;
  background-color: #fff;
  border-radius: 10px;
`;

export const MentorshipSkeleton = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem 0;

  > div {
    display: flex;
    flex-direction: column;
    margin: 0 1rem 0 0;

    > div {
      display: flex;
      gap: 1rem;
      margin: 11px 0 0 0;
    }
  }
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const Divider = styled.div`
  content: '';
  width: 100%;
  height: 2px;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const ProfileImage = styled.div`
  width: 124px;
  height: 76px;
  border-radius: 40px;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const ScheduleButton = styled.div`
  border: none;
  width: 165px;
  height: 30px;
  border-radius: 22.54px;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const StatusIndicator = styled.div`
  border-radius: 3.39px;
  padding: 0 1rem;
  font-weight: 400;
  height: 23px;
  width: 146px;
  margin: 0 0 20px 0;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const ExpandIcon = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;
