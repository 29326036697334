import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import {
  ContainerLegend,
  ContainerToolTip,
  GraphicCapsule,
  HeaderToolTip,
  Paragraph,
  ToolTipP,
} from '../../Styles/StyleGraphics';
import { formatNumber } from 'utils/formatNumber';

export function CustomLineChart({ graphicalData }) {
  const { chart, total_actives, total_curriculum } = graphicalData;
  const [filteredData, setFilteredData] = useState(chart);

  const legendLinearChart = {
    activeStudents: `Estudantes ativos: ${formatNumber(total_actives)}`,
    cvStudents: `Estudantes com currículo: ${formatNumber(total_curriculum)}`,
  };

  const customLegend = ({ payload }) => {
    return (
      <ContainerLegend>
        {payload.map((item) => (
          <Paragraph color={item.color}>{item.payload.legend}</Paragraph>
        ))}
      </ContainerLegend>
    );
  };

  const customTooltip = (props) => {
    if (props?.active) {
      const colorActiveStudents = props?.payload[0]?.color;
      const colorActiveStudentsCurriculum = props?.payload[1]?.color;

      return (
        <ContainerToolTip>
          <HeaderToolTip>
            <p>{props?.label}</p>
          </HeaderToolTip>
          <ToolTipP color={colorActiveStudents}>
            Ativos: {formatNumber(props?.payload[0]?.value)}
          </ToolTipP>
          <ToolTipP color={colorActiveStudentsCurriculum}>
            Com currículo: {formatNumber(props?.payload[1]?.value)}
          </ToolTipP>
        </ContainerToolTip>
      );
    }
    return null;
  };

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          y={10}
          textAnchor="middle"
          verticalAnchor="middle"
          fill="#666"
          width={100}
        >
          {payload.value}
        </Text>
      </g>
    );
  };

  const CustomTickFormatter = (tick) => {
    const number = formatNumber(tick);
    return Number.isInteger(tick) ? number : '';
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      let maxItems;

      if (screenWidth < 1100) {
        maxItems = 5;
      } else if (screenWidth < 1200) {
        maxItems = 7;
      } else if (screenWidth < 1300) {
        maxItems = 10;
      } else {
        maxItems = chart.length;
      }

      setFilteredData(chart.slice(-maxItems));
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chart]);

  return (
    <GraphicCapsule heightGraphic={311}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={filteredData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid
            strokeDasharray="4"
            horizontal="true"
            vertical="false"
          />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            tick={CustomizedXAxisTick}
            tickFormatter={CustomTickFormatter}
          />
          <YAxis
            style={{ fontWeight: 500 }}
            tickLine={false}
            axisLine={false}
            tickFormatter={CustomTickFormatter}
          />
          <Tooltip content={customTooltip} />
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="left"
            content={customLegend}
          />
          <Line
            type="monotone"
            dataKey="active_students"
            stroke="#009291"
            strokeWidth={2}
            dot={false}
            animationDuration={1000}
            legend={legendLinearChart.activeStudents}
          />
          <Line
            type="monotone"
            dataKey="curriculum_students"
            stroke="#8BD3D3"
            strokeWidth={2}
            dot={false}
            legend={legendLinearChart.cvStudents}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphicCapsule>
  );
}
