import styled from 'styled-components';
import arrowRight from 'assets/Oportutinites/Setadireita.svg';
//background mentoring modal arrow
export const NextButton = styled.button`
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 2.5rem;
  bottom: 2.5rem;
  border: none;
  padding: 0;
  cursor: pointer;
  background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'}
    url(${arrowRight}) no-repeat center;
`;
