import styled from 'styled-components';

export const TextInput = styled.p`
  margin: 0;
  align-self: center;
  text-align: center;
  width: 100%;
`;

export const ImgUrl = styled.img`
  margin: 0;
  align-self: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
`;

export const EditImg = styled.p`
  margin: 0;
  align-self: center;
  width: 100%;
  height: 35px;
  border-radius: ${(props) => props.radius || '25px'};
  border: none;
  background-color: #009291;
  color: white;
  font-family: roboto;
  font-size: 18px;
  font-weight: 800;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const DivInsertImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1103;
`;

export const FragmentModal = styled.div`
  width: 900px;
  height: 700px;
  background: #ffffff;
  border-radius: 15px;
`;

export const Title = styled.p`
  font-family: roboto;
  font-size: 25px;
  font-weight: 700;
  color: #009291;
  padding: 1rem;
  margin: 0;
`;

export const FrangmentAvatar = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: #dcdcdc;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

export const Buttons = styled.div`
  display: flex;
`;

export const FragmentZoom = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelZomm = styled.p`
  color: #606062;
  font-family: Roboto;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
`;

export const InputZomm = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  width: 200px;
  height: 7px;
  background: #d9d9d9;
  border-radius: 5px;
  background-image: linear-gradient(#009291, #009291);
  background-size: ${(props) => `${(props.value - 1) * 100}%`} 100%;
  background-repeat: no-repeat;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #009291;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 ##009291;
    transition: background 0.3s ease-in-out;
  }
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const Button = styled.p`
  border: none;
  width: 100%;
  height: 35px;
  min-width: 150px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.p`
  color: #606062;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DivAlterImg = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
