

export default function Calendar ({color}) {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.1493 7.52012C28.135 4.68947 25.8333 2.14473 22.6023 2.15903C22.3021 2.15903 22.0019 2.15903 21.6588 2.15903C21.6588 1.81592 21.6588 1.50141 21.6588 1.18689C21.6588 0.50067 21.187 0.000298724 20.558 0.000298724C19.9289 0.000298724 19.4715 0.500666 19.4715 1.20118C19.4715 1.5157 19.4715 1.81592 19.4715 2.14473H8.69211C8.69211 1.81592 8.69211 1.50141 8.69211 1.17259C8.69211 0.486372 8.20604 -0.0139975 7.577 0.000298724C6.94797 0.014595 6.50479 0.500669 6.49049 1.17259C6.49049 1.48711 6.49049 1.80163 6.49049 2.17333C5.99012 2.17333 5.50405 2.14474 5.03227 2.17333C2.47325 2.34488 0.385997 4.31776 0.042887 6.86249C0.042887 6.91968 0.0142962 6.96257 0 7.01975V23.2317C0.0428887 23.4461 0.0857765 23.6606 0.128665 23.875C0.64333 26.1338 2.48754 27.7922 4.78924 28.0781C4.84642 28.0781 4.88931 28.1067 4.94649 28.121H23.2028C23.4601 28.0638 23.7175 28.0209 23.9748 27.9637C26.3766 27.4062 28.135 25.2332 28.1493 22.7742C28.1636 17.7133 28.1636 12.6096 28.1493 7.52012ZM25.9477 11.1943C25.9477 14.9828 25.9477 18.7713 25.9477 22.5741C25.9477 24.5469 24.5609 25.948 22.5737 25.948C16.8981 25.948 11.2368 25.948 5.56123 25.948C3.58835 25.948 2.18732 24.5469 2.18732 22.5741C2.18732 18.7856 2.18732 14.9971 2.18732 11.1943V10.8654H25.9334V11.1943H25.9477ZM25.9334 8.63522H2.23021C1.84421 5.73309 3.70272 4.01754 6.49049 4.40354C6.49049 5.10406 6.49049 5.80457 6.49049 6.50509C6.50479 7.2056 7.09093 7.69167 7.77715 7.5916C8.29181 7.50582 8.67781 7.06264 8.67781 6.50509C8.69211 5.90464 8.67781 5.28991 8.67781 4.68947V4.37495H19.4429C19.4429 4.46073 19.4572 4.5608 19.4572 4.64658C19.4572 5.24702 19.4572 5.86176 19.4572 6.4622C19.4572 7.13412 19.9289 7.6059 20.5723 7.6059C21.2013 7.6059 21.6588 7.11983 21.6588 6.4765C21.6588 5.79028 21.6588 5.10405 21.6588 4.41783C24.0606 4.03184 26.3051 5.37568 25.9334 8.63522Z" fill={color}/>
      <path d="M9.76465 21.6446C9.76465 22.2308 9.30717 22.7311 8.69243 22.7454C7.94903 22.7597 7.20562 22.7597 6.47651 22.7454C5.87607 22.7311 5.4043 22.2308 5.4043 21.6446C5.4043 21.0585 5.87607 20.5724 6.49081 20.5581C6.86251 20.5438 7.23422 20.5581 7.62021 20.5581C7.97762 20.5581 8.33503 20.5438 8.69243 20.5581C9.29287 20.5724 9.76465 21.0442 9.76465 21.6446Z" fill={color}/>
      <path d="M22.7457 21.6589C22.7457 22.2451 22.274 22.7311 21.6592 22.7454C20.9301 22.7597 20.201 22.7597 19.4576 22.7454C18.8429 22.7311 18.3854 22.2451 18.3711 21.6589C18.3711 21.0585 18.8429 20.5724 19.4719 20.5581C19.8436 20.5438 20.201 20.5581 20.5727 20.5581C20.9301 20.5581 21.2875 20.5581 21.6449 20.5581C22.274 20.5724 22.76 21.0585 22.7457 21.6589Z" fill={color}/>
      <path d="M22.7451 15.1544C22.7594 15.7548 22.2733 16.2552 21.6586 16.2695C20.9295 16.2838 20.2147 16.2838 19.4856 16.2695C18.8565 16.2552 18.3848 15.7691 18.3848 15.1687C18.3848 14.5682 18.8708 14.0822 19.4856 14.0679C19.8573 14.0679 20.2147 14.0679 20.5864 14.0679C20.9438 14.0679 21.3012 14.0679 21.6586 14.0679C22.259 14.0965 22.7451 14.5682 22.7451 15.1544Z" fill={color}/>
      <path d="M9.76496 15.1687C9.76496 15.7691 9.29318 16.2552 8.66414 16.2695C8.30674 16.2838 7.94933 16.2695 7.59193 16.2695C7.23452 16.2695 6.87711 16.2695 6.51971 16.2695C5.87638 16.2552 5.39031 15.7691 5.4046 15.1544C5.4189 14.554 5.90497 14.0822 6.51971 14.0679C7.23452 14.0679 7.94933 14.0536 8.66414 14.0679C9.29318 14.0822 9.76496 14.5682 9.76496 15.1687Z" fill={color}/>
      <path d="M16.2544 15.1828C16.2544 15.7833 15.7826 16.2694 15.1822 16.2694C14.4531 16.2837 13.724 16.2837 12.9806 16.2694C12.3801 16.2551 11.894 15.769 11.894 15.1828C11.894 14.5824 12.3658 14.0963 12.9948 14.082C13.3665 14.0677 13.724 14.082 14.0957 14.082C14.4531 14.082 14.8105 14.082 15.1679 14.082C15.7826 14.082 16.2544 14.5681 16.2544 15.1828Z" fill={color}/>
      <path d="M16.2546 21.6447C16.2546 22.2452 15.7828 22.7312 15.1537 22.7455C15.0251 22.7455 14.8964 22.7455 14.7677 22.7455C14.6248 22.7455 14.4675 22.7455 14.3246 22.7455C14.2388 22.7455 14.153 22.7455 14.0672 22.7455C13.9672 22.7455 13.8528 22.7455 13.7527 22.7455C13.5097 22.7455 13.2666 22.7455 13.0236 22.7455C12.3946 22.7312 11.8942 22.2452 11.9085 21.6304C11.9228 21.03 12.3946 20.5582 13.0236 20.5439C13.7384 20.5296 14.4532 20.5296 15.168 20.5439C15.7685 20.5725 16.2403 21.0443 16.2546 21.6447Z" fill={color}/>
    </svg>
  )
}