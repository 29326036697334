import styled from 'styled-components';
import activeDropdownIcon from 'assets/mentorship/dropdown-active.svg';
import dropdownIcon from 'assets/mentorship/dropdown.svg';

export const Dropdown = styled.div`
  background-color: #fff;
  margin-top: 0.5rem;
  height: 38px;
  border-radius: ${(props) => (props.open ? '0.25rem 0.25rem 0 0' : '0.25rem')};
  border: 0.64px solid #c4c4c4;
  border-bottom: ${(props) => (props.open ? 'none' : undefined)};
  margin: 0 2.5rem;
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.625rem;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  p {
    font-weight: 400;
    color: #4b4b4d;
    font-family: 'Roboto', sans-serif;
    font-size: 0.9375rem;
    margin: 0;
  }

  position: relative;
`;

export const DropdownHeaderIcon = styled.div`
  position: absolute;
  right: 0.5rem;
  width: 9px;
  height: 6px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) =>
    props.open ? activeDropdownIcon : dropdownIcon});
`;

export const List = styled.div`
  border-radius: 0 0 0.25rem 0.25rem;
  border: 0.64px solid #c4c4c4;
  border-top: 2px solid #c4c4c4;
`;

export const ListItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: auto auto;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  position: relative;
  z-index: 3;

  &:hover {
    filter: brightness(0.9);
  }

  span {
    text-align: center;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.125rem;
    font-family: 'Roboto', sans-serif;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#4B4B4D'};
  }
`;
