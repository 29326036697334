import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 720px;

  > h1 {
    color: #009291;
    line-height: 24px;
    font-size: 21px;
    margin: 2rem 0 0;
    width: 100%;
    font-weight: 700;
  }

  > p {
    font-size: 14px;
    line-height: 16px;
    color: #606062;
    font-weight: 400;
    margin: 1rem 0 0;
    width: 100%;
  }

  > form {
    display: ${(props) => (props.modality === 'presential' ? 'grid' : 'flex')};
    flex-direction: column;
    grid-template: ${(props) =>
      props.isZipCodeFetched
        ? `'modality cep' 'address address_number' 'address_complement district' 'city state' 'footer footer'`
        : `'modality cep' 'footer footer'`};
    gap: 2rem;
    margin: 2rem 0 0;
    max-width: 100%;

    div.access_link {
      display: block;
    }
  }
`;

export const ModalitySelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.modality === 'presential' ? '100%' : 'calc((100% - 32px)/2)'};
  grid-area: modality;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
  }
`;

export const ModalityLegend = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: #606062;
  font-weight: 700;
  margin-top: 10px;

  > span {
    font-size: 12px;
    line-height: 14px;
    color: #606062;
    font-weight: 400;
  }
`;

export const StudentNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 0 0;
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #009291;
  flex-direction: column;

  strong {
    color: #606062;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
  }

  p {
    font-size: 14px;
    line-height: 16px;
    color: #4b4b4d;
    font-weight: 400;
    margin: 10px 0 0 0;
  }
`;
