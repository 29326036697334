import React, { useState, useRef, useEffect, useContext } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import {
  Container,
  Header,
  Wrapper,
  WrapperButton,
  LanguageButton,
  HeaderSelectLanguages,
  ProficiencyTestButton,
} from './Styled';

import LanguageCardItem from './LanguageCardItem';
import SearchableLanguage from './SearchableLanguage';

import {
  getLanguages,
  getOptionsLanguages,
  saveLanguage,
  updateLanguage,
} from '../../../../services/student/languages';

import BaseLayoutContext from 'contexts/base-layout';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

function LanguageCardForm({
  closeModal,
  isEdit = false,
  handleIsLoadingState,
  languagesState,
  dataLanguage,
  setOpenModal,
  setCardData,
  cardDataKey,
  fetchGamificationData,
}) {
  const formRef = useRef();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const { universityColor } = useContext(BaseLayoutContext);

  const validateLanguageData = async (data) => {
    try {
      const schema = Yup.object().shape({
        language: Yup.string()
          .required('Este campo é obrigatório')
          .typeError('Este campo é obrigatório'),
        fluence_level: Yup.string()
          .required('Informe seu nível de conhecimento para seguir em frente')
          .typeError('Informe seu nível de conhecimento para seguir em frente'),
      });
      const validatedData = await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      setIsSubmitted(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  async function handleSubmit(data) {
    if (isSubmitted) {
      return;
    }
    setIsSubmitted(true);
    const validatedData = await validateLanguageData(data);
    if (validatedData) {
      handleIsLoadingState(true);
      isEdit
        ? await updateLanguage(validatedData, dataLanguage.id)
        : await saveLanguage(validatedData);
      const response = await getLanguages();
      cardDataKey &&
        setCardData((prev) => ({ ...prev, [cardDataKey]: response }));
      await fetchGamificationData();
      handleIsLoadingState(false);
      closeModal();
      customSnackbar('Idioma cadastrado com sucesso!', 'confirmation');
    }
  }

  const [optionsLanguages, setOptionsLanguages] = useState([]);

  useEffect(() => {
    async function getLanguages() {
      const response = await getOptionsLanguages();
      const options = response.actions.POST.language.choices;
      setOptionsLanguages(options);
    }

    getLanguages();
  }, []);

  function filteredOptions() {
    if (isEdit) {
      return optionsLanguages.filter((lang) => {
        return !languagesState.some((item) => {
          return (
            lang.value === item.language.value &&
            dataLanguage.language.value !== lang.value
          );
        });
      });
    }
    return optionsLanguages.filter((lang) => {
      return !languagesState.some((item) => {
        return lang.value === item.language.value;
      });
    });
  }

  const dataCards = [
    {
      id: 'basic',
      title: 'Básico - A1',
      description: 'Você já pode formar e entender questões simples.',
    },
    {
      id: 'elementary',
      title: 'Elementar - A2',
      description:
        'Você pode entender simples artigos de jornal, escrever cartas e fazer declarações espontâneas.',
    },
    {
      id: 'intermediary',
      title: 'Intermediário - B1',
      description:
        'Você tem conhecimento prévio detalhado de gramática e vocabulário.',
    },
    {
      id: 'advanced',
      title: 'Avançado - B2',
      description:
        'Você pode apresentar tópicos específicos e conversar praticamente sobre qualquer assunto.',
    },
    {
      id: 'fluent',
      title: 'Fluente - C1',
      description:
        'Você tem técnicas especiais de vocabulário e gramática. Você pode se comunicar em alto nível.',
    },
    {
      id: 'academic',
      title: 'Acadêmico - C2',
      description:
        'Você tem o conhecimento perfeito de vocabulário e gramática. Você se comunica quase como um falante nativo.',
    },
  ];

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={languagesState}>
      <Container>
        <Header universityColor={universityColor}>
          <h1>Idioma</h1>
          <p>
            Selecione o idioma que você domina e classifique o seu nível de
            conhecimento
          </p>
        </Header>

        <Wrapper>
          <HeaderSelectLanguages>
            <p>Idioma</p>
          </HeaderSelectLanguages>
          <SearchableLanguage
            name="language"
            options={filteredOptions()}
            isEdit={isEdit}
            dataLanguage={dataLanguage}
            placeholder={dataLanguage && dataLanguage.language.label}
            onChange={(selectedOption) => {
              setSelectedLanguage(selectedOption.value);
            }}
          />
        </Wrapper>
        <WrapperButton>
          {selectedLanguage === 'english' ||
          (dataLanguage && dataLanguage.language.value === 'english') ? (
            <ProficiencyTestButton
              type="button"
              universityColor={universityColor}
              onClick={() => setOpenModal(true)}
            >
              Realizar teste de proficiência
            </ProficiencyTestButton>
          ) : null}
        </WrapperButton>
        <h2>Classifique o seu nível de conhecimento</h2>
        <Wrapper>
          <LanguageCardItem
            universityColor={universityColor}
            name="fluence_level"
            options={dataCards}
            dataLanguage={dataLanguage}
            isEdit={isEdit}
          />
        </Wrapper>

        <WrapperButton>
          <LanguageButton universityColor={universityColor} type="submit">
            Cadastrar idioma
          </LanguageButton>
        </WrapperButton>
      </Container>
    </Form>
  );
}

export default LanguageCardForm;
