export default function CheckIcon({ size = 100, color = 'white' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.0587 49.9921C95.0587 45.6862 100.929 40.6268 99.867 36.6367C98.7618 32.503 91.1189 31.0821 89.0305 27.4652C86.9422 23.8482 89.4682 16.478 86.49 13.4998C83.5118 10.5215 76.199 13.0835 72.5247 10.9665C68.8503 8.84942 67.5011 1.23519 63.3603 0.130019C59.3702 -0.939273 54.3251 4.93825 49.9977 4.93825C45.6703 4.93825 40.6253 -0.939273 36.6352 0.130019C32.5087 1.23519 31.0806 8.87095 27.4636 10.9665C23.8467 13.062 16.4765 10.5215 13.4983 13.4998C10.52 16.478 13.0892 23.798 10.965 27.4652C8.84072 31.1323 1.23369 32.4887 0.128514 36.6367C-0.933602 40.6268 4.93675 45.6647 4.93675 49.9921C4.93675 54.3195 -0.933602 59.3645 0.128514 63.3546C1.23369 67.4883 8.8766 68.9092 10.965 72.5262C13.0533 76.1431 10.5272 83.5061 13.5054 86.4915C16.4837 89.477 23.7965 86.9078 27.4708 89.0248C31.1452 91.1419 32.4943 98.7561 36.6352 99.8613C40.6253 100.931 45.6703 95.0531 49.9977 95.0531C54.3251 95.0531 59.3702 100.931 63.3603 99.8613C67.4868 98.7561 68.9149 91.1204 72.5318 89.0248C76.1488 86.9293 83.519 89.4698 86.4972 86.4915C89.4754 83.5133 86.9063 76.1933 89.0305 72.5262C91.1547 68.859 98.7618 67.5026 99.867 63.3546C100.951 59.3645 95.0587 54.3195 95.0587 49.9921Z"
        fill={color}
      />
      <path
        d="M49.3743 68.0553L29.9045 47.4373C29.4572 46.9615 29.2173 46.3274 29.2375 45.6747C29.2577 45.022 29.5364 44.404 30.0122 43.9567C30.488 43.5094 31.122 43.2695 31.7748 43.2897C32.4275 43.3099 33.0455 43.5885 33.4927 44.0644L48.7643 60.2616L68.0689 32.2734C68.453 31.7722 69.0151 31.4377 69.6389 31.3393C70.2627 31.2408 70.9005 31.3858 71.4203 31.7444C71.9402 32.1029 72.3023 32.6476 72.4319 33.2656C72.5615 33.8837 72.4485 34.5279 72.1165 35.0651L49.3743 68.0553Z"
        fill="white"
      />
    </svg>
  );
}
