import styled from 'styled-components';
export const HeaderModal = styled.div`
  width: 375px;
  width: 100%;

  > h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    width: 95%;
    font-weight: normal;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    color: #4b4b4d;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  height: auto;

  > div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 0.5rem 0;
    @media (max-width: 600px) {
      justify-content: space-around;
    }
  }

  > span {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    width: 100%;
    font-weight: normal;
    color: #606062;
    margin: 2rem 0 0 0;
  }

  > textarea {
    margin: 22px auto 0;
    width: 95%;
    resize: none;
    border-color: #c4c4c4;
    border-radius: 4.5px;
    padding: 0.5rem;
    color: #000;
  }

  p {
    text-align: left;
    color: #606062;
    font-size: 10px;
    width: 90%;
    font-family: 'Roboto', sans-serif;
    text-align: left;
  }
`;

export const ResponseButton = styled.button`
  width: 45%;
  background-color: ${(props) =>
    props.state === true ? props.universityColor : '#FFF'};
  color: ${(props) =>
    props.state === undefined
      ? props.universityColor
      : props.state === true
      ? '#FFF'
      : '#C4C4C4'};
  font-family: 'Roboto', sans-serif;
  height: 2rem;
  border-radius: 4.2px;
  border: ${(props) => (props.state === true ? 'none' : '1px solid')};
  border-color: ${(props) =>
    props.state === undefined
      ? props.universityColor
      : props.state === true
      ? '#FFF'
      : '#C4C4C4'};
  cursor: pointer;
`;

export const SendButton = styled.button`
  width: 90%;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #fff;
  font-family: 'Roboto', sans-serif;
  height: 38px;
  border-radius: 22.8px;
  border: none;
  margin: 2rem 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
