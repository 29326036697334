import Select, { components } from 'react-select';
import React from 'react';
import { SelectFilterBox } from '../styles';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';

export function FilterSingleSelect({ name, options }) {
  const { filterState, updateFilterState } = useFilterContext();

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#9FD6D6',
        primary50: '#80C9C8',
        primary: '#009291',
      },
    };
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <SelectFilterBox>
      <Select
        name={name}
        placeholder="Selecione"
        options={options}
        theme={selectTheme}
        components={{ NoOptionsMessage }}
        value={options.find((option) => option.value === filterState[name])}
        styles={selectStyles}
        onChange={(selected) =>
          updateFilterState(
            selected ? selected.value : null,
            name,
            FilterType.SINGLE_SELECT
          )
        }
        isClearable
      />
    </SelectFilterBox>
  );
}
