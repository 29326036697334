import React, { useCallback, useState, Fragment } from 'react';
import emailIcon from 'assets/mentorship/email-icon.svg';
import phoneIcon from 'assets/mentorship/phone-icon.svg';
import courseIcon from 'assets/mentorship/course-icon.svg';
import jobIcon from 'assets/mentorship/job-icon.svg';
import locationIcon from 'assets/mentorship/location-icon.svg';
import placeholder from 'assets/img/placeholder.jpg';

import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';

import {
  ArrowRightIcon,
  ButtonSkeleton,
  Detail,
  DocButtons,
  ExpandedContent,
  ExpandIcon,
  InformationIcon,
  OutlinedButton,
  ProfileImage,
  ScheduleButton,
  Summary,
} from './styles';
import { fetchStudentProfile } from 'services/university/mentorship';
import { useMentorship } from '../../contexts/MentorshipContext';
import { getStudentCurriculumData } from 'services/university/curriculum';
import { getApplicationCoverLetter } from 'services/university/coverLetter';
import { SkeletonBox, StatusIndicator } from '../../styles';
import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';

export default function MentorshipTile({
  mentorship,
  openModalWithContent,
  handleIsLoadingState,
  openSnackbar,
  closeModal,
  updateHasCoverLetter,
}) {
  const [studentProfile, setStudentProfile] = useState(null);
  const [isFetchingStudentProfile, setIsFetchingStudentProfile] = useState(
    true
  );
  const [isOpeningModal, setIsOpeningModal] = useState(false);

  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);

  const {
    openScheduleMentorshipModal,
    openDetailMentorshipModal,
    openAdjustMentorshipStatusModal,
    openRescheduleMentorshipModal,
  } = useMentorship();

  function getDate() {
    const date = new Date(mentorship.updated_at).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
    const time = new Date(mentorship.updated_at).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${date} às ${time}`;
  }

  async function handleExpand() {
    if (studentProfile === null) {
      setIsFetchingStudentProfile(true);
      const response = await fetchStudentProfile(mentorship.id);
      setStudentProfile(response);
      setIsFetchingStudentProfile(false);
    }
  }

  function getProfessionalStatus() {
    let status = studentProfile.professional_status;
    if (status !== 'Desempregado(a)') {
      status = `${status} - ${
        studentProfile.professional_working_in_formation_area
          ? 'Na área de formação'
          : 'Fora da área de formação'
      }`;
    }
    return status;
  }

  function getStudentCourse() {
    if (studentProfile.courses.length > 0) {
      const courseName = studentProfile.courses[0].name;
      const semester = studentProfile.courses[0].semester;
      return `${courseName} - ${semester}º semestre`;
    }
    return 'Não possui curso';
  }

  function getStatusLabel() {
    switch (mentorship.status.value) {
      case 'requested':
        return 'Nova solicitação';
      case 'reschedule_request':
        return 'Solicitação de reagendamento';
      case 'accepted':
        return 'Mentoria agendada';
      case 'rescheduled':
        return 'Aguardando confirmação do estudante';
      case 'canceled':
        return 'Mentoria cancelada';
      case 'realized':
        return 'Mentoria realizada';
      case 'scheduled':
        return 'Aguardando confirmação do estudante';
      default:
        // expired
        return 'Mentoria expirada';
    }
  }

  async function handleMentorshipAction() {
    setIsOpeningModal(true);
    switch (mentorship.status.value) {
      case 'requested':
        await openScheduleMentorshipModal(mentorship);
        break;
      case 'reschedule_request':
        await openRescheduleMentorshipModal(mentorship);
        break;
      case 'expired':
        await openAdjustMentorshipStatusModal(mentorship);
        break;
      default:
        await openDetailMentorshipModal(mentorship);
    }
    setIsOpeningModal(false);
  }

  const openCurriculum = useCallback(async ({ id }) => {
    handleIsLoadingState(true);

    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
      await registerAccessedCurriculum([response.curriculum_id]);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const openLetter = useCallback(async ({ id }) => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={getApplicationCoverLetter}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const getScheduleButtonLabel = useCallback((status) => {
    switch (status) {
      case 'requested':
        return 'AGENDAR MENTORIA';
      case 'reschedule_request':
        return 'REAGENDAR MENTORIA';
      case 'expired':
        return 'AJUSTAR STATUS';
      default:
        return 'VER DETALHES';
    }
  }, []);

  return (
    <Detail onClick={handleExpand}>
      <Summary>
        <ProfileImage
          image={
            !!mentorship.student_avatar
              ? mentorship.student_avatar
              : placeholder
          }
        />
        <div>
          <strong>Mentoria de Carreira</strong>
          <div>
            <h3>{mentorship.student_name}</h3>
            <ExpandIcon className="expand-icon" />
          </div>
          <p>Data: {getDate()}</p>
          {mentorship.scheduled_by && (
            <p>Agendado por {mentorship.scheduled_by}</p>
          )}
        </div>
        <div
          style={{
            margin: '0 0 0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            alignItems: 'end',
            justifyContent: 'flex-end',
          }}
        >
          <ScheduleButton
            onClick={handleMentorshipAction}
            disabled={isOpeningModal}
          >
            {isOpeningModal
              ? 'AGUARDE...'
              : getScheduleButtonLabel(mentorship.status.value)}
          </ScheduleButton>
          <StatusIndicator status={mentorship.status.value}>
            {getStatusLabel()}
          </StatusIndicator>
        </div>
      </Summary>
      {isFetchingStudentProfile || studentProfile === null ? (
        <ExpandedContent>
          <strong>Informações do estudante</strong>
          <SkeletonBox style={{ width: '35%', height: '21px' }} />
          <SkeletonBox style={{ width: '35%', height: '21px' }} />
          <SkeletonBox style={{ width: '55%', height: '21px' }} />
          <SkeletonBox style={{ width: '40%', height: '21px' }} />
          <DocButtons>
            <ButtonSkeleton style={{ width: '148px' }} />
            <ButtonSkeleton style={{ width: '170px' }} />
          </DocButtons>
        </ExpandedContent>
      ) : (
        <ExpandedContent>
          <strong>Informações do estudante</strong>
          <div>
            <InformationIcon image={jobIcon} />
            <p>{getProfessionalStatus()}</p>
          </div>
          <div>
            <InformationIcon image={courseIcon} />
            <p>{getStudentCourse()}</p>
          </div>
          {studentProfile.mobile_phone && studentProfile.email && (
            <div>
              {studentProfile.mobile_phone && (
                <Fragment>
                  <InformationIcon image={phoneIcon} />
                  <p>{studentProfile.mobile_phone}</p>
                </Fragment>
              )}
              {studentProfile.email && (
                <Fragment>
                  <InformationIcon image={emailIcon} />
                  <p>{studentProfile.email}</p>
                </Fragment>
              )}
            </div>
          )}
          {studentProfile.city && studentProfile.state && (
            <div>
              <InformationIcon image={locationIcon} />
              <p>{`${studentProfile.city} - ${studentProfile.state}`}</p>
            </div>
          )}
          <DocButtons>
            <OutlinedButton
              onClick={() => openCurriculum(studentProfile)}
              disabled={!studentProfile.has_curriculum}
              title={
                !studentProfile.has_curriculum
                  ? 'Este estudante não possui currículo'
                  : ''
              }
            >
              Currículo do futuro
              <ArrowRightIcon disabled={!studentProfile.has_curriculum} />
            </OutlinedButton>
            <OutlinedButton
              onClick={() => openLetter(studentProfile)}
              disabled={!studentProfile.has_cover_letter}
              title={
                !studentProfile.has_cover_letter
                  ? 'Este estudante não possui carta de apresentação'
                  : ''
              }
            >
              Carta de apresentação
              <ArrowRightIcon disabled={!studentProfile.has_cover_letter} />
            </OutlinedButton>
          </DocButtons>
        </ExpandedContent>
      )}
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
        />
      )}
    </Detail>
  );
}
