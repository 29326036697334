import { IconSuitcase } from 'assets/img/iconsJobOffers/iconSuitcase';
import { IconLocation } from 'assets/img/iconsJobOffers/iconLocation';
import { IconCompany } from 'assets/img/iconsJobOffers/iconCompany';
import { IconHouse } from 'assets/img/iconsJobOffers/iconHouse';
import { IconDate } from 'assets/img/iconsJobOffers/iconDate';
import { IconArrowEdit } from 'assets/img/iconsCurriculum/iconArrowEdit';
import { IconWithoutJobs } from 'assets/img/iconsJobOffers/iconWithoutJobs';
import { IconStar } from 'assets/img/iconsJobOffers/iconStar';
import { IconFireMatch } from 'assets/img/iconsJobOffers/iconFireMatch';
import { AiOutlineStar } from 'react-icons/ai';
import { AiFillStar } from 'react-icons/ai';
import { IconPCD } from 'assets/img/iconsJobOffers/iconPCD';
import { IconCheck } from 'assets/img/iconsJobOffers/iconCheck';
import { IconUnliked } from 'assets/img/iconsJobOffers/iconUnliked';
import { IconInformation } from 'assets/img/iconsJobOffers/iconInformation';

import iconCanceled from 'assets/img/iconsJobOffers/iconCanceled.png';
import iconCurriculumLiked from 'assets/img/iconsJobOffers/iconCurriculumLiked.png';
import iconCurriculumNotLiked from 'assets/img/iconsJobOffers/iconCurriculumNotLiked.png';
import iconCurriculumSent from 'assets/img/iconsJobOffers/iconCurriculumSent.png';
import iconExternal from 'assets/img/iconsJobOffers/iconExternal.png';
import iconRescheduled from 'assets/img/iconsJobOffers/iconRescheduled.png';
import iconScheduled from 'assets/img/iconsJobOffers/iconScheduled.png';
import iconUnscheduled from 'assets/img/iconsJobOffers/iconUnscheduled.png';
import iconExpired from 'assets/img/iconsJobOffers/iconExpired.png';
import iconFinished from 'assets/img/iconsJobOffers/iconFinished.png';
import iconInviteInterview from 'assets/img/iconsJobOffers/iconInviteInterview.png';

export const wrapperIconsJobOffers = () => {
  return {
    IconSuitcase,
    IconLocation,
    IconCompany,
    IconHouse,
    IconDate,
    IconArrowEdit,
    IconWithoutJobs,
    IconStar,
    IconFireMatch,
    AiOutlineStar,
    AiFillStar,
    IconPCD,
    IconCheck,
    IconUnliked,
    iconCanceled,
    iconCurriculumLiked,
    iconCurriculumNotLiked,
    iconCurriculumSent,
    iconExternal,
    iconRescheduled,
    iconScheduled,
    iconUnscheduled,
    iconExpired,
    iconFinished,
    IconInformation,
    iconInviteInterview,
  };
};
