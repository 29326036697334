export default function AfternoonIcon({ color }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08479 9.0972C3.04726 9.40671 2.78456 9.62563 2.4318 9.63318C2.14659 9.64073 1.85387 9.63318 1.56866 9.63318C1.30597 9.63318 1.05078 9.62563 0.788084 9.63318C0.405301 9.64828 0.1351 9.5124 0 9.1425V8.85563C0.150111 8.47818 0.435323 8.34985 0.825612 8.36495C1.35851 8.38004 1.8839 8.36495 2.41679 8.3725C2.85211 8.38005 3.13733 8.6971 3.08479 9.0972Z"
        fill={color}
      />
      <path
        d="M9.63536 0.82588C9.62023 1.33642 9.63536 1.85447 9.6278 2.36502C9.6278 2.50767 9.58999 2.65783 9.52949 2.78547C9.4085 3.01821 9.13627 3.13083 8.87916 3.07828C8.63718 3.03323 8.43301 2.82301 8.38763 2.57524C8.37251 2.49265 8.37251 2.41007 8.37251 2.32748C8.37251 1.82444 8.38763 1.32892 8.36495 0.82588C8.34982 0.435464 8.47838 0.15016 8.85648 0H9.14383C9.52193 0.15016 9.65048 0.435464 9.63536 0.82588Z"
        fill={color}
      />
      <path
        d="M9.08252 4.54598C6.59632 4.56096 4.53697 6.62031 4.54445 9.09153C4.54445 11.5702 6.61129 13.6296 9.09 13.6371C11.5612 13.6446 13.6206 11.5927 13.6356 9.09902C13.6505 6.6278 11.5537 4.531 9.08252 4.54598ZM9.06005 12.3865C7.1954 12.349 5.76509 10.9038 5.78755 9.06906C5.81002 7.20441 7.27778 5.75163 9.11247 5.78908C10.9771 5.82652 12.4074 7.27181 12.385 9.10651C12.3625 10.9712 10.8947 12.4239 9.06005 12.3865Z"
        fill={color}
      />
      <path
        d="M17.8272 9.43365C17.6999 9.58709 17.5302 9.63313 17.3464 9.63313H16.5333C16.2434 9.63313 15.9535 9.6408 15.6707 9.63313C15.3667 9.61778 15.1404 9.39528 15.098 9.10373C15.0556 8.80451 15.2041 8.52063 15.4657 8.41321C15.5505 8.38252 15.6424 8.36718 15.7273 8.36718C16.2717 8.35951 16.8161 8.36718 17.3605 8.36718C17.6363 8.36718 17.8484 8.49761 17.9544 8.77382C18.0464 9.01166 17.9969 9.23416 17.8272 9.43365Z"
        fill={color}
      />
      <path
        d="M9.62894 17.338C9.62134 17.7218 9.34769 18.0002 8.99804 18.0002C8.64839 18.0002 8.38235 17.7218 8.36715 17.3456C8.36715 17.3305 8.36715 17.323 8.36715 17.3079V16.4426C8.36715 16.1417 8.35955 15.8407 8.36715 15.5397C8.37475 15.2688 8.50397 15.0657 8.76241 14.9603C8.99804 14.8625 9.21847 14.9076 9.4161 15.0732C9.56813 15.2086 9.63654 15.3817 9.63654 15.5773C9.62894 16.1567 9.63654 16.7511 9.62894 17.338Z"
        fill={color}
      />
      <path
        d="M15.4584 15.4488C15.2186 15.6958 14.8515 15.7033 14.5892 15.4488C14.1471 15.0222 13.7125 14.5806 13.2779 14.1391C13.0906 13.9445 13.0381 13.7125 13.1505 13.458C13.2554 13.2185 13.4577 13.1138 13.7874 13.0913C13.8624 13.1287 14.0197 13.1736 14.1246 13.2709C14.5742 13.6975 15.0163 14.1391 15.4509 14.5881C15.6982 14.8576 15.6982 15.2093 15.4584 15.4488Z"
        fill={color}
      />
      <path
        d="M4.90912 4.26554C4.89513 4.57198 4.79015 4.73912 4.58721 4.84359C4.37726 4.94806 4.16732 4.92717 3.98537 4.77395C3.81042 4.62073 3.64946 4.45358 3.4885 4.29339C3.22957 4.03571 2.96364 3.78498 2.71171 3.51337C2.3968 3.17211 2.55075 2.6637 2.99164 2.55923C3.18059 2.51744 3.35554 2.56619 3.4955 2.69852C3.91539 3.11639 4.34227 3.5273 4.75516 3.95213C4.83914 4.0566 4.87413 4.20286 4.90912 4.26554Z"
        fill={color}
      />
      <path
        d="M15.4521 3.41063C15.0237 3.85288 14.5802 4.29514 14.1367 4.7224C13.9412 4.9098 13.7007 4.96227 13.4526 4.84983C13.2271 4.74489 13.1068 4.55 13.0918 4.21268C13.1294 4.13772 13.182 3.97281 13.2872 3.86038C13.7082 3.41812 14.1517 2.98336 14.5877 2.5561C14.8433 2.30124 15.2041 2.30124 15.4446 2.54111C15.6927 2.77348 15.7077 3.14827 15.4521 3.41063Z"
        fill={color}
      />
      <path
        d="M4.90912 13.7745C4.86722 13.8572 4.82533 14.0227 4.72757 14.1355C4.33652 14.5792 3.92453 15.0154 3.51952 15.4441C3.27512 15.6998 2.93994 15.6998 2.70951 15.4516C2.49304 15.211 2.48606 14.85 2.71649 14.5943C3.1215 14.1505 3.52651 13.7068 3.9385 13.2781C4.12005 13.0901 4.34351 13.0374 4.57394 13.1502C4.78343 13.2405 4.88817 13.436 4.90912 13.7745Z"
        fill={color}
      />
    </svg>
  );
}
