import {
  radioDna,
  dangerColor,
  dnaGreen,
  grayColor,
  grayLightColor,
  dnaColor,
  dnaDisabled,
  cardTitle,
} from 'assets/jss/material-dashboard-pro-react';

const FeedbackTalentsStyles = (theme) => {
  return {
    select: {
      marginBottom: '1rem',
      width: '100%',
      '& > div': {
        backgroundColor: 'rgb(245, 245, 245)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      margin: '0 auto',
      fontSize: 17,
      '& .react-select__menu': {
        zIndex: 999,
      },
      '& > div:nth-child(1)': {
        height: 56,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 10,
        },
      },
      '& > div:nth-child(2)': {
        height: 56,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 15,
        },
      },
    },
    selectAutoHeight: {
      '& > div:nth-child(1)': {
        paddingTop: 10,
        height: 'auto',
      },
      '& > div:nth-child(2)': {
        paddingTop: 10,
        height: 'auto',
      },
      '& > div:last-child': {
        display: 'none',
      },
    },
    inputField: {
      color: `${dnaGreen} !important`,
      borderBottom: 0,
      margin: '0 auto 1rem',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& root': {
        backgroundColor: '#fff !important',
        borderRadius: 10,
      },
    },
    input: {
      color: 'rgba(0, 0, 0, 0.54)',
      '& root': {
        backgroundColor: '#fff!important',
        borderRadius: 10,
      },
      borderRadius: 10,
      backgroundColor: '#fff',
      '&:before': {
        borderBottom: 0,
        borderRadius: 10,
      },
      '&:after': {
        borderBottom: 0,
        borderRadius: 10,
        backgroundColor: 'transparent',
        visibility: 'hidden',
      },
      '&:hover:before': {
        backgroundColor: '#fff',
        borderBottom: 0,
        borderRadius: 10,
        visibility: 'hidden',
      },
      '&:focus': {
        backgroundColor: '#fff',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#fff',
        color: 'black',
      },
      '& focused': {
        backgroundColor: '#fff',
        borderBottom: 0,
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:after:hover': {
        backgroundColor: 'transparent',
      },
    },
    textareaInput: {
      minHeight: '56px',
      backgroundColor: 'rgb(245, 245, 245)',
      width: '100%',
      borderRadius: 10,
      '& > div:first-child': {
        marginTop: '1.5rem',
        padding: '0 12px',
      },
    },
    extracourseModalButtons: {
      borderStyle: 'none',
      borderRadius: '1.5rem',
      fontWeight: 600,
      height: 'fit-content',
      padding: '0.5rem 3rem',
      margin: '2rem auto 0',
      display: 'block',
      fontSize: '1rem',

      transition: 'opacity 0.4s, background 0.2s',

      '&:hover': {
        cursor: 'pointer',
        opacity: '0.8',
      },
    },
  };
};

export default FeedbackTalentsStyles;
