import React, { useLayoutEffect, useState } from 'react';
import {
  Container,
  Title,
  ButtonSeeMoreJobs,
  ContainerFavoriteCompanies,
} from '../styled';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import FavoriteCompanyJobOffers from './FavoriteCompanyJobs';
import MoreFavoriteCompanyJobs from './MoreFavoriteCompanyJobs';

export default function FavoriteCardsCompanyJobs(props) {
  const {
    universityColor,
    listFavoriteJobOffers,
    search,
    isGamificationLevelOneIncomplete,
    isCoverLetterMissing,
    applyToJobOffer,
    fetchListFavoriteJobOffers,
  } = props;
  const { IconArrowEdit } = wrapperIconsJobOffers();
  const countFavoriteJobOffers = listFavoriteJobOffers?.data?.count;
  const dataFavoriteJobOffers = listFavoriteJobOffers?.data?.results;
  const [openMoreCards, setOpenMoreCards] = useState(false);
  const [
    jobIdCandidatureBeingProcessed,
    setJobIdCandidatureBeingProcessed,
  ] = useState(null);
  const [showSeeAllJobOffersButton, setShowSeeAllJobOffersButton] = useState(
    false
  );

  const handleOpenMoreCards = () => {
    setOpenMoreCards(true);
  };

  const handleCloseModal = () => {
    setOpenMoreCards(false);
  };

  async function candidateJobOffer(jobId) {
    try {
      if (isGamificationLevelOneIncomplete()) {
        return;
      }

      if (isCoverLetterMissing(jobId)) {
        return;
      }

      setJobIdCandidatureBeingProcessed(jobId);

      await applyToJobOffer(jobId);
    } finally {
      setJobIdCandidatureBeingProcessed(null);
    }
  }

  useLayoutEffect(
    function () {
      const isThereAnyHiddenCard = () => {
        const favoriteCompaniesCardsContainer = document.getElementById(
          'favorite-companies'
        );

        if (!favoriteCompaniesCardsContainer) return;
        if (!favoriteCompaniesCardsContainer.lastChild) return;

        let containersBottom = favoriteCompaniesCardsContainer.getBoundingClientRect()
          .bottom;
        let lastCardsTop = favoriteCompaniesCardsContainer.lastChild.getBoundingClientRect()
          .top;
        setShowSeeAllJobOffersButton(containersBottom < lastCardsTop);
      };
      window.addEventListener('resize', isThereAnyHiddenCard);
      isThereAnyHiddenCard();
      return () => window.removeEventListener('resize', isThereAnyHiddenCard);
    },
    [countFavoriteJobOffers]
  );

  return (
    <>
      {dataFavoriteJobOffers?.length > 0 ? (
        <Container style={{ paddingTop: '0px' }}>
          <ContainerFavoriteCompanies>
            <Title style={{ position: 'absolute', top: '30px' }}>
              Vagas de empresas que você favoritou ({countFavoriteJobOffers})
            </Title>
            <FavoriteCompanyJobOffers
              universityColor={universityColor}
              dataFavoriteJobOffers={dataFavoriteJobOffers}
              search={search}
              candidateJobOffer={candidateJobOffer}
              jobIdCandidatureBeingProcessed={jobIdCandidatureBeingProcessed}
              fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
            />
            {showSeeAllJobOffersButton && (
              <ButtonSeeMoreJobs
                universityColor={universityColor}
                onClick={handleOpenMoreCards}
              >
                Ver todas as vagas
                <IconArrowEdit color={universityColor} />
              </ButtonSeeMoreJobs>
            )}
          </ContainerFavoriteCompanies>

          {openMoreCards && (
            <MoreFavoriteCompanyJobs
              handleCloseModal={handleCloseModal}
              search={search}
              universityColor={universityColor}
              dataFavoriteJobOffers={dataFavoriteJobOffers}
              candidateJobOffer={candidateJobOffer}
              jobIdCandidatureBeingProcessed={jobIdCandidatureBeingProcessed}
              fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
            />
          )}
        </Container>
      ) : null}
    </>
  );
}
