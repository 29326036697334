import { format, parse } from 'date-fns';

const careerExperienceAdapter = ({
  id,
  isCurrentJob,
  role,
  company,
  location,
  area,
  section,
  dedicatedHours,
  knowledges,
  experienceType,
  startedAt,
  endedAt,
  type,
  country,
}) => {
  const careerExperience = {
    is_current_job: isCurrentJob || false,
    role,
    type,
    knowledges: knowledges.map((element) => element.value),
    dedicated_hours: dedicatedHours.value,
    experience_type: experienceType && experienceType.value,
    company,
    location: typeof location !== 'string' ? location.value : location,
    area: area.value,
    country: { label: country.label, value: country.value },
    section: section.value,
    started_at: format(
      parse(startedAt, 'yyyy-MM-dd', new Date()),
      'dd/MM/yyyy'
    ),
    ended_at: !isCurrentJob
      ? format(parse(endedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
      : null,
  };
  if (id) {
    careerExperience.id = id;
  }

  return careerExperience;
};

const lifeExperienceAdapter = ({
  experienceType,
  knowledges,
  country,
  city,
  startedAt,
  endedAt,
  dedicatedHours,
  dedicatedDays,
  inProgress,
  id,
  type,
  role,
}) => {
  const lifeExperience = {
    role,
    in_progress: inProgress,
    experience_type: experienceType && experienceType.value,
    knowledges: knowledges.map((element) => element.value),
    country: { label: country.label, value: country.value },
    city,
    type,
    dedicated_hours: dedicatedHours.value,
    dedicated_days: dedicatedDays.value,
    started_at: format(
      parse(startedAt, 'yyyy-MM-dd', new Date()),
      'yyyy-MM-dd'
    ),
    ended_at: !inProgress
      ? format(parse(endedAt, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd')
      : null,
  };
  if (id) {
    lifeExperience.id = id;
  }

  return lifeExperience;
};

const loadExperience = ({
  area,
  id,
  is_current_job,
  role,
  company,
  location,
  section,
  started_at,
  ended_at,
  dedicated_hours,
  dedicated_days,
  knowledges,
  in_progress,
  experience_type,
  city,
  country,
  type,
}) => ({
  area,
  id,
  country,
  dedicatedDays: dedicated_days,
  isCurrentJob: is_current_job,
  role,
  city,
  company,
  location,
  section,
  startedAt: started_at,
  endedAt: ended_at,
  dedicatedHours: dedicated_hours,
  inProgress: in_progress,
  knowledges,
  experienceType: experience_type,
  type,
});

export { careerExperienceAdapter, lifeExperienceAdapter, loadExperience };
