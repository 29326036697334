import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';
import {
  Container,
  Title,
  Subtitle,
  WhiteBox,
  HeaderTab,
  Header,
  DisconnectButton,
  FilterContainer,
} from '../Styles/BaseComponent';
import SelectAllCheckBox from '../UIComponents/SelectAllCheckBox';

import { useContext, useState, useEffect, useRef } from 'react';
import AccordionSkelleton from '../UIComponents/AccordionSkelleton';
import GenericAccordion from '../UIComponents/GenericAccordion';
import { AccordionContent } from '../UIComponents/AccordionContent';
import { renderItemsAccordion } from 'views/StaffPages/CompaniesManagement/Utils/renderItemsAccordion';
import Pagination from 'components/CustomPagination';
import { FilterCompaniesManagement } from '../UIComponents/FilterCompaniesManagement';
import BaseLayoutContext from 'contexts/base-layout';
import CompanyProfileModal from '../UIComponents/Modals/CompanyProfileModal';
import ModalUploadEvidences from 'components/UploadEvidences';
import {
  connectCompany,
  connectionAndDisconnection,
  getCitiesOptions,
  getDisconnectCompaniesFilters,
} from '../../Services';
import GenericMessage from 'components/GenericMessage';

export default function DisconnectedCompanies({
  activeTab,
  getCountTabs,
  setCountDisconnectCompanies,
}) {
  const { getDisconnectCompanies } = useAPICalls();

  const {
    returnStatus,
    returnIcons,
    returnIconSessions,
  } = renderItemsAccordion();

  const [details, setDetails] = useState(null);
  const [documents, setDocuments] = useState([]);

  const {
    listDisconnectCompanies,
    setListDisconnectCompanies,

    companiesDisconnectCount,

    usingModalUpload,
    setUsingModalUpload,
    idPostDocument,
  } = useContext(CompaniesManagementContext);

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const [filterOptions, setFilterOptions] = useState({});

  const { openSnackbar } = useContext(BaseLayoutContext);

  const [
    openModalConnectedCompanies,
    setOpenModalConnectedCompanies,
  ] = useState(false);

  const [queryFilter, setQueryFilter] = useState(null);

  const [isFetchingCities, setIsFetchingCities] = useState(false);

  const [openModalViewStageData, setOpenModalViewStageData] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const disconnectedCompaniesDiv = useRef();

  useEffect(() => {
    setSelectedCompanies([]);
  }, [currentPage]);

  useEffect(() => {
    if (activeTab === 1) {
      setListDisconnectCompanies(null);
      setCurrentPage(1);

      getDisconnectCompaniesFilters().then((data) => {
        setFilterOptions(data);
      });
    }
  }, [activeTab]);

  const scrollToTopDiv = () => {
    if (disconnectedCompaniesDiv) {
      disconnectedCompaniesDiv.current.scrollIntoView();
    }
  };

  const handlePageChange = async (page) => {
    if (queryFilter === null) {
      const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
      setListDisconnectCompanies(null);
      await getDisconnectCompanies(newPage);
    }

    if (queryFilter !== null) {
      const query = `${queryFilter}&limit=${limit}&offset=${
        (page - 1) * limit || 0
      }`;
      setListDisconnectCompanies(null);
      await getDisconnectCompanies(query);
    }
  };

  const onPageChange = (page) => {
    scrollToTopDiv();
    setCurrentPage(page);
    handlePageChange(page);
  };

  const refreshLimit = (page) => {
    handlePageChange(page);
  };

  useEffect(() => {
    refreshLimit(currentPage);
  }, [limit]);

  async function handleChangeStates(states) {
    if (states && states.length > 0) {
      setIsFetchingCities(true);
      const data = await getCitiesOptions(states, 'disconnected');

      if (filterState['cities']) {
        const newFilterState = { ...filterState };
        const responseCitiesValues = data.cities.map((city) => city.value);
        const newCities = newFilterState.cities.filter((city) =>
          responseCitiesValues.includes(city)
        );
        if (newCities.length > 0) {
          newFilterState.cities = newCities;
        } else {
          delete newFilterState.cities;
        }
        newFilterState.states = states;
        setFilterState(newFilterState);
      }
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
      setIsFetchingCities(false);
    } else {
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.states;
        delete newFilterState.cities;
        return newFilterState;
      });
    }
  }

  const isAllCompaniesSelected =
    selectedCompanies?.map((s) => s.id).length ===
    listDisconnectCompanies?.map((s) => s.id).length;

  function toggleSelectAll() {
    if (selectedCompanies?.length === listDisconnectCompanies?.length) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(listDisconnectCompanies.map((s) => s.id));
    }
  }

  const handleConnectCompanie = async (id) => {
    const response = await connectCompany(id);
    await getCountTabs();
    setFilterState({});
    setSelectedCompanies([]);
    setListDisconnectCompanies(null);
    getDisconnectCompanies('limit=20&offset=0');

    if (response.status === 200 || response.status === 201) {
      openSnackbar('Conexão realizada com sucesso!');
    }
  };

  const renderItems = () => {
    if (listDisconnectCompanies?.length === 0) {
      return (
        <GenericMessage
          title="Nenhuma empresa encontrada"
          subtitle="
            Caso queira, acesse a aba ”empresas conectadas”
            para se desconectar de alguma empresa.
          "
        />
      );
    }

    if (listDisconnectCompanies === null) {
      return Array(8)
        .fill(1)
        .map((_, index) => <AccordionSkelleton />);
    }

    if (listDisconnectCompanies?.length > 0) {
      return listDisconnectCompanies?.map((companies) => (
        <GenericAccordion
          companyId={companies.id}
          data={companies}
          checkbox={true}
          stateSelectedCheckbox={selectedCompanies}
          funcSetSelectedCheckbox={setSelectedCompanies}
          flagStatus={true}
          textStatus={returnStatus}
          iconStatus={returnIcons}
          colorStatus={companies.agreement_status}
          greenRightButton={true}
          grayRightButton={false}
          greenRightButtonLabel={'Conectar'}
          greenRightButtonFunc={() => handleConnectCompanie(companies.id)}
          textTitle={companies?.legal_name || 'Não informado'}
          textSubtitle={`${companies?.organization_number_type}:`}
          contentSubtitle={companies?.organization_number}
          iconSession={() => returnIconSessions(companies, true)}
          setDocuments={setDocuments}
          getCountTabs={getCountTabs}
          disableButton={selectedCompanies?.length > 1}
        >
          <AccordionContent
            companies={companies}
            responsibleForTheDisconnect={true}
            setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
            setDetails={setDetails}
            buttonCompanyProfileDown={true}
            setDocuments={setDocuments}
            setSelectedCompanyId={setSelectedCompanyId}
          />
        </GenericAccordion>
      ));
    }
  };

  const functionCloseUpdateModal = () => {
    setUsingModalUpload(false);
  };

  const handleConnectAll = async () => {
    if (selectedCompanies.length >= 1) {
      const data = {
        ids: selectedCompanies,
        status: 'accepted',
      };
      await connectionAndDisconnection(data);
      await getCountTabs();
      openSnackbar(
        `
        Conexão realizada com ${selectedCompanies.length}
        ${selectedCompanies.length > 1 ? 'empresas' : 'empresa'}! `,
        false
      );
      setListDisconnectCompanies(null);
      getDisconnectCompanies('limit=24&offset=0');
      setFilterState({});
      setSelectedCompanies([]);
    }
  };

  return (
    <Container>
      <Title>Empresas desconectadas</Title>
      <Header padRight={Object.keys(filterState).length > 0}>
        <Subtitle>
          Confira aqui as empresas nas quais você se desconectou e caso deseje,
          conecte-se com elas novamente.
        </Subtitle>
        <FilterContainer>
          <FilterCompaniesManagement
            activeTab={activeTab}
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            disconnect={true}
            setQueryFilter={setQueryFilter}
            isFetchingCities={isFetchingCities}
            handleChangeStates={handleChangeStates}
            loading={listDisconnectCompanies === null}
            getCountTabs={getCountTabs}
            setCountDisconnectCompanies={setCountDisconnectCompanies}
            setCurrentPage={setCurrentPage}
          />
        </FilterContainer>
      </Header>
      <WhiteBox ref={disconnectedCompaniesDiv}>
        {listDisconnectCompanies?.length !== 0 && (
          <HeaderTab>
            <SelectAllCheckBox
              type="checkbox"
              id="checkAll"
              checked={isAllCompaniesSelected}
              onChange={toggleSelectAll}
              disabled={listDisconnectCompanies === null}
              buttonLabel={'Selecionar tudo'}
            />
            {selectedCompanies?.length > 0 && (
              <DisconnectButton onClick={handleConnectAll}>
                Conectar com {selectedCompanies.length}{' '}
                {selectedCompanies.length > 1 ? 'empresas' : 'empresa'}
              </DisconnectButton>
            )}
          </HeaderTab>
        )}
        {renderItems()}
      </WhiteBox>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={companiesDisconnectCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        setQueryFilter={setQueryFilter}
        paginationItensValue={[20, 50, 100, 500]}
      />
      <ModalUploadEvidences
        open={usingModalUpload}
        evidenceFiles={[]}
        evidenceLinks={[]}
        acceptFileTypes={['PDF']}
        functionCloseAndUpdate={functionCloseUpdateModal}
        idPost={idPostDocument}
        headerTitle={'Adicionar documentos de estágio'}
        buttonLabe={'SALVAR'}
        uniqueUploadType={'file'}
        limitUpload={2}
        buttonLabel={'SALVAR'}
      />
      {openModalConnectedCompanies && (
        <CompanyProfileModal
          isOpen={openModalConnectedCompanies}
          details={details}
          setDocuments={setDocuments}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          documents={documents}
          setOpenModalViewStageData={setOpenModalViewStageData}
          openModalViewStageData={openModalViewStageData}
        />
      )}
    </Container>
  );
}
