import React, { useContext, useState, useEffect } from 'react';
import {
  ContainerGenericMessage,
  ContainerCardsFavoriteCompanies,
  Component,
} from '../styled';

import GenericCardsJobOffers from '../Cards';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { DetailsJobOffer } from '../../JobDetails';
import GenericMessage from 'components/GenericMessage';

const FavoriteCompanyJobOffers = ({
  universityColor,
  dataFavoriteJobOffers,
  search,
  candidateJobOffer,
  jobIdCandidatureBeingProcessed,
  fetchListFavoriteJobOffers,
}) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedJobMatch, setSelectedJobMatch] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [
    jobIdBeingProcessedAsFavorite,
    setJobIdBeingProcessedAsFavorite,
  ] = useState(null);
  const { filterData } = useContext(JobOffersContext);

  const onClickDetails = (jobId, match) => {
    setOpenDetailsModal(true);
    setSelectedJobMatch(match);
    setSelectedJobId(jobId);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  const handleFavoriteToggle = async (jobId, isFavorited) => {
    try {
      setJobIdBeingProcessedAsFavorite(jobId);
      if (isFavorited === false) {
        const data = { job_offer: jobId };
        await JobOffersApi.fetchActionToFavoriteJobs(data);
        await fetchListFavoriteJobOffers(false);
      }
      if (isFavorited === true) {
        await JobOffersApi.fetchActionToUnfavoriteJobs(jobId);
        await fetchListFavoriteJobOffers(false);
      }
    } finally {
      setJobIdBeingProcessedAsFavorite(null);
    }
  };

  const returnCardJobs = (dataFavoriteJobOffers) => {
    const filteredData = filterData(dataFavoriteJobOffers, search);

    if (filteredData.length === 0) {
      return (
        <ContainerGenericMessage>
          <GenericMessage
            title="Nenhuma vaga encontrada."
            subtitle="Não encontramos nenhuma vaga de empresa favoritada com este nome."
          />
        </ContainerGenericMessage>
      );
    }

    if (dataFavoriteJobOffers?.length >= 1) {
      return filterData(dataFavoriteJobOffers, search).map((job) => (
        <div key={job}>
          <GenericCardsJobOffers
            match={job.match}
            code={job.id}
            name={job.name}
            companyName={job.company_name}
            finallyDate={job.end_offer_at}
            jobModality={job.modality}
            shift={job.shift}
            location={job.location ? job.location : 'Não informado'}
            processingAsFavorite={job.id === jobIdBeingProcessedAsFavorite}
            like={true}
            status={job.status}
            isFavorite={job.is_favorite}
            onClickLike={() => handleFavoriteToggle(job.id, job.is_favorite)}
            onClickDetailsButton={() => onClickDetails(job.id, job.match)}
            labelTwo={'Detalhes'}
            onClickButton={() => candidateJobOffer(job.id)}
            label={'Candidatar'}
            processingCandidature={job.id === jobIdCandidatureBeingProcessed}
            universityColor={universityColor}
          />
        </div>
      ));
    }
  };

  return (
    <>
      <Component />
      <ContainerCardsFavoriteCompanies>
        <div id="favorite-companies">
          {returnCardJobs(dataFavoriteJobOffers)}
        </div>
      </ContainerCardsFavoriteCompanies>

      {openDetailsModal && (
        <DetailsJobOffer
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
          match={selectedJobMatch}
          jobId={selectedJobId}
          onClickApplyToJobOffer={() => candidateJobOffer(selectedJobId)}
          candidatureBeingProcessed={jobIdCandidatureBeingProcessed !== null}
          isExclusive={true}
        />
      )}
    </>
  );
};

export default FavoriteCompanyJobOffers;
