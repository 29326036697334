import React, { useContext, useEffect, useState, Fragment } from 'react';
import { Form } from '@unform/web';
import {
  WrapperTitle,
  H3Title,
  WrapperPasswords,
  WrapperVerifyPassword,
  ContainsH5,
  ParagraphError,
  WrapperVerifications,
} from '../style';
import ErrorVector from '../../../../../assets/JobOffer/ErrorVector.svg';
import CheckedVector from '../../../../../assets/JobOffer/CheckedVector.svg';
import { optionsMacroAreas, getOptions } from '../../../Services';
import AuthContext from 'contexts/authentication';
import JobOfferPublicationTypeModal from 'views/JobOfferRebuild/Components/JobOfferForm/JobOfferPublicationTypeModal';

import InformationModal from 'components/informationModal';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import { isStaffEmailExists } from 'services/companies';
import Checkbox from 'components/UnformFields/CheckBox';
import BaseLayoutContext from 'contexts/base-layout';
import { getTermCompanyNotAuthenticate } from 'services/acceptanceTerm';
import Input from 'components/UnformFields/inputField';

export default function StepTwo(props) {
  const formRef = props.formRef;
  const [modalInformationIsOpen, setModalInformationIsOpen] = useState(false);
  const [onUserAuthenticate, setOnUserAuthenticate] = useState({});
  const [isValidated, setIsValidated] = useState(false);
  const [macroAreaOptions, setMacroAreaOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsLink, setTermsLink] = useState('');

  const universityColor = props?.location?.state?.universityColor || '#009291';

  useEffect(() => {
    getAllOptions();
  }, []);

  const getAllOptions = async (e) => {
    e && e.preventDefault();
    const [macroAreas, options, terms] = await Promise.allSettled([
      optionsMacroAreas(),
      getOptions(),
      getTermCompanyNotAuthenticate(),
    ]);

    setMacroAreaOptions(macroAreas.value);
    setOptions(options.value);
    setTermsLink(terms?.value?.file_term);
  };

  const [securePassword, setSecurePassword] = useState({
    length: false,
    letters: false,
    specialCharacters: false,
    numbers: false,
    password: null,
  });

  const [verificatePassword, setVerificatePassword] = useState({
    password: null,
  });
  const [equalPassword, setEqualsPassword] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const [errorEmailExist, setErrorEmailexist] = useState(true);

  const handleChange = (e) => {
    const password = e.target.value;
    const regexNumber = new RegExp(/^(?=.*[0-9]).+$/);
    const regexLetters = new RegExp(/^(?=.*[a-zA-Z]).+$/);
    const regexSpecialCharacters = new RegExp(
      /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+$/
    );
    const length = password.length >= 8;

    setSecurePassword({
      length,
      password,
      letters: regexLetters.test(password),
      specialCharacters: regexSpecialCharacters.test(password),
      numbers: regexNumber.test(password),
    });
  };

  const handleVerifyPassword = (e) => {
    const password = e.target.value;
    setVerificatePassword({ password });
  };

  const handleBlurEmail = async (e) => {
    const email = e.target.value;
    if (email) {
      const output = await isStaffEmailExists(email);
      const { exists } = output;
      setErrorEmailexist(exists);
    }
  };

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const existPasswordValues = () => {
    return (
      verificatePassword?.password?.length > 0 &&
      securePassword?.password?.length > 0
    );
  };

  const passwordPassed = () => {
    if (
      securePassword.length &&
      securePassword.letters &&
      securePassword.numbers &&
      securePassword.specialCharacters &&
      !errorEmailExist
    ) {
      setDisabledButton(false);
    }
  };

  useEffect(() => {
    if (existPasswordValues()) {
      if (verificatePassword.password === securePassword.password) {
        passwordPassed();
        setEqualsPassword(true);
        return;
      }

      setEqualsPassword(false);
    }
  }, [securePassword, verificatePassword, errorEmailExist]);

  useEffect(() => {
    props.setPasswordsMatch(equalPassword);
    props.setTermsAccepted(termsAccepted);
  }, [equalPassword, termsAccepted]);

  return (
    <Fragment>
      <JobOfferPublicationTypeModal
        openModal={modalInformationIsOpen}
        setOpenModal={setModalInformationIsOpen}
      />

      <AuthContext.Consumer>
        {({ routerOnUserLogin }) => {
          return (
            <>
              <InformationModal
                modalOpenState={modalInformationIsOpen}
                config={{
                  bubbleText: 'Parabéns!',
                  ParagraphText: `Seu cadastro foi realizado com sucesso!
                   Deseja cadastrar uma vaga para o nosso banco de talentos?`,
                  buttonConfirmText: 'SIM, CADASTRAR VAGA',
                  showButtonCancel: true,
                  buttonCancelText: 'NÃO',
                }}
                buttonConfirmFunction={() => setOpenJobOfferModal(true)}
                buttonCancelFunction={() => {
                  setModalInformationIsOpen(false);
                  routerOnUserLogin();
                  window.location.reload();
                }}
              />
              <WrapperTitle>
                <H3Title universityColor={universityColor}>
                  Dados de acesso
                </H3Title>
              </WrapperTitle>
              <Form ref={formRef} initialData={props.objectSteps}>
                <UnformSelect
                  name="staff_role"
                  options={options.roles}
                  label="Cargo*"
                  placeholder="Selecione"
                  universityColor={universityColor}
                  labelWhite={true}
                />
                <UnformSelect
                  name="staff_sector"
                  options={options.sectors || []}
                  label="Setor*"
                  placeholder="Selecione"
                  universityColor={universityColor}
                  labelWhite={true}
                />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5em',
                  }}
                >
                  <Input
                    universityColor={universityColor}
                    name="staff_name"
                    label="Nome e sobrenome*"
                  />

                  <Input
                    name="email"
                    label="E-mail (este será o seu usuário para acessar a plataforma)*"
                    onBlur={handleBlurEmail}
                    universityColor={universityColor}
                  />
                </div>
                <WrapperPasswords>
                  <Input
                    name="staff_password"
                    label="Senha*"
                    type="password"
                    autocomplete="new-password"
                    onChange={handleChange}
                    universityColor={universityColor}
                  />
                  <Input
                    label="Confirme sua senha*"
                    type="password"
                    name="staff_password_confirmation"
                    onChange={handleVerifyPassword}
                    universityColor={universityColor}
                  />
                </WrapperPasswords>

                {existPasswordValues() && !equalPassword && (
                  <ParagraphError>Senhas não correspondem</ParagraphError>
                )}

                <WrapperVerifyPassword>
                  <ContainsH5 universityColor={universityColor}>
                    Sua senha deve conter:
                  </ContainsH5>

                  <WrapperVerifications>
                    <div>
                      <img
                        src={
                          securePassword.length ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Mínimo de 8 caracteres</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.letters ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Letras</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.specialCharacters
                            ? CheckedVector
                            : ErrorVector
                        }
                      />
                      <p>Caracteres especiais</p>
                    </div>

                    <div>
                      <img
                        src={
                          securePassword.numbers ? CheckedVector : ErrorVector
                        }
                      />
                      <p>Números</p>
                    </div>
                  </WrapperVerifications>
                </WrapperVerifyPassword>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '30px',
                  }}
                >
                  <Checkbox
                    universityColor={universityColor}
                    name="terms"
                    options={[
                      {
                        type: 'checkbox',
                        value: true,
                        label: 'Eu li e concordo com os',
                      },
                    ]}
                    onChange={handleTermsChange}
                  />
                  <a
                    href={termsLink}
                    target="_blank"
                    style={{
                      color: universityColor,
                      textDecoration: 'underline',
                    }}
                  >
                    Termos de uso da plataforma
                  </a>
                </div>
              </Form>
            </>
          );
        }}
      </AuthContext.Consumer>
    </Fragment>
  );
}
