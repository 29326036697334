import React, { Fragment, useEffect, useContext } from 'react';
import { AddButton, AddButtonIcon, CardAddNewMentorship } from './styles';
import { TabContent, TabContainer, LoadingCard } from '../../styles';
import ModalRequestMentorship from '../ModalRequestMentorship';
import { useMentorship } from '../../contexts/MentorshipContext';
import MentorshipCard from '../MentorshipCard';
import ModalCancelMentorship from '../ModalCancelMentorship';
import BaseLayoutContext from 'contexts/base-layout';

export default function ScheduledMentorshipTab() {
  const {
    openRequestMentorshipModal,
    currentMentorship,
    getCurrentMentorship,
  } = useMentorship();
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    getCurrentMentorship();
  }, []);

  return (
    <TabContainer>
      {currentMentorship === null ? (
        <LoadingCard>Carregando...</LoadingCard>
      ) : !!currentMentorship && !!currentMentorship.id ? (
        <Fragment>
          <p>
            Encontre profissionais com experiência no mercado e agende sua
            mentoria.
          </p>
          <TabContent universityColor={universityColor}>
            <h1 style={{ textAlign: 'center' }}>Mentorias em Agendamento</h1>
            <div>
              <MentorshipCard mentorship={currentMentorship} />
            </div>
          </TabContent>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            Agende sua Mentoria de Carreira com um profissional da sua
            Instituição de Ensino e tire todas as suas dúvidas em relação a
            construção do seu Plano de Carreira!
          </p>
          <CardAddNewMentorship color={universityColor || "#009291"}>
            <strong>Mentoria Agendada</strong>
            <p>
              Não encontramos Mentoria Agendada. Agende uma mentoria para seguir
              em frente
            </p>
            <AddButton onClick={openRequestMentorshipModal}>
              <div>
                <AddButtonIcon color={universityColor || "#009291"} />
                Agendar Mentoria
              </div>
            </AddButton>
          </CardAddNewMentorship>
        </Fragment>
      )}
      <ModalRequestMentorship />
      <ModalCancelMentorship />
    </TabContainer>
  );
}
