import { useEffect, useRef, useContext } from 'react';
import { LearningContext } from '../Context/LearningContext';

/**
 * Custom hook for pagination using intersection with a sentinel element.
 *
 * @param {Object} options - The configuration options for the IntersectionObserver.
 * @param {Array} array - The array of items to be paginated.
 * @param {number} arrayCount - The total number of items in the array.
 * @param {Function} getPagination - The function to fetch more items for the next page.
 * @param {number} currentPageArray - The index of the current page in the items array.
 * @param {number} limit - The maximum number of items per page.
 * @param {Object|null} queryFilter - The optional search filter to use in pagination.
 * @returns {Object} - An object containing a reference to the sentinel element.
 */

export const useIntersectionPagination = (
  options,
  array,
  arrayCount,
  getPagination,
  currentPageArray,
  limit,
  queryFilter
) => {
  const sentinelRef = useRef(null);

  const { setLoading } = useContext(LearningContext);

  const loadMoreItems = async () => {
    if (array === null || array.length === arrayCount) {
      return () => observer.unobserve(sentinelRef.current);
    }

    if (array.length === 0) {
      observer.unobserve(sentinelRef.current);
    }

    if (sentinelRef.current && array.length > limit - 1) {
      observer.unobserve(sentinelRef.current);
      if (array.length < arrayCount) {
        if (queryFilter === null) {
          setLoading(true);
          await getPagination(false, currentPageArray + limit, _);
          setLoading(false);
        }
        if (queryFilter !== null) {
          setLoading(true);
          await getPagination(false, currentPageArray + limit, queryFilter);
          setLoading(false);
        }
      }
    }
  };

  const callbackFunction = (entries) => {
    const [entry] = entries;

    if (entry.isIntersecting) {
      loadMoreItems();
    }
  };

  const observer = new IntersectionObserver(callbackFunction, options);

  useEffect(() => {
    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [array, arrayCount]);

  return { sentinelRef };
};
