import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import {
  ContainerCreateNewProfession,
  ContainerProfessions,
  P,
  ProfessionsP,
  SalaryWrapper,
  SpanRemove,
  WrapperCourses,
  WrapperGlobal,
  WrapperStepOne,
  FooterForm,
} from './style';
import { Form } from '@unform/web';
import {
  getBackOfficeProfessions,
  getBehaviorAttributes,
  getCourses,
  getInformationProfessions,
  getTechnicalSkills,
} from '../../services';
import Input from '../../../../StaffPages/Opportunities/Components/Form/input';
import InputDesc from '../../../../StaffPages/Opportunities/Components/Form/inputDesc';
import SearchableSelectUn from '../form/searchableSelect';

import Header from '../header';
import InputCurrency from '../form/inputCurrency';
import { courseProgess, currency } from './useCases/SelectOptions';

import { schemaValidationStepOne } from './useCases/schemaValidation';
import { NextButton } from '../../../Opportunities/Styles/formStyled';

import ModalInformation from '../informationModal';
import { getUniqueListBy } from '../../utils/listSet';
import { JobOfferContext } from '../../context/jobOfferContext';
import { useOutSideClick } from '../../../../JobOfferRebuild/hooks/useOutsideClick';
import { IModalConfigProfession } from './useCases/iModalConfig';
import { validationCurrency } from '../../../../../utils/validation';

export default function StepOneJobOffer() {
  const {
    setFormValues,
    nextJobOfferStep,
    prevsStepRegister,
    data,
    stepOneRef,
    optionsProfession,
    setOptionsProfession,
    jobOfferStep,
  } = useContext(JobOfferContext);
  const divRef = useRef(null);
  const divCourses = useRef(null);
  const divBehavior = useRef(null);
  const divTechnical = useRef(null);

  const [professions, setProfessions] = useState([]);

  const [errorNoValueId, setErrorNoValueId] = useState(false);

  const [studentCourses, setStudentCourses] = useState([]);
  const [apiTechnicalSkills, setApiTechnicalSkills] = useState([]);
  const [apiSkillsBehavior, setApiSkillsBehavior] = useState([]);

  const [cacheItens, setCacheItens] = useState({
    cacheProfessions: [],
    cacheBehavior: [],
    cacheTechnical: [],
    cacheCourses: [],
  });
  const [selectedProfession, setSelectedProfession] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [openDivProfessions, setOpenDivProfessions] = useState(false);

  const [controllerSearches, setControllerSearches] = useState({
    openSearchBehavior: false,
    openSearchTechnical: false,
    openSearchCourses: false,
  });
  const [coursesStudent, setCoursesStudent] = useState([]);
  const [associatedBehavioral, setAssociatedBehavioral] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);

  const [openModal, setOpenModal] = useState({
    openModal: false,
    neverOpen: true,
  });

  const [clickedOutside, SetClickedOutside] = useOutSideClick(divRef);

  const [clickedOutsideCourses, SetClickedOutsideCourses] = useOutSideClick(
    divCourses
  );
  const [clickedOutsideBehavior, SetClickedOutsideBehavior] = useOutSideClick(
    divBehavior
  );
  const [clickedOutsideTechnical, SetClickedOutsideTechnical] = useOutSideClick(
    divTechnical
  );

  const ScrollToFinalDiv = () => {
    if (divRef && divRef.current) {
      divRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  };

  if (clickedOutside) {
    if (openDivProfessions) {
      setOpenDivProfessions(false);
      SetClickedOutside(false);
    }
  }

  if (clickedOutsideCourses) {
    if (controllerSearches.openSearchCourses) {
      setControllerSearches((prevsState) => ({
        ...prevsState,
        openSearchCourses: false,
      }));
      SetClickedOutsideCourses(false);
    }
  }

  if (clickedOutsideBehavior) {
    if (controllerSearches.openSearchBehavior) {
      setControllerSearches((prevsState) => ({
        ...prevsState,
        openSearchBehavior: false,
      }));
      SetClickedOutsideBehavior(false);
    }
  }

  if (clickedOutsideTechnical) {
    if (controllerSearches.openSearchTechnical) {
      setControllerSearches((prevsState) => ({
        ...prevsState,
        openSearchTechnical: false,
      }));
      SetClickedOutsideTechnical(false);
    }
  }

  useEffect(() => {
    const searchProfessions = async () => {
      const response = await getBackOfficeProfessions();
      setProfessions(response);
      setCacheItens((prevsState) => ({
        ...prevsState,
        cacheProfessions: response,
      }));
    };

    const getTechnicalSkillsAPI = async () => {
      const response = await getTechnicalSkills();
      setApiTechnicalSkills(response);
      setCacheItens((prevsState) => ({
        ...prevsState,
        cacheTechnical: response,
      }));
    };

    const getSkillsBehavior = async () => {
      const response = await getBehaviorAttributes();
      setApiSkillsBehavior(response);
      setCacheItens((prevsState) => ({
        ...prevsState,
        cacheBehavior: response,
      }));
    };

    const getAddionalCourses = async () => {
      const response = await getCourses();
      setStudentCourses(response);
      setCacheItens((prevsState) => ({
        ...prevsState,
        cacheCourses: response,
      }));
    };

    searchProfessions();
    getTechnicalSkillsAPI();
    getSkillsBehavior();
    getAddionalCourses();
  }, []);

  useEffect(() => {
    stepOneRef.current.setFieldValue('profession', selectedProfession.label);

    if (
      selectedProfession.label &&
      openModal.neverOpen === true &&
      optionsProfession.id !== 'noValueId'
    ) {
      setOpenModal({ openModal: true });
    }

    if (selectedProfession.value) {
      getProfessionsInformation(selectedProfession.value);
    }
  }, [selectedProfession]);

  useEffect(() => {
    if (optionsProfession.associated_courses) {
      setCoursesStudent(optionsProfession.associated_courses);
    }
    if (optionsProfession.associated_behavioral) {
      setAssociatedBehavioral(optionsProfession.associated_behavioral);
    }
  }, [optionsProfession]);

  const getProfessionsInformation = async (id) => {
    if (optionsProfession && optionsProfession.id !== 'noValueId') {
      setOptionsProfession({});
    }
    const response = await getInformationProfessions(id);

    if (optionsProfession.id !== 'noValueId') {
      setOptionsProfession({
        ...response,
        course_progress: [response.course_progress],
        salary_coin: 'real',
      });
      stepOneRef.current.setData({ course_progress: response.course_progress });
    }

    if (optionsProfession.id === 'noValueId') {
      setOptionsProfession((prevs) => ({
        ...prevs,
        id: response.id,
      }));
    }
  };

  const professionsMap = () => {
    const handleClickProfession = (value) => {
      if (errorNoValueId) {
        setErrorNoValueId(false);
      }
      setSelectedProfession(value);
    };

    return professions?.map((value) => (
      <ProfessionsP onClick={() => handleClickProfession(value)}>
        {value.label}
      </ProfessionsP>
    ));
  };

  const filterProfessions = (e) => {
    setOpenDivProfessions(true);
    const value = e.target.value.toLowerCase();
    setInputValue(value);
    const filter = cacheItens.cacheProfessions.filter((item) =>
      item.label.toLowerCase().includes(value)
    );
    setProfessions(filter);
  };

  const mapAssociateCourses = () => {
    if (coursesStudent) {
      return coursesStudent.map((item) => (
        <P>
          {item.label}
          <SpanRemove
            onClick={() =>
              removeItem(setCoursesStudent, coursesStudent, item.value)
            }
          >
            X
          </SpanRemove>
        </P>
      ));
    }
  };

  const mapBehavioral = () => {
    if (associatedBehavioral) {
      return associatedBehavioral.map((item) => (
        <P>
          {item.label}
          <SpanRemove
            onClick={() =>
              removeItem(
                setAssociatedBehavioral,
                associatedBehavioral,
                item.value
              )
            }
          >
            X
          </SpanRemove>
        </P>
      ));
    }
  };

  const mapTechnicalSkills = () => {
    if (technicalSkills) {
      return technicalSkills.map((item) => (
        <P>
          {item.label}
          <SpanRemove
            onClick={() =>
              removeItem(setTechnicalSkills, technicalSkills, item.value)
            }
          >
            X
          </SpanRemove>
        </P>
      ));
    }
  };

  const handleSubmit = async (data) => {
    stepOneRef.current.setErrors({});

    if (optionsProfession.id === 'noValueId') {
      setErrorNoValueId(true);
      ScrollToFinalDiv();
      return;
    }

    try {
      const schema = schemaValidationStepOne;
      await schema.validate(
        { ...data, id: optionsProfession.id },
        {
          abortEarly: false,
        }
      );

      setFormValues({
        ...data,
        required_behavioral_attributes: associatedBehavioral,
        required_additional_courses_areas: technicalSkills,
        courses: coursesStudent,
        id: optionsProfession.id,
      });
      nextJobOfferStep();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepOneRef.current.setErrors(validationErrors);
        ScrollToFinalDiv();
      }
    }
  };

  const handleAppendItem = (item, setState) => {
    setState((prevsState) => getUniqueListBy([...prevsState, item], 'value'));
  };

  const populateNewSelection = (array, setState, currentArrayPopulated) => {
    const newCurrentArrayPopulated = currentArrayPopulated.reduce(
      (accumulator, item) => {
        accumulator[item.label] = item.value;
        return accumulator;
      },
      {}
    );

    const filterArray = array.filter((item) => {
      return !newCurrentArrayPopulated[item.label];
    });

    return filterArray.map((value) => (
      <ProfessionsP onClick={() => handleAppendItem(value, setState)}>
        {value.label}
      </ProfessionsP>
    ));
  };

  const removeItem = (setArray, arrayState, value) => {
    const array = [...arrayState];
    const newArray = array.filter((item) => item.value !== value);
    setArray(newArray);
  };

  const handleOpenCurrenteSearch = (currentOpen, e) => {
    e.preventDefault();
    if (controllerSearches[currentOpen] !== true) {
      setControllerSearches((prevsState) => ({
        ...prevsState,
        [currentOpen]: true,
      }));
    }
  };

  const filterCurrentSearch = (e, setArrayFunction, currentCache) => {
    const value = e.target.value.toLowerCase();
    const filter = cacheItens[currentCache].filter((item) =>
      item.label.toLowerCase().includes(value)
    );
    setArrayFunction(filter);
  };

  const resetItens = () => {
    setOptionsProfession({});
    setTechnicalSkills([]);
    setAssociatedBehavioral([]);
    setCoursesStudent([]);
  };

  const handleCreateNewAtuation = () => {
    resetItens();
    stepOneRef.current.setFieldValue('profession', '');
    setTimeout(() => {
      setOptionsProfession({ id: 'new' });
    }, 100);
  };

  useEffect(() => {
    if (data.id) {
      stepOneRef.current.setFieldValue('profession', data.profession);
      setOptionsProfession(data);
      setAssociatedBehavioral(data.required_behavioral_attributes);
      setTechnicalSkills(data.required_additional_courses_areas);
      setCoursesStudent(data.courses);
    }
  }, [data]);

  return (
    <WrapperStepOne>
      <ModalInformation
        modalOpenState={openModal.openModal}
        buttonFunction={() =>
          setOpenModal({ openModal: false, neverOpen: false })
        }
        config={IModalConfigProfession}
      />
      <Form
        ref={stepOneRef}
        initialData={data.length > 1 ? data : optionsProfession}
        onSubmit={handleSubmit}
      >
        <section>
          <main ref={divRef}>
            <Header header="Atuação/Cargo*" />

            <Input
              name="profession"
              type="text"
              placeholder="Digite..."
              onChange={filterProfessions}
            />
            {errorNoValueId && (
              <span style={{ color: '#f00' }}>
                Um elemento da lista deve ser selecionado
              </span>
            )}
            {openDivProfessions && (
              <Fragment>
                <ContainerProfessions>{professionsMap()}</ContainerProfessions>
              </Fragment>
            )}
          </main>
          {optionsProfession.id && (
            <Fragment>
              <section>
                <WrapperGlobal>
                  <Header
                    header="Título da Atuação Profissional*"
                    subTitle="Seja preciso ao incluir frases-chave que descrevam o cargo com precisão.Títulos com menos de 80 caracteres recebem mais visualizações."
                  />
                  <Input name="name" />
                </WrapperGlobal>

                <WrapperGlobal>
                  <Header
                    header="Descrição da Atuação"
                    subTitle="Descreva as principais responsabilidades do cargo, destaque as atividades diárias,  hierarquia do cargo e como a pessoa trabalhará em sua organização, ajudando o candidato a enxergar o panorama geral e entender como a função impacta o negócio."
                  />
                  <InputDesc name="description" />
                </WrapperGlobal>

                <WrapperGlobal>
                  <Header
                    header="Essa vaga destina-se para estudantes dos cursos"
                    subTitle="Caso queira que a vaga apareça para todos os estudantes de qualquer curso, deixe em branco esse campo."
                  />
                  <WrapperCourses>
                    {mapAssociateCourses()}
                    <button
                      onClick={(e) =>
                        handleOpenCurrenteSearch('openSearchCourses', e)
                      }
                    >
                      + Adicionar mais
                    </button>
                  </WrapperCourses>

                  {controllerSearches.openSearchCourses && (
                    <div ref={divCourses}>
                      <Input
                        name="Competencias_cursos_TESTE"
                        placeholder="Busque novos cursos"
                        onChange={(e) =>
                          filterCurrentSearch(
                            e,
                            setStudentCourses,
                            'cacheCourses'
                          )
                        }
                      />
                      <ContainerProfessions>
                        {populateNewSelection(
                          studentCourses,
                          setCoursesStudent,
                          coursesStudent
                        )}
                      </ContainerProfessions>
                    </div>
                  )}
                </WrapperGlobal>
              </section>

              <section>
                <WrapperGlobal>
                  <Header
                    header="Andamento Mínimo do Curso"
                    subTitle="Especifique a partir de qual período o estudante poderá se candidatar à sua vaga."
                  />
                  <SearchableSelectUn
                    name="course_progress"
                    options={courseProgess}
                  />
                </WrapperGlobal>
              </section>

              <section>
                <WrapperGlobal>
                  <Header
                    header="Faixa Salarial"
                    subTitle="Caso queira, informe ao estudante o valor que será pago mensalmente."
                  />
                  <SalaryWrapper>
                    <SearchableSelectUn name="salary_coin" options={currency} />
                    <Input
                      name="salary"
                      maxlength="10"
                      style={{ padding: '10px' }}
                      placeholder="Ex: 1.700"
                      onKeyUp={(event) =>
                        (event.target.value = validationCurrency(
                          event.target.value
                        ))
                      }
                    />
                  </SalaryWrapper>
                </WrapperGlobal>
              </section>

              <WrapperGlobal>
                <Header
                  header="Pré-requisitos obrigatórios"
                  subTitle="Especifique, se houver, pré-requisitos obrigatórios."
                />
                <Input
                  name="required_attributes"
                  placeholder="Ex. Ter carteira de motorista A e B ou disponibilidade para viagens nacionais e internacionais."
                />
              </WrapperGlobal>

              <section>
                <WrapperGlobal>
                  <Header
                    header="Competências Técnicas"
                    subTitle="Mantenha aqui somente os principais requisitos técnicos para sua vaga. "
                  />
                  <WrapperCourses>
                    {mapTechnicalSkills()}
                    <button
                      onClick={(e) =>
                        handleOpenCurrenteSearch('openSearchTechnical', e)
                      }
                    >
                      + Adicionar mais
                    </button>
                  </WrapperCourses>

                  {controllerSearches.openSearchTechnical && (
                    <div ref={divTechnical}>
                      <Input
                        name="Competencias_tecnicas_TESTE"
                        placeholder="Busque novas competências"
                        onChange={(e) =>
                          filterCurrentSearch(
                            e,
                            setApiTechnicalSkills,
                            'cacheTechnical'
                          )
                        }
                      />
                      <ContainerProfessions>
                        {populateNewSelection(
                          apiTechnicalSkills,
                          setTechnicalSkills,
                          technicalSkills
                        )}
                      </ContainerProfessions>
                    </div>
                  )}
                </WrapperGlobal>
              </section>

              <section>
                <WrapperGlobal>
                  <Header
                    header="Competências Comportamentais"
                    subTitle="Mantenha aqui somente 4 requisitos comportamentais para sua vaga."
                  />
                  <WrapperCourses>
                    {mapBehavioral()}
                    {associatedBehavioral.length < 4 && (
                      <button
                        onClick={(e) =>
                          handleOpenCurrenteSearch('openSearchBehavior', e)
                        }
                      >
                        + Adicionar mais
                      </button>
                    )}
                  </WrapperCourses>
                </WrapperGlobal>

                <section>
                  <Fragment>
                    {controllerSearches.openSearchBehavior &&
                    associatedBehavioral.length < 4 ? (
                      <div ref={divBehavior}>
                        <Input
                          name="Competencias_comportamentais_TESTE"
                          placeholder="Busque novas competências"
                          onChange={(e) =>
                            filterCurrentSearch(
                              e,
                              setApiSkillsBehavior,
                              'cacheBehavior'
                            )
                          }
                        />
                        <ContainerProfessions>
                          {populateNewSelection(
                            apiSkillsBehavior,
                            setAssociatedBehavioral,
                            associatedBehavioral
                          )}
                        </ContainerProfessions>
                      </div>
                    ) : null}
                  </Fragment>
                </section>
              </section>
              <FooterForm>
                <div></div>
                <NextButton />
              </FooterForm>
            </Fragment>
          )}
        </section>
      </Form>
    </WrapperStepOne>
  );
}
