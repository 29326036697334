import styled from 'styled-components';
import eyeIcon from 'assets/mentorship/eye.svg';
import requestedIcon from 'assets/mentorship/status-requested.svg';
import acceptedIcon from 'assets/mentorship/status-accepted.svg';
import rescheduledIcon from 'assets/mentorship/status-rescheduled.svg';
import canceledIcon from 'assets/mentorship/status-canceled.svg';
import expiredIcon from 'assets/mentorship/status-expired.svg';
import pinIcon from 'assets/mentorship/pin.svg';
import linkIcon from 'assets/mentorship/link.svg';
import selectedStar from 'assets/mentorship/small-selected-star.svg';
import unselectedStar from 'assets/mentorship/small-unselected-star.svg';

export const Container = styled.div`
  max-width: 100%;
  height: auto;
  padding: 2rem;
  border: 1.26px solid #c4c4c4;
  border-radius: 12px;

  h2 {
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 22px;
    line-height: 26px;
    margin: 0 0 5px 0;
    color: #4b4b4d;
  }

  span {
    font-family: Roboto, sans-serif;
    font-size: 13px;
    color: #c4c4c4;
  }

  > p {
    margin-top: 13px;
    color: #4b4b4d;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    margin-bottom: 32px;
  }
`;

function getStatusColor(status) {
  switch (status) {
    case 'requested':
      return '#009291';
    case 'realized':
      return '#068E2C';
    case 'accepted':
      return '#068E2C';
    case 'reschedule_request':
      return '#E9B600';
    case 'canceled':
      return '#DA0505';
    default:
      return '#606062';
  }
}

function getStatusBackgroundColor(status) {
  switch (status) {
    case 'requested':
      return '#D9FFE3';
    case 'realized':
      return '#D9FFE3';
    case 'accepted':
      return '#D9FFE3';
    case 'reschedule_request':
      return '#FFFDED';
    case 'canceled':
      return '#FFEDED';
    default:
      return '#F4F4F4';
  }
}

function getStatusIcon(status) {
  switch (status) {
    case 'requested':
      return requestedIcon;
    case 'realized':
      return acceptedIcon;
    case 'accepted':
      return acceptedIcon;
    case 'reschedule_request':
      return rescheduledIcon;
    case 'canceled':
      return canceledIcon;
    default:
      return expiredIcon;
  }
}

export const StatusMentorshipContainer = styled.div`
  background-color: ${(props) => getStatusBackgroundColor(props.status)};
  font-family: Roboto, sans-serif;
  color: ${(props) => getStatusColor(props.status)};
  height: 2rem;
  border-radius: 3.39px;
  width: auto;
  display: flex;
  gap: 0.5rem;
  padding: 0 0.5rem;
  align-items: center;
  margin-bottom: 1.75rem;
`;

export const IconContainer = styled.div`
  height: 21.54px;
  width: 21.54px;
  border-radius: 3.68px;
  background-color: ${(props) => getStatusColor(props.status)};
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  justify-content: center;
`;

export const StatusLabel = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => getStatusColor(props.status)};
  margin: 0;
`;

export const StatusIcon = styled.div`
  width: 15.26px;
  height: 16px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => getStatusIcon(props.status)});
`;

export const DetailButtonIcon = styled.div`
  width: 14px;
  height: 11.65px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${eyeIcon});
`;

export const RoundedButton = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  width: auto;
  height: 34px;
  border-radius: 20px;
  border: none;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 1rem;
  font-weight: 700;
  gap: 0.5rem;
  cursor: pointer;
  @media (max-width: 600px) {
    margin: 0 auto 18px;
    width: 100%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const RoundedOutlinedButton = styled.button`
  background-color: #fff;
  width: auto;
  height: 34px;
  border-radius: 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 1rem;
  font-weight: 700;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: 1.01px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  cursor: pointer;
  @media (max-width: 600px) {
    margin-bottom: ${(props) => props.noMarginBottom && '0'};
  }
`;

export const DetailMentorshipContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin-bottom: 2rem;
`;

export const DetailItem = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 0.5rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;

    strong {
      font-family: Roboto, sans-serif;
      font-size: 16px;
      line-height: 17px;
      font-weight: 500;
      color: #4b4b4d;
      @media (max-width: 600px) {
        font-size: 14px;
      }
      @media (min-width: 600px) {
        min-width: 200px;
      }
    }

    span {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 14px;
      color: #606062;
    }
  }
  @media (max-width: 600px) {
    font-size: 12px;

    strong {
      font-size: 14px;
    }
  }
`;

export const DetailIcon = styled.div`
  height: 36px;
  width: 36px;
  justify-content: center;
  align-items: center;
  /* background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => props.icon}); */
`;

export const Actions = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  @media (max-width: 600px) {
    flex-direction: column-reverse;
    gap: 5px;
  }
`;

export const LocalizationContainer = styled.div`
  font-family: Roboto, sans-serif;

  > div {
    display: flex;
    gap: 0.5rem;

    > strong {
      color: #4b4b4d;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }

  > p {
    color: #606062;
    font-size: 12px;
    line-height: 14px;
    max-width: 250px;
    margin: 0.625rem 0 1rem 0;
  }
`;

export const PinIcon = styled.div`
  width: 10px;
  height: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${pinIcon});
`;

export const ButtonAccessMentorship = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 12px 15px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  border-radius: 2.97px;
  text-decoration: none;
  margin-bottom: 1rem;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
  }
`;

export const LinkIcon = styled.div`
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${linkIcon});
`;

export const CancelButton = styled.button`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  background-color: #fff;
  @media (max-width: 600px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const CancellationReason = styled.div`
  font-family: Roboto, sans-serif;
  padding: 13px;
  background-color: #f4f4f4;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: #4b4b4d;
  margin: 1.5rem 0 0 0;
  border-radius: 3.79px;
  word-break: break-all;

  span {
    color: #4b4b4d;
    font-weight: 700;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #777777b2;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const StarIcon = styled.div`
  height: 14px;
  width: 14px;
  background-image: url(${(props) =>
    props.selected ? selectedStar : unselectedStar});
  background-repeat: no-repeat;
  background-position: center;
`;

export const ButtonFile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 170px;
  height: 30px;
  padding: 10px;

  background-color: ${(props) => props.universityColor || '#009291'};

  font-weight: 700;
  font-size: 12px;
  color: #fff;

  border: none;
  border-radius: 20px;

  cursor: pointer;
`;
export const FragmentFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 9rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    > div {
      align-self: flex-start;
    }
  }
`;
