import styled from 'styled-components';
import calendarGreenIcon from 'assets/mentorship/calendar-green.svg';
import morningIcon from 'assets/mentorship/morning.svg';
import afternoonIcon from 'assets/mentorship/afternoon.svg';
import eveningIcon from 'assets/mentorship/evening.svg';
import leftArrow from 'assets/Oportutinites/Setaesquerda.svg';

export const Container = styled.div`
  width: 720px;

  h1 {
    color: #009291;
    line-height: 24px;
    font-size: 21px;
    margin: 2rem 0 0 0;
    font-weight: 700;
  }

  > p {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 400;
    margin: 1rem 0 0 0;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 1rem 0 0 0;
  }
`;

export const PeriodPreferences = styled.div`
  display: flex;
  height: 98px;
  width: 100%;
  gap: 22px;
  margin: 15px 0 0 0;
`;

export const DayContainer = styled.div`
  width: 85px;
  height: 98px;
  border-radius: 12.22px;
  border: 0.49px solid #c4c4c4;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > strong {
    text-align: center;
    color: #4b4b4d;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }
`;

export const CalendarIcon = styled.div`
  width: 19.35px;
  height: 19.33px;
  justify-content: center;
  align-items: center;
  background-image: url(${calendarGreenIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const PeriodsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: #f4f4f4;
  border-radius: 10.47px;
  margin: 0 0.5rem;
  width: calc(100% - 1rem);
`;

function getIcon(period) {
  switch (period) {
    case 'morning':
      return morningIcon;
    case 'afternoon':
      return afternoonIcon;
    default:
      return eveningIcon;
  }
}

export const PeriodIcon = styled.div`
  width: 11px;
  height: 17px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) => getIcon(props.period)});
`;

export const PeriodsLegend = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0 0 0;
  padding: 10px 15px;
  background-color: #f4f4f4;
  gap: 0.5rem;
  border-radius: 7px;
  width: fit-content;

  strong {
    color: #4b4b4d;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  span {
    color: #4b4b4d;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
  }
`;

export const SendButton = styled.button`
  background-color: #009291;
  width: 220px;
  max-width: 100%;
  height: 38px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
  justify-content: center;
  margin: 0 0 0 auto;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const BackButton = styled.button`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 2.5rem;
  bottom: 2.5rem;
  border: none;
  padding: 0;
  cursor: pointer;
  background: #009291 url(${leftArrow}) no-repeat center;
`;

export const ButtonsDiv = styled.div`
  width: 50%;
  margin-left: auto;
`;
