import React, { Fragment, useContext } from 'react';
import SearchableSelect from 'react-select';
import {
  Dialog,
  FormControl,
  TextField,
  withStyles,
  InputLabel,
  InputBase,
  FormHelperText,
} from '@material-ui/core';
import classNames from 'classnames';
import { Formik } from 'formik';
import { bool, func, number, shape } from 'prop-types';
import Button from 'components/CustomButtons/Button';
import BaseLayoutContext from 'contexts/base-layout';
import { FeedbackContainer } from './styled';
import FeedbackTalentsStyles from './styled/FeedbackTalentsStyles';
import { postAnalysisFeedback } from '../../services/university/students';

import {
  approveFeedbackSchema,
  failedFeedbackSchema,
} from './schemas/feedbackFormSchemas';

const qualquer = [
  {
    label: 'Atividade de Extensão',
    value: 'extension',
  },
  {
    label: 'Atividade Complementar',
    value: 'complementary',
  },
];

function FeedbackModal({
  isOpen,
  setClose,
  analysisStatus,
  classes,
  id,
  workloadInitialValue,
  competencesInitialValues,
  handleUpdateAnalysisStatus,
}) {
  const { openSnackbar, closeModal } = useContext(BaseLayoutContext);

  const initialValues = analysisStatus
    ? {
        activityType: { label: '', value: '' },
        dedicatedHours: workloadInitialValue,
        competences: competencesInitialValues,
        feedback: '',
      }
    : { feedback: '' };

  async function handleClick(values) {
    const {
      activityType,
      dedicatedHours: workload,
      competences,
      feedback,
    } = values;

    if (!analysisStatus) {
      const response = await postAnalysisFeedback(id, { feedback }, 'failed');

      if (!response.message) {
        handleUpdateAnalysisStatus('failed', id, response);
        setClose();
        openSnackbar('Análise realizada com sucesso!');
        closeModal();
      } else
        openSnackbar(
          'Ocorreu um erro, tente novamente mais tarde! Caso o erro persistir entre em contato com o suporte.',
          true
        );
      return;
    }

    const acquired_knowledges = competences.map((item) => item.value);
    const activity_type = activityType.value;

    const response = await postAnalysisFeedback(
      id,
      { activity_type, workload, acquired_knowledges, feedback },
      'approved'
    );

    if (!response.message) {
      handleUpdateAnalysisStatus('approve', id, response);
      setClose();
      openSnackbar('Análise realizada com sucesso!');
      closeModal();
    } else
      openSnackbar(
        'Ocorreu um erro, tente novamente mais tarde! Caso o erro persistir entre em contato com o suporte.',
        true
      );
  }

  return (
    <Dialog
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
      }}
      open={isOpen}
      onBackdropClick={() => setClose()}
      maxWidth="md"
      fullWidth
    >
      <FeedbackContainer>
        <header>
          {analysisStatus ? (
            <h2>Validação da Análise</h2>
          ) : (
            <h2>Recusa da Análise</h2>
          )}

          <button
            type="button"
            onClick={() => {
              setClose();
            }}
          >
            +
          </button>
        </header>
        <article>
          <Formik
            enableReinitialize
            onSubmit={(values) => {
              handleClick(values);
            }}
            validationSchema={
              analysisStatus ? approveFeedbackSchema : failedFeedbackSchema
            }
            initialValues={initialValues}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {analysisStatus && (
                    <Fragment>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel
                          htmlFor="activityType"
                          shrink
                          error={errors.activityType && touched.activityType}
                        >
                          Tipo de Atividade *
                        </InputLabel>
                        <SearchableSelect
                          name="activityType"
                          id="activityType"
                          isClearable={false}
                          InputLabel={{
                            shrink: true,
                          }}
                          variant="filled"
                          menuPlacement="bottom"
                          isSearchable
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.select,
                            classes.selectAutoHeight,
                          ])}
                          onBlur={handleBlur}
                          onChange={(event) => {
                            if (event) {
                              setFieldValue('activityType', event);
                            }
                          }}
                          placeholder=""
                          options={qualquer}
                          value={values.activityType}
                        />
                        {errors.activityType && touched.activityType ? (
                          <FormHelperText style={{ color: 'red' }}>
                            {errors.activityType.label}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        onChange={handleChange}
                        variant="filled"
                        name="dedicatedHours"
                        id="dedicatedHours"
                        type="number"
                        label="Quantidade de horas validadas *"
                        value={values.dedicatedHours}
                      />
                      {errors.dedicatedHours && touched.dedicatedHours ? (
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.dedicatedHours}
                        </FormHelperText>
                      ) : null}
                      <FormControl variant="filled" fullWidth>
                        <InputLabel
                          htmlFor="competences"
                          shrink
                          error={errors.competences && touched.competences}
                        >
                          Competências Validadas
                        </InputLabel>
                        <SearchableSelect
                          name="competences"
                          id="competences"
                          isClearable={false}
                          InputLabel={{
                            shrink: true,
                          }}
                          isMulti
                          variant="filled"
                          menuPlacement="bottom"
                          isSearchable
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.select,
                            classes.selectAutoHeight,
                          ])}
                          onBlur={(event) =>
                            setFieldTouched('competences', event.label)
                          }
                          onChange={(event) => {
                            if (event) {
                              setFieldValue('competences', event);
                            } else {
                              setFieldValue('competences', '');
                            }
                          }}
                          placeholder=""
                          options={competencesInitialValues || []}
                          value={values.competences}
                        />
                        {errors.competences && touched.competences ? (
                          <FormHelperText style={{ color: 'red' }}>
                            {errors.competences}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Fragment>
                  )}
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="feedback"
                      shrink
                      error={errors.feedback && touched.feedback}
                    >
                      {analysisStatus
                        ? 'Feedback ao Estudante'
                        : 'Feedback ao Estudante *'}
                    </InputLabel>

                    <InputBase
                      className={classes.textareaInput}
                      InputProps={{
                        className: classes.input,
                        style: { backgroundColor: '#f5f5f5' },
                      }}
                      multiline
                      InputLabelProps={{
                        shrink: true,
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      onChange={handleChange}
                      variant="filled"
                      name="feedback"
                      id="feedback"
                      placeholder="Indique ao estudante pontos de melhorias ou o motivo da não validação dessa solicitação"
                      type="text"
                      value={values.feedback}
                    />
                    {errors.feedback && touched.feedback ? (
                      <FormHelperText style={{ color: 'red' }}>
                        {errors.feedback}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                  <Button
                    type="submit"
                    className={classes.extracourseModalButtons}
                  >
                    ENVIAR ANÁLISE
                  </Button>
                </form>
              );
            }}
          />
        </article>
      </FeedbackContainer>
    </Dialog>
  );
}

FeedbackModal.propTypes = {
  isOpen: bool.isRequired,
  setClose: func.isRequired,
  classes: shape.isRequired,
  analysisStatus: shape.isRequired,
  id: number.isRequired,
  workloadInitialValue: number.isRequired,
  competencesInitialValues: shape.isRequired,
  handleUpdateAnalysisStatus: func.isRequired,
};

export default withStyles(FeedbackTalentsStyles)(FeedbackModal);
