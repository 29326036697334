import React, { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';

import { BoxIcon, Container } from './styles';
import CheckIcon from 'assets/ComponentIcons/CheckIcon';

export default function SuccessModal({ title, description }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Container universityColor={universityColor}>
      <BoxIcon>
        <CheckIcon color={universityColor || "#009291"}/>
      </BoxIcon>
      <h2>{title}</h2>
      {description && <p>{description}</p>}
    </Container>
  );
}
