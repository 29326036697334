import React, { useEffect } from 'react';
import { useMentorship } from '../../contexts/MentorshipContext';
import { MentorshipList } from '../MentorshipList';
import { ActionButton, Container, ContainerEmpty } from '../../styles';
import MentorshipListSkeleton from '../MentorshipListSkeleton';

export default function MentorshipHistoricTab({
  setActiveTab,
  openModalWithContent,
  handleIsLoadingState,
  openSnackbar,
  closeModal,
  updateHasCoverLetter,
  activeTab,
}) {
  const { historicMentorships, getHistoricMentorships } = useMentorship();

  useEffect(() => {
    getHistoricMentorships();
  }, []);

  return (
    <Container>
      <strong>Faça a gestão das mentorias solicitadas pelos estudantes</strong>
      <span>
        Você poderá aceitar a solicitação e definir o melhor horário e dia para
        prestar a mentoria.
      </span>
      {historicMentorships === null ? (
        <MentorshipListSkeleton />
      ) : historicMentorships.length > 0 ? (
        <MentorshipList
          activeTab={activeTab}
          closeModal={closeModal}
          openModalWithContent={openModalWithContent}
          handleIsLoadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          updateHasCoverLetter={updateHasCoverLetter}
          mentorships={historicMentorships}
        />
      ) : (
        <ContainerEmpty>
          <GenericMessage
            title="No momento, não há nenhuma mentoria no histórico. Confira as
            solicitações de mentorias."
            buttonLabel="Agendar mentoria"
            buttonFunc={() => setActiveTab(0)}
          />
        </ContainerEmpty>
      )}
    </Container>
  );
}
