import React, { useEffect } from 'react';
import { ActionButton, Container, ContainerEmpty } from '../../styles';
import { useMentorship } from '../../contexts/MentorshipContext';
import { MentorshipList } from '../MentorshipList';
import MentorshipListSkeleton from '../MentorshipListSkeleton';

export default function MentorshipSolicitationTab({
  setActiveTab,
  openModalWithContent,
  handleIsLoadingState,
  openSnackbar,
  closeModal,
  updateHasCoverLetter,
}) {
  const { requestedMentorships, getRequestedMentorships } = useMentorship();

  useEffect(() => {
    getRequestedMentorships();
  }, []);

  return (
    <Container>
      <strong>Faça a gestão das mentorias solicitadas pelos estudantes</strong>
      <span>
        Você poderá aceitar a solicitação e definir o melhor horário e dia para
        prestar a mentoria.
      </span>
      {requestedMentorships === null ? (
        <MentorshipListSkeleton />
      ) : requestedMentorships.length > 0 ? (
        <MentorshipList
          closeModal={closeModal}
          openModalWithContent={openModalWithContent}
          handleIsLoadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          updateHasCoverLetter={updateHasCoverLetter}
          mentorships={requestedMentorships}
        />
      ) : (
        <ContainerEmpty>
          <strong>
            No momento, não há nenhuma solicitação de mentoria disponível.
            Confira as mentorias já agendadas
          </strong>
          <ActionButton onClick={() => setActiveTab(1)}>
            Visualizar
          </ActionButton>
        </ContainerEmpty>
      )}
    </Container>
  );
}
