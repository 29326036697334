import {
  radioDna,
  dangerColor,
  dnaGreen,
  grayColor,
  grayLightColor,
  dnaColor,
  dnaDisabled,
  cardTitle,
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  orangeIE,
  yellowIESradio,
  lightBlueIESradio,
  orangeIESradio,
  blueIESradio,
  darkBlueIESradio,
  greenIESradio,
  redIESradio,
  primaryradio,
  darkBlueIEDisabled,
  redIEDisabled,
  greenIEDisabled,
  yellowIEDisabled,
  blueIEDisabled,
  lightBlueIEDisabled,
  orangeIEDisabled,
} from 'assets/jss/material-dashboard-pro-react';

import cardBodyStyle from 'assets/jss/material-dashboard-pro-react/components/cardBodyStyle';
import cardStyle from 'assets/jss/material-dashboard-pro-react/components/cardStyle';
import styled from 'styled-components';
import { OutlinedInput } from '@material-ui/core';
import learningTrailsStyle from '../learningTrailsStyle';

const professionalExperienceFormStyles = (theme) => ({
  ...learningTrailsStyle(theme),
  jobFormDescriptions: {
    marginTop: -8,
    marginLeft: 15,
  },
  cardHeader: {
    alignItems: 'center',
  },
  cardIcon: {
    boxShadow: 'none',
    display: 'flex',
    padding: 0,
    marginTop: 0,
  },
  icon: {
    margin: '0 auto',
    '& img': {
      verticalAlign: 'top',
    },
  },
  jobRadioDescription: {
    opacity: 0.87,
    fontSize: '0.875rem',
  },
  gridProfessionalStatus: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  titleFormQuestion: {
    ...cardTitle,
    color: '#262626',
    fontWeight: 'bold',
    fontSize: 17,
    marginTop: 0,
    width: 638,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
    textAlign: 'left',
    '@media only screen and (max-width: 370px)': {
      fontSize: 15,
    },
  },
  cardCompositionTitle: {
    ...cardTitle,
    fontSize: 20,
    fontWeight: 400,
    marginTop: 20,
    paddingRight: 30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 15,
    },
  },
  card: {
    ...cardStyle.card,
  },
  cardBody: {
    ...cardBodyStyle.cardBody,
  },
  centeredForm: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  cardTitle: {
    ...cardTitle,
    fontSize: 20,
    fontWeight: 400,
  },
  timeInputContainer: {
    display: 'flex',
    marginTop: 12,
  },
  timeInput: {
    marginRight: 12,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: 12,
    width: '100%',
  },
  cardList: {
    backgroundColor: '#FFF',
    maxWidth: 800,
    borderRadius: 10,
    padding: '30px 0 10px',
    marginBottom: '30px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 20px 5px',
    },
  },
  fullScreenGridCompanyRegistration: {
    width: '100%',
    alignItems: 'center',
    paddingRight: 15,
    paddingLeft: 15,
  },
  listItemCompanyRegistration: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 700,
    margin: '0 auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 800,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  listItemReset: {
    paddingTop: 0,
    marginTop: '-12px',
  },
  locationListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 800,
    margin: '0 auto',
    flexWrap: 'wrap',
    '& > p': {
      textAlign: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      flexDirection: 'column',
      '& > div': {
        width: '100% !important',
      },
      '& > div:nth-child(1)': {
        marginBottom: '24px',
      },
    },
  },
  listTitle: {
    fontWeight: 'bold',
    width: '100%',
  },
  listItemPaddingTopZero: {
    paddingTop: 0,
  },
  listItemPaddingBottomZero: {
    paddingBottom: 0,
  },
  listItemPaddingZeroSides: {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    '& > p': {
      width: '100%',
    },
  },
  listItemSelect: {
    maxWidth: 545,
    margin: '0 auto',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },

  inputField: {
    color: `#606062 !important`,
    borderBottom: 0,
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& root': {
      backgroundColor: '#000 !important',
      borderRadius: 10,
    },
  },

  inputFieldMd: {
    width: '95%',
  },
  input: {
    color: 'rgba(0, 0, 0, 0.54)',
    '& root': {
      backgroundColor: '#fff!important',
      borderRadius: 10,
    },
    borderRadius: 10,
    backgroundColor: '#fff',
    '&:before': {
      borderBottom: 0,
      borderRadius: 10,
    },
    '&:after': {
      borderBottom: 0,
      borderRadius: 10,
      backgroundColor: 'transparent',
      visibility: 'hidden',
    },
    '&:hover:before': {
      backgroundColor: '#fff',
      borderBottom: 0,
      borderRadius: 10,
      visibility: 'hidden',
    },
    '&:focus': {
      backgroundColor: '#fff',
      color: 'black',
    },
    '&:active': {
      backgroundColor: '#fff',
      color: 'black',
    },
    '& focused': {
      backgroundColor: '#fff',
      borderBottom: 0,
    },
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:after:hover': {
      backgroundColor: 'transparent',
    },
  },
  inputErrorCep: {
    color: 'rgba(0, 0, 0, 0.54)',
    '& root': {
      backgroundColor: '#FFEDED !important',
      borderRadius: 10,
    },
    borderRadius: 10,
    backgroundColor: '#FFEDED',
    border: '1px solid #FFCECE',
    '&:before': {
      borderBottom: 0,
      borderRadius: 10,
    },
    '&:after': {
      borderBottom: 0,
      borderRadius: 10,
      backgroundColor: 'transparent',
      visibility: 'hidden',
    },
    '&:hover:before': {
      backgroundColor: '#FFEDED',
      borderBottom: 0,
      borderRadius: 10,
      visibility: 'hidden',
    },
    '&:focus': {
      backgroundColor: '#FFEDED',
      color: 'black',
    },
    '&:active': {
      backgroundColor: '#FFEDED',
      color: 'black',
    },
    '& focused': {
      backgroundColor: '#FFEDED',
      borderBottom: 0,
    },
    '&:hover': {
      backgroundColor: '#FFEDED',
    },
    '&:after:hover': {
      backgroundColor: 'transparent',
    },
  },
  textFieldSelect: {
    width: '100%',
    '&:first-child': {
      marginRight: 20,
    },
  },
  selectSection: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
    fontSize: 17,
    '& .react-select__menu': {
      zIndex: 999,
    },
    '& > div:nth-child(1)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        marginTop: 15,
      },
    },
    '& > div:nth-child(2)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        marginTop: 15,
      },
    },
  },
  selectSectionCandidate: {
    width: 545,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
    fontSize: '1rem',
    '& .react-select__menu': {
      zIndex: 999,
    },
    '& > div:nth-child(1)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        '& > span': {
          display: 'none',
        },
      },
    },
    '& > div > div': {
      marginTop: 8,
    },
  },
  selectSectionCustom: {
    width: 545,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
    fontSize: 17,
    '& .react-select__menu': {
      zIndex: 999,
    },
    '& > div:nth-child(1)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      fontWeight: 400,
      '& > div': {
        marginLeft: 4,
        marginBottom: 10,
        '& > span': {
          display: 'none',
        },
      },
    },
    '& > div:nth-child(2)': {
      height: 56,
      border: 0,
      borderRadius: 10,
      '& > div': {
        marginLeft: 4,
        marginBottom: 10,
      },
    },
  },
  selectStyle: {
    borderWidth: 1,
    borderColor: grayLightColor,
    borderStyle: 'solid',
    borderRadius: 10,
  },
  selectWidth: {
    position: 'relative',
    minWidth: 300,
  },
  coursesDropdownHolder: {
    width: '100%',
    '& $selectSection': {
      '& > div:nth-child(1)': {
        minHeight: 65,
        height: 'auto',
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 10,
          marginBottom: 5,
        },
      },
      '& > div:nth-child(2)': {
        minHeight: 65,
        height: 'auto',
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 10,
          marginBottom: 5,
        },
      },
    },
  },
  courseProfessionGroup: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  customSelectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    position: 'relative',
    paddingLeft: 12,
    paddingRight: 8,
    width: 30,
    height: 30,
    marginLeft: 20,
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContainer: 'center',
    alignItems: 'center',
    marginLeft: 16,
  },
  professionDropdownHolder: {
    float: 'left',
    marginTop: 20,
    width: '100%',
    paddingLeft: '5%',
    '& > label': {
      paddingLeft: '7%',
    },
    '& $selectSection': {
      '& > div:nth-child(1)': {
        height: 46,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 3,
        },
      },
      '& > div:nth-child(2)': {
        height: 46,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 3,
        },
      },
    },
  },
  dropDownMarginLeft: {
    marginLeft: '5%',
  },
  labelMarginBottom: {
    marginBottom: '0.2rem',
  },
  selectSectionCard: {
    width: '100%',
    borderWidth: 1,
    borderColor: grayLightColor,
    borderStyle: 'solid',
    borderRadius: 10,
  },
  selectSectionCardCustom: {
    width: '100%',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.54)',
    borderStyle: 'solid',
    borderRadius: 10,
  },

  selectAutoHeight: {
    '& > div:nth-child(1)': {
      paddingTop: 10,
      height: 'auto',
      marginBottom: 7,
    },
    '& > div:nth-child(2)': {
      paddingTop: 10,
      height: 'auto',
      marginBottom: 7,
    },
  },
  selectSectionAutoHeight: {
    '& > div:nth-child(1)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:nth-child(2)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:last-child': {
      display: 'none',
    },
  },

  selectSectionAutoHeightFilter: {
    '& > div:nth-child(1)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:nth-child(2)': {
      paddingTop: 10,
      height: 'auto',
    },
  },

  selectControlHeight: {
    '& > div:nth-child(1)': {
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:nth-child(2)': {
      paddingTop: 10,
      height: 'auto',
    },
  },
  selectSectionAutoHeightFullPlaceholder: {
    '& > div:nth-child(1)': {
      fontWeight: 400,
      paddingTop: 10,
      paddingBottom: 10,
      height: 'auto',
    },
    '& > div:nth-child(2)': {
      paddingBottom: 10,
      paddingTop: 10,
      height: 'auto',
    },
    '& > div:last-child': {
      display: 'none',
    },
  },
  saveButton: {
    margin: '20px auto',
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  zipCodeList: {
    width: '100%',
    '& li': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  zipCodeButton: {
    marginLeft: 15,
    backgroundColor: dnaGreen,
    color: '#fff',
  },

  dnaColorZipCodeButton: {
    marginLeft: 15,
    backgroundColor: dnaGreen,
    color: '#fff',
  },

  redIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${redIE}`,
    color: '#fff',
  },

  greenIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${greenIE}`,
    color: '#fff',
  },

  yellowIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${yellowIE}`,
    color: '#fff',
  },

  blueIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${blueIE}`,
    color: '#fff',
  },

  darkBlueIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${darkBlueIE}`,
    color: '#fff',
  },

  lightBlueIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${lightBlueIE}`,
    color: '#fff',
  },

  orangeIESZipCodeButton: {
    marginLeft: 15,
    backgroundColor: `${orangeIE}`,
    color: '#fff',
  },

  deleteButton: {
    color: '#fff',
    backgroundColor: dangerColor,
  },
  checkBox: {
    color: `${dnaColor} !important `,
  },

  dnaColorcheckBox: {
    color: `${dnaGreen} !important `,
  },

  yellowIEScheckBox: {
    color: `${yellowIE} !important `,
  },

  redIEScheckBox: {
    color: `${redIE} !important `,
  },

  greenIEScheckBox: {
    color: `${greenIE} !important `,
  },

  darkBlueIEScheckBox: {
    color: `${darkBlueIE} !important `,
  },

  blueIEScheckBox: {
    color: `${blueIE} !important `,
  },

  lightBlueIEScheckBox: {
    color: `${lightBlueIE} !important `,
  },

  orangeIEScheckBox: {
    color: `${orangeIE} !important `,
  },

  checkboxLabelControl: {
    width: 445,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '0 auto',
  },
  textFieldDatePicker: {
    width: '100%',
  },
  fullWidth: {
    width: '100% !important',
  },
  selectBoxLevel: {
    width: '100%',
    zIndex: '2',
    '& > div': {
      border: 'none',
      padding: '3px 2px',
    },
  },
  cardCategory: {
    color: '#000',
    fontSize: '18px',
    paddingTop: '10px',
    margin: '0',
    lineHeight: '1.7em',
  },
  radioInput: {
    ...radioDna,
  },

  primaryradio: {
    ...primaryradio,
  },

  yellowIESradio: {
    ...yellowIESradio,
  },

  redIESradio: {
    ...redIESradio,
  },

  greenIESradio: {
    ...greenIESradio,
  },

  darkBlueIESradio: {
    ...darkBlueIESradio,
  },

  blueIESradio: {
    ...blueIESradio,
  },

  lightBlueIESradio: {
    ...lightBlueIESradio,
  },

  orangeIESradio: {
    ...orangeIESradio,
  },

  dnaColorradio: {
    ...radioDna,
  },

  radioLabel: {
    fontSize: 17,
  },
  radioLabelFluid: {
    marginRight: 0,
    '& > span:first-child': {
      paddingRight: 0,
    },
    '& > span:last-child': {
      paddingLeft: 28,
      width: '100%',
    },
  },
  optionsLabelFluid: {
    marginRight: 0,
    '& > span:first-child': {
      paddingRight: 0,
    },
    '& > span:last-child': {
      paddingLeft: 15,
      width: '100%',
    },
  },
  divider: {
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  dividerAcademicLevel: {
    marginTop: 25,
    marginBottom: 20,
  },
  dot: {
    borderRadius: 50,
    border: `2px solid ${grayColor}`,
    color: grayColor,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: 17,
    fontWeight: 400,
    marginRight: 15,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: '#fff',
    border: `2px solid ${dnaColor}`,
    backgroundColor: dnaColor,
  },
  companyLogoHolder: {
    paddingTop: 65,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 33,
  },
  zeroMarginBottom: {
    marginBottom: 0,
  },
  radioLabelControl: {
    justifyContent: 'space-between',
    margin: 0,
  },
  error: {
    color: '#f44336',
  },
  inputError: {
    border: '1px solid #f44336',
    borderRadius: 10,
  },
  inlineGroup: {
    display: 'flex !important',
    flexDirection: 'row',
  },
  alignVerticalCenter: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  listFlex: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  switchBase: {
    color: `${dnaColor} !important`,
  },
  dnaColorSwitchBase: {
    color: `${dnaGreen} !important`,
  },
  dnaColorChecked: {
    color: `${dnaGreen}`,
  },
  bar: {
    color: 'gray',
    backgroundColor: `${dnaColor} !important`,
  },
  dnaColorBar: {
    color: 'gray',
    backgroundColor: `${dnaDisabled} !important`,
  },

  redIESSwitchBase: {
    color: `${redIE} !important`,
  },
  redIESChecked: {
    color: `${redIE}`,
  },
  redIESNoChecked: {
    color: `gray !important`,
  },
  redIESBar: {
    color: 'gray',
    backgroundColor: `${redIEDisabled} !important`,
  },

  yellowIESSwitchBase: {
    color: `${yellowIE} !important`,
  },
  yellowIESChecked: {
    color: `${yellowIE}`,
  },
  yellowIESNoChecked: {
    color: `gray !important`,
  },
  yellowIESBar: {
    color: 'gray',
    backgroundColor: `${yellowIEDisabled} !important`,
  },

  greenIESSwitchBase: {
    color: `${greenIE} !important`,
  },
  greenIESChecked: {
    color: `${greenIE}`,
  },
  greenIESNoChecked: {
    color: `gray !important`,
  },
  greenIESBar: {
    color: 'gray',
    backgroundColor: `${greenIEDisabled} !important`,
  },

  darkBlueIESSwitchBase: {
    color: `${darkBlueIE} !important`,
  },
  darkBlueIESChecked: {
    color: `${darkBlueIE}`,
  },
  darkBlueIESNoChecked: {
    color: `gray !important`,
  },
  darkBlueIESBar: {
    color: 'gray',
    backgroundColor: `${darkBlueIEDisabled} !important`,
  },

  blueIESSwitchBase: {
    color: `${blueIE} !important`,
  },
  blueIESChecked: {
    color: `${blueIE}`,
  },
  blueIESNoChecked: {
    color: `gray !important`,
  },
  blueIESBar: {
    color: 'gray',
    backgroundColor: `${blueIEDisabled} !important`,
  },

  lightBlueIESSwitchBase: {
    color: `${lightBlueIE} !important`,
  },
  lightBlueIESChecked: {
    color: `${lightBlueIE}`,
  },
  lightBlueIESNoChecked: {
    color: `gray !important`,
  },
  lightBlueIESBar: {
    color: 'gray',
    backgroundColor: `${lightBlueIEDisabled} !important`,
  },

  orangeIESSwitchBase: {
    color: `${orangeIE} !important`,
  },
  orangeIESChecked: {
    color: `${orangeIE}`,
  },
  orangeIESNoChecked: {
    color: `gray !important`,
  },
  orangeIESBar: {
    color: 'gray',
    backgroundColor: `${orangeIEDisabled} !important`,
  },

  fieldLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  inputFieldApplicationReason: {
    color: `${dnaGreen} !important`,
    borderBottom: 0,
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& root': {
      backgroundColor: '#fff!important',
      borderRadius: 10,
    },
  },
  inputProps: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 12,
    '& root': {
      backgroundColor: '#e9e9e9!important',
      borderRadius: 10,
    },
    borderRadius: 10,
    backgroundColor: '#e9e9e9',
    '&:before': {
      borderBottom: 0,
      borderRadius: 10,
    },
    '&:after': {
      borderBottom: 0,
      borderRadius: 10,
      backgroundColor: 'transparent',
      visibility: 'hidden',
    },
    '&:hover:before': {
      backgroundColor: '#e9e9e9',
      borderBottom: 0,
      borderRadius: 10,
      visibility: 'hidden',
    },
    '&:focus': {
      backgroundColor: '#e9e9e9',
      color: 'black',
    },
    '&:active': {
      backgroundColor: '#e9e9e9',
      color: 'black',
    },
    '& focused': {
      backgroundColor: '#e9e9e9',
      borderBottom: 0,
    },
    '&:hover': {
      backgroundColor: '#e9e9e9',
    },
    '&:after:hover': {
      backgroundColor: 'transparent',
    },
  },
  textAlgR: {
    textAlign: 'right',
  },
  textAlgC: {
    textAlign: 'center',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  professionListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 800,
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  padding20: {
    padding: 20,
  },
  textJustify: {
    textAlign: 'justify',
  },
  helperText: {
    width: '100%',
    textAlign: 'right',
    color: 'red',
  },
  newHelperText: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    width: '100%',
    textAlign: 'right',
    gap: '4px',
    color: 'red',
  },
  paddingReset: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
  },
  searchInput: {
    boxShadow: 'none !important',
  },
  coursesWrapper: {
    margin: '20px 0',
  },
  loading: {
    margin: 20,
    color: dnaGreen,
  },
  marginRAuto: {
    marginRight: 'auto',
  },
  questionsList: {
    listStyle: 'none',
    padding: 0,
    marginBottom: 10,
  },
  alignSelfStart: {
    alignSelf: 'flex-start',
  },
  selectForm: {
    margin: 0,
    display: 'flex',
    width: '100%',
  },
  trailsCheckBox: {
    padding: '0 12px 0 0',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  groupItem: {
    marginBottom: 20,
    '&:first-child': {
      marginTop: 20,
    },
  },
  cssLabelYellow: {
    '&$cssFocused': {
      color: '#eb9b00 !important',
    },
  },
  cssOutlinedInputYellow: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#eb9b00 !important',
    },
  },
  btnConfirmVacany: {
    minWidth: '60%',
    height: '50px',
    color: '#009291',
    border: '2px solid #009291',
    borderRadius: '200px',
    fontSize: '17px',
  },
  cssFocused: {},
  notchedOutline: {},
  modalBgLowOpacity: {},

  modalPaper: {
    boxShadow: 'none',
    overflow: 'hidden',
    backgroundColor: '#efeeec',
  },

  currencyBox: {
    display: 'grid',
    justifyContent: 'center',
    marginBottom: 20,
  },
  cardFormLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '1rem',
    lineHeight: 1,
  },
  confirmDialogVacancy: {
    fontSize: '25px',
    textAlign: 'center',
    paddingRight: '20px',
    marginTop: '16px',
    marginBottom: '13px',
  },
  contentDialogVacancy: {
    padding: '30px',
    marginBottom: '-14px',
  },
  CustomDialogActions: {
    justifyContent: 'center',
  },
  formTitle: {
    fontWeight: 'bold',
    color: 'black',
  },
  formContainer: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
  },
  formLabel: {
    marginTop: 20,
    marginBottom: 15,
    color: 'black',
    fontWeight: 'bold',
  },
  buttonReverse: {
    padding: '8px 20px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  cardAlertWarning: {
    color: 'rgb(97, 26, 21)',
    backgroundColor: 'rgb(253, 236, 234)',
  },
  alertContentWarning: {
    padding: '0px 26px 0px !important',
  },
  alertWarningTitle: {
    margin: '10px 2px 2px',
    fontSize: '16px',
    fontWeight: '700',
  },
  iconWarning: {
    marginBottom: '-6px !important',
    marginLeft: '-6px !important',
    marginRight: '6px !important',
  },
  descTitle: {
    width: '100%',
    marginTop: '-10px',
    fontSize: '13px',
    color: '#696767',
  },
  roundedCard: {
    marginBottom: 0,
  },
  roundedCardContent: {
    padding: '15px 20px',
  },
  roundedCardTitle: {
    color: '#3C4858',
    fontSize: '20px',
    marginTop: '20px',
    minHeight: 'auto',
    fontWeight: 400,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    padding: '0 15px',
    textDecoration: 'none',
  },
  timeJob: {
    width: '100%',
    margin: 5,
  },
  resetButton: {
    color: dnaColor,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  dnaColorresetButton: {
    color: dnaColor,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  yellowIESresetButton: {
    color: yellowIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  redIESresetButton: {
    color: redIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  greenIESresetButton: {
    color: greenIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  darkBlueIESresetButton: {
    color: darkBlueIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  blueIESresetButton: {
    color: blueIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  lightBlueIESresetButton: {
    color: lightBlueIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  orangeIESresetButton: {
    color: orangeIE,
    display: 'block',
    marginLeft: 'auto',
    marginTop: '1rem',
  },

  dateJobErrors: {
    display: 'flex',
    width: '100%',
    padding: '0px 24px',
  },
  interestHeaderText: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 25,
    padding: '0px 40px',
  },
  infoCompanyContainer: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },

  attractionsFilterForm: {
    position: 'sticky',
    top: 0,
    zIndex: 0,
    paddingTop: 0,
    marginTop: '0.5rem',
  },
  formFilterControl: {
    marginBottom: 15,
    width: '100%',
  },
});

export default professionalExperienceFormStyles;

export const CurrencyField = styled(OutlinedInput)`
  && {
    position: absolute;
    margin: 0 auto;

    & :focus {
      border-color: ${dnaColor} !important;
    }
  }
`;

export const CurrencyFieldWhite = styled(OutlinedInput)`
  fieldset {
    border: none !important;
  }
  & :disabled {
    background-color: rgb(201 201 201);
    border-radius: 10px;
    color: black;
  }

  && {
    position: relative;

    width: 100%;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 10px;
    background-color: #fff;
    margin: 0 auto;

    & :focus {
      border-color: ${dnaColor} !important;
    }
  }
`;

export const FilterContainer = styled.div`
  overflow-y: scroll;
  height: 90vh;
  padding-bottom: 24px;
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(238, 238, 238, 1) 90%
    );
    width: 100%;
    height: 4em;
  }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
