import React, { useEffect, useRef, useState } from 'react';
import Step0 from './Step0';
import Step1 from './Step1';
import Step2 from './Step2';
import { useMentorship } from '../../contexts/MentorshipContext';
import MentorshipModal from 'components/MentorshipModal';
import SuccessModal from 'components/SuccessModal';
import StepIndicator from '../../../../../components/StepIndicator';

export default function ModalRequestMentorship() {
  const {
    isRequestMentorshipModalOpen,
    closeRequestMentorshipModal,
    formAction,
    setModalityAndDescription,
    setPeriodPreferences,
  } = useMentorship();
  const formStep1Ref = useRef(null);
  const formStep2Ref = useRef(null);

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (formAction === 'save') {
      setStep(0);
    } else {
      setStep(1);
    }
  }, [isRequestMentorshipModalOpen, formAction]);

  function CurrentStep() {
    switch (step) {
      case 0:
        return <Step0 setStep={setStep} />;
      case 1:
        return (
          <Step1
            setStep={setStep}
            formRef={formStep1Ref}
            handleStepChange={handleStepChange}
          />
        );
      case 2:
        return (
          <Step2
            setStep={setStep}
            formRef={formStep2Ref}
            handleStepChange={handleStepChange}
          />
        );
      default:
        return (
          <SuccessModal
            title={
              formAction === 'edit'
                ? 'Solicitação de mentoria atualizada com sucesso!'
                : formAction === 'save'
                ? 'Mentoria solicitada com sucesso!'
                : 'Solicitação de reagendamento enviado com sucesso!'
            }
            description={
              formAction === 'save'
                ? 'Aguarde, logo você receberá' +
                  ' um retorno com o dia e o horário para sua mentoria.'
                : null
            }
          />
        );
    }
  }

  function handleStepChange(value) {
    switch (value) {
      case 2:
        setPeriodPreferences(
          formStep1Ref?.current?.getData().periods_preference
        );
        setStep(value);
        break;
      case 1:
        const data = formStep2Ref?.current?.getData();
        setModalityAndDescription(data.desired_modality, data.description);
        setStep(value);
        break;
    }
  }

  return (
    <MentorshipModal
      isOpen={isRequestMentorshipModalOpen}
      onClose={closeRequestMentorshipModal}
      header={
        step !== 0 &&
        step !== 3 && (
          <StepIndicator
            count={2}
            currentStep={step}
            onStepChange={handleStepChange}
          />
        )
      }
    >
      <CurrentStep />
    </MentorshipModal>
  );
}
