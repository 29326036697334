import React from 'react';
import {
  Main,
  ModalContent,
  ModalHeader,
  ModalBody,
  CloseButton,
} from './styled';

function Modal({ title, children, handleCloseModal }) {
  return (
    <Main>
      <ModalContent>
        <ModalHeader>
          {title}
          <CloseButton onClick={() => handleCloseModal()}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Main>
  );
}

export default Modal;
