import React, { useEffect, useState, Fragment } from 'react';
import { func } from 'prop-types';

import {
  getValuesTestQuestions,
  applyValuesTest,
} from 'services/student/quizzes';

import withStyles from '@material-ui/core/styles/withStyles';
import dragDropTestStyle from 'assets/jss/material-dashboard-pro-react/views/dragDropTestStyle';

import DragDropTest from 'components/DragDropTest/DragDropTest';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import { triggerPageView } from '../../../../utils/analytics';

import CardsContentValues from '../ValuesCardTest/CardsContent';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

const ValuesTest = (props) => {
  const [testOptions, setTestOptions] = useState([]);

  const {
    openSnackbar,
    handleIsLoadingState,
    closeModal,
    metadata,
    cardDataKey,
    setCardData,
    fetchGamificationData,
  } = props;

  useEffect(() => {
    handleIsLoadingState(true);
    const fetchValuesData = async () => {
      const options = await getValuesTestQuestions();

      if (options.message) {
        customSnackbar(
          'Falha ao carregar o teste! Tente novamente mais tarde.',
          'error'
        );
        props.closeModal();
      } else {
        setTestOptions([options]);
      }

      handleIsLoadingState(false);

      if (metadata.university_slug !== 'uniexemplo') {
        triggerHotjarEvent('values_test');
      }
    };
    Mixpanel.track('Abriu Teste de Valores');
    fetchValuesData();
  }, []);

  const handleTestOptions = (newTestOptions) => {
    setTestOptions(newTestOptions);
  };

  const sendTestData = async (testData) => {
    handleIsLoadingState(true);
    const data = await dataAdapter(testData);
    const response = await applyValuesTest(data);

    if (response.message) {
      customSnackbar(
        'Falha ao finalizar o teste, tente novamente mais tarde.',
        'error'
      );
    } else {
      const data = {
        is_done: true,
        is_enabled: false,
        results: response,
      };

      cardDataKey && setCardData((prev) => ({ ...prev, [cardDataKey]: data }));
      await fetchGamificationData();
      customSnackbar(
        `
        Teste finalizado com sucesso! Continue firme 
        para ter um currículo cada vez mais completo.
        `,
        'confirmation'
      );
      Mixpanel.track('Salvou Teste de Valores');
      triggerPageView('student/valores/cadastro');
    }

    closeModal();
    handleIsLoadingState(false);
  };

  const dataAdapter = async (testData) => {
    const data = [];
    Object.keys(testData).map((key) => {
      data.push(testData[key][0]);
    });
    return data;
  };

  return (
    <Fragment>
      <Fragment>
        <CardsContentValues />
      </Fragment>
      <DragDropTest
        headerText={`Clique, arraste e ordene os 3 valores que representam o tipo de ambiente de trabalho que é mais importante para você.`}
        totalSteps={1}
        optionsPerPage={3}
        testOptions={testOptions}
        openSnackbar={customSnackbar}
        handleTestOptions={handleTestOptions}
        snackbarErrorMessage="Selecione todas as características"
        sendTestData={sendTestData}
        changeSourceEnable
        destinationLabel="Valor"
      />
    </Fragment>
  );
};

ValuesTest.propTypes = {
  openSnackbar: func,
  handleIsLoadingState: func,
  closeModal: func,
  updateValuesTestState: func,
};

export default withStyles(dragDropTestStyle)(ValuesTest);
