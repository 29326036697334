import React, { useState, useEffect, useContext } from 'react';
import GridItem from 'components/Grid/GridItem';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import {
  FormHelperText,
  TextField,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { func, shape, bool, string } from 'prop-types';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import AuthContext from 'contexts/authentication';
import Face from '@material-ui/icons/Face';
import { emailExists, sendEmail } from '../../services/login/recoverPassword';
import CustomModalDialog from '../../components/CustomModalDialog/CustomModalDialog';

const SendEmail = ({
  classes,
  handleClose,
  isCompanyConfig,
  isStudentLogin,
  companyUsername,
}) => {
  const auth = useContext(AuthContext);

  const [cardAnimation, setCardAnimation] = useState('cardHidden');
  const [sendSuccess, setSendSuccess] = useState(false);
  const [userName, setUserName] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [emailNotFound, setEmailNotFound] = useState(false);
  const [isBlacklisted, setIsBlacklisted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCardAnimation('');
    }, 300);
  }, []);

  const transformEmail = (email) => {
    return `${email.substr(0, 3)}******${email.substring(email.indexOf('@'))}`;
  };

  const SendEmailSchema = Yup.object().shape({
    showEmail: Yup.boolean(),
    email: Yup.string()
      .email('Preencha um e-mail válido')
      .when('showEmail', {
        is: true,
        then: Yup.string().required('Campo obrigatório'),
      }),
    username: Yup.string().when('showEmail', {
      is: false,
      then: Yup.string().required('Campo obrigatório'),
    }),
  });

  return (
    <GridItem
      xs={12}
      sm={6}
      md={4}
      style={isCompanyConfig ? { margin: 'auto' } : null}
    >
      <Formik
        initialValues={{ email: '', username: '', showEmail: !isStudentLogin }}
        enableReinitialize
        validateOnChange={false}
        validationSchema={SendEmailSchema}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true);
          if (isStudentLogin) {
            const response = await emailExists({
              username: values.username,
            });
            if (response.exists) {
              if (!response.is_blacklisted) {
                setIsBlacklisted(false);
                values.email = response.email;
                setUserName(response.name);
                if (response.email) {
                  const sendResponse = await sendEmail(values);
                  if (sendResponse.status === 'OK') setEmailSent(true);
                } else setEmailNotFound(true);
              } else {
                setIsBlacklisted(true);
              }
            } else setUserNotFound(true);
          } else if (isCompanyConfig) {
            if (values.email === companyUsername) {
              const response = await sendEmail(values);
              if (response.status === 'OK') {
                setSendSuccess(true);
                setTimeout(() => {
                  auth.routerOnUserLogout();
                }, 2000);
              } else if (response.response.data.email) {
                setEmailNotFound(true);
              }
            } else {
              setEmailNotFound(true);
            }
          } else {
            const response = await sendEmail(values);
            if (response.status === 'OK') {
              setSendSuccess(true);
            } else if (response.response.data.email) {
              setEmailNotFound(true);
            }
          }

          actions.setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimation]}>
              <CardBody>
                <CardHeader
                  style={{ padding: 0, marginBottom: 14 }}
                  action={
                    !isCompanyConfig && (
                      <IconButton
                        style={{ marginRight: 11 }}
                        onClick={() => handleClose()}
                      >
                        <Icon>arrow_back</Icon>
                      </IconButton>
                    )
                  }
                  title={
                    <h4 style={{ fontSize: 17 }} className="form-title">
                      Recuperar senha
                    </h4>
                  }
                  subheader={`Para iniciar, digite o ${
                    !isStudentLogin ? 'e-mail' : 'login'
                  } cadastrado.`}
                />

                {!sendSuccess && !isStudentLogin && (
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            email
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    fullWidth
                    name="email"
                    id="email"
                    label="E-mail"
                    placeholder="workalove@workalove.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email && !!touched.email}
                    value={values.email}
                    component={TextField}
                  />
                )}
                {!!errors.email && !!touched.email && (
                  <FormHelperText className={classes.helperText}>
                    {errors.email}
                  </FormHelperText>
                )}
                {!sendSuccess && isStudentLogin && (
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    fullWidth
                    name="username"
                    id="username"
                    label="Login"
                    placeholder="09382118921"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.username && !!touched.username}
                    value={values.username}
                    component={TextField}
                  />
                )}
                {!!errors.username && !!touched.username && (
                  <FormHelperText className={classes.helperText}>
                    {errors.username}
                  </FormHelperText>
                )}
                <CustomModalDialog
                  classes={classes}
                  visible={emailSent}
                  handleClose={handleClose}
                  setMessageFalse={setEmailSent}
                >
                  <p>
                    <strong>Olá {userName}!</strong>
                  </p>
                  <p>
                    Encaminhamos para o e-mail {transformEmail(values.email)} as
                    instruções para a recuperação da sua senha!
                    <br />
                    Caso você não tenha mais acesso a este e-mail, entre em
                    contato com a sua <strong>Instituição de Ensino</strong> e
                    solicite a atualização dos dados na plataforma Workalove!
                  </p>
                  <br />
                </CustomModalDialog>
                <CustomModalDialog
                  classes={classes}
                  visible={emailNotFound}
                  handleClose={handleClose}
                  setMessageFalse={setEmailNotFound}
                >
                  <p>
                    {!isStudentLogin ? (
                      <strong>Olá!</strong>
                    ) : (
                      <strong>Olá {userName}!</strong>
                    )}
                  </p>
                  {!isStudentLogin ? (
                    <p>
                      Infelizmente não identificamos no seu cadastro nenhum
                      e-mail relacionado ao e-mail preenchido, por favor
                      verifique se o e-mail preenchido é o mesmo do cadastro.
                    </p>
                  ) : (
                    <p>
                      Infelizmente não identificamos nenhum e-mail relacionado a
                      esse número de registro. Entre em contato com a sua{' '}
                      <strong>instituição de ensino</strong> e solicite a
                      atualização de seu cadastro na plataforma Workalove!
                    </p>
                  )}

                  <br />
                </CustomModalDialog>
                <CustomModalDialog
                  classes={classes}
                  visible={isBlacklisted}
                  handleClose={handleClose}
                  setMessageFalse={setEmailNotFound}
                >
                  <p>
                    <strong>Ops!</strong>
                  </p>
                  <p>
                    Não foi possível solicitar a sua recuperação de senha. É
                    possível que haja problemas com o seu endereço de e-mail
                    cadastrado em nossa plataforma.
                    <br />
                    <br />
                    Entre em contato com a sua{' '}
                    <strong>instituição de ensino</strong> e solicite a
                    atualização de seu cadastro na plataforma Workalove!
                  </p>
                  <br />
                </CustomModalDialog>
                <CustomModalDialog
                  classes={classes}
                  visible={userNotFound}
                  handleClose={handleClose}
                  setMessageFalse={setUserNotFound}
                >
                  <p>
                    <strong>Olá!</strong>
                  </p>
                  <p>
                    Infelizmente não identificamos esse registro{' '}
                    <strong>{values.username}</strong> na base da Workalove.
                    Entre em contato com a sua{' '}
                    <strong>instituição de ensino</strong> e solicite a
                    integração dos seus dados na Workalove!
                  </p>
                  <br />
                </CustomModalDialog>
                <Grid
                  container
                  justify="center"
                  style={{ textAlign: 'center', padding: '2px 22px' }}
                >
                  {isSubmitting && (
                    <Grid item md={12} xs={12} style={{ marginTop: 25 }}>
                      <CircularProgress style={{ color: '#2f9190' }} />
                    </Grid>
                  )}

                  {sendSuccess && !isStudentLogin && (
                    <Grid item md={12} xs={12} style={{ marginTop: 16 }}>
                      <Icon style={{ fontSize: '4em', color: '#2f9190' }}>
                        mark_email_read
                      </Icon>
                      <p>
                        Tudo certo! Enviamos um e-mail para{' '}
                        <b>{values.email}</b>{' '}
                      </p>
                    </Grid>
                  )}
                </Grid>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                {!sendSuccess && (
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className={classes.saveButton}
                    round
                  >
                    Enviar
                  </Button>
                )}
                {sendSuccess && (
                  <strong>
                    Para dar prosseguimento, você será deslogado. Por favor,
                    verifique seu email.
                  </strong>
                )}
              </CardFooter>
            </Card>
          </form>
        )}
      />
    </GridItem>
  );
};

SendEmail.defaultProps = {
  isCompanyConfig: false,
  isStudentLogin: false,
  companyUsername: undefined,
};

SendEmail.propTypes = {
  classes: shape({}).isRequired,
  handleClose: func.isRequired,
  isCompanyConfig: bool,
  isStudentLogin: bool,
  companyUsername: string,
};

export default withStyles(loginPageStyle)(SendEmail);
