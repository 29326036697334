import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, DropdownHeader, List, ListItem } from './styles';

export default function ModalitySelect({
  selectedModality,
  setSelectedModality,
  formRef,
  disabled,
  error = false,
}) {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [modalities, setModalities] = useState([
    {
      label: 'Selecione',
      value: 'indifferent',
    },
    {
      label: 'Presencial',
      value: 'presential',
    },
    {
      label: 'Online',
      value: 'remote',
    },
  ]);

  useEffect(() => {
    if (selectedModality !== 'indifferent') {
      setModalities([
        {
          label: 'Presencial',
          value: 'presential',
        },
        {
          label: 'Online',
          value: 'remote',
        },
      ]);
    }
  }, [selectedModality]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      } else {
        toggle();
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  const toggle = () => setOpen(!open);

  return (
    <Dropdown ref={ref} open={open} disabled={disabled} error={error}>
      <DropdownHeader disabled={disabled}>
        <p>{modalities.find((m) => m.value === selectedModality).label}</p>
      </DropdownHeader>
      {open && !disabled && (
        <List>
          {modalities.map((modality) => (
            <ListItem
              key={modality.value}
              onClick={() => {
                setSelectedModality(modality.value);
                formRef.current.setErrors({});
              }}
              selected={selectedModality === modality.value}
              type="button"
            >
              <span>{modality.label}</span>
            </ListItem>
          ))}
        </List>
      )}
    </Dropdown>
  );
}
