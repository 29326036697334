import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';
import { useEffect, useRef, useState } from 'react';
import StudentCoursesModal from '../../StudentCoursesModal';
import Button from '../../Button';
import altAvatar from 'assets/img/placeholder.jpg';
import { FaCheck } from 'react-icons/fa';
import {
  ContainerImageAndInformations,
  ContainerListing,
  Wrapper,
  Container,
  Header,
  LabelPresence,
} from './styled';
import CustomCheckbox from '../../CustomCheckbox';
import Search from '../../SearchBar';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import { MdOutlineChecklist, MdQrCodeScanner } from 'react-icons/md';
import { getCheckInConfirmationConfig } from 'views/StaffPages/FairAndEvents/StaticOptions/CheckInConfirmationOptions';
import ModalInformation from 'components/informationModal';

export default function ListingCheckIn() {
  const [studentCourseModalOpen, setStudentCourseModalOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const visualizationContextInstance = useVisualizationContext();

  const studentHandlerFactory = StudentHandlers(
    visualizationContextInstance,
    visualizationStudentContextInstance
  );

  const CoreHandlersFactory = CoreHandlers(
    visualizationContextInstance,
    _,
    visualizationStudentContextInstance
  );

  const scrollRef = useRef(null);

  const { detailsObject } = visualizationContextInstance;
  const {
    listing,
    selectedStudent,
    setSelectedStudent,
    checkedStudents,
    setCheckedStudents,
    checkedAll,
    setCheckedAll,
    typeChecked,
    setTypeChecked,
  } = visualizationStudentContextInstance;

  const eventId = detailsObject.id;

  useEffect(() => {
    scrollRef?.current?.scrollIntoView();
  }, [scrollRef]);

  useEffect(() => {
    if (eventId) {
      studentHandlerFactory.getListingStudentEvent(eventId);
    }
  }, [eventId]);

  const config = getCheckInConfirmationConfig(checkedStudents.length);

  const handleClickMoreCourses = (studentData) => {
    setStudentCourseModalOpen(true);
    setSelectedStudent(studentData);
  };

  const currentButton = (presence, studentData) => {
    if (presence) {
      return (
        <Button
          theme="unfilled"
          width="100%"
          customColor="#CF0000"
          onClick={() =>
            studentHandlerFactory.onRemovePresenceStudent(
              studentData.uuid,
              studentData.presence_id,
              eventId
            )
          }
        >
          Retirar presença
        </Button>
      );
    }

    return (
      <Button
        theme="unfilled"
        width="100%"
        onClick={() =>
          studentHandlerFactory.onConfirmPresenceStudent(
            studentData.uuid,
            eventId
          )
        }
      >
        Confirmar presença
      </Button>
    );
  };

  function StudentCard({ studentData, handleClickMoreCourses, currentButton }) {
    const avatarSource = studentData.student.avatar
      ? studentData.student.avatar
      : altAvatar;

    const formatCourseAndPeriod = () => {
      const firstCourse = studentData.student.courses[0]?.course__name;
      const firstPeriod = studentData.student.courses[0]?.semester+"°";
      const coursesCount = studentData.student.courses?.length - 1;

      return { firstCourse, firstPeriod, coursesCount };
    };

    const { firstCourse, firstPeriod, coursesCount } = formatCourseAndPeriod();
    const formatStringCourseAndPeriod = `${firstCourse} - ${firstPeriod} período`;

    const checked =
      checkedStudents.includes(studentData.uuid) ||
      checkedStudents.includes(studentData.presence_id);

    const onChangeStudentCheckbox = (event) => {
      const checked = event.target.checked;

      if (checked) {
        setCheckedStudents((prev) => [...prev, studentData.uuid]);
        return;
      }

      setCheckedStudents((prev) =>
        prev.filter((uuid) => uuid !== studentData.uuid)
      );
    };

    return (
      <Container>
        <Wrapper>
          <ContainerImageAndInformations active={studentData.presence}>
            <div>
              {checked && (
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={onChangeStudentCheckbox}
                />
              )}

              <img src={avatarSource} />
              <div className="checkActive">
                <FaCheck color={'#FFF'} size={14} />
              </div>
            </div>
            <div>
              <h4>{studentData.student.name}</h4>
              <p>
                {formatStringCourseAndPeriod}
                {coursesCount > 0 && (
                  <span onClick={() => handleClickMoreCourses(studentData)}>
                    + {coursesCount}
                  </span>
                )}
              </p>
              <LabelPresence presence={studentData.presence}>
                {studentData.presence
                  ? 'Presença confirmada'
                  : 'Presença não confirmada'}
              </LabelPresence>
              <span>ID: {studentData.student.external_id}</span>
            </div>
          </ContainerImageAndInformations>

          <div>{currentButton(studentData.presence, studentData)}</div>
        </Wrapper>

        <div className="divider" />
      </Container>
    );
  }

  const renderStudentList =
    listing &&
    listing?.map((studentData) => (
      <StudentCard
        key={studentData.uuid}
        studentData={studentData}
        handleClickMoreCourses={handleClickMoreCourses}
        currentButton={currentButton}
      />
    ));

  const onChangeCheckbox = (checked) => {
    setCheckedAll(checked);
  };

  const handleClickOption = (checked, type) => {
    setTypeChecked(type);
    setCheckedAll(true);

    if (type === 'presence') {
      const students = listing?.filter((student) => student.presence);
      setCheckedStudents(students?.map((student) => student.presence_id));
    }

    if (type === 'noPresence') {
      const students = listing?.filter((student) => !student.presence);
      setCheckedStudents(students?.map((student) => student.uuid));
    }
  };

  const optionsCheckbox = [
    {
      label: 'Com presença confirmada',
      type: 'presence',
      onClick: handleClickOption,
    },
    {
      label: 'Sem presença confirmada',
      type: 'noPresence',
      onClick: handleClickOption,
    },
  ];

  const onClearOption = () => {
    setCheckedStudents([]);
    setCheckedAll(false);
    setTypeChecked(null);
  };

  const confirmedStudents = listing.filter((student) => student.presence);

  return (
    <ContainerListing>
      <ModalInformation
        config={config[typeChecked]}
        buttonConfirmFunction={() => {
          studentHandlerFactory.bulkStudentType(
            checkedStudents,
            typeChecked,
            eventId
          );
          setModalInformationOpen(false);
        }}
        buttonCancelFunction={() => setModalInformationOpen(false)}
        modalOpenState={modalInformationOpen}
      />

      <Header ref={scrollRef}>
        <h4>Realizar check-in</h4>
        <span>
          Confirme a presença dos estudantes através do QR Code <br /> fornecido
          para o estudante ou realiza o check-in manual
          <br /> utilizando a lista abaixo.
        </span>

        <div className="headerButtons">
          <Button
            icon={<MdQrCodeScanner color="#FFF" />}
            theme="dark"
            width="100%"
            onClick={() => CoreHandlersFactory.scanQrCode()}
          >
            Escanear Qr Code
          </Button>

          <Button
            icon={<MdOutlineChecklist color="#FFF" />}
            theme="unfilled"
            width="100%"
            onClick={() => CoreHandlersFactory.exportList(eventId)}
          >
            Exportar lista
          </Button>
        </div>

        <h4 className="studentsConfirmed">
          Estudantes confirmados:{' '}
          <span>
            {confirmedStudents.length}/{listing.length}
          </span>
        </h4>

        <div className="filtersAndBulk">
          <div>
            <CustomCheckbox
              onChange={onChangeCheckbox}
              options={optionsCheckbox}
              onClearOption={onClearOption}
            />
            <Search
              onChange={(event) =>
                studentHandlerFactory.onFilter(eventId, event.target.value)
              }
            />
          </div>
          {checkedAll && (
            <Button
              theme="flatUniversityColor"
              onClick={() => setModalInformationOpen(true)}
            >
              {typeChecked === 'presence'
                ? `Retirar ${checkedStudents.length} ${
                    checkedStudents.length > 1 ? 'estudantes' : 'estudante'
                  }`
                : `Confirmar ${checkedStudents.length} ${
                    checkedStudents.length > 1 ? 'estudantes' : 'estudante'
                  }`}
            </Button>
          )}
        </div>
      </Header>

      <StudentCoursesModal
        studentData={selectedStudent}
        open={studentCourseModalOpen}
        onClose={() => setStudentCourseModalOpen(false)}
      />
      {renderStudentList}
    </ContainerListing>
  );
}
