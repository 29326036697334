import Select, { components } from 'react-select';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { ChipButton, SelectedContainer, SelectFilterBox } from '../styles';
import { CloseIcon } from 'assets/JobOffer/Close';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterMultiSelect({
  name,
  options,
  disabled,
  onChange,
  loading,
  listHeight,
  onType,
}) {
  const { filterState, updateFilterState } = useFilterContext();
  const [value, setValue] = useState(null);
  const selectedOptions = filterState[name] || [];
  const unselectedOptions = options.filter(
    (option) => !selectedOptions.includes(option.value)
  );
  const selectRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      height: listHeight,
      maxHeight: '192px',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: universityColor || '#009291',
      },
    };
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  const offsetWidth = useMemo(() => selectRef.current?.offsetWidth, [
    selectRef.current,
  ]);

  return (
    <SelectFilterBox ref={selectRef}>
      <Select
        name={name}
        placeholder="Selecione"
        options={unselectedOptions}
        theme={selectTheme}
        components={{ NoOptionsMessage }}
        onInputChange={(inputValue) => {
          if (inputValue.length > 0) {
            onType && onType(inputValue);
          }
        }}
        value={value}
        styles={selectStyles}
        onChange={(selected) => {
          if (selected) {
            updateFilterState(selected.value, name, FilterType.MULTI_SELECT);
            setValue(null);
            if (onChange) {
              const newSelectedOptions = filterState[name]
                ? [...filterState[name], selected.value]
                : [selected.value];
              onChange(newSelectedOptions);
            }
          }
        }}
        isDisabled={disabled}
        isLoading={loading}
        isClearable
      />
      {selectedOptions.length > 0 && (
        <SelectedContainer>
          {selectedOptions.map((value) => {
            const chipText = options?.find((option) => option?.value === value)
              ?.label;
            return (
              <ChipButton
                universityColor={universityColor}
                key={value}
                maxWidth={`${offsetWidth}px`}
                onClick={() => {
                  updateFilterState(
                    options.find((option) => option.value === value)?.value,
                    name,
                    FilterType.MULTI_SELECT
                  );
                  if (onChange) {
                    const newSelectedOptions = filterState[name].filter(
                      (option) => option !== value
                    );
                    onChange(newSelectedOptions);
                  }
                }}
              >
                <p title={chipText}>{chipText}</p>
                <CloseIcon />
              </ChipButton>
            );
          })}
        </SelectedContainer>
      )}
    </SelectFilterBox>
  );
}
