import React, { useEffect, useState } from 'react';
import {
  func,
  string,
  shape,
  objectOf,
  arrayOf,
  bool,
  array,
  number,
} from 'prop-types';

import ViewsHeader from 'components/ViewsHeader/ViewsHeader';
import ApplicationReturn from 'views/Company/components/ApplicationReturn/ApplicationReturn';
import Like from 'views/Company/components/Like/Like';
import ScoreRating from 'views/Company/components/ScoreRating/ScoreRating';
import { Grid } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import applicationReturnStyle from 'assets/jss/material-dashboard-pro-react/components/applicationReturnStyle';
import CurriculumContainer from './CurriculumContainer';

const CompanyCurriculum = (props) => {
  const {
    role,
    classes,
    profile,
    behavioral,
    values,
    languages,
    interests,
    lifeInterests,
    experiencesCareer,
    experiencesLife,
    extraCourses,
    projects,
    technicalCompetences,
    personality,
    returnJobApplication,
    studentData,
    updateCandidates,
    options,
    handleIsLoadingState,
    like,
    studentId,
    liked,
    updateLiked,
    updateProfile,
    fetchStudentData,
    partnerJob,
    specialNeeds,
    showSpecialNeeds,
    applicationIsInteresting,
  } = props;

  const [canShowContact, setCanShowContact] = useState();

  const handleContactVisibility = () => {
    return setCanShowContact(applicationIsInteresting ? true : false);
  };

  useEffect(() => {
    handleContactVisibility();
  }, []);

  return (
    <Grid container className={classes.talentPreview}>
      <Grid item xs md={12}>
        <ViewsHeader title={`${profile.name} - Currículo do Futuro`} />
        <CurriculumContainer
          profile={profile}
          specialNeeds={specialNeeds}
          showSpecialNeeds={showSpecialNeeds}
          interests={interests}
          lifeInterests={lifeInterests}
          behavioral={behavioral}
          personality={personality}
          experiencesCareer={experiencesCareer}
          values={values}
          languages={languages}
          extraCourses={extraCourses}
          projects={projects}
          technicalCompetences={technicalCompetences}
          handleIsLoadingState={handleIsLoadingState}
          experiencesLife={experiencesLife}
          canShowContact={canShowContact}
          role={role}
          applicationReturn={
            studentData &&
            returnJobApplication && (
              <ApplicationReturn
                role={role}
                classes={classes}
                studentData={studentData}
                updateCandidates={updateCandidates}
                returnJobApplication={returnJobApplication}
                options={options}
                fetchStudentData={fetchStudentData}
                partnerJob={partnerJob}
                setCanShowContact={setCanShowContact}
              />
            )
          }
          like={
            like && (
              <Like
                studentId={studentId}
                hasLiked={liked}
                updateLiked={updateLiked}
                updateProfile={updateProfile}
                setCanShowContact={setCanShowContact}
              />
            )
          }
          rating={
            studentData && (
              <ScoreRating
                role={role}
                classes={classes}
                studentData={studentData}
                updateCandidates={updateCandidates}
              />
            )
          }
        />{' '}
      </Grid>
    </Grid>
  );
};

CompanyCurriculum.propTypes = {
  classes: objectOf(shape).isRequired,
  profile: arrayOf(shape).isRequired,
  specialNeeds: arrayOf(shape),
  showSpecialNeeds: bool,
  behavioral: arrayOf(shape).isRequired,
  personality: arrayOf(shape).isRequired,
  values: arrayOf(shape).isRequired,
  interests: arrayOf(shape).isRequired,
  lifeInterests: arrayOf(shape).isRequired,
  experiencesCareer: arrayOf(shape),
  experiencesLife: arrayOf(shape),
  extraCourses: arrayOf(shape),
  projects: arrayOf(shape),
  role: string.isRequired,
  technicalCompetences: shape,
  talentPreview: bool,
  returnJobApplication: func.isRequired,
  studentData: shape.isRequired,
  options: array.isRequired,
  handleIsLoadingState: func.isRequired,
  like: func.isRequired,
  studentId: number.isRequired,
  liked: bool.isRequired,
  updateLiked: func.isRequired,
  updateProfile: func.isRequired,
  fetchStudentData: func.isRequired,
};

export default withStyles(applicationReturnStyle)(CompanyCurriculum);
