import styled from 'styled-components'

export const Container = styled.div`
  padding: 60px;
`

export const Title = styled.p`
  font-size: 22px;
  color: #009291;
  font-weight: 700;
  padding-bottom: 13px;
`

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
`

export const Header = styled.div`
  width: 100%;
  padding-right: ${(props) => props.padRight ? "128px" : '100px'};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;
  padding-top: 8px;

`

export const WhiteBox = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 17.8019px rgba(0, 0, 0, 0.1);
  border-radius: 8.90097px;

  border-top-left-radius: ${props => props.internalWhiteBox && 'unset'} ;
  border-top-right-radius: ${props => props.internalWhiteBox && 'unset'} ;

  margin-top: ${props => props.internalWhiteBox ? 'unset' : '33px'};
`


export const FilterContainer = styled.div`
  display: flex;
  right: 0;
  top: .5rem;

  position: absolute;
`;

export const HeaderTab = styled.div`
  height: 81px;
  display: flex;

  border-bottom: 1px solid #E6E6E6;

  align-items: center;

`
export const DisconnectButton = styled.button`
  width: 100%;
  max-width: 194px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  border: unset;

  border-radius: 4px;

  font-size: 13px;
  font-weight: 400;
  color: #fff;

  cursor: pointer;
`

export const InviteCompanyButton = styled.button`
  padding: 7px 12px;

  background-color: #009291;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-around;
  
  gap: 7px;

  border: none;
  cursor: pointer;

  border-radius: 3px;

  margin-right: ${props => props.marginRight ? '27px' : '13px'};

  flex: none;


  .base-icon {
    width: 22px;
    height: 22px;

    background-color: #fff;
    border-radius: 3px;
    
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
