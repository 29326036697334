import React, { Component, Fragment } from 'react';
import { func, number, array, bool, string } from 'prop-types';

import { profileAdapter } from 'adapters/student/profileAdapter';
import CompanyCurriculum from './CompanyCurriculum';

export default class OpenCurriculum extends Component {
  state = {
    isFirstRequest: false,
    talentsListState: [],
    behavioralProfiles: [],
    personalityProfiles: [],
    values: [],
    interestList: [],
    lifeInterestsList: [],
    professionalExperienceState: [],
    extraCourseState: [],
    projectsState: [],
    technicalCompetences: {},
    studentProfileState: {},
    dataLoad: false,
    specialNeeds: [],
    experiencesLife: [],
  };

  async componentDidMount() {
    await this.fetchStudentData();
  }

  fetchStudentData = async (newStudentData = null) => {
    let response;
    if (!this.props.studentData) {
      response = await this.props.getStudentCurriculumData(
        this.props.studentId
      );
    } else {
      response = newStudentData
        ? newStudentData.student
        : this.props.studentData.student;
    }

    if (!response.message) {
      const {
        academic_profile,
        quizzes,
        interests,
        life_interests,
        professional_experience,
        extra_courses,
        student_languages,
        projects,
        technical_competences,
        special_needs,
        life_experience,
        show_special_needs,
      } = response;

      this.setState({
        specialNeeds: special_needs,
        showSpecialNeeds: show_special_needs,
        studentProfileState: profileAdapter(academic_profile),
        behavioralProfiles: quizzes.behavioral.results,
        personalityProfiles: quizzes.personality.results,
        values: quizzes.values.results,
        languages: student_languages,
        interestList: interests,
        lifeInterestsList: life_interests,
        professionalExperienceState: professional_experience,
        extraCourseState: extra_courses,
        projectsState: projects,
        technicalCompetences: technical_competences,
        dataLoad: true,
        experiencesLife: life_experience,
      });
    } else if (response.message) {
      this.props.openSnackbar(
        'Error ao obter os dados! Tente novamente mais tarde',
        true
      );
    }
  };

  updateProfile = (email, mobilePhone) => {
    const { studentProfileState } = this.state;

    const newProfile = { ...studentProfileState, email, mobilePhone };

    this.setState({ studentProfileState: newProfile });
  };

  render() {
    const {
      dataLoad,
      studentProfileState,
      interestList,
      lifeInterestsList,
      behavioralProfiles,
      personalityProfiles,
      values,
      languages,
      professionalExperienceState,
      extraCourseState,
      projectsState,
      technicalCompetences,
      specialNeeds,
      experiencesLife,
      showSpecialNeeds,
    } = this.state;

    const {
      handleIsLoadingState,
      returnJobApplication,
      studentData,
      updateCandidates,
      options,
      like,
      role,
      studentId,
      liked,
      updateLiked,
      partnerJob,
      applicationIsInteresting,
    } = this.props;

    return (
      dataLoad && (
        <Fragment>
          <CompanyCurriculum
            profile={studentProfileState}
            specialNeeds={specialNeeds}
            showSpecialNeeds={showSpecialNeeds}
            interests={interestList}
            updateCandidates={updateCandidates}
            role={role}
            lifeInterests={lifeInterestsList}
            behavioral={behavioralProfiles}
            personality={personalityProfiles}
            experiencesCareer={professionalExperienceState}
            values={values}
            languages={languages}
            extraCourses={extraCourseState}
            experiencesLife={experiencesLife}
            projects={projectsState}
            technicalCompetences={technicalCompetences}
            handleIsLoadingState={handleIsLoadingState}
            returnJobApplication={returnJobApplication}
            studentData={studentData}
            options={options}
            like={like}
            liked={liked}
            studentId={studentId}
            updateLiked={updateLiked}
            updateProfile={this.updateProfile}
            fetchStudentData={this.fetchStudentData}
            partnerJob={partnerJob}
            applicationIsInteresting={applicationIsInteresting}
          />
        </Fragment>
      )
    );
  }
}

OpenCurriculum.propTypes = {
  getStudentCurriculumData: func,
  studentId: number,
  openSnackbar: func,
  updateCandidates: func,
  handleIsLoadingState: func,
  studentData: array,
  returnJobApplication: func,
  options: array,
  role: string,
  like: func,
  liked: bool,
  updateLiked: func,
};
