import styled, { keyframes } from 'styled-components';
import expandIcon from 'assets/mentorship/expand-icon.svg';
import expandActiveIcon from 'assets/mentorship/expand-active.svg';
import arrowRightIcon from 'assets/mentorship/arrow-right.svg';

export const Detail = styled.details`
  display: flex;
  flex-direction: column;

  &[open] .expand-icon {
    background-image: url(${expandActiveIcon});
  }
`;

export const Summary = styled.summary`
  display: flex;
  width: 100%;
  padding: 3rem 0;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    margin: 0 1rem 0 0;

    strong {
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: #009291;
    }

    > div {
      display: flex;
      gap: 1rem;
      margin: 11px 0 0 0;

      h3 {
        margin: 0;
        font-size: 18px;
        line-height: 21px;
        font-weight: 700;
        color: #4b4b4d;
      }
    }

    p {
      color: #777777;
      font-size: 13px;
      line-height: 15px;
      font-weight: 400;
      margin: 6px 0 0 0;
    }
  }
`;

export const ProfileImage = styled.div`
  width: 76px;
  height: 75px;
  background-image: url(${(props) => props.image});
  border-radius: 40px;
  background-position: center;
  background-size: cover;
`;

export const ExpandIcon = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #009291;
  background-image: url(${expandIcon});
  background-position: center;
  background-repeat: no-repeat;
`;

export const ScheduleButton = styled.button`
  border: none;
  background-color: #009291;
  width: 165px;
  height: 30px;
  border-radius: 22.54px;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ExpandedContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 1rem;

  strong {
    color: #4b4b4d;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    margin: 0 0 20px 0;
  }

  div {
    display: flex;
    gap: 0.5rem;

    p {
      color: #4b4b4d;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      margin: 0 0.5rem 0 0;
    }
  }

  > div {
    margin: 0 0 1rem 0;
  }
`;

export const InformationIcon = styled.div`
  width: 21px;
  height: 21px;
  background-color: #d3faf9;
  border-radius: 2.61px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-repeat: no-repeat;
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

export const ButtonSkeleton = styled.button`
  display: inline-block;
  height: 31px;
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  border: none;
  background-size: 200px 100%;
  margin: auto 10px;
  border-radius: 36.46px;
`;

export const OutlinedButton = styled.button`
  height: 31px;
  margin: auto 10px;
  background-color: #fff;
  border-radius: 36.46px;
  font-size: 12px;
  font-weight: 400;
  color: #009291;
  border: 1px solid #009291;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  line-height: 31px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;

  &:disabled {
    cursor: not-allowed;
    color: #949494;
    border: 1px solid #949494;
  }
`;

export const DocButtons = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: flex-end;
  gap: 13px;
  margin: 0 0 2rem 0 !important;
`;

export const ArrowRightIcon = styled.div`
  height: 22.5px;
  width: 22.5px;
  border-radius: 50%;
  background-color: #009291;
  background-image: url(${arrowRightIcon});
  background-position: center;
  background-repeat: no-repeat;
  ${(props) =>
    props.disabled &&
    `
    background-color: #949494;
    cursor: not-allowed;
  `}
`;
