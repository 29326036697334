import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { object, func, bool } from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { Check } from '@material-ui/icons';
import companyIcon from 'assets/img/icons/companyIcon.svg';
import styled from 'styled-components';
import BaseLayoutContext from 'contexts/base-layout';

const DetailPartnerDialog = styled.div`
  h5 {
    font-size: 16px;
  }
  .detailField {
    padding: 13px 13px 12px;
    border-radius: 10px;
    background: whitesmoke;
    width: 100%;
    word-wrap: break-word;
  }
  .imgCompany {
    width: 150px;
    border-radius: 10px;
  }
  .imgRadius {
    width: 170px;
    height: 170px;
    padding: 10px;
    background: #e4e3e1;
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    border: 3px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  }
`;
//borda da imagem da empresa - network
const PartnerDetailDialog = ({
  classes,
  company,
  visibleDialog,
  setVisibleDialog,
}) => {
  const { universityColor } = useContext(BaseLayoutContext);
  return (
    <Dialog
      open={visibleDialog}
      onClose={async () => setVisibleDialog(false)}
      PaperProps={{
        classes: {
          root: classes.modalPaper,
        },
      }}
      fullWidth
    >
      <DialogContent>
        <DetailPartnerDialog universityColor={universityColor}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div
                className="imgRadius"
                style={{
                  backgroundImage: `url(${
                    company.logo ? company.logo : companyIcon
                  })`,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <h5>
                <b>Nome da Empresa</b>
              </h5>
              <div className="detailField">{company.name}</div>
            </Grid>

            <Grid item xs={12} md={12}>
              <h5>
                <b>Desafios Atuais</b>
              </h5>
              <div className="detailField">{company.challenges}</div>
            </Grid>

            <Grid item xs={12} md={12}>
              <h5>
                <b>Descrição</b>
              </h5>

              <div className="detailField">{company.description}</div>
            </Grid>

            <Grid item xs={12} md={12}>
              <h5>
                <b>Principais Valores</b>
              </h5>
              <div className="detailField">
                {company.values &&
                  company.values.length > 0 &&
                  company.values.map((obj) => (
                    <Chip
                      key={obj.value}
                      label={obj.label}
                      onDelete={() => {}}
                      className={classes.chip}
                      deleteIcon={<Check />}
                      variant="outlined"
                    />
                  ))}
              </div>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginBottom: 15 }}>
              <h5>
                <b>Principais competências comportamentais dos profissionais</b>
              </h5>

              <div className="detailField">
                {company.skills &&
                  company.skills.length > 0 &&
                  company.skills.map((obj) => (
                    <Chip
                      key={obj.value}
                      label={obj.label}
                      onDelete={() => {}}
                      className={classes.chip}
                      deleteIcon={<Check />}
                      variant="outlined"
                    />
                  ))}
              </div>
            </Grid>
          </Grid>
        </DetailPartnerDialog>
      </DialogContent>
    </Dialog>
  );
};
PartnerDetailDialog.propTypes = {
  classes: object.isRequired,
  company: func.isRequired,
  visibleDialog: bool,
  setVisibleDialog: func.isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(
  PartnerDetailDialog
);
