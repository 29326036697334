import React, { useEffect, useState, useContext } from 'react';
import { BackButton, ButtonSend, Container } from './styles';
import { useMentorship } from '../../../contexts/MentorshipContext';
import HtmlEditor from '../HtmlEditor';
import ModalitySelect from '../ModalitySelect';
import { Form } from '@unform/web';
import BaseLayoutContext from 'contexts/base-layout';

export default function Step2({ setStep, handleStepChange, formRef }) {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [description, setDescription] = useState('');

  const { universityColor } = useContext(BaseLayoutContext);

  const {
    handleSendSolicitation,
    formAction,
    handleEditSolicitation,
    getDescription,
  } = useMentorship();

  useEffect(() => {
    setDescription(getDescription());
  }, []);

  const handleFormSubmit = async (data) => {
    setRequestInProgress(true);
    let success = undefined;
    switch (formAction) {
      case 'save':
        success = await handleSendSolicitation(
          data.desired_modality,
          data.description || ''
        );
        if (success) {
          setStep(3);
        }
        break;
      case 'edit':
        success = await handleEditSolicitation(
          data.desired_modality,
          data.description || ''
        );
        if (success) {
          setStep(3);
        }
        break;
      case 'reschedule':
        success = await handleEditSolicitation(
          data.desired_modality,
          data.description || '',
          true
        );
        if (success) {
          setStep(3);
        }
        break;
    }
    setRequestInProgress(false);
  };

  function getSendRequestButtonLabel() {
    if (requestInProgress) {
      return 'Enviando solicitação...';
    } else {
      switch (formAction) {
        case 'save':
          return 'Enviar solicitação';
        case 'edit':
          return 'Enviar solicitação';
        case 'reschedule':
          return 'Enviar nova solicitação de mentoria';
      }
    }
  }

  return (
    <Container universityColor={universityColor}>
      <Form ref={formRef} onSubmit={handleFormSubmit}>
        <h1>
          {formAction === 'reschedule'
            ? 'Solicitar reagendamento'
            : 'Agendar mentoria'}
        </h1>
        <h2>Modalidade de preferência </h2>
        <ModalitySelect />
        <h2>Descrição</h2>
        <HtmlEditor
          name={'description'}
          placeholder={
            formAction === 'reschedule'
              ? 'Conta pra gente o motivo do reagendamento dessa mentoria :)'
              : 'Conta pra gente o motivo de você querer essa mentoria :)'
          }
          setValue={setDescription}
          value={description}
          style={{ margin: '0 2.5rem 6.5rem 2.5rem' }}
        />
        <ButtonSend
          universityColor={universityColor}
          type="submit"
          disabled={requestInProgress}
        >
          {getSendRequestButtonLabel()}
        </ButtonSend>
        <BackButton
          universityColor={universityColor}
          onClick={() => handleStepChange(1)}
        />
      </Form>
    </Container>
  );
}
