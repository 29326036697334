import React, { useContext } from 'react';

import { Avatar, Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import companyIcon from 'assets/img/icons/companyIcon.svg';
import styled from 'styled-components';
import { triggerPageView } from '../../../../../utils/analytics';
import { Mixpanel } from 'utils/mixpanel';
import BaseLayoutContext from 'contexts/base-layout';

const Profile = styled.div`
  cursor: pointer;
  p + p {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
    text-decoration: underline;
  }
`;

const Companies = (likes, classes, showDetail) => {
  const { metadata } = useContext(BaseLayoutContext);

  return likes.map((company) => (
    <Profile universityColor={metadata.university_color} key={company.id}>
      <div
        className={classes.avatarContainer}
        onClick={() => {
          showDetail(company);
          triggerPageView('student/networking/empresas/ver-perfil');
          Mixpanel.track('Menu Networking: Clicou - Ver Perfil de empresas');
        }}
      >
        <Avatar
          alt={company.name}
          className={classes.avatar}
          style={{
            backgroundImage: `url(${company.logo || companyIcon})`,
          }}
        />
        <Typography className={classes.avatarText}>{company.name}</Typography>
        <p>Ver Perfil</p>
        {company.views && (
          <div className={classes.viewsContainer}>
            <Visibility color="disabled" /> {company.views}
          </div>
        )}
      </div>
    </Profile>
  ));
};

export default Companies;
