import React, { Fragment, useState } from 'react';
import {
  Divider,
  FormControl,
  InputLabel,
  FormHelperText,
  List,
  ListItem,
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import SearchableSelect from 'react-select';
import { Formik } from 'formik';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles, {
  FilterContainer,
} from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

import { func, shape, bool } from 'prop-types';
import { SkeletonAnimation } from '../JobsFinishDialog/FormComponents/Select/styled';

function TalentsFiltersForm(props) {
  const {
    classes,
    filtersOptions,
    filterTalentsQS,
    setfilterTalentsQS,
    haveCourse,
    liked,
    university,
    requesting,
    resultsCount,
    citiesOptions,
  } = props;

  const initialValues = {
    selectedCoursesFilter: [],
    selectedPeriods: [],
    selectedBehaviors: [],
    selectedExperienceAreas: [],
    professionalStatus: [],
    jobTypes: [],
    areas: [],
    workingInFormationArea: [],
    professionalOffers: [],
    selectedPersonalities: [],
    selectedStudentStatus: [],
    selectedCourseLevel: [],
    location: [],
    attractionRelated: [],
    analysisStatus: [],
    couses: haveCourse,
    studentName: null,
    studentNameWasSearched: false,
    studentExternalId: null,
    studentExternalIdWasSearched: false,
    curriculum: null,
  };

  const diversityFilterCSMFields = [
    {
      label: 'PCD - Pessoa com Deficiência',
      name: 'special_needs',
      options: filtersOptions.special_needs,
      isMulti: true,
    },
    {
      label: 'Cor/Raça',
      name: 'color_or_race',
      options: filtersOptions.color_or_race,
      isMulti: true,
    },
    {
      label: 'Identidade de Gênero',
      name: 'gender_identity',
      options: filtersOptions.gender_identity,
      isMulti: true,
    },
  ];

  const setNameFilter = (studentName) => {
    setfilterTalentsQS(
      {
        ...filterTalentsQS,
        ...{
          name: studentName,
        },
      },
      true
    );
  };

  const setExternalIdFilter = (externalId) => {
    setfilterTalentsQS(
      {
        ...filterTalentsQS,
        ...{
          external_id: externalId,
        },
      },
      true
    );
  };

  const [selectedCity, setSelectedCity] = useState([]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      render={({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        handleChange,
      }) => (
        <List style={{ position: 'sticky', top: 0, zIndex: 0, paddingTop: 0 }}>
          <FilterContainer>
            <h4 style={{ marginTop: 0 }}>Filtro avançado</h4>
            {university && (
              <Fragment>
                {/* Name */}
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <p>
                    <strong
                      className={classNames({
                        [classes.error]:
                          !!errors.selectedBehaviors &&
                          !!touched.selectedBehaviors,
                      })}
                    >
                      Dados Básicos
                    </strong>
                  </p>
                  <TextField
                    className={classes.inputField}
                    name="studentName"
                    id="studentName"
                    label="Nome"
                    placeholder="Digite o nome do estudante"
                    fullWidth
                    margin="normal"
                    variant="filled"
                    disabled={values.studentNameWasSearched || requesting}
                    InputProps={{
                      className: classes.input,
                      endAdornment: values.studentName && (
                        <InputAdornment position="start">
                          {!values.studentNameWasSearched ? (
                            <IconButton
                              aria-label="Pesquisar"
                              onClick={() => {
                                setFieldValue('studentNameWasSearched', true);
                                setNameFilter(values.studentName);
                              }}
                            >
                              <Search />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="Limpar campo"
                              onClick={() => {
                                setFieldValue('studentName', '');
                                setFieldValue('studentNameWasSearched', false);
                                setNameFilter('');
                              }}
                            >
                              <Close />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    onChange={handleChange}
                    value={values.studentName}
                    onKeyPress={(e) => {
                      if (!values.studentNameWasSearched && e.charCode === 13) {
                        setFieldValue('studentNameWasSearched', true);
                        setNameFilter(values.studentName);
                      }
                    }}
                  />
                </ListItem>

                {/* External ID */}
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <TextField
                    className={classes.inputField}
                    name="studentExternalId"
                    id="studentExternalId"
                    label="Código do Estudante"
                    placeholder="Digite o código do estudante"
                    fullWidth
                    margin="normal"
                    variant="filled"
                    disabled={values.studentExternalIdWasSearched || requesting}
                    InputProps={{
                      className: classes.input,
                      endAdornment: values.studentExternalId && (
                        <InputAdornment position="start">
                          {!values.studentExternalIdWasSearched ? (
                            <IconButton
                              aria-label="Pesquisar"
                              onClick={() => {
                                setFieldValue(
                                  'studentExternalIdWasSearched',
                                  true
                                );
                                setExternalIdFilter(values.studentExternalId);
                              }}
                            >
                              <Search />
                            </IconButton>
                          ) : (
                            <IconButton
                              aria-label="Limpar campo"
                              onClick={() => {
                                setFieldValue('studentExternalId', '');
                                setFieldValue(
                                  'studentExternalIdWasSearched',
                                  false
                                );
                                setExternalIdFilter('');
                              }}
                            >
                              <Close />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    onChange={handleChange}
                    value={values.studentExternalId}
                    onKeyPress={(e) => {
                      if (
                        !values.studentExternalIdWasSearched &&
                        e.charCode === 13
                      ) {
                        setFieldValue('studentExternalIdWasSearched', true);
                        setNameFilter(values.studentExternalId);
                      }
                    }}
                  />
                </ListItem>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  {university && (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel
                        htmlFor="selectedUnity"
                        shrink
                        error={errors.location && touched.location}
                      >
                        Unidade/Polo
                      </InputLabel>
                      <SearchableSelect
                        name="selectedUnity"
                        id="selectedUnity"
                        isClearable
                        InputLabel={{
                          shrink: true,
                        }}
                        placeholder="Selecione"
                        isSearchable
                        isDisabled={requesting}
                        isMulti
                        multiline
                        rows={3}
                        classNamePrefix="react-select"
                        className={classNames({
                          [classes.selectSection]: true,
                          [classes.selectSectionAutoHeight]: !requesting,
                          [classes.selectControlHeight]: requesting,
                          [classes.selectSectionCard]: true,
                        })}
                        onBlur={() => {
                          setFieldTouched('selectedUnity');
                        }}
                        onChange={async (event) => {
                          if (event) {
                            setfilterTalentsQS({
                              ...filterTalentsQS,
                              location_atributes: event.map(
                                (item) => item.value
                              ),
                            });
                          } else {
                            setFieldValue('selectedUnity', null);
                            setfilterTalentsQS(
                              filterTalentsQS.filter((item) => item !== 'unity')
                            );
                          }
                        }}
                        options={filtersOptions.unities || []}
                        defaultValue={[]}
                      />
                    </FormControl>
                  )}
                  {!!errors.selectedUnity && !!touched.selectedUnity && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedUnity}
                    </FormHelperText>
                  )}
                </ListItem>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedState"
                      shrink
                      error={errors.selectedState && touched.selectedState}
                    >
                      UF
                    </InputLabel>
                    <SearchableSelect
                      name="selectedState"
                      id="selectedState"
                      isClearable
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isDisabled={requesting}
                      isMulti
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedState');
                      }}
                      onChange={async (event) => {
                        if (event.length > 0) {
                          await setfilterTalentsQS({
                            ...filterTalentsQS,
                            states: event.map((item) => item.value),
                          });
                        }
                        if (event.length === 0) {
                          setSelectedCity([]);
                          setfilterTalentsQS(
                            Object.keys(filterTalentsQS).filter(
                              (item) => item !== 'states' || 'cities'
                            )
                          );
                        }
                      }}
                      options={filtersOptions.states || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedState && !!touched.selectedState && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedState}
                    </FormHelperText>
                  )}
                </ListItem>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedCity"
                      shrink
                      error={errors.selectedCity && touched.selectedCity}
                    >
                      Cidade
                    </InputLabel>
                    <SearchableSelect
                      name="selectedCity"
                      id="selectedCity"
                      value={selectedCity}
                      isClearable
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder={
                        citiesOptions && citiesOptions.length > 0
                          ? 'Selecione'
                          : 'Escolha um estado'
                      }
                      isSearchable
                      isDisabled={requesting || citiesOptions.length === 0}
                      isMulti
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]:
                          citiesOptions.length > 0 && !requesting,
                        [classes.selectControlHeight]:
                          requesting || citiesOptions.length === 0,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedCity');
                      }}
                      onChange={async (event) => {
                        setSelectedCity(event.value);
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            cities: event.map((item) => item.value),
                          });
                        } else {
                          setFieldValue('selectedCity', null);
                          setfilterTalentsQS(
                            filterTalentsQS.filter((item) => item !== 'cities')
                          );
                        }
                      }}
                      options={citiesOptions || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                </ListItem>
                <ListItem
                  className={classNames([
                    classes.listItemPaddingZeroSides,
                    classes.listItemPaddingBottomZero,
                    classes.listItemPaddingTopZero,
                  ])}
                >
                  <FormControlLabel
                    className={classes.marginRAuto}
                    classes={{
                      checked: classes.checkBox,
                    }}
                    labelPlacement="center"
                    label="Possui Currículo"
                    control={
                      <Checkbox
                        id="curriculum"
                        name="curriculum"
                        checked={values.curriculum}
                        value
                        disabled={requesting}
                        classes={{
                          checked: classes.checkBox,
                        }}
                        onChange={(event) => {
                          if (filterTalentsQS.has_curriculum) {
                            setfilterTalentsQS({
                              ...filterTalentsQS,
                              has_curriculum: '',
                            });
                            delete filterTalentsQS.has_curriculum;
                          } else {
                            setfilterTalentsQS({
                              ...filterTalentsQS,
                              has_curriculum: event.target.value,
                            });
                          }
                        }}
                      />
                    }
                  />
                </ListItem>
                <ListItem
                  className={classNames([
                    classes.listItemPaddingZeroSides,
                    classes.listItemPaddingBottomZero,
                    classes.listItemPaddingTopZero,
                  ])}
                >
                  <FormControlLabel
                    className={classes.marginRAuto}
                    classes={{
                      checked: classes.checkBox,
                    }}
                    labelPlacement="center"
                    label="Possui Carta de Apresentação"
                    control={
                      <Checkbox
                        id="has_cover_letter"
                        name="has_cover_letter"
                        checked={values.has_cover_letter}
                        value
                        disabled={requesting}
                        classes={{
                          checked: classes.checkBox,
                        }}
                        onChange={(event) => {
                          if (filterTalentsQS.has_cover_letter) {
                            setfilterTalentsQS({
                              ...filterTalentsQS,
                              has_cover_letter: '',
                            });
                            delete filterTalentsQS.has_cover_letter;
                          } else {
                            setfilterTalentsQS({
                              ...filterTalentsQS,
                              has_cover_letter: event.target.value,
                            });
                          }
                        }}
                      />
                    }
                  />
                  <Divider height={1} className={classes.divider} />
                  <span
                    style={{
                      fontWeight: 500,
                      margin: '8px 0 0 8px',
                      width: '100%',
                    }}
                  >
                    Dados de Diversidade
                  </span>
                  {diversityFilterCSMFields.map((field) => (
                    <ListItem className={classes.listItemPaddingZeroSides}>
                      <FormControl variant="filled" fullWidth>
                        <InputLabel htmlFor={field.name} shrink>
                          {field.label}
                        </InputLabel>
                        <SearchableSelect
                          name={field.name}
                          id={field.name}
                          isClearable
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          isSearchable
                          isMulti
                          multiline
                          rows={3}
                          value={values[field.name]}
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          options={field.options}
                          onChange={(event) => {
                            if (event) {
                              setfilterTalentsQS({
                                ...filterTalentsQS,
                                [field.name]: event.map((item) => item.value),
                              });
                            }
                          }}
                        />
                      </FormControl>
                    </ListItem>
                  ))}
                </ListItem>
              </Fragment>
            )}
            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedCoursesFilter &&
                      !!touched.selectedCoursesFilter,
                  })}
                >
                  Status Profissional
                </strong>
              </p>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="professionalStatus"
                  shrink
                  error={
                    errors.professionalStatus && touched.professionalStatus
                  }
                >
                  Status Profissional
                </InputLabel>
                <SearchableSelect
                  name="professionalStatus"
                  id="professionalStatus"
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('professionalStatus');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_status: event.value,
                      });
                    } else {
                      setFieldValue('professionalStatus', null);
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_status: '',
                      });
                    }
                  }}
                  options={filtersOptions.professional_status || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.professionalStatus && !!touched.professionalStatus && (
                <FormHelperText className={classes.helperText}>
                  {errors.professionalStatus}
                </FormHelperText>
              )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="jobTypes"
                  shrink
                  error={errors.jobTypes && touched.jobTypes}
                >
                  Tipo de Trabalho
                </InputLabel>
                <SearchableSelect
                  name="jobTypes"
                  id="jobTypes"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('jobTypes');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        job_types: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('jobTypes', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter((item) => item !== 'job_types')
                      );
                    }
                  }}
                  options={filtersOptions.job_types || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.jobTypes && !!touched.jobTypes && (
                <FormHelperText className={classes.helperText}>
                  {errors.jobTypes}
                </FormHelperText>
              )}
            </ListItem>
            {university && (
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="workingInFormationArea"
                    shrink
                    error={
                      errors.workingInFormationArea &&
                      touched.workingInFormationArea
                    }
                  >
                    Na Área de Formação
                  </InputLabel>
                  <SearchableSelect
                    name="workingInFormationArea"
                    id="workingInFormationArea"
                    isClearable
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder="Selecione"
                    isSearchable
                    multiline
                    isDisabled={requesting}
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('workingInFormationArea');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          working_in_formation_area: event.value,
                        });
                      } else {
                        setFieldValue('workingInFormationArea', null);
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          working_in_formation_area: '',
                        });
                      }
                    }}
                    options={filtersOptions.working_in_formation_area || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.workingInFormationArea &&
                  !!touched.workingInFormationArea && (
                    <FormHelperText className={classes.helperText}>
                      {errors.workingInFormationArea}
                    </FormHelperText>
                  )}
              </ListItem>
            )}
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="professionalOffers"
                  shrink
                  error={
                    errors.professionalOffers && touched.professionalOffers
                  }
                >
                  Interessado em:
                </InputLabel>
                <SearchableSelect
                  name="professionalOffers"
                  id="professionalOffers"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  menuPlacement="top"
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('professionalOffers');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_offers: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('professionalOffers', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'professional_offers'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.professional_offers || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.professionalOffers && !!touched.professionalOffers && (
                <FormHelperText className={classes.helperText}>
                  {errors.professionalOffers}
                </FormHelperText>
              )}
            </ListItem>
            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedCoursesFilter &&
                      !!touched.selectedCoursesFilter,
                  })}
                >
                  Requisitos Acadêmicos:
                </strong>
              </p>
              {filtersOptions.universities && (
                <Fragment>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedUniversity"
                      shrink
                      error={
                        errors.selectedUniversity && touched.selectedUniversity
                      }
                    >
                      Instituição de Ensino
                    </InputLabel>
                    <SearchableSelect
                      name="selectedUniversity"
                      id="selectedUniversity"
                      isClearable={false}
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isMulti
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedUniversity');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            university: event.map((item) => item.value),
                          });
                        } else {
                          setFieldValue('selectedUniversity', null);
                          setfilterTalentsQS(
                            filterTalentsQS.filter(
                              (item) => item !== 'university'
                            )
                          );
                        }
                      }}
                      options={filtersOptions.universities || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedUniversity &&
                    !!touched.selectedUniversity && (
                      <FormHelperText className={classes.helperText}>
                        {errors.selectedUniversity}
                      </FormHelperText>
                    )}
                </Fragment>
              )}
            </ListItem>
            <ListItem
              className={classNames({
                [classes.listItemPaddingZeroSides]: true,
                [classes.listItemReset]: !filtersOptions.universities,
              })}
            >
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedCourseLevel"
                  shrink
                  error={
                    errors.selectedCourseLevel && touched.selectedCourseLevel
                  }
                >
                  Grau de Escolaridade
                </InputLabel>
                <SearchableSelect
                  name="selectedCourseLevel"
                  id="selectedCourseLevel"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedCourseLevel');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS(
                        {
                          ...filterTalentsQS,
                          course_level: event.map((item) => item.value),
                        },
                        true
                      );
                    } else {
                      setFieldValue('selectedCourseLevel', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'course_level'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.course_level || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedCourseLevel &&
                !!touched.selectedCourseLevel && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedCourseLevel}
                  </FormHelperText>
                )}
            </ListItem>
            {values.couses && (
              <Fragment>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedCoursesFilter"
                      shrink
                      error={
                        errors.selectedCoursesFilter &&
                        touched.selectedCoursesFilter
                      }
                    >
                      Curso
                    </InputLabel>
                    <SearchableSelect
                      name="selectedCoursesFilter"
                      id="selectedCoursesFilter"
                      isClearable={false}
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isMulti
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedCoursesFilter');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            courses: event.map((item) => item.value),
                          });
                        } else {
                          setFieldValue('selectedCoursesFilter', null);
                          setfilterTalentsQS(
                            filterTalentsQS.filter((item) => item !== 'courses')
                          );
                        }
                      }}
                      options={filtersOptions.courses || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedCoursesFilter &&
                    !!touched.selectedCoursesFilter && (
                      <FormHelperText className={classes.helperText}>
                        {errors.selectedCoursesFilter}
                      </FormHelperText>
                    )}
                </ListItem>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedPeriods"
                      shrink
                      error={errors.selectedPeriods && touched.selectedPeriods}
                    >
                      Período
                    </InputLabel>
                    <SearchableSelect
                      name="selectedPeriods"
                      id="selectedPeriods"
                      isClearable={false}
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isMulti
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedPeriods');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            periods: event.map((item) => item.value),
                          });
                        } else {
                          setFieldValue('selectedPeriods', null);
                          setfilterTalentsQS(
                            filterTalentsQS.filter((item) => item !== 'periods')
                          );
                        }
                      }}
                      options={filtersOptions.periods || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedPeriods && !!touched.selectedPeriods && (
                    <FormHelperText className={classes.helperText}>
                      {errors.selectedPeriods}
                    </FormHelperText>
                  )}
                </ListItem>
              </Fragment>
            )}
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedStudentStatus"
                  shrink
                  error={
                    errors.selectedStudentStatus &&
                    touched.selectedStudentStatus
                  }
                >
                  Tipo de Estudante
                </InputLabel>
                <SearchableSelect
                  name="selectedStudentStatus"
                  id="selectedStudentStatus"
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedStudentStatus');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        student_status: event.value,
                      });
                    } else {
                      setFieldValue('selectedStudentStatus', null);
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        student_status: '',
                      });
                    }
                  }}
                  options={filtersOptions.student_status || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedStudentStatus &&
                !!touched.selectedStudentStatus && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedStudentStatus}
                  </FormHelperText>
                )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedAcademicProgress"
                  shrink
                  error={
                    errors.selectedAcademicProgress &&
                    touched.selectedAcademicProgress
                  }
                >
                  Rendimento Acadêmico
                </InputLabel>
                <SearchableSelect
                  name="selectedAcademicProgress"
                  id="selectedAcademicProgress"
                  isClearable
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedAcademicProgress');
                  }}
                  options={filtersOptions.academic_progress || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedAcademicProgress &&
                !!touched.selectedAcademicProgress && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedAcademicProgress}
                  </FormHelperText>
                )}
            </ListItem>

            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedExperienceAreas &&
                      !!touched.selectedExperienceAreas,
                  })}
                >
                  Requisitos Profissionais:
                </strong>
              </p>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="areas"
                  shrink
                  error={errors.areas && touched.areas}
                >
                  Competências Técnicas
                </InputLabel>
                <SearchableSelect
                  name="areas"
                  id="areas"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('areas');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        areas: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('areas', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter((item) => item !== 'areas')
                      );
                    }
                  }}
                  options={filtersOptions.areas || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.areas && !!touched.areas && (
                <FormHelperText className={classes.helperText}>
                  {errors.areas}
                </FormHelperText>
              )}
            </ListItem>
            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedExperienceAreas"
                  shrink
                  error={
                    errors.selectedExperienceAreas &&
                    touched.selectedExperienceAreas
                  }
                >
                  Setor de Experiência
                </InputLabel>
                <SearchableSelect
                  name="selectedExperienceAreas"
                  id="selectedExperienceAreas"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isMulti
                  isDisabled={requesting}
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedExperienceAreas');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        professional_experience_areas: event.map(
                          (item) => item.value
                        ),
                      });
                    } else {
                      setFieldValue('selectedExperienceAreas', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'professional_experience_areas'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.professional_experience_areas || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedExperienceAreas &&
                !!touched.selectedExperienceAreas && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedBehaviors}
                  </FormHelperText>
                )}
            </ListItem>

            <Divider height={1} className={classes.divider} />
            <ListItem className={classes.listItemPaddingZeroSides}>
              <p>
                <strong
                  className={classNames({
                    [classes.error]:
                      !!errors.selectedBehaviors && !!touched.selectedBehaviors,
                  })}
                >
                  Soft Skills:
                </strong>
              </p>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedBehaviors"
                  shrink
                  error={errors.selectedBehaviors && touched.selectedBehaviors}
                >
                  Comportamental
                </InputLabel>
                <SearchableSelect
                  name="selectedBehaviors"
                  id="selectedBehaviors"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedBehaviors');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        behavioral_attributes: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedBehaviors', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter(
                          (item) => item !== 'behavioral_attributes'
                        )
                      );
                    }
                  }}
                  options={filtersOptions.behavioral_attributes || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedBehaviors && !!touched.selectedBehaviors && (
                <FormHelperText className={classes.helperText}>
                  {errors.selectedBehaviors}
                </FormHelperText>
              )}
            </ListItem>

            <ListItem className={classes.listItemPaddingZeroSides}>
              <FormControl variant="filled" fullWidth>
                <InputLabel
                  htmlFor="selectedPersonalities"
                  shrink
                  error={
                    errors.selectedPersonalities &&
                    touched.selectedPersonalities
                  }
                >
                  Personalidade
                </InputLabel>
                <SearchableSelect
                  name="selectedPersonalities"
                  id="selectedPersonalities"
                  isClearable={false}
                  InputLabel={{
                    shrink: true,
                  }}
                  placeholder="Selecione"
                  isSearchable
                  isDisabled={requesting}
                  isMulti
                  multiline
                  rows={3}
                  classNamePrefix="react-select"
                  className={classNames({
                    [classes.selectSection]: true,
                    [classes.selectSectionAutoHeight]: !requesting,
                    [classes.selectControlHeight]: requesting,
                    [classes.selectSectionCard]: true,
                  })}
                  onBlur={() => {
                    setFieldTouched('selectedPersonalities');
                  }}
                  onChange={async (event) => {
                    if (event) {
                      setfilterTalentsQS({
                        ...filterTalentsQS,
                        personality: event.map((item) => item.value),
                      });
                    } else {
                      setFieldValue('selectedPersonalities', null);
                      setfilterTalentsQS(
                        filterTalentsQS.filter((item) => item !== 'personality')
                      );
                    }
                  }}
                  options={filtersOptions.personality || []}
                  defaultValue={[]}
                />
              </FormControl>
              {!!errors.selectedPersonalities &&
                !!touched.selectedPersonalities && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedPersonalities}
                  </FormHelperText>
                )}
            </ListItem>

            <Divider height={1} className={classes.divider} />
            {university ? (
              <Fragment>
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <p>
                    <strong
                      className={classNames({
                        [classes.error]:
                          !!errors.selectedCoursesFilter &&
                          !!touched.selectedCoursesFilter,
                      })}
                    >
                      Análise de Extracurricular:
                    </strong>
                  </p>
                </ListItem>
                <ListItem
                  className={classNames({
                    [classes.listItemPaddingZeroSides]: true,
                    [classes.listItemReset]: !filtersOptions.universities,
                  })}
                >
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="analysisStatus"
                      shrink
                      error={
                        errors.selectedCourseLevel &&
                        touched.selectedCourseLevel
                      }
                    >
                      Status da Análise
                    </InputLabel>
                    <SearchableSelect
                      name="analysisStatus"
                      id="analysisStatus"
                      isClearable={false}
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isMulti
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('analysisStatus');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS(
                            {
                              ...filterTalentsQS,
                              analysis_status: event.map((item) => item.value),
                            },
                            true
                          );
                        } else {
                          setFieldValue('analysisStatus', null);
                          setfilterTalentsQS(
                            filterTalentsQS.filter(
                              (item) => item !== 'analysisStatus'
                            )
                          );
                        }
                      }}
                      options={filtersOptions.analysis_status || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedCourseLevel &&
                    !!touched.selectedCourseLevel && (
                      <FormHelperText className={classes.helperText}>
                        {errors.selectedCourseLevel}
                      </FormHelperText>
                    )}
                </ListItem>

                {filtersOptions &&
                  filtersOptions.attraction_related &&
                  filtersOptions.attraction_related.length > 0 && (
                    <Fragment>
                      <ListItem className={classes.listItemPaddingZeroSides}>
                        <FormControl variant="filled" fullWidth>
                          <InputLabel
                            htmlFor="selectedStudentStatus"
                            shrink
                            error={
                              errors.selectedStudentStatus &&
                              touched.selectedStudentStatus
                            }
                          >
                            Oportunidade Relacionada
                          </InputLabel>
                          <SearchableSelect
                            name="selectedAttractionRelated"
                            id="selectedAttractionRelated"
                            isClearable
                            InputLabel={{
                              shrink: true,
                            }}
                            placeholder="Selecione"
                            isSearchable
                            isDisabled={requesting}
                            multiline
                            rows={3}
                            classNamePrefix="react-select"
                            className={classNames({
                              [classes.selectSection]: true,
                              [classes.selectSectionAutoHeight]: !requesting,
                              [classes.selectControlHeight]: requesting,
                              [classes.selectSectionCard]: true,
                            })}
                            onBlur={() => {
                              setFieldTouched('selectedAttractionRelated');
                            }}
                            onChange={async (event) => {
                              if (event) {
                                setfilterTalentsQS({
                                  ...filterTalentsQS,
                                  attraction_related: event.value,
                                });
                              } else {
                                setFieldValue(
                                  'selectedAttractionRelated',
                                  null
                                );
                                delete filterTalentsQS.attraction_related;
                                setfilterTalentsQS(filterTalentsQS);
                              }
                            }}
                            options={filtersOptions.attraction_related || []}
                            defaultValue={[]}
                          />
                        </FormControl>
                        {!!errors.selectedAttractionRelated &&
                          !!touched.selectedAttractionRelated && (
                            <FormHelperText className={classes.helperText}>
                              {errors.selectedAttractionRelated}
                            </FormHelperText>
                          )}
                      </ListItem>
                    </Fragment>
                  )}
              </Fragment>
            ) : null}

            {filtersOptions.knowledge && (
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel
                    htmlFor="selectedKnowledge"
                    shrink
                    error={
                      errors.selectedKnowledge && touched.selectedKnowledge
                    }
                  >
                    Competências Técnicas
                  </InputLabel>
                  <SearchableSelect
                    name="selectedKnowledge"
                    id="selectedKnowledge"
                    isClearable={false}
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder="Selecione"
                    isSearchable
                    menuPlacement="top"
                    isMulti
                    isDisabled={requesting}
                    multiline
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames({
                      [classes.selectSection]: true,
                      [classes.selectSectionAutoHeight]: !requesting,
                      [classes.selectControlHeight]: requesting,
                      [classes.selectSectionCard]: true,
                    })}
                    onBlur={() => {
                      setFieldTouched('selectedKnowledge');
                    }}
                    onChange={async (event) => {
                      if (event) {
                        setfilterTalentsQS({
                          ...filterTalentsQS,
                          knowledge: event.map((item) => item.value),
                        });
                      } else {
                        setFieldValue('selectedKnowledge', null);
                        setfilterTalentsQS(
                          filterTalentsQS.filter((item) => item !== 'knowledge')
                        );
                      }
                    }}
                    options={filtersOptions.knowledge || []}
                    defaultValue={[]}
                  />
                </FormControl>
                {!!errors.selectedKnowledge && !!touched.selectedKnowledge && (
                  <FormHelperText className={classes.helperText}>
                    {errors.selectedKnowledge}
                  </FormHelperText>
                )}
              </ListItem>
            )}

            {liked && (
              <Fragment>
                <Divider height={1} className={classes.divider} />
                <ListItem className={classes.listItemPaddingZeroSides}>
                  <p>
                    <strong
                      className={classNames({
                        [classes.error]:
                          !!errors.selectedCoursesStatus &&
                          !!touched.selectedCoursesStatus,
                      })}
                    >
                      Status do Currículo:
                    </strong>
                  </p>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="selectedCoursesStatus"
                      shrink
                      error={
                        errors.selectedCoursesStatus &&
                        touched.selectedCoursesStatus
                      }
                    >
                      Curtidas
                    </InputLabel>
                    <SearchableSelect
                      name="selectedCoursesStatus"
                      id="selectedCoursesStatus"
                      isClearable
                      InputLabel={{
                        shrink: true,
                      }}
                      placeholder="Selecione"
                      isSearchable
                      isDisabled={requesting}
                      multiline
                      rows={3}
                      classNamePrefix="react-select"
                      className={classNames({
                        [classes.selectSection]: true,
                        [classes.selectSectionAutoHeight]: !requesting,
                        [classes.selectControlHeight]: requesting,
                        [classes.selectSectionCard]: true,
                      })}
                      onBlur={() => {
                        setFieldTouched('selectedCoursesStatus');
                      }}
                      onChange={async (event) => {
                        if (event) {
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                            curriculum_like: event.value,
                          });
                        } else {
                          setFieldValue('selectedCoursesStatus', null);
                          delete filterTalentsQS.curriculum_like;
                          setfilterTalentsQS({
                            ...filterTalentsQS,
                          });
                        }
                      }}
                      options={filtersOptions.curriculum_like || []}
                      defaultValue={[]}
                    />
                  </FormControl>
                  {!!errors.selectedCoursesStatus &&
                    !!touched.selectedCoursesStatus && (
                      <FormHelperText className={classes.helperText}>
                        {errors.selectedCoursesStatus}
                      </FormHelperText>
                    )}
                </ListItem>
              </Fragment>
            )}
          </FilterContainer>
        </List>
      )}
    />
  );
}

TalentsFiltersForm.propTypes = {
  setfilterTalentsQS: func.isRequired,
  university: bool.isRequired,
  classes: shape({}).isRequired,
  haveCourse: bool.isRequired,
  liked: bool.isRequired,
  filtersOptions: shape.isRequired,
  filterTalentsQS: shape.isRequired,
  resultsCount: shape.isRequired,
  requesting: bool.isRequired,
  citiesOptions: shape.isRequired,
  options: shape.isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(TalentsFiltersForm);
