import ImageDefault from 'assets/img/imageDefault';
import {
  BoxLink,
  Container,
  Info,
  ContainerImage,
  LetterCounter,
  TextImage,
  Title,
  WrapperImage,
  InputImage,
  AddImage,
  ContainerButtons,
  BoxLinkSkeleton,
  InputImageSkeleton,
} from '../Styles/BaseComponent';
import { Form } from '@unform/web';
import { useRef, useState, useCallback, useEffect } from 'react';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import ModalUploadEvidences from 'components/UploadEvidences';
import { DividerResponsive } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/MoreInformationModalStudent/style';
import { IoEyeOutline } from 'react-icons/io5';
import {
  createLoginConfig,
  deleteImageLoginConfig,
  editLoginConfig,
  getImageLoginConfig,
  getLoginConfig,
  postImageSettings,
} from 'views/StaffPages/SettingsPage/services';
import { PreviewModal } from '../PreviewModal';
import CopyLinkButton from 'components/CopyLinkButton';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import InputWithCounter from 'components/UnformFields/InputWithCounter';

export function GraduatesTab({ setNoScroll }) {
  const formRef = useRef(null);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [image, setImage] = useState([]);
  const [controlPreviewModal, setControlPreviewModal] = useState(false);
  const [dataGraduates, setDataGraduates] = useState({});
  const [skeleton, setSkeleton] = useState(false);
  const [backgroundImageSkeleton, setBackgroundImageSkeleton] = useState(false);

  const url = `https://${dataGraduates.slug}.worka.love/#/login/egressos`;

  const handleCloseUpdateModal = () => {
    setOpenModalUpload(false);
    getSettingsGraduates('egress');
    setNoScroll(false);
  };

  const getSettingsGraduates = useCallback(async () => {
    const response = await getLoginConfig('egress');
    setDataGraduates(response);
    return response;
  }, []);

  const handleSubmitForm = async (
    { login_hint, password_hint },
    firstAccess = false
  ) => {
    let data = {
      login_type: 'egress',
      login_hint,
      password_hint,
    };

    if (firstAccess) {
      data = {
        login_type: 'egress',
      };
    }

    if (dataGraduates.id) {
      data = {
        login_hint,
        password_hint,
      };
    }

    let response;

    try {
      if (dataGraduates.id) {
        response = await editLoginConfig(data, 'egress');
        customSnackbar('Configurações salvas com sucesso!', 'confirmation');
      } else {
        response = await createLoginConfig(data);
      }
      setDataGraduates(response);
      return response;
    } catch (error) {
      customSnackbar('Ocorreu um erro ao enviar o formulário:', 'error');
    }
  };

  const handleChangeUploadModal = async (id) => {
    setOpenModalUpload(true);

    let firstAccessId = id;

    if (!firstAccessId) {
      const response = await handleSubmitForm(_, true);
      firstAccessId = response.id;
    }
    if (firstAccessId && !id) {
      await getImageLoginConfig(firstAccessId);
    }

    if (id) {
      const response = await getImageLoginConfig(id);
      if (response && response !== undefined && response.image !== null) {
        setImage([response]);
      }
    }
  };

  useEffect(() => {
    getSettingsGraduates();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSkeleton(true);
      setBackgroundImageSkeleton(true);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, []);

  const returnBoxLink = () => {
    if (!skeleton) {
      return <BoxLinkSkeleton />;
    }

    return (
      <BoxLink>
        <strong> Link de acesso: </strong>
        <span>{url}</span>
        <CopyLinkButton textToCopy={url} tooltipMessage="Copiar Link" />
      </BoxLink>
    );
  };

  const returnBackgroundImage = (image) => {
    if (!backgroundImageSkeleton && image?.file) {
      return <InputImageSkeleton />;
    }

    if (!image) {
      return (
        <InputImage>
          <ImageDefault />
        </InputImage>
      );
    }

    return <img src={image.file} />;
  };

  return (
    <Container>
      <ModalUploadEvidences
        open={openModalUpload}
        evidenceFiles={image??[]}
        evidenceLinks={[]}
        acceptFormats={['file']}
        acceptFileTypes={['PNG', 'JPEG']}
        functionCloseAndUpdate={handleCloseUpdateModal}
        headerTitle={'Adicionar Imagem'}
        buttonLabel={'SALVAR'}
        limitUpload={1}
        idPost={dataGraduates.id}
        functionPostFile={postImageSettings}
        functionDeleteEvidence={deleteImageLoginConfig}
        uniqueUploadType={'file'}
        nameTypeDoc={false}
        ctaDownloadPreview={false}
        limitSizeDocument={2097152}
        messageLimitSize={'O tamanho máximo da imagem é de 2MB.'}
      />
      <PreviewModal
        control={controlPreviewModal}
        data={dataGraduates}
        updatedData={formRef.current?.getData()}
        close={() => {
          setControlPreviewModal(false);
          setNoScroll(false);
        }}
      />
      {returnBoxLink()}
      <Info>
        Adicione orientações claras abaixo dos campos de acesso e senha para que
        os <strong> estudantes egressos </strong> compreendam facilmente como
        acessar a plataforma.
      </Info>
      <Form
        ref={formRef}
        onSubmit={handleSubmitForm}
        initialData={dataGraduates}
      >
        <Title> Usuário </Title>
        <Info>Informe como o estudante deverá inserir o nome de usuário.</Info>
        <InputWithCounter
          name="login_hint"
          placeholder="Exemplo: Insira seu registro acadêmico"
          maxLength={30}
          useCount={true}
        />
        <Title> Senha </Title>
        <Info>
          Informe qual é a senha padrão utilizada para realizar o acesso. Essa
          informação aparecerá para o estudante caso ele tente fazer o acesso e
          informe a senha incorreta.
        </Info>
        <InputWithCounter
          name="password_hint"
          placeholder="Exemplo: Data de nascimento invertida"
          maxLength={30}
          useCount={true}
        />
        <WrapperImage>
          <TextImage>
            <Title> Imagem da tela de acesso</Title>
            <Info marginTop="13px">
              Escolha uma imagem que represente a sua Instituição para
              apresenta-lá na tela de acesso dos seus estudantes.
            </Info>
          </TextImage>
          <ContainerImage>
            {returnBackgroundImage(dataGraduates.image)}
            <AddImage
              type="button"
              onClick={() => {
                handleChangeUploadModal(dataGraduates.id);
                setNoScroll(true);
              }}
            >
              <MdOutlineAddPhotoAlternate size={23} />
              {dataGraduates.image?.file
                ? 'Substituir imagem'
                : 'Adicionar Imagem'}
            </AddImage>
          </ContainerImage>
        </WrapperImage>
        <DividerResponsive />
        <ContainerButtons>
          <button
            type="button"
            className="preview-button"
            onClick={() => {
              setNoScroll(true);
              setControlPreviewModal(true);
            }}
          >
            <IoEyeOutline size={23} />
            Pré-visualizar
          </button>
          <button type="submit" className="save-button">
            Salvar ajustes
          </button>
        </ContainerButtons>
      </Form>
    </Container>
  );
}
