import styled from 'styled-components';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
  position: relative;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 2px;
`;

export const TextArea = styled.textarea`
  resize: none;
  border: ${(props) =>
    props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
  height: ${({ textAreaHeight }) => textAreaHeight || '160px'};
  font-family: sans-serif;
  background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  padding: 12px;
  border-radius: 4px;
  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const Count = styled.span`
  position: absolute;
  bottom: -25px;
  right: 0;
`;
