import React, { Fragment, useEffect, useState } from 'react';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import { useMentorship } from './contexts/MentorshipContext';
import backgroundMentoring from 'assets/img/mentorship-background.jpg';
import { Content } from './styles';
import MentorshipSolicitationTab from './components/MentorshipSolicitationTab';
import ScheduleMentorshipTab from './components/ScheduledMentorshipTab';
import MentorshipHistoricTab from './components/MentorshipHistoricTab';
import ScheduleMentorshipModal from './components/ScheduleMentorshipModal';
import ModalCancelMentorship from './components/ModalCancelMentorship';
import DetailMentorshipModal from './components/DetailMentorshipModal';
import ModalAdjustMentorshipStatus from './components/ModalAdjustMentorshipStatus';

export default function MentorshipContent({
  handleIsLoadingState,
  openModalWithContent,
  openSnackbar,
  closeModal,
  updateHasCoverLetter,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const {
    requestedMentorships,
    scheduledMentorships,
    historicMentorships,
  } = useMentorship();

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);
  return (
    <Fragment>
      <CompanyViewsHeader
        title="Gestão de Mentoria"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundMentoring}
        tabs={[
          `Agendar Mentoria${
            requestedMentorships ? ` (${requestedMentorships.length || 0})` : ''
          }`,
          `Mentorias Agendadas${
            scheduledMentorships ? ` (${scheduledMentorships.length || 0})` : ''
          }`,
          `Histórico de Mentorias${
            historicMentorships ? ` (${historicMentorships.length || 0})` : ''
          }`,
        ]}
      />
      <Content>
        {activeTab === 0 && (
          <MentorshipSolicitationTab
            setActiveTab={setActiveTab}
            openModalWithContent={openModalWithContent}
            handleIsLoadingState={handleIsLoadingState}
            openSnackbar={openSnackbar}
            closeModal={closeModal}
            updateHasCoverLetter={updateHasCoverLetter}
          />
        )}
        {activeTab === 1 && (
          <ScheduleMentorshipTab
            setActiveTab={setActiveTab}
            openModalWithContent={openModalWithContent}
            handleIsLoadingState={handleIsLoadingState}
            openSnackbar={openSnackbar}
            closeModal={closeModal}
            updateHasCoverLetter={updateHasCoverLetter}
          />
        )}
        {activeTab === 2 && (
          <MentorshipHistoricTab
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            openModalWithContent={openModalWithContent}
            handleIsLoadingState={handleIsLoadingState}
            openSnackbar={openSnackbar}
            closeModal={closeModal}
            updateHasCoverLetter={updateHasCoverLetter}
          />
        )}
      </Content>
      <DetailMentorshipModal />
      <ScheduleMentorshipModal />
      <ModalCancelMentorship />
      <ModalAdjustMentorshipStatus />
    </Fragment>
  );
}
