import { useContext, useEffect } from 'react';

import {
  getArticles,
  getChallenges,
  getCourses,
  getEbooks,
  getEvents,
  getPrograms,
  getSeries,
  getTrails,
  getAnnouncements,
} from 'services/university/events';
import { ListOfPublishedLearningContext } from '../Context/ListOfPublishedLearningContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export const useLearningAPICalls = () => {
  const {
    setRefreshCountUniversity,

    setEvents,
    setCourses,
    setPrograms,
    setCommunicate,
    setTrails,
    setEbooks,
    setArticle,
    setChallenge,
    setSerie,

    setEventCount,
    setCoursesCount,
    setProgramsCount,
    setAnnouncementsCount,
    setTrailsCount,
    setEbooksCount,
    setArticlesCount,
    setChallengesCount,
    setSeriesCount,

    setCurrentPageEvents,
    setCurrentPageCourses,
    setCurrentPagePrograms,
    setCurrentPageAnnouncements,
    setCurrentPageTrails,
    setCurrentPageEbooks,
    setCurrentPageArticles,
    setCurrentPageChallenges,
    setCurrentPageSeries,
  } = useContext(ListOfPublishedLearningContext);

  const getAllEvents = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getEvents(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEventCount(count);

      if (reset) {
        setEvents(null);
        setCurrentPageEvents(0);
        setEventCount(0);
        setEvents(results);
        setEventCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageEvents(currentPage);
        setEvents((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEventCount(count);
      }
    }
  };

  const getAllCourses = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getCourses(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCoursesCount(count);
      if (reset) {
        setCourses(null);
        setCurrentPageCourses(0);
        setCoursesCount(0);
        setCourses(results);
        setCoursesCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageCourses(currentPage);
        setCourses((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setCoursesCount(count);
      }
    }
  };

  const getAllPrograms = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getPrograms(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setProgramsCount(count);
      if (reset) {
        setPrograms(null);
        setCurrentPagePrograms(0);
        setProgramsCount(0);
        setPrograms(results);
        setProgramsCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPagePrograms(currentPage);
        setPrograms((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setProgramsCount(count);
      }
    }
  };

  const getAllAnnouncements = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getAnnouncements(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;

      setAnnouncementsCount(count);
      if (reset) {
        setCommunicate(null);
        setCurrentPageAnnouncements(0);
        setAnnouncementsCount(0);
        setCommunicate(results);
        setAnnouncementsCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageAnnouncements(currentPage);
        setCommunicate((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setAnnouncementsCount(count);
      }
    }
  };

  const getAllTrails = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getTrails(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setTrailsCount(count);
      if (reset) {
        setTrails(null);
        setCurrentPageTrails(0);
        setTrailsCount(0);
        setTrails(results);
        setTrailsCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageTrails(currentPage);
        setTrails((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setTrailsCount(count);
      }
    }
  };

  const getAllEbooks = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getEbooks(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEbooksCount(count);
      if (reset) {
        setEbooks(null);
        setCurrentPageEbooks(0);
        setEbooksCount(0);
        setEbooks(results);
        setEbooksCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageEbooks(currentPage);
        setEbooks((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEbooksCount(count);
      }
    }
  };

  const getAllArticles = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getArticles(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;

      setArticlesCount(count);
      if (reset) {
        setArticle(null);
        setCurrentPageArticles(0);
        setArticlesCount(0);
        setArticle(results);
        setArticlesCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageArticles(currentPage);
        setArticle((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setArticlesCount(count);
      }
    }
  };

  const getAllChallenges = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getChallenges(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setChallengesCount(count);
      if (reset) {
        setChallenge(null);
        setCurrentPageChallenges(0);
        setChallengesCount(0);
        setChallenge(results);
        setChallengesCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageChallenges(currentPage);
        setChallenge((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setChallengesCount(count);
      }
    }
  };

  const getAllSeries = async (
    educationalGroup = false,
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getSeries(educationalGroup, page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setSeriesCount(count);
      if (reset) {
        setSerie(null);
        setCurrentPageSeries(0);
        setSeriesCount(0);
        setSerie(results);
        setSeriesCount(count);
        setRefreshCountUniversity(true);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageSeries(currentPage);
        setSerie((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setSeriesCount(count);
      }
    }
  };

  return {
    getAllEvents,
    getAllPrograms,
    getAllTrails,
    getAllArticles,
    getAllSeries,
    getAllEbooks,
    getAllChallenges,
    getAllAnnouncements,
    getAllCourses,
  };
};
