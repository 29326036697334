import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  UnInput,
  UnInputLabel,
  ContainerLabelInput,
  Count,
  Error,
  HintInformation,
} from './style';
import Tooltip from 'components/NewTooltip';

export default function Input({
  label,
  name,
  maxLength,
  onChange,
  useCount,
  width,
  padding,
  removeMargin,
  useHint,
  hintConfig,
  formatDecimal,
  height,
  fontSize,
  universityColor,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [count, setCount] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.value = value ? value : '';
        if (useCount) {
          setCount(value.length);
        }
      },
    });
  }, [fieldName, registerField]);

  const formatValue = (value) => {
    if (!formatDecimal) return value;
    let newValue = value;
    if (typeof value === 'number') {
      newValue = String(value);
    }
    const numericValue = Number(
      newValue.replace(/[^0-9,-]+/g, '').replace(',', '.')
    );
    const formattedValue = new Intl.NumberFormat('pt-br').format(numericValue);
    return formattedValue;
  };

  const handleChange = (event, useDefault) => {
    let value = useDefault ? event : event.target.value;
    value = formatValue(value);

    if (onChange) {
      if (formatDecimal) {
        onChange(value);
      } else {
        onChange(event);
      }
    }
    if (useCount) {
      setCount(value.length);
    }
    if (!useDefault) {
      event.target.value = value;
    }
  };

  const lengthRemaing = maxLength - count;
  const renderCount = useCount && (
    <Count>{lengthRemaing} caracteres restantes</Count>
  );

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, []);

  return (
    <ContainerLabelInput style={{ width: width }} removeMargin={removeMargin}>
      {label && (
        <UnInputLabel htmlFor={name} fontSize={fontSize}>
          {label}
          {useHint && (
            <Tooltip {...hintConfig}>
              <HintInformation>i</HintInformation>
            </Tooltip>
          )}
        </UnInputLabel>
      )}
      <UnInput
        universityColor={universityColor}
        error={!!error}
        ref={inputRef}
        maxLength={maxLength}
        defaultValue={defaultValue}
        onChange={(e) => handleChange(e, false)}
        style={{ padding: padding, height: height }}
        {...rest}
      />
      {renderCount}
      {error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
