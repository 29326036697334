import React, { useRef } from 'react';
import { object, array, func } from 'prop-types';

import { Grid, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './carrousel.css';

const Carrousel = (props) => {
  const { classes, invites, content, showDetail } = props;
  const list = useRef();
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item className={classes.listGrid}>
        <ScrollMenu
          data={content(invites, classes, showDetail)}
          alignCenter={false}
          alignOnResize
          hideSingleArrow
          wheel={false}
          hideArrows
          ref={list}
          arrowLeft={
            <Button size="small" className={classes.directionButtons}>
              <KeyboardArrowLeft className={classes.arrowIcons} />
            </Button>
          }
          arrowRight={
            <Button size="small" className={classes.directionButtons}>
              <KeyboardArrowRight className={classes.arrowIcons} />
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

Carrousel.propTypes = {
  classes: object.isRequired,
  invites: array.isRequired,
  content: func,
  showDetail: func,
};

export default Carrousel;
