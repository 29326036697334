import React, { useState } from 'react';

import {
  Container,
  Button,
  WrapperInfos,
  VisualIndicator,
  ContainerButton,
  JobsStatus,
  ListButton,
  FeedbackButton,
} from './styled';

import Edifice from '../../../../../../assets/JobOffer/edifice.svg';
import User from '../../../../../../assets/JobOffer/user.svg';

import classnames from 'classnames';
import FinishModal from '../Modal/FinishModal';
import { JobDetailButton } from '../JobDetails';

import { EngineListCandidates } from 'components/ListCandidates/EngineListCandidates';

export default function JobHistoryCards(props) {
  const {
    jobName,
    openedSince,
    responsibleName,
    numberOfCandidates,
    jobID,
    status,
    jobs,
    limit,
    setFeedback,
    changeModalFeedbackState,
    seeMatch,
    setListJobsCompanyHistory,
    setJobsCount,
    companyFilters,
    dataFunction,
  } = props;

  const [expandedResponsible, setExpandedResponsible] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleModalListContent = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const strReduced = (str) => {
    if (str.length > 17) {
      return `${str.substr(0, 17)}...`;
    } else {
      return str;
    }
  };

  const handleModalFinish = () => {
    setModalFinish(true);
  };

  const handleClickFeedBack = () => {
    setFeedback({ feedback: jobs.feedback, id: jobID });

    changeModalFeedbackState();
  };

  return (
    <Container>
      {openModal && (
        <EngineListCandidates
          controlModal={openModal}
          setControlModal={setOpenModal}
          jobs={jobs}
          isPartner={jobs.is_partner_offer}
          history={true}
          company={true}
          seeMatch={seeMatch}
        />
      )}

      <FinishModal
        setModalFinish={setModalFinish}
        isOpen={modalFinish}
        jobID={jobID}
        isPartnerOffer={jobs.is_partner_offer}
        limit={limit}
        status={status}
        setListJobsCompanyHistory={setListJobsCompanyHistory}
        setJobsCount={setJobsCount}
        companyFilters={companyFilters}
        dataFunction={dataFunction}
      />
      <WrapperInfos>
        <strong> {jobName} </strong>
        <span> {openedSince} </span>
        <span> {`CÓDIGO: ${jobID}`} </span>

        <VisualIndicator>
          <div className="icon">
            <img src={Edifice} alt="Icone Edifício" />
          </div>
          <p title={responsibleName}>
            {expandedResponsible || responsibleName.length <= 17
              ? responsibleName
              : strReduced(responsibleName)}
          </p>
          {!expandedResponsible && responsibleName.length > 17 && (
            <button
              onClick={() => {
                setExpandedResponsible(true);
              }}
            >
              ver mais
            </button>
          )}
        </VisualIndicator>
        <VisualIndicator>
          <div className="icon">
            <img src={User} alt="Icone Usuário" />
          </div>

          <p>
            {numberOfCandidates === 0 ? 'Nenhum estudante candidatado' : null}
            {numberOfCandidates === 1
              ? `${numberOfCandidates} estudante candidatado`
              : null}
            {numberOfCandidates > 1
              ? `${numberOfCandidates} estudantes candidatados`
              : null}
          </p>
          {numberOfCandidates ? (
            <ListButton onClick={handleModalListContent}>ver</ListButton>
          ) : null}
        </VisualIndicator>
        <VisualIndicator>
          <JobsStatus
            className={classnames({
              expired: status === 'Vaga expirada',
              withContractingWorkalove:
                status === 'Finalizadas com contratações providas pelo sistema',
              withContractingExternal:
                status === 'Finalizada com contratação externa',
              unpublishJob: status === 'Vaga despublicada',
              noContract: status === 'Vaga finalizada sem contratação',
            })}
          />
          <p> {status} </p>
        </VisualIndicator>
        <ContainerButton>
          {!jobs.is_partner_offer && status === 'Vaga expirada' ? (
            <Button
              background="#009291"
              color="#ffffff"
              onClick={handleModalFinish}
            >
              Finalizar Vaga
            </Button>
          ) : null}

          {jobs.feedback && (
            <FeedbackButton onClick={handleClickFeedBack}>
              Olhar Feedback
            </FeedbackButton>
          )}

          <JobDetailButton
            jobID={jobID}
            isFromPartners={jobs.is_partner_offer}
            externalJobOffer={jobs.is_external}
            style={
              jobs.is_partner_offer || status !== 'Vaga expirada'
                ? {
                    backgroundColor: '#009291',
                    color: '#ffffff',
                  }
                : undefined
            }
          >
            Detalhes da Vaga
          </JobDetailButton>
        </ContainerButton>
      </WrapperInfos>
    </Container>
  );
}
