import React, { useState, useEffect, useContext, useRef } from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import {
  DivInsertImg,
  EditImg,
  ImgUrl,
  ModalWrapper,
  TextInput,
  FragmentModal,
  Title,
  FrangmentAvatar,
  Actions,
  Buttons,
  FragmentZoom,
  Row,
  LabelZomm,
  InputZomm,
  Button,
  Text,
  DivAlterImg,
  ContainerGallery,
} from './styles';
import BaseLayoutContext from 'contexts/base-layout';
import Gallery from 'assets/companiesManagement/Gallery.svg';

const UploadedAvatarCustom = ({
  setFile,
  borderRadiusImageDisplay = '50%',
  widthImageDisplay = '500px',
  heightImageDisplay = '500px',
  cutWidht = 500,
  cutHeight = 500,
  cutBorderRadius = 250,
  zIndez = '9999',
  imageUrl,
  buttonUploadRef,
  dataFile,
  radius = '25px',
}) => {
  const dropzoneStyle = {
    width: widthImageDisplay,
    height: heightImageDisplay,
    border: '2px dashed #ccc',
    borderRadius: borderRadiusImageDisplay,
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: zIndez,
    display: 'flex',
  };
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [zoom, setZoom] = useState(1.0);
  const [openModal, setOpenModal] = useState(false);
  const [savedImageUrl, setSavedImageUrl] = useState(null);
  const { deactivateScrollModalWithContent } = useContext(BaseLayoutContext);

  useEffect(() => {
    deactivateScrollModalWithContent(openModal);
  }, [openModal]);

  useEffect(() => {
    if (dataFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: dataFile.type });
        const file = new File([blob], dataFile.name, { type: dataFile.type });
        setFile(file);
        setSavedImageUrl(URL.createObjectURL(blob));
      };
      reader.readAsArrayBuffer(dataFile);
    }
  }, [dataFile]);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setImage(file);
      setOpenModal(true);
    }
  };

  const hasImage = () => {
    return Boolean(imageUrl) || Boolean(savedImageUrl);
  };

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const handleSave = (e) => {
    e && e.preventDefault();
    if (editor) {
      const { props } = editor;
      const canvas = editor.getImage();
      canvas.toBlob((blob) => {
        const file = new File([blob], props?.image?.name, {
          type: props?.image?.type,
        });
        setFile(file);
        setSavedImageUrl(URL.createObjectURL(blob));
      }, props?.image?.type);
      setOpenModal(false);
      setZoom(1.0);
    }
  };

  const handleCancel = (e) => {
    e && e.preventDefault();
    setImage(null);
    setOpenModal(false);
    setZoom(1.0);
  };

  return (
    <div style={{ zIndex: zIndez }}>
      {!openModal && (
        <DivInsertImg>
          {!hasImage() ? (
            <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} />
                  <TextInput>
                    {buttonUploadRef ? (
                      <img src={Gallery} />
                    ) : (
                      'Arraste e solte uma imagem aqui ou clique para selecionar.'
                    )}
                  </TextInput>
                </div>
              )}
            </Dropzone>
          ) : (
            <ImgUrl
              src={savedImageUrl || imageUrl}
              width={widthImageDisplay}
              height={heightImageDisplay}
              radius={borderRadiusImageDisplay}
            />
          )}
          <div>
            <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    width: '100%',
                    height: '35px',
                    borderRadius: '25px',
                  }}
                >
                  <input
                    type="file"
                    {...getInputProps()}
                    assignUploadRef={
                      (buttonUploadRef &&
                        (buttonUploadRef.current = getRootProps())) ||
                      null
                    }
                  />

                  <EditImg
                    radius={radius}
                    style={{
                      visibility: buttonUploadRef ? 'hidden' : 'visible',
                    }}
                  >
                    {`${hasImage() ? 'Alterar' : 'Inserir'} Imagem`}
                  </EditImg>
                </div>
              )}
            </Dropzone>
          </div>
        </DivInsertImg>
      )}

      {image && openModal && (
        <ModalWrapper>
          <FragmentModal>
            <Title>Editar foto</Title>
            <FrangmentAvatar>
              <AvatarEditor
                style={{ zIndex: zIndez }}
                ref={(editorRef) => setEditor(editorRef)}
                image={image}
                width={cutWidht}
                height={cutHeight}
                border={0}
                borderRadius={cutBorderRadius}
                color={[0, 0, 0, 0.5]}
                scale={zoom}
                rotate={0}
              />
            </FrangmentAvatar>
            <Actions>
              <FragmentZoom>
                <Row>
                  <LabelZomm>Ajuste de zoom:</LabelZomm>
                  <InputZomm
                    type="range"
                    min="1"
                    max="2"
                    step="0.01"
                    value={zoom}
                    onChange={(e) =>
                      handleZoomChange(parseFloat(e.target.value))
                    }
                  />
                </Row>
                <Text>
                  Caso queira, ajuste o zoom para deixar sua foto mais próxima.
                </Text>
              </FragmentZoom>
              <Buttons>
                <Button
                  background={'#fff'}
                  color={'#009291'}
                  onClick={(e) => handleCancel(e)}
                >
                  Cancelar
                </Button>

                <Button
                  background={'#009291'}
                  color={'#fff'}
                  onClick={(e) => handleSave(e)}
                >
                  Concluir
                </Button>
              </Buttons>
            </Actions>

            <DivAlterImg>
              <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      width: '100%',
                      height: '30px',
                      border: '2px dashed #ccc',
                      borderRadius: '5px',
                      padding: '1rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      zIndex: zIndez,
                      display: 'flex',
                    }}
                  >
                    <input {...getInputProps()} />
                    <TextInput>
                      Clique aqui ou arraste uma nova imagem para substituir
                    </TextInput>
                  </div>
                )}
              </Dropzone>
            </DivAlterImg>
          </FragmentModal>
        </ModalWrapper>
      )}
    </div>
  );
};

export default UploadedAvatarCustom;
