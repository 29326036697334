import React, { useEffect, useRef, useContext } from 'react';

import classnames from 'classnames';
import { usePagination, DOTS } from './hook/usePagination';

import {
  Container,
  PaginationContainer,
  PaginationItem,
  PaginationDots,
  selectPagination,
  Visualization,
} from './style';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import BaseLayoutContext from 'contexts/base-layout';

const Pagination = (props) => {
  const {
    classes,
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    className,
    setLimit,
    showLimit = true,
    pageRef,
    limit,
    setCurrentPage,
    paginationItensValue,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize: limit,
  });
  const { universityColor } = useContext(BaseLayoutContext);
  const onNext = async () => {
    scrollToTop();
    await onPageChange(currentPage + 1);
  };

  const onPrevious = async () => {
    scrollToTop();
    await onPageChange(currentPage - 1);
  };

  const selectPagination = async (e) => {
    if (e.target.value) {
      await setLimit(e.target.value);
      scrollToTop();
      setCurrentPage(1);
    }
  };

  const scrollToTop = () => {
    if (pageRef) {
      pageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  const showPagination = currentPage === 0 || paginationRange.length < 2;

  return (
    <Container>
      <div className="wrapper">
        <Visualization visible={showPagination}>
          <PaginationContainer
            className={classnames({ [className]: className })}
          >
            <PaginationItem
              className={classnames({ disabled: currentPage === 1 })}
              universityColor={universityColor}
              onClick={onPrevious}
            >
              <div className="arrow left" />
            </PaginationItem>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <PaginationItem
                    universityColor={universityColor}
                    className="dots"
                  >
                    &#8230;
                  </PaginationItem>
                );
              }

              return (
                <PaginationDots
                  className={classnames({
                    selected: pageNumber === currentPage,
                  })}
                  universityColor={universityColor}
                  onClick={async () => {
                    scrollToTop();
                    await onPageChange(pageNumber);
                  }}
                >
                  {pageNumber}
                </PaginationDots>
              );
            })}
            <PaginationItem
              className={classnames({ disabled: currentPage === lastPage })}
              universityColor={universityColor}
              onClick={onNext}
            >
              <div className="arrow right" />
            </PaginationItem>
          </PaginationContainer>
        </Visualization>

        {showLimit && (
          <FormControl
            variant="outlined"
            classes={classes.formControl}
            style={{ position: 'absolute', right: 0, top: '7px' }}
            className={'formControl'}
          >
            <InputLabel
              htmlFor="outlined-age-native-simple"
              className={classes.selectLabel}
              classes={{
                root: classes.cssLabel,
                focused: classes.cssFocused,
              }}
            >
              Itens por página
            </InputLabel>
            <Select
              value={limit}
              fullWidth
              className={classes.selectInput}
              onChange={selectPagination}
              input={
                <OutlinedInput
                  name="pageSelect"
                  labelWidth={120}
                  id="page-select"
                />
              }
            >
              {paginationItensValue?.length > 0
                ? paginationItensValue.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))
                : [10, 20, 50, 100].map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
            </Select>
          </FormControl>
        )}
      </div>
    </Container>
  );
};

export default withStyles(selectPagination)(Pagination);
