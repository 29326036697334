import MentorshipTile from '../MentorshipTile';
import React from 'react';
import { Container, AbandonedCourses, Content, Divider } from './styles';
import iconAttention from 'assets/JobOffer/attention.svg';

export const MentorshipList = ({
  mentorships,
  openSnackbar,
  openModalWithContent,
  handleIsLoadingState,
  closeModal,
  updateHasCoverLetter,
  activeTab,
}) => {
  return (
    <Container>
      {mentorships.map((mentorship, index) => (
        <Content>
          {activeTab === 2 && mentorship.student_abandoned && (
            <AbandonedCourses>
              <img src={iconAttention} alt="" />
              <span> Estudante abandonado/evadido </span>
            </AbandonedCourses>
          )}
          <MentorshipTile
            key={mentorship.id || index}
            mentorship={mentorship}
            openSnackbar={openSnackbar}
            openModalWithContent={openModalWithContent}
            handleIsLoadingState={handleIsLoadingState}
            closeModal={closeModal}
            updateHasCoverLetter={updateHasCoverLetter}
          />
          <Divider></Divider>
        </Content>
      ))}
    </Container>
  );
};
