import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ContainerPdf, DownloadButton } from './style';
import Axios from 'axios';

export default function GenericPDFViewer({
  file,
  name,
  universityColor,
  download=false,
  title='FAZER DOWNLOAD',
}) {
  const [numOfPages, setNumOfPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  const GenerateBlob = (url) => {
    const blobResponse = Axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
      .then((response) => {
        return window.URL.createObjectURL(new Blob([response.data]));
      })
      .catch((error) => error);

    return blobResponse;
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const generatePages = () => {
    const array = Array.from({ length: numOfPages }, (_, index) => (
      <Page pageNumber={index + 1} />
    ));
    return array;
  };

  const handleClickDownload = async () => {
    const link = await GenerateBlob(file);
    const downloadLink = document.createElement('a');
    downloadLink.href = link;
    downloadLink.download = `${name}`;
    downloadLink.click();
  };

  return (
    <ContainerPdf>
      {download && (
        <DownloadButton
          universityColor={universityColor}
          onClick={handleClickDownload}
        >
          {title}
        </DownloadButton>
      )}
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {generatePages()}
      </Document>
    </ContainerPdf>
  );
}
