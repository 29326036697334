import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 730px;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  min-height: 350px;
  max-height: auto;
  height: auto;
`;

export const Header = styled.div`
  background-color: #009291;
  height: 90px;
  width: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const InsideInformations = styled.div`
  margin-top: 30px;
  padding: 0px 60px;

  @media (max-width: 380px) {
    padding: 0px 30px;
  }
`;

export const ContainerMiddleInformations = styled.div`
  margin-top: 30px;
  max-height: 170px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    max-height: unset;
    height: auto;
  }

  > div {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    > span {
      line-height: 1.2;

      max-width: 300px;
      max-height: 60px;
      overflow-y: auto;

      > h6 {
        margin: 0px;
        font-weight: 500;
        color: #606062;
        text-transform: none;
        font-size: 13px;
      }
    }
  }
`;

export const ToDateHeader = styled.h4`
  color: #009291;
`;

export const CreateByAndDate = styled.div`
  margin-top: 20px;

  > span {
    color: #949494;
    font-size: 13px;
    margin-bottom: 5px;
  }

  > h4 {
    color: #606062;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 6px;
  }

  > p {
    color: #606062;
    font-size: 13px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  margin: 30px 0px;
`;

export const ContainerBottomInformations = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const ContainerFeedBacks = styled.div`
  display: flex;
  gap: 12px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const H4Header = styled.h4`
  color: #606062;
  font-weight: bold;
`;

export const ContainerInformationActivities = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InformationActivities = styled.div`
  width: fit-content;
  border: 1px solid #606062;
  height: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 18px;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerBottomActions = styled.div`
  padding: 0px 50px;
`;

export const SpanAll = styled.span`
  color: #009291;
  font-weight: 600;
`;
