import React, { useEffect, useState, useRef, useContext } from 'react';
import { object, shape, func } from 'prop-types';

import { defaultButton } from 'assets/jss/material-dashboard-pro-react';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import { ButtonSaveCoverLetterAndCurriculum } from '../../views/MyProfile/components/Styled';
import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';
import withStyles from '@material-ui/core/styles/withStyles';
import BaseLayoutContext from 'contexts/base-layout';
import Curriculum from './Curriculum';
import { triggerEvent } from '../../utils/analytics';

import {
  dnaGreen,
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  lightBlueIE,
  orangeIE,
  blueIE,
} from 'assets/jss/material-dashboard-pro-react.jsx';
import useDefineColor from 'constants/colors';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = (theme) => ({
  defaultButton: {
    ...defaultButton,
  },
  buttonPrint: {
    ...defaultButton,
    marginTop: 20,
    color: '#009291',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  container: {
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  content: {
    paddingLeft: 65,
  },
  menuContainer: {
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  curriculumMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: 20,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  page: {
    marginBottom: 20,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  loading: {
    margin: 20,
    color: '#009291',
  },
  dnaColorLoading: {
    margin: 20,
    color: dnaGreen,
  },
  yellowIESLoading: {
    margin: 20,
    color: yellowIE,
  },
  redIESLoading: {
    margin: 20,
    color: redIE,
  },
  greenIESLoading: {
    margin: 20,
    color: greenIE,
  },
  darkBlueIESLoading: {
    margin: 20,
    color: darkBlueIE,
  },
  lightBlueIESLoading: {
    margin: 20,
    color: lightBlueIE,
  },
  orangeIESLoading: {
    margin: 20,
    color: orangeIE,
  },
  blueIESLoading: {
    margin: 20,
    color: blueIE,
  },
  stickContainer: {
    position: 'sticky !important',
    top: 20,
  },
});

const CurriculumContainer = (props) => {
  const [ready, setReady] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfPages, setPdfPages] = useState(0);
  const { universityColor, metadata } = useContext(BaseLayoutContext);
  const documentDiv = useRef();

  const {
    classes,
    profile,
    applicationReturn,
    handleIsLoadingState,
    like,
    rating,
    role,
    showSpecialNeeds,
    showColorOrRace,
    showGenderIdentity,
    specialNeeds,
  } = props;

  useEffect(() => {
    setReady(!ready);
    return () => {
      setReady(false);
      setPdfLink(null);
      setPdfPages(null);
    };
  }, []);

  const loadPages = () => {
    const pages = [];
    for (let i = 1; i <= pdfPages; i++) {
      pages.push(
        <Page
          width={documentDiv.current.offsetWidth}
          height={documentDiv.current.offsetHeight}
          className={classes.page}
          pageNumber={i}
        />
      );
    }

    return pages;
  };

  return (
    <Grid container>
      <Grid item xs md={12}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={12} md={8}>
            <div ref={documentDiv}>
              {ready && (
                <BlobProvider
                  document={
                    <Curriculum
                      universityColor={universityColor}
                      profile={profile}
                      showSpecialNeeds={showSpecialNeeds}
                      showColorOrRace={showColorOrRace}
                      showGenderIdentity={showGenderIdentity}
                      specialNeeds={specialNeeds}
                      {...props}
                    />
                  }
                >
                  {({ blob, url, loading, error }) =>
                    !loading ? (
                      <Document
                        className={classes.document}
                        file={url}
                        loading={
                          <CircularProgress
                            color={universityColor}
                            className={
                              classes[
                                `${useDefineColor(universityColor)}Loading`
                              ]
                            }
                          />
                        }
                        noData={null}
                        onLoadSuccess={(pdf) => {
                          handleIsLoadingState(false);
                          setPdfPages(pdf.numPages);
                          setPdfLink(url);
                        }}
                      >
                        {loadPages().map((page) => page)}
                      </Document>
                    ) : null
                  }
                </BlobProvider>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={classes.menuContainer}>
            <div className={classes.stickContainer}>
              <div className={classes.curriculumMenu}>
                <a href={pdfLink} download={`Currículo ${profile.name}`}>
                  <ButtonSaveCoverLetterAndCurriculum
                    style={{ width: '200px' }}
                    universityColor={universityColor}
                    onClick={() => {
                      triggerEvent('click', 'Baixar Currículo', metadata.role);
                    }}
                  >
                    Baixar currículo
                  </ButtonSaveCoverLetterAndCurriculum>
                </a>
                <Button
                  style={{
                    width: '200px',
                    borderRadius: '5px',
                    color: universityColor,
                  }}
                  onClick={() => {
                    const win = window.open(pdfLink);
                    win.print();
                  }}
                  className={classes.buttonPrint}
                >
                  Imprimir
                </Button>
              </div>
              {applicationReturn}
              {like}
              {rating}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CurriculumContainer.propTypes = {
  classes: shape(object).isRequired,
  handleIsLoadingState: func.isRequired,
  profile: shape.isRequired,
  applicationReturn: func,
  rating: func,
  like: func,
};

CurriculumContainer.defaultProps = {
  applicationReturn: null,
};

export default withStyles(styles)(CurriculumContainer);
