import React, { useState, useEffect } from 'react';
import {
  Body,
  Header,
  ModalContent,
  Card,
  CustomContainer,
  CustomBox,
} from './style';

import Button from 'components/CustomButtons/Button';

import SkeletonJobsCard from 'views/StaffPages/jobOpeningsRebuild/components/JobsSkeleton';
import CloseIcon from '@material-ui/icons/Close';
import {
  getStudentIndications,
  getNextResults,
} from '../../../services/IndicationStudent.service';
import IndicationJobsCards from '../../IndicationJobCards';

export default function IndicationJobsModal(props) {
  let { isOpen, closeModalFunc, student } = props;
  const [listJobs, setListJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextUrl, setNextUrl] = useState(null);

  useEffect(() => {
    loadJobsData();
  }, []);

  const loadJobsData = async () => {
    try {
      const page = `limit=6&offset=0`;
      const data = await getStudentIndications(student.id, page);
      const { results, next } = data;
      setNextUrl(next);
      setListJobs(results);
      setLoading(false);
    } catch (e) {
      setListJobs([]);
    }
  };

  const loadNext = async () => {
    const data = await getNextResults(nextUrl);
    const { results, next } = data;
    setNextUrl(next);
    setListJobs([...listJobs, ...results]);
  };

  return (
    <CustomContainer isOpen={isOpen}>
      <CustomBox>
        <ModalContent>
          <Header>
            <button onClick={closeModalFunc}>
              <CloseIcon />
            </button>
          </Header>
          <Body>
            {loading ? (
              <SkeletonJobsCard quantity={2} />
            ) : (
              listJobs.map((job) => <IndicationJobsCards job={job} closeModalFunc={closeModalFunc} />)
            )}
            {!!nextUrl && (
              <Button
                style={{
                  width: '112px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
                onClick={loadNext}
              >
                Ver mais
              </Button>
            )}
          </Body>
        </ModalContent>
      </CustomBox>
    </CustomContainer>
  );
}
