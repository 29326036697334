const defineUrlPublicProfile = (slug=null) => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:3002/perfil';
  }

  if (hostname.includes('worka.local')) {
    return `http://${slug ?? 'workability'}.worka.local/perfil`;
  }

  if (hostname.includes('.dev')) {
    return `https://${slug ?? 'workability'}.dev.worka.love/perfil`;
  }

  if (hostname.includes('.stg')) {
    return `https://${slug ?? 'workability'}.stg.worka.love/perfil`;
  }

  if (hostname.includes('.hmg')) {
    return `https://${slug ?? 'workability'}.hmg.worka.love/perfil`;
  }

  return `https://${slug ?? 'workability'}.worka.love/perfil`;
};

export default defineUrlPublicProfile;
