import {
  BackButton,
  Container,
  Content,
  HeaderContent,
  HeaderModal,
  ContainerCheckbox,
  SearchAndSelection,
  customStyles,
  selectTheme,
  FilterStyled,
  ContainerCards,
  CallToDownloads,
} from './Styles/BaseComponent';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { useRef, useEffect, useState } from 'react';
import { GenericInputSearch } from 'components/GenericInputSearch';
import Select from 'react-select';
import { FilterCandidates } from './UIComponents/Filter/FilterCandidates';
import { CardCandidates } from './UIComponents/Card';
import moment from 'moment';
import {
  isAllCandidatesSelected,
  loadJobsData,
  returnConfigModal,
  returnDownloadZippedCoverLetter,
  returnDownloadZippedCurriculum,
  returnOptionsFilter,
  sortCandidatesByCriterion,
  toggleSelectAll,
} from './Utils/mainFunctions';
import Tooltip from 'components/NewTooltip';
import ModalMatchInformation from 'views/JobOffers/components/Modals/ModalMatchInfo';
import ModalInformation from 'components/informationModal';
import { SkeletonCard } from './UIComponents/Skeletons/SkeletonCard';
import PaginationSeeMore from 'views/JobOffers/components/PaginationLoadMore';
import GenericMessage from 'components/GenericMessage';

export function EngineListCandidates({
  controlModal,
  setControlModal,
  jobs,
  isPartner,
  seeMatch,

  history,
  publishedJobs,

  company,
  university,
}) {
  const { name, number_of_candidates, id, created_at, is_external } = jobs;
  const ContainerRef = useRef();

  const [candidates, setCandidates] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [openModalMatch, setModalMatch] = useState(false);
  const [search, setSearch] = useState('');
  const [controlOpenDownloadModal, setControlDownloadModal] = useState(false);
  const [typeDownload, setTypeDownload] = useState('');
  const [controlLoading, setControlLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const conditionSeeMatch =
    (university || (company && seeMatch)) && is_external === false;

  let curriculumCounter = selectedCandidates?.filter(
    (candidates) => candidates.has_curriculum === true
  );
  let resumeCoverLetter = selectedCandidates?.filter(
    (candidates) => candidates.has_cover_letter === true
  );

  let environmentType = company ? 'company' : 'university';
  let selectedTab = history ? 'history' : 'publishedJobs';

  useEffect(() => {
    if (ContainerRef) {
      ContainerRef.current.scrollIntoView();
    }
  }, []);

  const [visibleItems, setVisibleItems] = useState(10);

  const handleSeeMore = () => {
    setVisibleItems((prev) => prev + 10);
  };

  useEffect(() => {
    loadJobsData(environmentType, id, isPartner, selectedTab, setCandidates);
  }, [jobs, company, university]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await returnOptionsFilter(
        environmentType,
        id,
        isPartner,
        selectedTab
      );
      setFilterOptions(response);
    };
    fetchOptions();
  }, [jobs]);

  const controlDownloadModal = (typeDownload) => {
    setControlDownloadModal(true);
    setTypeDownload(typeDownload);
  };

  const controlFunctionsDownloadZipped = async (typeDownload) => {
    if (typeDownload === 'curriculum') {
      await returnDownloadZippedCurriculum(
        selectedCandidates,
        environmentType,
        setControlDownloadModal,
        setControlLoading
      );
    }

    if (typeDownload === 'coverLetter') {
      await returnDownloadZippedCoverLetter(
        selectedCandidates,
        environmentType,
        setControlDownloadModal,
        setControlLoading
      );
    }
  };

  const options = [
    { value: 'matchPercentage', label: 'Percentual de Match' },
    { value: 'mostRated', label: 'Mais bem avaliados' },
    { value: 'markedAsInteresting', label: 'Marcados como interessantes' },
    { value: 'notEvaluated', label: 'Não avaliados' },
  ];

  const filteredOptions = is_external
    ? options.filter((option) => option.value !== 'matchPercentage')
    : options;

  const filteredCandidates = candidates?.filter((candidate) => {
    const lowercaseSearch = search.toLowerCase();
    const lowercaseStudentName = candidate.student.name.toLowerCase();
    return lowercaseSearch === ''
      ? candidate
      : lowercaseStudentName.includes(lowercaseSearch);
  });

  const returnCardCandidates = (candidates, visibleItems) => {
    if (candidates === null) {
      return [1, 2, 3].map(() => <SkeletonCard matchView={true} />);
    }

    if (candidates?.length === 0) {
      return (
        <GenericMessage
          title="Nenhum candidato encontrado."
          subtitle="Nenhum candidato foi encontrado."
        />
      );
    }

    if (candidates?.length >= 1) {
      return filteredCandidates?.slice(0, visibleItems)
      .sort((a, b) =>
        a.is_indication && !b.is_indication ? -1 : 0
      )
      .map((candidate) => (
        <div key={candidate}>
          <CardCandidates
            jobId={id}
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            candidate={candidate}
            history={history}
            publishedJobs={publishedJobs}
            university={university}
            company={company}
            isPartner={isPartner}
            setCandidates={setCandidates}
            conditionSeeMatch={conditionSeeMatch}
          />
        </div>
      ));
    }
  };

  const returnCandidates = () => {
    return number_of_candidates === 1
      ? `${number_of_candidates} candidatura`
      : `${number_of_candidates} candidaturas`;
  };

  const returnTopButtons = () => {
    if (selectedCandidates?.length >= 1) {
      return (
        <div className="right-side">
          {curriculumCounter?.length >= 1 && (
            <CallToDownloads onClick={() => controlDownloadModal('curriculum')}>
              Baixar currículos ({curriculumCounter?.length})
            </CallToDownloads>
          )}
          {resumeCoverLetter?.length >= 1 && (
            <CallToDownloads
              outlined
              onClick={() => controlDownloadModal('coverLetter')}
            >
              Baixar cartas de apresentação ({resumeCoverLetter?.length})
            </CallToDownloads>
          )}
        </div>
      );
    }
    return (
      <div className="right-side">
        <Select
          name="sortCandidates"
          onChange={(e) =>
            sortCandidatesByCriterion(e, candidates, setCandidates)
          }
          options={filteredOptions}
          theme={selectTheme}
          placeholder="Ordenar por"
          styles={customStyles}
        />
        <FilterStyled>
          <FilterCandidates
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setCandidates={setCandidates}
            candidates={candidates}
            environmentType={environmentType}
            jobId={id}
            isPartner={isPartner}
            selectedTab={selectedTab}
            seeMatch={seeMatch}
            isExternal={is_external}
            company={company}
            university={university}
          />
        </FilterStyled>
      </div>
    );
  };

  return (
    <Container ref={ContainerRef} isOpen={controlModal}>
      {openModalMatch && (
        <ModalMatchInformation setOpenModalMatch={setModalMatch} />
      )}
      <HeaderModal>
        <BackButton onClick={() => setControlModal(false)}>
          <IoIosArrowBack color="#009291" size={25} />
          <p>Voltar</p>
        </BackButton>
        {conditionSeeMatch ? (
          <button
            className="understand-the-match"
            onClick={() => setModalMatch(true)}
          >
            <IoIosInformationCircleOutline size={20} color="#009291" />
            Entenda o Match
          </button>
        ) : null}
      </HeaderModal>
      {controlOpenDownloadModal && (
        <ModalInformation
          modalOpenState={controlOpenDownloadModal}
          buttonConfirmFunction={() =>
            controlFunctionsDownloadZipped(typeDownload)
          }
          buttonCancelFunction={() => setControlDownloadModal(false)}
          config={returnConfigModal(
            selectedCandidates,
            typeDownload,
            controlLoading
          )}
        />
      )}

      <Content>
        <HeaderContent>
          <div className="application-counter-tag">{returnCandidates()}</div>
          <div className="job-code">
            <span>CÓDIGO DA VAGA: {id}</span>
          </div>
          <div className="job-name">
            <h3>{name}</h3>
          </div>
          <div className="vacancy-publication">
            <span>{`Publicada em ${moment(created_at).format('LL')}`}</span>
          </div>
        </HeaderContent>
        <SearchAndSelection>
          <div className="left-side">
            <Tooltip direction="top" colorSchema="black" text="Selecionar tudo">
              <ContainerCheckbox htmlFor="checkAll">
                <input
                  type="checkbox"
                  id="checkAll"
                  checked={isAllCandidatesSelected(
                    selectedCandidates,
                    candidates
                  )}
                  onChange={() =>
                    toggleSelectAll(
                      selectedCandidates,
                      setSelectedCandidates,
                      candidates
                    )
                  }
                />
              </ContainerCheckbox>
            </Tooltip>
            <GenericInputSearch
              placeholder="Buscar"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {returnTopButtons()}
        </SearchAndSelection>
        <ContainerCards>
          {returnCardCandidates(candidates, visibleItems)}

          <PaginationSeeMore
            onClick={() => handleSeeMore()}
            renderedItems={returnCardCandidates(candidates, visibleItems)}
            data={candidates}
            filled
          />
        </ContainerCards>
      </Content>
    </Container>
  );
}
