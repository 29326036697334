import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Container,
  DropdownIcon,
  Header,
  ItemIcon,
  List,
  ListItem,
  PeriodIcon,
  PeriodsList,
} from './styles';
import { useMentorship } from '../../../contexts/MentorshipContext';
import { useField } from '@unform/core';
import BaseLayoutContext from 'contexts/base-layout';
import MorningIcon from 'assets/ComponentIcons/MorningIcon';
import AfternoonIcon from 'assets/ComponentIcons/Afternoon';
import EveningIcon from 'assets/ComponentIcons/Evening';

export default function PeriodSelector({ isDaySelected, day }) {
  const { options, getSelectedPeriods } = useMentorship();
  const [periods, setPeriods] = useState([]);
  const ref = useRef(null);
  const periodsRef = useRef([]);
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  const { fieldName, registerField } = useField('favorite_periods');

  const { universityColor } = useContext(BaseLayoutContext);

  const isPeriodSelected = useCallback(
    (period) => {
      return periods.includes(period);
    },
    [periods]
  );

  function togglePeriod(period) {
    if (isPeriodSelected(period)) {
      setPeriods(periods.filter((p) => p !== period));
    } else {
      setPeriods([...periods, period]);
    }
  }

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setPeriods(getSelectedPeriods(day));
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => {
        return periods;
      },
      clearValue: () => {
        setPeriods([]);
      },
      setValue: (ref, value) => {
        setPeriods(value);
      },
    });
  }, [fieldName, registerField, periods, isDaySelected]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.split(' ').includes('no-outside')
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const togglePeriods = (event) => {
    if (!periodsRef.current) {
      return;
    }
    if (periodsRef.current.length === 0) {
      setOpen(true);
    } else if (
      !open ||
      (!periodsRef.current[0].contains(event.target) &&
        !periodsRef.current[1].contains(event.target) &&
        !periodsRef.current[2].contains(event.target))
    ) {
      toggle();
    }
  };

  const getIcon = (period, selected) => {
    switch (period) {
      case 'morning':
        return <MorningIcon color={selected ? universityColor : '#4B4B4D'} />;
      case 'afternoon':
        return <AfternoonIcon color={selected ? universityColor : '#4B4B4D'} />;
      default:
        return <EveningIcon color={selected ? universityColor : '#4B4B4D'} />;
    }
  };

  return (
    <Container ref={ref} isDaySelected={isDaySelected} type="button">
      <Header onClick={togglePeriods}>
        {open || periods.length === 0 ? (
          <p>período</p>
        ) : (
          <PeriodsList className="no-outside">
            {periods.map((period) => (
              <PeriodIcon period={period} key={period} className="no-outside" />
            ))}
          </PeriodsList>
        )}
        <DropdownIcon open={open} />
      </Header>
      {open && (
        <List>
          {options.periods.map((period, index) => (
            <ListItem
              universityColor={universityColor}
              ref={(el) => (periodsRef.current[index] = el)}
              key={period.value}
              onClick={() => togglePeriod(period.value)}
              selected={isPeriodSelected(period.value)}
              type="button"
            >
              <ItemIcon>
                {getIcon(period.value, isPeriodSelected(period.value))}
              </ItemIcon>
              <span>{period.label}</span>
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  );
}
